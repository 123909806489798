import useAxiosRequest from '@/hooks/useAxiosRequest'
import useQuery from '@/hooks/useQuery'
import animals from '@/services/api/animals'
import PropTypes from 'prop-types'
import React from 'react'
import Avatar from '../../Avatar'
import Flexbox from '../../Flexbox'
import Form from '../../Form'
import ListManager from '../../ListManager'
import Mask from '../../Mask'
import Paginator from '../../Paginator'
import QuickFilter from '../../QuickFilter'

export default function AllAnimals({ onChange }) {
  const query = useQuery()

  const { data, error, loading } = useAxiosRequest(
    animals.list, { ...(query || {}), take: 5 },
  )

  return (
    <div className="mt-2">
      <QuickFilter
        initialValues={{
          animalName: '',
          animalChip: '',
          tutorName: '',
          tutorEmail: '',
          tutorDocumentNumber: '',
        }}
        queryAttribute="q"
        title="Filtrar pets"
        renderForm={({ setFieldValue }) => (
          <>
            <Flexbox.Column className="col-4 mt-2">
              <Form.Group>
                <Form.Label className="label-sm pt-2" htmlFor="animalName">Nome do pet</Form.Label>
                <Form.ValidationField
                  name="animalName"
                  className="input-sm"
                  id="animalName"
                  autoComplete="none"
                  placeholder="Totó da Silva"
                />
              </Form.Group>
            </Flexbox.Column>

            <Flexbox.Column className="col-4 mt-2">
              <Form.Group>
                <Form.Label className="label-sm pt-2" htmlFor="animalChip">Chip do pet</Form.Label>
                <Form.ValidationField
                  name="animalChip"
                  className="input-sm"
                  id="animalChip"
                  autoComplete="none"
                  placeholder="Código do chip"
                />
              </Form.Group>
            </Flexbox.Column>
            <Flexbox.Column className="col-4 mt-2">
              <Form.Group>
                <Form.Label className="label-sm pt-2" htmlFor="tutorName">Nome do tutor</Form.Label>
                <Form.ValidationField
                  name="tutorName"
                  className="input-sm"
                  id="tutorName"
                  autoComplete="none"
                  placeholder="Fulano da Silva"
                />
              </Form.Group>
            </Flexbox.Column>
            <Flexbox.Column className="col-4 mt-2">
              <Form.Group>
                <Form.Label className="label-sm pt-2" htmlFor="tutorEmail">E-mail do tutor</Form.Label>
                <Form.ValidationField
                  name="tutorEmail"
                  type="email"
                  className="input-sm"
                  id="tutorEmail"
                  autoComplete="none"
                  placeholder="fulano@email.com"
                />
              </Form.Group>
            </Flexbox.Column>
            <Flexbox.Column className="col-4 mt-2">
              <Form.Group>
                <Form.Label className="label-sm pt-2" htmlFor="tutorDocumentNumber">CPF/CNPJ do tutor</Form.Label>
                <Form.ValidationField name="tutorDocumentNumber">
                  {
                    ({ field }) => (
                      <Mask.CpfCnpj
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        className="input-sm"
                        placeholder="CPF/CNPJ do tutor"
                        id="tutorDocumentNumber"
                        onAccept={(value) => {
                          setFieldValue('tutorDocumentNumber', value)
                        }}
                      />
                    )
                  }
                </Form.ValidationField>
              </Form.Group>
            </Flexbox.Column>
          </>
        )}
      />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        {
          Boolean(data) && (
            <div>
              <small>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Pet</th>
                      <th>Tutor</th>
                      <th width="1">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.rows.map((row) => (
                        <tr key={row.id}>
                          <td>
                            <div>
                              <Avatar
                                avatarSize="sm"
                                className="mr-1"
                                name={row?.name}
                                photoURL={row?.photoURL}
                                alt="Imagem do pet"
                              />
                              {row?.name}
                            </div>
                            <div>
                              <small>
                                {row.category.description} - {row.breed.description}
                              </small>
                            </div>
                          </td>
                          <td>
                            <div>
                              <Avatar
                                avatarSize="sm"
                                className="mr-1"
                                name={row?.tutor?.fullName}
                                photoURL={row?.tutor?.photoURL}
                                alt="Imagem do pet"
                              />
                              {row?.tutor?.fullName}
                            </div>
                          </td>
                          <td className="text-right">
                            <a
                              onClick={() => onChange(row)}
                              disabled={row === row.id}
                              className={`btn btn-action btn-primary btn-sm ${row === row.id ? 'loading' : ''}`}
                            >
                              <i className="fas fa-check" />
                            </a>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </small>
              <Paginator paginatedList={data} />
            </div>
          )
        }
      </ListManager>
    </div>
  )
}

AllAnimals.propTypes = {
  onChange: PropTypes.func.isRequired,
}

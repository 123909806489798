import {
  Flexbox, LoadManager, PageHeader, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import petisUsers from '@/services/api/petisUsers'
import React from 'react'
import { useParams } from 'react-router-dom'
import AnimalsCard from './components/AnimalsCard'
import GovBrCard from './components/GovBrCard'
import LicensingCard from './components/LicensingCard'
import LowIncomeCard from './components/LowIncomeCard'
import PetisUserCard from './components/PetisUserCard'

function Show({ title }) {
  usePageTitle({ title })
  const { id } = useParams()
  const {
    data, loading, error, setData,
  } = useAxiosRequest(petisUsers.show, id)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader title="Detalhes do usuário" icon="fas fa-users" subtitle="Histórico completo do usuário" />
      <LoadManager loading={loading} error={error}>
        {
          Boolean(data) && (
            <>
              <Flexbox.Columns>
                <Flexbox.Column className="col-12 col-md-12 my-2">
                  <PetisUserCard
                    title="Dados pessoais do usuário"
                    person={data?.person}
                    isNgo={data?.licensing?.some((licensing) => licensing.type === 'ngo' && licensing.status === 'approved')}
                  />
                </Flexbox.Column>
                {
                  data?.person?.personGovBr && (
                    <Flexbox.Column className="col-12 my-2">
                      <GovBrCard personGovBr={data?.person?.personGovBr} />
                    </Flexbox.Column>
                  )
                }
                <Flexbox.Column className="col-12 my-2">
                  <LowIncomeCard
                    lowIncome={data?.lowIncome}
                    onUpdate={(lowIncome) => setData({ ...data, lowIncome })}
                  />
                </Flexbox.Column>
                {
                  data?.licensing?.length ? (
                    <Flexbox.Column className="col-12 col-md-12 my-2">
                      <LicensingCard licensing={data?.licensing} />
                    </Flexbox.Column>
                  ) : null
                }
                <Flexbox.Column className="col-12 my-2">
                  <AnimalsCard adoptions={data?.adoptions} />
                </Flexbox.Column>
              </Flexbox.Columns>
            </>
          )
        }
      </LoadManager>
    </Styles.Container>
  )
}

export default Show

import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Flexbox } from '@/components'
import dateMask from '@/lib/masks/date'

function Approved({ license }) {
  return (
    <>
      <Flexbox.Columns className="align-center col-gapless pb-2">
        <Flexbox.Column className="col-auto">
          <div className="text-small">Licença aprovada em</div>
          {
            license?.approvedAt ? (
              <span>
                <span className="text-bold">
                  {dateMask(license?.approvedAt, { dateStyle: 'full' })}&nbsp;
                </span>
                <small className="label label-sm label-rounded">
                  <i className="far fa-clock" />&nbsp;
                  {new Date(license?.approvedAt).toLocaleTimeString()}
                </small>
              </span>
            ) : (
              <span className="text-gray text-small text-italic">Não há registro</span>
            )
          }
        </Flexbox.Column>
      </Flexbox.Columns>
      <Flexbox.Columns className="align-center col-gapless pb-2">
        <Flexbox.Column className="col-auto">
          <div className="text-small">Licença aprovada por</div>
          {
            license?.approvedBy ? (
              <>
                <Avatar
                  avatarSize="sm"
                  name={license?.approvedBy?.fullName}
                  photoURL={license?.approvedBy?.photoURL}
                  alt="Imagem de usuário"
                />
                <span className="text-bold pl-1">{license?.approvedBy?.fullName}</span>
              </>
            ) : (
              <span className="text-gray text-small text-italic">Não há registro</span>
            )
          }
        </Flexbox.Column>
      </Flexbox.Columns>
      <div className="divider" />
    </>
  )
}

Approved.propTypes = {
  license: PropTypes.objectOf(Object).isRequired,
}

export default Approved

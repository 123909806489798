import { LoadManager } from '@/components'
import useClininic from '@/hooks/useClinic'
import documentNumberMask from '@/lib/masks/documentNumber'
import { setClinic } from '@/reducers/clinic'
import clinics from '@/services/api/clinics'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

function Revoke() {
  const { id } = useParams()
  const history = useHistory()
  const [revoking, setRevoking] = useState(false)
  const { state: { clinic }, dispatch } = useClininic()

  async function revoke() {
    try {
      setRevoking(true)
      const { data } = await clinics.revoke(id)
      dispatch(setClinic(data))
      toast.success(`A licença de ${clinic?.licensed?.fullName} foi revogada`)
      history.goBack()
    } catch (e) {
      setRevoking(false)
      toast.error(e?.message)
    }
  }

  return (
    <div className="modal active" id="modal-id">
      <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
      <div className="modal-container">
        <div className="modal-header">
          <a
            type="button"
            className="btn btn-clear float-right"
            onClick={() => history.goBack()}
          />
          <div className="modal-title h5">Revogar clínica</div>
        </div>
        <div className="modal-body">
          <div className="content text-justify">
            <LoadManager loading={!clinic}>
              {
                clinic?.status === 'approved' ? (
                  <>
                    <p>
                      Deseja revogar a licença da clínica
                      <b className="text-primary"> {clinic?.licensed?.fullName} </b>
                      portadora do documento
                      <b className="text-primary"> {documentNumberMask(clinic?.licensed?.documentNumber)}</b>?
                    </p>
                    <p className="text-small">
                      Com isso, a clínica precisará
                      fazer uma nova solicitação caso queira se credenciar
                    </p>
                  </>
                )
                : <p>Este credenciamento não pode mais ser revogado</p>
              }
            </LoadManager>
          </div>
        </div>
        {
           clinic?.status === 'approved' && (
            <div className="modal-footer">
              <a
                className="btn mr-2"
                disabled={revoking}
                onClick={() => history.goBack()}
              >
                <i className="fas fa-chevron-left" />
                &nbsp;Voltar
              </a>
              <button
                className={`btn btn-error ${revoking ? 'loading' : ''}`}
                disabled={revoking}
                type="button"
                onClick={revoke}
              >
                <i className="fas fa-times" />
                &nbsp;Revogar
              </button>
            </div>
           )
        }
      </div>
    </div>
  )
}

export default Revoke

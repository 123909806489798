import {
  Card,
  Flexbox, LoadManager, PageHeader, Styles, UserCard,
} from '@/components'
import { singularStatusMapLabel } from '@/constants/castration'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import useCastration from '@/hooks/useCastration'
import usePageTitle from '@/hooks/usePageTitle'
import useReadTimelineItem from '@/hooks/useReadTimelineItem'
import dateMask from '@/lib/masks/date'
import { setCastration } from '@/reducers/castration'
import castrations from '@/services/api/castrations'
import React, { useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'
import formatAddress from '@/lib/formatters/address'
import Actions from './components/Actions'
import AnimalCard from './components/AnimalCard'
import CastrationCard from './components/CastrationCard'
import ClinicCard from './components/ClinicCard'
import Events from './components/Events'
import Messages from './components/Messages'
import RatingCard from './components/RatingCard'

function Show({ title }) {
  const { id } = useParams()
  usePageTitle({ title: `${title} #${id}` })
  const { data, loading, error } = useAxiosRequest(castrations.show, id)
  const { state: { castration }, dispatch } = useCastration()
  useReadTimelineItem()

  useEffect(() => {
    if (data) dispatch(setCastration(data))
  }, [data, dispatch])

  return (
    <Styles.Container className="grid-xl">
      <PageHeader title={`Castração #${id}`} icon="fas fa-cut" subtitle={`Código: ${castration?.uid}`} />
      <LoadManager loading={loading} error={error}>
        {
          Boolean(castration) && (
            <>
              <Actions castration={castration} />
              <div className="my-2">
                Situação: <b>{singularStatusMapLabel[castration?.status]}</b>
              </div>
              {
                castration?.scheduledTo && (
                  <div className="my-2">
                    Agendado para: <b>{dateMask(castration?.scheduledTo, { hour: '2-digit', minute: '2-digit' })}</b>
                  </div>
                )
              }
              {
                castration?.rejectionReason && (
                  <div className="my-2">
                    Motivo da rejeição: <b>{castration?.rejectionReason?.description}</b>
                  </div>
                )
              }
              <div className="divider" />
              {
                Boolean(castration.partnerBlacklist) && (
                  <>
                    <div className="toast toast-error">
                      <div className="h6 text-bold">Este usuário foi bloqueado</div>
                      <div className="pre">{castration.partnerBlacklist.description}</div>
                      <div className="pre mt-2 text-small">{castration.partnerBlacklist.info}</div>
                      <div className="mt-2 text-right">
                        <Link to={`/app/blacklist/${castration.partnerBlacklist.id}`} style={{ textDecoration: 'none' }}>
                          <i className="fas fa-eye" />
                          &nbsp;Exibir bloqueio
                        </Link>
                      </div>
                    </div>
                    <div className="divider" />
                  </>
                )
              }
              <Flexbox.Columns>
                <Flexbox.Column className="col-12 mb-2">
                  <h1 className="h5">Detalhes do solicitante</h1>
                </Flexbox.Column>
                <Flexbox.Column className="col-5 col-lg-6 col-sm-12 my-2">
                  <UserCard
                    title="Solicitante"
                    user={castration?.tutor}
                    lowIncome={castration?.lowIncome}
                    renderFooter={() => (
                      <Card.Footer>
                        {
                          Boolean(castration?.tutor?.address) && (
                            <>
                              <div className="divider" />
                              {formatAddress(castration?.tutor?.address)}<br />
                            </>
                          )
                        }
                        <div className="divider" />
                        <span className="chip">Castrações ativas: {castration?.countCastrationsByTutor}</span>

                      </Card.Footer>
                    )}
                  />
                </Flexbox.Column>
                <Flexbox.Column className="col-2 text-center hide-lg flex-centered">
                  <i className="fas fa-arrow-right fa-2x" />
                </Flexbox.Column>
                <Flexbox.Column className="col-5 col-lg-6 col-sm-12 my-2">
                  <AnimalCard animal={castration?.animal} castration={castration} />
                </Flexbox.Column>
              </Flexbox.Columns>
              <div className="divider" />
              <Flexbox.Columns>
                <Flexbox.Column className="col-12 mb-2">
                  <h1 className="h5">Detalhes da castração</h1>
                </Flexbox.Column>
                <Flexbox.Column className="col-5 col-lg-6 col-sm-12 my-2">
                  <CastrationCard castration={castration} />
                </Flexbox.Column>
                <Flexbox.Column className="col-2 text-center hide-lg flex-centered">
                  <i className="fas fa-arrow-right fa-2x" />
                </Flexbox.Column>
                <Flexbox.Column className="col-5 col-lg-6 col-sm-12 my-2">
                  <ClinicCard preApproved={castration?.status === 'preApproved'} clinic={castration?.clinic} />
                </Flexbox.Column>
              </Flexbox.Columns>
              {
                Boolean(castration?.rating) && (
                  <div className="mb-2 pb-2">
                    <div className="divider" />
                    <h1 className="h5">Avaliação do atendimento</h1>
                    <Flexbox.Columns>
                      <Flexbox.Column className="col-5 col-lg-6 col-sm-12">
                        <RatingCard rating={castration?.rating} />
                      </Flexbox.Column>
                    </Flexbox.Columns>
                  </div>
                )
              }
              <div className="divider" />
              <h1 className="h5 mb-2">Eventos da castração</h1>
              <Flexbox.Columns>
                <Flexbox.Column className="col-9 col-sm-12">
                  <Events castrationCity={castration?.city} events={castration?.events} />
                </Flexbox.Column>
              </Flexbox.Columns>
              <Messages castration={castration} />
            </>
          )
        }
      </LoadManager>
    </Styles.Container>
  )
}

export default Show

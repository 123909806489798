import api from '../api'

export default {
  current() {
    return api.get('/partner-licensings/:partnerLicensingId/settings/partner-licensings/current')
  },
  updateCurrent(body) {
    return api.put('/partner-licensings/:partnerLicensingId/settings/partner-licensings/current', body)
  },
}

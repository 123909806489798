import {
  Avatar, Flexbox, ItemCol, LoadManager,
} from '@/components'
import { Button } from '@/components/styles'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import rga from '@/lib/masks/rga'
import animalFairs from '@/services/api/animalFairs'
import React, { useCallback, useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import {
  Link, useHistory, useLocation, useParams,
} from 'react-router-dom'
import { toast } from 'react-toastify'
import QRCode from 'react-qr-code'
import envs from '@/constants/envs'

function Show({ title }) {
  usePageTitle({ title })
  const [removing, setRemoving] = useState(false)
  const { petId, id } = useParams()
  const history = useHistory()
  const location = useLocation()

  const {
    data, loading, error, fetchData,
  } = useAxiosRequest(animalFairs.show, petId)

  useEffect(() => {
    if (location.pathname) fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname])

  const onRemove = useCallback(async () => {
    try {
      setRemoving(true)
      await animalFairs.destroy(petId)
      toast.success('Animal removido da feira')
      history.goBack()
    } catch (e) {
      setRemoving(false)
      toast.error(e?.message)
    }
  }, [history, petId])

  const handleRemove = useCallback(async () => {
    confirmAlert({
      title: 'Remover pet',
      message: `Você está certo que deseja remover ${data?.animal?.name} da feira?`,
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim',
          className: 'bg-error',
          onClick: onRemove,
        },
      ],
    })
  }, [data?.animal?.name, onRemove])

  return (
    <div className="modal active">
      <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
      <div className="modal-container">
        <div className="modal-header">
          <a
            type="button"
            className="btn btn-clear float-right"
            onClick={() => history.goBack()}
          />
          <div className="modal-title h5">
            Detalhes do Pet
          </div>
        </div>
        <div className="modal-body">
          <LoadManager error={error} loading={loading}>
            {
              Boolean(data) && (
                <>
                  <Flexbox.Columns>
                    <Flexbox.Column className="col-auto">
                      <Avatar avatarSize="xl" name={data?.animal?.name} photoURL={data?.animal?.photoURL} />
                    </Flexbox.Column>
                    <Flexbox.Column>
                      <div className="h5">{data?.animal?.name}</div>
                      <div>
                        {data?.animal?.category?.description} - {data?.animal?.breed?.description}
                      </div>
                    </Flexbox.Column>
                  </Flexbox.Columns>
                  <Flexbox.Columns className="mt-2 pt-2">
                    <Flexbox.Column className="col-6">
                      <ItemCol label="RGA" value={rga(data?.animal?.uid)} />
                    </Flexbox.Column>
                    <Flexbox.Column className="col-6">
                      <ItemCol label="Adotado?" value={data?.adopted ? 'Sim' : 'Não'} />
                    </Flexbox.Column>
                  </Flexbox.Columns>
                  <div className="my-2 py-2">
                    <QRCode
                      style={{ width: 150, height: 150 }}
                      value={`${envs.REACT_APP_BASE_WEB_SHOW_URL}/adoption-fairs/${id}/animals/${petId}`}
                    />
                  </div>
                </>
              )
            }
          </LoadManager>
        </div>
        <div className="modal-footer">
          {
            Boolean(data?.complaints) && (
              <Link to={`${petId}/complaints`} className="btn btn-link btn-sm">
                Exibir {`${data?.complaints} ${data?.complaints > 1 ? 'denúncias' : 'denúncia'}`}
              </Link>
            )
          }
          {
            !data?.adopted && (
              <Button onClick={handleRemove} disabled={removing} type="button" className={`btn-sm btn-error ml-2 ${removing ? 'loading' : ''}`}>
                <i className="fas fa-trash" />
                &nbsp;Remover da feira
              </Button>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Show

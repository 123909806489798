/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
/* eslint-disable jsx-a11y/alt-text */
import { Avatar, Flexbox } from '@/components'
import upload from '@/services/api/upload'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { toast } from 'react-toastify'
import adoptionFairs from '@/services/api/adoptionFairs'
import { useParams, useHistory } from 'react-router-dom'
import { ButtonRemove, Image, ImageContainer } from './styles'

const fileTypes = ['JPG', 'PNG']

export default function FormComplete({ animal, onCancel }) {
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const history = useHistory()

  useEffect(() => {
    if (animal.photoURL) {
      const initialAvatarFile = {
        name: 'profile-avatar',
        url: animal.photoURL,
      }
      setFiles([initialAvatarFile])
    } else {
      setFiles([])
    }
  }, [animal])

  function onSelectFiles(params) {
    const added = Object.values(params).filter((file) => !files.find((f) => f.name === file.name))
    setFiles([...files, ...(added)])
  }

  async function onConfirm() {
    try {
      setLoading(true)
      const pictures = []
      for (const file of files) {
        if (file.url === animal.photoURL) {
          pictures.push(animal.photoURL)
        } else {
          const { data: { url } } = await upload.sendFile(file)
          pictures.push(url)
        }
      }
      await adoptionFairs.addAnimals(id, { animal, pictures })
      setLoading(false)
      toast.success('Pet adicionado à feira')
      history.replace(`/app/adoption-fairs/${id}/pets`)
    } catch (e) {
      toast.error(e?.message)
      setLoading(false)
    }
  }

  return (
    <fieldset disabled={loading}>
      <div className="my-2 py-2">
        <Flexbox.Columns className="align-center">
          <Flexbox.Column className="col-auto">
            <Avatar avatarSize="lg" name={animal.name} photoURL={animal.photoURL} />
          </Flexbox.Column>
          <Flexbox.Column>
            <div className="text-bold">{animal.name}</div>
            <div>
              <small>
                {animal.category.description} - {animal.breed.description}
              </small>
            </div>
          </Flexbox.Column>
        </Flexbox.Columns>
        <div className="divider" />
        <p>Adicione ao menos uma foto do pet para adicioná-lo à feira</p>
        <FileUploader
          handleChange={onSelectFiles}
          hoverTitle="Solte aqui"
          required
          multiple
          types={fileTypes}
          // disabled={loading}
        >
          {
            files.length === 0 ? (
              <div className="c-hand bg-gray text-center text-dark" style={{ paddingTop: 30, paddingBottom: 30 }}>
                <div className="empty-icon">
                  <i className="fas fa-image fa-3x" />
                </div>
                <div className="empty-title h5">Selecione as fotos</div>
                <div className="empty-subtitle">Clique ou arraste as fotos para adicionar</div>
              </div>
            ) : (
              <div className="c-hand bg-gray text-center" style={{ paddingTop: 30, paddingBottom: 30 }}>
                <div className="empty-title h5">Fotos adicionadas: {files.length}</div>
                <div className="empty-subtitle">Clique ou arraste as fotos para adicionar mais</div>
              </div>
            )
          }
        </FileUploader>
        <div className="bg-gray p-2">
          <Flexbox.Columns className="pt-2">
            {
              files.map((file) => (
                <Flexbox.Column key={file.name} className="col-auto">
                  <ImageContainer>
                    <ButtonRemove onClick={() => setFiles(files.filter((f) => f.name !== file.name))} className="btn btn-action btn-sm bg-error s-circle">
                      <i className="fas fa-trash" />
                    </ButtonRemove>
                    <Image src={file.url || URL.createObjectURL(file)} />
                  </ImageContainer>
                </Flexbox.Column>
              ))
            }
          </Flexbox.Columns>
        </div>
        <div className="text-right mt-2">
          <button onClick={onCancel} type="button" className="btn btn-link">
            <i className="fas fa-times" />
            &nbsp;Cancelar
          </button>
          <button onClick={onConfirm} type="button" className={`btn btn-primary ${loading ? 'loading' : ''}`} disabled={files.length === 0}>
            <i className="fas fa-check" />
            &nbsp;Adicionar pet
          </button>
        </div>
      </div>
    </fieldset>
  )
}

FormComplete.propTypes = {
  animal: PropTypes.objectOf(Object).isRequired,
  onCancel: PropTypes.func.isRequired,
}

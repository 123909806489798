import { ANIMAL } from '@/lib/enums'
import dateMask from '@/lib/masks/date'
import rga from '@/lib/masks/rga'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import Avatar from '../Avatar'
import Card from '../Card'
import Flexbox from '../Flexbox'

function AnimalCard({ animal }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Pet</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-paw" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>
            <Flexbox.Columns>
              <Flexbox.Column className="col-auto">
                <Avatar
                  avatarSize="lg"
                  name={animal?.name}
                  photoURL={animal?.photoURL}
                  smart={false}
                  alt="Imagem do animal"
                />
              </Flexbox.Column>
              <Flexbox.Column>
                <div className="text-primary text-bold">{animal?.name}</div>
                <div className="text-small">{rga(animal?.uid)}</div>
              </Flexbox.Column>
            </Flexbox.Columns>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <Link className="btn btn-link btn-sm" to={`/app/pets/${animal?.id}`}>
              <i className="fas fa-link" />
              &nbsp;Ver pet
            </Link>
          </Flexbox.Column>
        </Flexbox.Columns>
        <div className="divider" />
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Raça</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {animal?.breed?.description}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Espécie</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {animal?.category?.description}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Sexo</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {ANIMAL.gender.t(animal?.gender)}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Nascimento</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {dateMask(animal?.bornedAt)}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Porte</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {ANIMAL.size.t(animal?.size)}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Chip</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {animal?.chip?.uid || '-'}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column className="col-12">Observações</Flexbox.Column>
          <Flexbox.Column className="col-12 text-bold">
            {animal?.description || '-'}
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Body>
    </Card.Base>
  )
}

AnimalCard.propTypes = {
  animal: PropTypes.objectOf(Object).isRequired,
}

export default AnimalCard

import {
  iconClasses, singularRestrictMapLabel, singularStatusMapLabel, textClasses, textRestrict,
} from '@/constants/adoptionFair'
import animalFairs from '@/services/api/animalFairs'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

export default function Row({ fair }) {
  const { id, petId } = useParams()
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  async function onTransfer() {
    try {
      setLoading(true)
      await animalFairs.transferAnimal(petId, { destinationFairId: fair.id })
      toast.success('Pet transferido')
      history.goBack()
      setLoading(false)
    } catch (e) {
      toast.error(e?.message)
      setLoading(false)
    }
  }

  async function onChange() {
    confirmAlert({
      title: 'Transferir o pet',
      message: 'Você está certo que deseja transferir o pet para esta feira?',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim, transferir',
          className: 'bg-primary',
          onClick: onTransfer,
        },
      ],
    })
  }

  return (
    <tr>
      <td>{fair?.id}</td>
      <td style={{ maxWidth: 300 }} className="text-ellipsis">{fair?.title}</td>
      <td>
        <div className={textClasses[fair?.status]}>
          <i className={`fa-fw ${iconClasses[fair?.status]}`} />
          {singularStatusMapLabel[fair?.status]}
        </div>
      </td>
      <td>
        <div className={textRestrict[fair?.restrict]}>
          {singularRestrictMapLabel[fair?.restrict]}
        </div>
      </td>
      <td className="text-right">
        {
          fair?.id === Number(id) ? (
            <i className="fas fa-check fa-lg text-success px-2" />
          ) : (
            <a
              className={`btn btn-action btn-sm btn-primary tooltip tooltip-left ${loading ? 'loading' : ''}`}
              disabled={loading}
              data-tooltip="Transferir para esta feira"
              onClick={onChange}
            >
              <i className="fas fa-exchange-alt" />
            </a>
          )
        }
      </td>
    </tr>
  )
}

Row.propTypes = {
  fair: PropTypes.objectOf(Object).isRequired,
}

import { Form } from '@/components'
import useAuth from '@/hooks/useAuth'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { toast } from 'react-toastify'
import termsService from '@/services/api/settings/terms'

function Terms() {
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState('')
  const textareaRef = useRef()
  const { licensing, setLicensing } = useAuth()

  async function onSubmit(event) {
    event?.preventDefault()
    try {
      setLoading(true)
      await termsService.updateCurrent({ terms: content })
      toast.success('Termos atualizados com sucesso')
      setLicensing({ ...licensing, terms: content })
    } catch (e) {
      toast.error(e?.message)
      setLoading(false)
    }
  }

  useLayoutEffect(() => {
    textareaRef?.current?.focus()
  }, [])

  return (

    <div className="modal-container">
      <fieldset disabled={loading}>
        <form onSubmit={onSubmit}>

          <div className="modal-header">
            <div className="modal-title h5">Atualização dos termos</div>
          </div>
          <div className="modal-body">
            <div className="content">
              <Form.Group>
                <Form.Label htmlFor="terms">
                  Informe os
                  <b> termos que o usuário deve concordar</b>
                </Form.Label>
                <textarea
                  ref={textareaRef}
                  onInput={(e) => setContent(e.target.value)}
                  placeholder="Para solicitar este serviço você deve concordar com...x"
                  id="terms"
                  cols="30"
                  rows="10"
                  className="form-input"
                  value={content}
                />
              </Form.Group>
            </div>
          </div>
          <div className="modal-footer">
            <button
              disabled={loading || !content}
              className={`btn btn-primary ${loading ? 'loading' : ''}`}
              type="submit"
            >
              <i className="fas fa-save" />
              &nbsp;Salvar termos
            </button>
          </div>
        </form>
      </fieldset>
    </div>
  )
}

export default Terms

import api from './api'

export default {
  list(params) {
    return api.get('/partner-licensings/:partnerLicensingId/adoption-fairs', { params })
  },
  show(id) {
    return api.get(`/partner-licensings/:partnerLicensingId/adoption-fairs/${id}`)
  },
  destroy(id) {
    return api.delete(`/partner-licensings/:partnerLicensingId/adoption-fairs/${id}`)
  },
  create(body) {
    return api.post('/partner-licensings/:partnerLicensingId/adoption-fairs', body)
  },
  update(id, body) {
    return api.put(`/partner-licensings/:partnerLicensingId/adoption-fairs/${id}`, body)
  },
  updateContacts(id, body) {
    return api.put(`/partner-licensings/:partnerLicensingId/adoption-fairs/${id}/contacts`, body)
  },
  publish(id) {
    return api.patch(`/partner-licensings/:partnerLicensingId/adoption-fairs/${id}/publish`)
  },
  start(id) {
    return api.patch(`/partner-licensings/:partnerLicensingId/adoption-fairs/${id}/start`)
  },
  close(id) {
    return api.patch(`/partner-licensings/:partnerLicensingId/adoption-fairs/${id}/close`)
  },
  cancel(id) {
    return api.patch(`/partner-licensings/:partnerLicensingId/adoption-fairs/${id}/cancel`)
  },
  adoptions(id, params = {}) {
    return api.get(`/partner-licensings/:partnerLicensingId/adoption-fairs/${id}/adoptions`, { params })
  },
  animals(id, params = {}) {
    return api.get(`/partner-licensings/:partnerLicensingId/adoption-fairs/${id}/animals`, { params })
  },
  addAnimals(id, body) {
    return api.post(`/partner-licensings/:partnerLicensingId/adoption-fairs/${id}/add-animal`, body)
  },
  adoptionsRequested(id, params = {}) {
    return api.get(`/partner-licensings/:partnerLicensingId/adoption-fairs/${id}/adoptions-requested`, { params })
  },
}

import {
  Avatar,
  Card,
  Flexbox,
  ListManager, PageHeader, Paginator, Styles,
} from '@/components'
import { singularStatusMapLabel } from '@/constants/castration'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import documentNumberMask from '@/lib/masks/documentNumber'
import reports from '@/services/api/reports'
import React from 'react'
import FilterCastrations from '../components/FilterCastrations'

function CastrationsByProtector({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const { data, loading, error } = useAxiosRequest(reports.castrationsByProtectors, query)
  const castrationStatus = [
    { status: 'Solicitada', count: data?.results?.countcastrationrequested },
    { status: 'Correção', count: data?.results?.countcastrationcorrection },
    { status: 'Corrigida pelo tutor', count: data?.results?.countcastrationcorrected },
    { status: 'Em vistoria', count: data?.results?.countcastrationrevision },
    { status: 'Pré aprovada', count: data?.results?.countcastrationpreapproved },
    { status: 'Aprovada', count: data?.results?.countcastrationapproved },
    { status: 'Agendada', count: data?.results?.countcastrationscheduled },
    { status: 'Em tratamento', count: data?.results?.countcastrationintreatment },
    { status: 'Em atendimento', count: data?.results?.countcastrationattendance },
    { status: 'Aguardando confirmar', count: data?.results?.countcastrationawaitingconfirmation },
    { status: 'Concluída', count: data?.results?.countcastrationcompleted },
    { status: 'Rejeitada', count: data?.results?.countcastrationrejected },
    { status: 'Cancelada', count: data?.results?.countcastrationcanceled },
  ]

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Castrações por protetor(a)"
        subtitle="Mostra a quantidade de castrações solicitadas pelos protetores"
        icon="fas fa-hand-holding-heart"
        rightAction={() => <FilterCastrations />}
      />
      <ListManager count={data?.count || 0} loading={loading} error={error}>
        <Flexbox.Columns>
          <Flexbox.Column className="col-sm-12 my-2">
            <Card.Base>
              <Card.Header>
                <Flexbox.Columns className="align-center col-gapless">
                  <Flexbox.Column className="ml-2">
                    <b>
                      Quantidade total de castrações solicitadas por todos os protetores
                    </b>
                  </Flexbox.Column>
                </Flexbox.Columns>
              </Card.Header>
              <Card.Body>
                <div className="text-right">
                  <span>Total: <b>{data?.results?.countcastrations}</b></span>
                </div>
                <div className="divider" />
                <table className="table table-hover text-small">
                  <thead>
                    <tr>
                      <th>Status</th>
                      <th width="100px" className="text-right">Quantidade</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      castrationStatus.map((item) => (
                        <tr key={item.status}>
                          <td>{item.status}</td>
                          <td className="text-right">{item.count}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </Card.Body>
            </Card.Base>
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          {
            Boolean(data) && data.rows.map((item) => (
              <Flexbox.Column key={item.tutorId} className="col-6 col-sm-12 my-2">
                <Card.Base>
                  <Card.Header>
                    <Flexbox.Columns className="align-center col-gapless">
                      <Flexbox.Column className="col-auto">
                        <Avatar
                          avatarSize="lg"
                          name={item?.tutorFullName}
                          photoURL={item?.tutorAvatar}
                          alt="Imagem de usuário"
                        />
                      </Flexbox.Column>
                      <Flexbox.Column className="ml-2">
                        <b>{item?.tutorFullName}</b>
                        {
                          Boolean(item?.tutorDocumentNumber) && (
                            <div>
                              <small>{documentNumberMask(item?.tutorDocumentNumber)}</small>
                            </div>
                          )
                        }
                      </Flexbox.Column>
                    </Flexbox.Columns>
                  </Card.Header>
                  <Card.Body>
                    <div className="text-right">
                      <span>Total: <b>{item?.total}</b></span>
                    </div>
                    <div className="divider" />
                    <table className="table table-hover text-small">
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th width="100px" className="text-right">Quantidade</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          data.statusList.map((status) => (
                            <tr key={status}>
                              <td>{singularStatusMapLabel[status]}</td>
                              <td className="text-right">{item[status] || 0}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </Card.Body>
                </Card.Base>
              </Flexbox.Column>
            ))
          }
        </Flexbox.Columns>
        {
          Boolean(data) && (
            <div className="float-right my-2">
              <Paginator paginatedList={data} />
            </div>
          )
        }
      </ListManager>
    </Styles.Container>
  )
}

export default CastrationsByProtector

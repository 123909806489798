import { Card, Flexbox, ImageThumb } from '@/components'
import React from 'react'
import PropTypes from 'prop-types'
import dateMask from '@/lib/masks/date'

function Records({ records }) {
  if (!records) return null

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Prontuário</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-clipboard-list" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        {!records.length && <p>Nada no prontuário</p>}
        {
          records.map((record) => (
            <div key={record.id} className="mb-2 pb-2">
              <span>
                {dateMask(record.createdAt)} - {record.description}
              </span>
              <Flexbox.Columns>
                {
                  record.attachments.map((url) => (
                    <Flexbox.Column key={url} className="col-auto">
                      <a href={url} target="_blank" rel="noreferrer">
                        <ImageThumb className="s-rounded" height={50} width={50} src={url} />
                      </a>
                    </Flexbox.Column>
                  ))
                }
              </Flexbox.Columns>
            </div>
          ))
        }
      </Card.Body>
    </Card.Base>
  )
}

Records.propTypes = {
  records: PropTypes.arrayOf(Object).isRequired,
}

export default Records

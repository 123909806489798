import {
  Avatar, ListManager, Paginator, Styles,
} from '@/components'
import { statusColorMap } from '@/constants/adoption'
import envs from '@/constants/envs'
import useAdoptionFair from '@/hooks/useAdoptionFair'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import { ADOPTION } from '@/lib/enums'
import dateMask from '@/lib/masks/date'
import adoptionFairs from '@/services/api/adoptionFairs'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import AdoptionsQuickFilter from './QuickFilter'

function Adoptions({ title }) {
  usePageTitle({ title })
  const { id } = useParams()
  const query = useQuery()
  const { loading, data, error } = useAxiosRequest(adoptionFairs.adoptions, id, query)
  const { state: { adoptionFair } } = useAdoptionFair()

  if (!adoptionFair) return null

  return (
    <Styles.Container className="grid-xl no-print">
      <AdoptionsQuickFilter />
      <ListManager
        emptyMessage="Nenhuma adoção na feira"
        emptyIcon="fas fa-heart"
        emptySubtitle="As adoções vão aparecer aqui"
        loading={loading}
        count={data?.count || 0}
        error={error}
      >
        <>
          <table className="table text-small table-striped table-hover">
            <thead>
              <tr>
                <th>Doador</th>
                <th>Novo tutor</th>
                <th>Status</th>
                <th>Animal</th>
                <th>Data</th>
                <th className="text-right" width="1">Ações</th>
              </tr>
            </thead>
            <tbody>
              {
                data && data.rows.map((adoption) => (
                  <tr key={adoption.id}>
                    <td>
                      <Link to={`/app/pets/${adoption?.animalId}`}>
                        <Avatar
                          avatarSize="sm"
                          className="mr-1"
                          name={adoption?.donor?.fullName}
                          photoURL={adoption?.donor?.photoURL}
                          alt="Imagem de usuário"
                        />
                        {adoption?.donor?.fullName}
                      </Link>
                    </td>
                    <td>
                      <Avatar
                        avatarSize="sm"
                        className="mr-1"
                        name={adoption?.newTutor?.fullName}
                        photoURL={adoption?.newTutor?.photoURL}
                        alt="Imagem de usuário"
                      />
                      {adoption?.newTutor?.fullName}
                    </td>
                    <td className={`${statusColorMap[adoption?.status]}`}>
                      {ADOPTION.status.t(adoption?.status)}
                    </td>
                    <td>
                      <Avatar
                        avatarSize="sm"
                        className="mr-1"
                        name={adoption?.animal?.name}
                        photoURL={adoption?.animal?.photoURL}
                        alt="Imagem do pet"
                      />
                      {adoption?.animal?.name}
                    </td>
                    <td>{dateMask(adoption?.createdAt)}</td>
                    <td className="text-right">
                      {
                        adoption?.status === 'confirmed'
                          ? (
                            <a
                              href={`${envs.REACT_APP_BASE_WEB_SHOW_URL}/adoption-term/${adoption?.animal?.uid}/${adoption?.fairId}`}
                              data-tooltip="Imprimir Termo de Adoção"
                              className="btn btn-action btn-sm btn-primary tooltip tooltip-top"
                              target="_blank"
                              rel="noreferrer"
                            >
                              <i className="fas fa-print" />
                            </a>
                          )
                          : (
                            <div className="text-center">-</div>
                          )
                      }

                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          {
            Boolean(data) && (
              <div className="float-right my-2">
                <Paginator paginatedList={data} />
              </div>
            )
          }
        </>
      </ListManager>
    </Styles.Container>
  )
}

export default Adoptions

import { LoadManager } from '@/components'
import useLicensing from '@/hooks/useLicensing'
import documentNumberMask from '@/lib/masks/documentNumber'
import { setLicensing } from '@/reducers/licensing'
import ngos from '@/services/api/ngos'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

function Approve() {
  const { id } = useParams()
  const history = useHistory()
  const [approving, setApproving] = useState(false)
  const { state: { licensing }, dispatch } = useLicensing()

  async function approve() {
    try {
      setApproving(true)
      const { data } = await ngos.approve(id)
      dispatch(setLicensing(data))
      toast.success(`${licensing?.licensed?.fullName} agora é um(a) ONG`)
      history.goBack()
    } catch (e) {
      setApproving(false)
      toast.error(e?.message)
    }
  }

  return (
    <div className="modal active" id="modal-id">
      <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
      <div className="modal-container">
        <div className="modal-header">
          <a
            type="button"
            className="btn btn-clear float-right"
            onClick={() => history.goBack()}
          />
          <div className="modal-title h5">Aprovar solicitação de credenciamento de ONG</div>
        </div>
        <div className="modal-body">
          <div className="content text-justify">
            <LoadManager loading={!licensing}>
              {
                licensing?.status === 'requested' ? (
                  <>
                    <p>
                      Deseja credenciar o(a) usuário(a)
                      <b className="text-primary"> {licensing?.licensed?.fullName} </b>
                      portador(a) do documento
                      <b className="text-primary"> {documentNumberMask(licensing?.licensed?.documentNumber)} </b>
                      como ONG?
                    </p>
                    <p className="text-small">
                      Com isso, este usuário ganhará status de ONG
                    </p>
                  </>
                )
                : <p>Este credenciamento não pode mais ser aprovado</p>
              }
            </LoadManager>
          </div>
        </div>
        {
           licensing?.status === 'requested' && (
            <div className="modal-footer">
              <a
                className="btn mr-2"
                disabled={approving}
                onClick={() => history.goBack()}
              >
                <i className="fas fa-chevron-left" />
                &nbsp;Voltar
              </a>
              <button
                className={`btn btn-success ${approving ? 'loading' : ''}`}
                disabled={approving}
                type="button"
                onClick={approve}
              >
                <i className="fas fa-check" />
                &nbsp;Aprovar
              </button>
            </div>
           )
        }
      </div>
    </div>
  )
}

export default Approve

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import DocumentForm from '../DocumentForm'

function DocumentFormAdd({ onAdded, loading }) {
  const [active, setActive] = useState(false)

  return (
    <div>
      {
        active && (
          <DocumentForm
            title="Adicionar documento"
            active={active}
            onClose={() => setActive(false)}
            onSubmit={onAdded}
          />
        )
      }
      <button
        disabled={loading}
        onClick={() => setActive(true)}
        type="button"
        className={`btn btn-primary btn-sm ${loading ? 'loading' : ''}`}
      >
        <i className="fas fa-plus" />
        &nbsp;Incluir documento
      </button>
    </div>
  )
}

export default DocumentFormAdd

DocumentFormAdd.propTypes = {
  onAdded: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

DocumentFormAdd.defaultProps = {
  loading: false,
}

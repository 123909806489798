import React from 'react'
import List from '@/views/app/PartnerBlacklists'
import Show from '@/views/app/PartnerBlacklists/Show'
import { Route, useRouteMatch } from 'react-router-dom'

function PartnerBlacklistsRoutes() {
  const { path } = useRouteMatch()

  return (
    <div>
      <Route path={path} exact>
        <List title="Petis - Parceiros / Bloqueios" />
      </Route>
      <Route path={`${path}/:id`} exact>
        <Show title="Petis - Parceiros / Bloqueio" />
      </Route>
    </div>
  )
}

export default PartnerBlacklistsRoutes

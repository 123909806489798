import React from 'react'
import PropTypes from 'prop-types'
import TimelineItem from './TimelineItem'

function Events({ castrationCity, events }) {
  return (
    <div className="timeline">
      {
        events.map((event, index) => (
          <TimelineItem
            castrationCity={castrationCity}
            key={event?.id}
            index={index}
            event={event}
          />
        ))
      }
    </div>
  )
}

Events.propTypes = {
  events: PropTypes.arrayOf(Object),
  castrationCity: PropTypes.objectOf(Object),
}

Events.defaultProps = {
  events: [],
  castrationCity: null,
}

export default Events

import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Card, Flexbox } from '@/components'
import phoneNumber from '@/lib/masks/phoneNumber'
import documentNumberMask from '@/lib/masks/documentNumber'
import dateMask from '@/lib/masks/date'

function TutorCard({ tutor }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Dados do tutor</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-user" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <Flexbox.Columns className="align-center">
            <Flexbox.Column className="col-auto">
              <Avatar avatarSize="xl" name={tutor?.fullName} photoURL={tutor?.photoURL} />
            </Flexbox.Column>
            <Flexbox.Column className="text-ellipsis">
              <div className="h5">{tutor?.fullName}</div>
              <div>{tutor?.email}</div>
            </Flexbox.Column>
        </Flexbox.Columns>
        <div className="divider" />
        <Flexbox.Columns>
          <Flexbox.Column>Fone</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">{phoneNumber(tutor?.phoneNumber) || '-'}</Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          <Flexbox.Column>Documento</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">{documentNumberMask(tutor?.documentNumber) || '-'}</Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          <Flexbox.Column>Criado em</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">{dateMask(tutor?.createdAt)}</Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          <Flexbox.Column>Conta verificada?</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">{tutor?.emailVerified ? 'Sim' : 'Não'}</Flexbox.Column>
        </Flexbox.Columns>
      </Card.Body>
    </Card.Base>
  )
}

TutorCard.propTypes = {
  tutor: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default TutorCard

import { PageHeader, Styles } from '@/components'
import envs from '@/constants/envs'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import convertBounds from '@/lib/convertBounds'
import people from '@/services/api/people'
import reports from '@/services/api/reports'
import GoogleMapReact from 'google-map-react'
import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import FilterCastrations from '../components/FilterCastrations'
import Marker from './Marker'
import { MapContainer } from './styles'

// import { Container } from './styles';

function CastrationsPointMap({ title }) {
  usePageTitle({ title })
  const [partner, setPartner] = useState(null)
  const [report, setReport] = useState([])
  const [mapOptions, setMapOptions] = useState({ zoom: 12 })
  const query = useQuery()
  const mapRef = useRef()

  async function fetchPartner() {
    const { data } = await people.partner()
    setPartner(data)
  }

  const params = useMemo(() => {
    let obj = {}
    if (mapOptions) {
      const { zoom } = mapOptions
      const bounds = convertBounds(mapOptions?.bounds)
      obj = { ...obj, zoom, bounds }
    }
    return { ...query, ...obj }
  }, [mapOptions, query])

  const fetchReport = useCallback(async () => {
    const { data } = await reports.pointMap(params)
    setReport(data)
  }, [params])

  const center = partner?.location ? {
    lat: partner?.location?.coordinates[1],
    lng: partner?.location?.coordinates[0],
  } : {}

  const points = report.map((item) => ({
    type: 'Feature',
    id: item.id,
    properties: { cluster: item.count > 1, ...item },
    geometry: {
      type: 'Point',
      coordinates: [parseFloat(item.longitude), parseFloat(item.latitude)],
    },
  }))

  useEffect(() => {
    fetchPartner()
  }, [])

  useEffect(() => {
    fetchReport()
  }, [fetchReport, params])

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        icon="fas fa-map-marker-alt"
        title="Castrações no mapa"
        subtitle="Mostra as castrações no lugar onde foram solicitadass"
        rightAction={() => <FilterCastrations />}
      />
      <MapContainer className="bg-red">
        <GoogleMapReact
          onGoogleApiLoaded={({ map }) => {
            mapRef.current = map
          }}
          center={query?.lat && query?.lng ? { lat: query.lat, lng: query.lng } : center}
          bootstrapURLKeys={{
            key: envs.REACT_APP_GOOGLE_MAPS_API_KEY,
            libraries: ['visualization'],
          }}
          onChange={setMapOptions}
          defaultZoom={query?.zoom ? Number(query.zoom) : 12}
          yesIWantToUseGoogleMapApiInternals
        >
          {points.map((cluster) => {
            const [longitude, latitude] = cluster.geometry.coordinates

            return (
              <Marker
                key={`cluster-${cluster.id || cluster.properties.uid}`}
                lat={latitude}
                lng={longitude}
                cluster={cluster}
                points={points}
                mapRef={mapRef}
              />
            )
          })}
        </GoogleMapReact>
      </MapContainer>
    </Styles.Container>
  )
}

export default CastrationsPointMap

import { useCallback, useLayoutEffect, useState } from 'react'

export const API_STATUS = {
  LOADING: 'loading',
  ERROR: 'error',
  DONE: 'done',
}

export default function useAxiosRequest(asyncFunction, ...args) {
  const [status, setStatus] = useState()
  const [data, setData] = useState()
  const [error, setError] = useState()

  const loading = status === API_STATUS.LOADING

  const fetchData = useCallback(() => {
    if (status === API_STATUS.LOADING) return
    setStatus(API_STATUS.LOADING)
    asyncFunction(...args)
      .then((res) => {
        setData(res.data)
        setStatus(API_STATUS.DONE)
      })
      .catch((e) => {
        setError(e)
        setStatus(API_STATUS.ERROR)
      })
  }, [args, asyncFunction, status])

  useLayoutEffect(() => {
    if (!loading) fetchData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(args)])

  return {
    status,
    data,
    error,
    setData,
    loading,
    asyncFunction,
    fetchData,
  }
}

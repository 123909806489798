export const INITIAL_STATE = { clinic: null }

export default function clinicReducer(state, action) {
  switch (action.type) {
    case ('setClinic'): return { clinic: action.payload }
    default: return INITIAL_STATE
  }
}

export function setClinic(payload) {
  return { type: 'setClinic', payload }
}

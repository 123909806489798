import { Flexbox } from '@/components'
import dateMask from '@/lib/masks/date'
import thumbor from '@/lib/thumbor'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import AdditionalData from './AdditionalData'

const paths = {
  ClinicalCare(scopeId) {
    return `/app/clinical-cares/show/${scopeId}`
  },
  Castration(scopeId) {
    return `/app/castrations/status/all/${scopeId}`
  },
  News(scopeId) {
    return `/app/news/${scopeId}`
  },
  Mistreatment(scopeId) {
    return `/app/mistreatments/show/${scopeId}`
  },
  default() {},
}
function Row({ item, last }) {
  const navigationPathFn = paths[item.scope] || paths.default
  const pathname = navigationPathFn(item.scopeId)

  return (
    <>
      <div style={{ opacity: item?.read ? 0.7 : 1 }} className="scale-animated s-rounded px-2 py-1 no-decoration">
        <Link to={{ pathname, state: { timelineItemId: item?.id } }} className="text-dark no-decoration">
          <div>
            <span className={`${item?.read ? '' : 'text-bold'} decorated`}>
              {item?.title}
              {
                item?.read && (
                  <small className="float-right">
                    <i className="fas fa-check" />
                    &nbsp;Lido
                  </small>
                )
              }
            </span>
            <div>
              <small>
                <span>
                  {dateMask(item?.createdAt, { dateStyle: 'full' })}&nbsp;
                </span>
                <small className="label label-sm label-rounded">
                  <i className="far fa-clock" />&nbsp;
                  {new Date(item?.createdAt).toLocaleTimeString()}
                </small>
              </small>
            </div>
            {
              Boolean(item?.description) && (
                <div className="mt-2">
                  {item?.description}
                </div>
              )
            }
          </div>
        </Link>
        <AdditionalData additionalData={item?.additionalData} />
        <Flexbox.Columns>
            {
              Boolean(item?.images) && item?.images.map((image) => (
                <Flexbox.Column key={image} className="col-auto">
                  <img
                    className="s-rounded mt-2"
                    src={thumbor.thumb(image, { width: 100, height: 100 })}
                    alt="Imagem da timeline"
                  />
                </Flexbox.Column>
              ))
            }
        </Flexbox.Columns>
      </div>
      {!last && <div className="divider" />}
    </>
  )
}

Row.propTypes = {
  item: PropTypes.objectOf(Object).isRequired,
  last: PropTypes.bool.isRequired,
}

export default Row

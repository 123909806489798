import React from 'react'
import PropTypes from 'prop-types'
import { Flexbox } from '@/components'

function Stars({ rate }) {
  return (
    <Flexbox.Columns className="col-gapless">
      {
        Array(rate).fill(null).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Flexbox.Column key={index} className="col-auto mr-1">
            <i className={`fas fa-star ${index + 1 <= rate ? 'text-primary' : 'text-gray'}`} />
          </Flexbox.Column>
        ))
      }
    </Flexbox.Columns>
  )
}

Stars.propTypes = {
  rate: PropTypes.number.isRequired,
}

export default Stars

import React from 'react'
import AdoptionsRequestedShow from '@/views/app/AdoptionFairs/Show/AdoptionsRequested/Show'
import { Route, useRouteMatch } from 'react-router-dom'

function AdoptionsRequestedRoutes() {
  const { path } = useRouteMatch()

  return (
    <div>
      <Route path={`${path}/:petId`}>
        <AdoptionsRequestedShow title="Petis - Parceiros / Feira de adoção / Solicitações de adoção / Pet" />
      </Route>
    </div>
  )
}

export default AdoptionsRequestedRoutes

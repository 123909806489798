import useInterval from '@/hooks/useInterval'
import castrations from '@/services/api/castrations'
import PropTypes from 'prop-types'
import React, {
  useCallback, useEffect, useLayoutEffect, useRef, useState,
} from 'react'
import CommentForm from './CommentForm'
import Row from './Row'
import {
  Body, Container, Content, Header, ListContainer,
} from './styles'

function Messages({ castration }) {
  const [lastLength, setLastLength] = useState(0)
  const [list, setList] = useState([])
  const [active, setActive] = useState(false)
  const endListRef = useRef()

  const fetchList = useCallback(async () => {
    const { data } = await castrations.messages(castration?.id)
    setList(data)
    setLastLength(data?.length)
  }, [castration?.id, setList])

  function handleCommentAdded(comment) {
    setList([...list, comment])
  }

  useInterval(fetchList, 5000)

  useEffect(() => {
    fetchList()
  }, [fetchList])

  useLayoutEffect(() => {
    if (list.length > 0 && list.length !== lastLength) {
      endListRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [lastLength, list])

  return (
    <Container className={`${active ? 'active' : ''}`}>
      <Header className={`${active ? 'active' : ''}`} onClick={() => setActive(!active)}>
        <div className="text-bold text-primary badge" data-badge={list.length}>Comentários</div>
        <i className="fas fa-chevron-up text-primary" />
      </Header>
      <Content className={`${active ? 'active' : ''}`}>
        <Body>
          <ListContainer>
            {
              list.map((comment) => (
                <Row key={comment?.id} castration={castration} comment={comment} />
              ))
            }
            <span ref={endListRef} />
          </ListContainer>
          <CommentForm castration={castration} onCommentAdded={handleCommentAdded} />
        </Body>
      </Content>
    </Container>
  )
}

Messages.propTypes = {
  castration: PropTypes.objectOf(Object).isRequired,
}

export default Messages

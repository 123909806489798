import api from './api'

export default {
  list(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/clinics', { params })
  },
  allActive() {
    return api.get('/partner-licensings/:partnerLicensingId/clinics/all-active')
  },
  show(id) {
    return api.get(`/partner-licensings/:partnerLicensingId/clinics/${id}`)
  },

  approve(id) {
    return api.post(`/partner-licensings/:partnerLicensingId/clinics/${id}/approve`)
  },

  createClinicalCareClinic(body) {
    return api.post('/partner-licensings/:partnerLicensingId/clinics/create-clinical-care-clinic', body)
  },

  reject(id) {
    return api.delete(`/partner-licensings/:partnerLicensingId/clinics/${id}/reject`)
  },

  revoke(id) {
    return api.post(`/partner-licensings/:partnerLicensingId/clinics/${id}/revoke`)
  },

  addCastrations(id, params = {}) {
    return api.post(`/partner-licensings/:partnerLicensingId/clinics/${id}/add-castrations`, params)
  },

  transactions(id, params = {}) {
    return api.get(`/partner-licensings/:partnerLicensingId/clinics/${id}/transactions`, { params })
  },
}

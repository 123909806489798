import { Avatar, Flexbox, ImageViewer } from '@/components'
import { singularStatusMapLabel } from '@/constants/batches'
import dateMask from '@/lib/masks/date'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import mapEvents from './mapEvents'

function TimelineItem({ event }) {
  const [image, setImage] = useState('')
  const mappedEvent = mapEvents(event?.status)

  if (!mappedEvent) return null

  return (
    <>
      {
        Boolean(image) && (
          <ImageViewer src={image} onClose={() => setImage('')} />
        )
      }
      <div className="timeline-item">
        <div className="timeline-left">
          <span
            className={`timeline-icon tooltip tooltip-right ${mappedEvent?.bgIconClass} ${mappedEvent?.icon ? 'icon-lg' : ''}`}
            data-tooltip={singularStatusMapLabel[event.status]}
          >
            <i className={`${mappedEvent?.icon}`} />
          </span>
        </div>
        <div className="timeline-content">
          <div className="tile">
            <div className="tile-content">
              <p className="tile-subtitle">
                <span className="text-bold">
                  {dateMask(event?.createdAt, { dateStyle: 'full' })}&nbsp;
                </span>
                <small className="label label-sm label-rounded">
                  <i className="far fa-clock" />&nbsp;
                  {new Date(event?.createdAt).toLocaleTimeString()}
                </small>
              </p>
              <p className="tile-title pre">{mappedEvent?.title}</p>
              {
                Boolean(event?.description) && (
                  <p className="tile-title">
                    <cite className="pre">{event?.description}</cite>
                  </p>
                )
              }
              <Flexbox.Columns className="align-center col-gapless">
                <Flexbox.Column className="col-auto">
                  <Avatar
                    avatarSize="sm"
                    name={event?.author?.fullName}
                    photoURL={event?.author?.photoURL}
                    alt="Imagem de usuário"
                  />
                </Flexbox.Column>
                <Flexbox.Column className="text-small ml-2">{event?.author?.fullName}</Flexbox.Column>
              </Flexbox.Columns>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

TimelineItem.propTypes = {
  event: PropTypes.objectOf(Object).isRequired,
}

export default TimelineItem

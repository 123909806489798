import { LoadManager } from '@/components'
import useCastration from '@/hooks/useCastration'
import usePageTitle from '@/hooks/usePageTitle'
import { setCastration } from '@/reducers/castration'
import castrations from '@/services/api/castrations'
import React, { useState } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { toast } from 'react-toastify'

function Approve({ title }) {
  usePageTitle({ title })
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const { state: { castration }, dispatch } = useCastration()
  const history = useHistory()

  async function approve() {
    try {
      setLoading(true)
      const { data } = await castrations.approve(id)
      dispatch(setCastration(data))
      toast.success('Castração aprovada')
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="modal active" id="modal-id">
      <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
      <div className="modal-container">
        <div className="modal-header">
          <a
            type="button"
            className="btn btn-clear float-right"
            onClick={() => history.goBack()}
          />
          <div className="modal-title h5">Aprovar a castração #{id}</div>
        </div>
        <div className="modal-body">
          <div className="content">
            <LoadManager loading={!castration}>
              {
                castration?.releasedAt && new Date(castration.releasedAt) > new Date()
                  ? (
                    <>
                      <p>Confirma a aprovação para a castração de <b className="text-primary">{castration?.animal?.name}</b>?</p>
                      <p className="text-small text-error">
                        Atenção! Este pet ainda não tem a idade mínima para castração.
                      </p>
                      <p className="text-small">
                        Você pode aprová-la, mas até a data de liberação
                        (quando o pet completar a idade mínima),
                        a solicitação <b>NÃO</b> será encaminhada para uma clínica.
                      </p>
                      <p className="text-small">
                        Você pode escolher manualmente uma clínica para realizar a castração,
                        <b> isto gerará um registro para auditoria caso necessário</b>:&nbsp;
                        <Link to="approve-with-clinic" replace>Clique aqui para escolher uma clínica</Link>
                      </p>
                    </>
                  )
                  : (
                    <>
                      <p>Confirma a aprovação para a castração de <b className="text-primary">{castration?.animal?.name}</b>?</p>
                      <p className="text-small">
                        Esta ação vai gerar um evento de aprovação e selecionará
                        uma clínica credenciada para realizar a castração.
                      </p>
                      <p className="text-small">
                        Você pode escolher uma clínica para realizar a castração,
                        <b> isto gerará um registro para auditoria caso necessário</b>:&nbsp;
                        <Link to="approve-with-clinic" replace>Clique aqui para escolher uma clínica</Link>
                      </p>
                    </>
                  )
              }
            </LoadManager>
          </div>
        </div>
        <div className="modal-footer">
          <a
            className="btn mr-2"
            disabled={loading}
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left" />
            &nbsp;Voltar
          </a>
          <button
            className={`btn btn-success ${loading ? 'loading' : ''}`}
            disabled={loading}
            type="button"
            onClick={approve}
          >
            <i className="fas fa-check" />
            &nbsp;Aprovar
          </button>
        </div>
      </div>
    </div>
  )
}

export default Approve

import React, { useState } from 'react'
import {
  Flexbox,
  Form,
  ListManager, PageHeader, Paginator, QuickFilter, Styles,
} from '@/components'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import clinicalCareItems from '@/services/api/settings/clinicalCareItems'
import { A } from '@/components/styles'
import FormClinicalCareItem from './components/Form'
import Row from './Row'

function ClinicalCareItems({ title }) {
  usePageTitle({ title })
  const [formVisible, setFormVisible] = useState(false)
  const [item, setItem] = useState()
  const query = useQuery()
  const {
    data, loading, error, fetchData,
  } = useAxiosRequest(clinicalCareItems.list, query)

  function openForm(i = null) {
    setItem(i)
    setFormVisible(true)
  }

  function closeForm() {
    setItem(null)
    setFormVisible(false)
  }

  function onSave() {
    fetchData()
    closeForm()
  }

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Itens para atendimento clínico"
        icon="fas fa-star-of-life"
        subtitle="Lista com os itens que as clínicas podem adicionar ao atendimento clínico"
        rightAction={() => (
          error ? null : (
            <A className="btn btn-primary" onClick={() => openForm()}>
              <i className="fas fa-plus" />
              &nbsp;Adicionar
            </A>
          )
        )}
      />
      <QuickFilter
        initialValues={{
          title_cont: '', description_cont: '',
        }}
        renderForm={() => (
          <>
            <Flexbox.Column className="pt-2">
              <Form.Group>
                <Form.Label className="label-sm pt-2" htmlFor="title_cont">Título</Form.Label>
                <Form.ValidationField
                  name="title_cont"
                  className="input-sm"
                  id="title_cont"
                  autoComplete="none"
                  placeholder="Título do item"
                />
              </Form.Group>
            </Flexbox.Column>
            <Flexbox.Column className="pt-2">
              <Form.Group>
                <Form.Label className="label-sm pt-2" htmlFor="description_cont">Descrição</Form.Label>
                <Form.ValidationField
                  name="description_cont"
                  className="input-sm"
                  id="description_cont"
                  autoComplete="none"
                  placeholder="Descrição do item"
                />
              </Form.Group>
            </Flexbox.Column>
          </>
        )}
      />
      <ListManager count={data?.rows?.length || 0} loading={loading} error={error}>
        {
          Boolean(data) && (
            <>
              <table className="table table-striped text-small">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th width="100" className="text-right">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.rows.map((i) => (
                      <Row
                        key={i.id}
                        item={i}
                        onStatusChange={fetchData}
                        onEdit={openForm}
                      />
                    ))
                  }
                </tbody>
              </table>
              <div className="float-right my-2">
                <Paginator paginatedList={data} />
              </div>
            </>
          )
        }
      </ListManager>
      {formVisible && (
        <FormClinicalCareItem
          onClose={closeForm}
          item={item}
          onSave={onSave}
        />
      )}
    </Styles.Container>
  )
}

export default ClinicalCareItems

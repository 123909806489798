import timelineItems from '@/services/api/timelineItems'
import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

export default function useReadTimelineItem() {
  const location = useLocation()

  const { timelineItemId } = location.state || {}

  useEffect(() => {
    if (timelineItemId) timelineItems.read(timelineItemId)
  }, [timelineItemId])
}

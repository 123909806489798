import styled from 'styled-components'
import { NavLink as RouterNavLink } from 'react-router-dom'

export const Navbar = styled.header.attrs({
  className: 'navbar',
})`
  position: sticky;
  top: .4rem;
  background-color: #fff;
  padding-bottom: .4rem;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
  z-index: 29;
  box-shadow: 0px 0px 8px 10px rgba(255,255,255,0.9);
`

export const HiddenTop = styled.div`
  position: sticky;
  width: 100%;
  top: 0;
  height: .4rem;
  background-color: #fff;
  margin-top: -.5rem;
`

export const NavbarSection = styled.section.attrs({
  cassName: 'navbar-section',
})``

export const Breadcrumb = styled.ul.attrs({
  className: 'breadcrumb',
})``

export const BreadcrumbItem = styled.li.attrs({
  className: 'breadcrumb-item',
})``

export const NavLink = styled(RouterNavLink)`
  text-decoration: none !important; 
`

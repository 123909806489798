/* eslint-disable max-len */
import { ErrorBox, Form, LoadManager } from '@/components'
import useCastration from '@/hooks/useCastration'
import usePageTitle from '@/hooks/usePageTitle'
import currency from '@/lib/masks/currency'
import yup from '@/lib/yupPt'
import { setCastration } from '@/reducers/castration'
import castrations from '@/services/api/castrations'
import React, { useCallback, useEffect, useState } from 'react'
import { useParams, useHistory, Link } from 'react-router-dom'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  description: yup.string().trim().required().min(10),
  licensingId: yup.number().required(),
})
function ChangeClinic({ title }) {
  usePageTitle({ title })
  const [licensings, setLicensings] = useState([])
  const [fetchingLicensings, setFetchingLicensings] = useState(false)
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const { state: { castration }, dispatch } = useCastration()
  const history = useHistory()

  const onSubmit = useCallback(async (form) => {
    try {
      setLoading(true)
      const { data } = await castrations.changeClinic(id, { ...form })
      dispatch(setCastration(data))
      toast.success('Clínica alterada')
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }, [dispatch, history, id])

  const fetchLicensings = useCallback(async () => {
    try {
      setFetchingLicensings(true)
      const { data } = await castrations.allowedLicensings(id)
      setLicensings(data)
    } finally {
      setFetchingLicensings(false)
    }
  }, [id])

  useEffect(() => {
    fetchLicensings()
  }, [fetchLicensings])

  const filteredLicensings = licensings.filter((i) => i.licensedId !== castration.clinicId)
  const isEmptyLicensings = !fetchingLicensings && !filteredLicensings.length

  return (
    <fieldset disabled={loading}>
      <Form.Formik
        initialValues={{
          description: '',
          licensingId: null,
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
        validateOnMount
      >
        {
          ({
            errors, touched, isValid, values,
          }) => (
            <Form.ValidationForm>
              <div className="modal active" id="modal-id">
                <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
                <div className="modal-container">
                  <div className="modal-header">
                    <a
                      type="button"
                      className="btn btn-clear float-right"
                      onClick={() => history.goBack()}
                    />
                    <div className="modal-title h5">Trocar a clínica da castração #{id}</div>
                  </div>
                  <div className="modal-body">
                    <div className="content">
                      <LoadManager loading={!castration}>
                        {
                          isEmptyLicensings ? (
                            <ErrorBox
                              error={{
                                icon: 'fas fa-clinic-medical fa-3x text-error',
                                title: 'Nenhuma clínica disponível',
                                message: 'Não encontramos nenhuma clínica com saldo para realizar castrações',
                              }}
                            >
                              <Link className="btn btn-link" to="/app/clinics/approved">
                                <i className="fas fa-plus" />
                                &nbsp;Clique aqui para adicionar saldo nas clínicas
                              </Link>
                            </ErrorBox>
                          ) : (
                            <>
                              <p>
                                Escolha uma clínica para realizar o serviço.
                                O saldo será reposto na clínica atual e decrementado da nova clínica
                              </p>
                              <div className="divider" />
                              {
                                fetchingLicensings ? (
                                  <div className="columns mx-1 mb-2">
                                    <div className="column col-auto loading" />
                                    <div className="column">
                                      <cite>
                                        Buscando licenças
                                      </cite>
                                    </div>
                                  </div>
                                ) : (
                                  <Form.Group>
                                    <Form.Label>Selecione para qual clínica a castração será enviada <span className="text-error">*</span></Form.Label>
                                    {
                                      filteredLicensings.map((licensing) => (
                                        <React.Fragment key={licensing?.id}>
                                          <div className="divider" />
                                          <Form.Label className="form-radio" key={licensing?.id}>
                                            <Form.ValidationField
                                              type="radio"
                                              name="licensingId"
                                              value={licensing?.id}
                                              checked={
                                                String(values?.licensingId) === String(licensing?.id)
                                              }
                                            />
                                            <i className="form-icon" />
                                            &nbsp;{licensing?.licensed?.fullName}
                                            <small> ({licensing?.licensed?.balance?.amount} {licensing?.licensed?.balance?.amount === 1 ? 'castração disponível' : 'castrações disponíveis'})</small>
                                            <div>
                                              <small>{currency(licensing.kmDistance, '', " km's de distância para o tutor")}</small>
                                            </div>
                                          </Form.Label>
                                        </React.Fragment>
                                      ))
                                    }
                                  </Form.Group>
                                )
                              }
                              <div className="divider" />
                              <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="description">Qual o motivo para alterar a clínica? <span className="text-error">*</span></Form.Label>
                                <Form.ValidationField
                                  name="description"
                                  id="description"
                                >
                                  {
                                    ({ field }) => (
                                      <textarea
                                        name="description"
                                        className="form-input"
                                        rows={2}
                                        placeholder="Foi necessário a alteração da clínica porque..."
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...field}
                                      />
                                    )
                                  }
                                </Form.ValidationField>
                                {
                                  touched?.description && errors?.description
                                    ? <Form.Hint className="text-error">{errors.description}</Form.Hint> : null
                                }
                              </Form.Group>
                            </>
                          )
                        }
                      </LoadManager>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <a
                      className="btn mr-2"
                      disabled={loading}
                      onClick={() => history.goBack()}
                    >
                      <i className="fas fa-chevron-left" />
                      &nbsp;Voltar
                    </a>
                    {
                      !isEmptyLicensings && (
                        <button
                          className={`btn btn-primary ${loading ? 'loading' : ''}`}
                          disabled={!isValid}
                          type="submit"
                          onClick={() => null}
                        >
                          <i className="fas fa-check" />
                          &nbsp;Trocar
                        </button>
                      )
                    }
                  </div>
                </div>
              </div>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </fieldset>
  )
}

export default ChangeClinic

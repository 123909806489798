import { Styles } from '@/components'
import useAdoptionFair from '@/hooks/useAdoptionFair'
import { setAdoptionFair } from '@/reducers/adoptionFair'
import { deepClone } from 'paliari-js-utils'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import FormEdit from '../../../components/Form'
import allowedActions from './allowedActions'
import Cancel from './Cancel'
import Close from './Close'
import Destroy from './Destroy'
import Publish from './Publish'
import Start from './Start'
import EditContacts from './EditContacts'

function Actions({ fair }) {
  const actions = allowedActions(fair?.status)
  const history = useHistory()
  const { dispatch } = useAdoptionFair()

  const onFairSave = useCallback((obj) => {
    dispatch(setAdoptionFair(obj))
  }, [dispatch])

  return (
    <Styles.ActionsContainer>
      {
        actions?.includes('start') && (
          <Start fair={fair} onStart={onFairSave} />
        )
      }
      {
        actions?.includes('confirm') && (
          <Publish fair={fair} onPublish={onFairSave} />
        )
      }
      {
        actions?.includes('edit') && (
          <FormEdit
            btnClass="btn btn-sm btn-primary mx-1"
            btnText="Editar"
            btnIcon="fas fa-edit"
            onFairSave={onFairSave}
            fair={deepClone(fair)}
          />
        )
      }
      {
        actions?.includes('editContacts') && (
          <EditContacts
            onFairSave={onFairSave}
            fair={deepClone(fair)}
          />
        )
      }
      {
        actions?.includes('delete') && (
          <Destroy fair={fair} onDestroy={() => history.goBack()} />
        )
      }
      {
        actions?.includes('cancel') && (
          <Cancel fair={fair} onCancel={onFairSave} />
        )
      }
      {
        actions?.includes('close') && (
          <Close fair={fair} onClose={onFairSave} />
        )
      }
      <a className="btn btn-sm btn-link mx-1" onClick={() => window.print()}>
        <i className="fas fa-print" />
        &nbsp;Imprimir
      </a>
    </Styles.ActionsContainer>
  )
}

Actions.propTypes = {
  fair: PropTypes.objectOf(Object).isRequired,
}

export default Actions

import { LoadManager, PageHeader, Styles } from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import terms from '@/services/api/settings/terms'
import React, { useState } from 'react'
import Edit from './components/Edit'

function Current({ title }) {
  usePageTitle({ title })
  const [editing, setEditing] = useState(false)
  const {
    data, loading, error, setData,
  } = useAxiosRequest(terms.current)

  function onUpdate(newTerms) {
    setData({ ...data, terms: newTerms })
    setEditing(false)
  }

  return (
      <Styles.Container className="grid-xl">
      <div className="py-2 only-print">
        <header className="navbar">
          <section className="navbar-section">
            <div className="d-flex align-center">
              <Styles.Logo style={{ width: 20 }} />
              &nbsp;
              <b>Petis</b>
            </div>
          </section>
        </header>
        <div className="divider" />
      </div>
        <PageHeader
          icon="fas fa-file-alt"
          title="Termos de castração"
          subtitle="Estes são os termos que o usuário deve aceitar para solicitar castrações"
        />
        <LoadManager error={error} loading={loading}>
          <>
            {
              data?.terms
                ? (
                  <>
                    <div className="pre text-justify">
                      {data?.terms}
                    </div>
                    <div className="mt-2 no-print">
                      <a className="c-hand mr-2" onClick={() => setEditing(true)}>
                        <i className="fas fa-edit" />
                        &nbsp;Alterar os termos
                      </a>
                      <a className="c-hand ml-2" onClick={() => window.print()}>
                        <i className="fas fa-print" />
                        &nbsp;Imprimir os termos
                      </a>
                    </div>
                  </>
                )
                : (
                  <div className="empty">
                    <div className="empty-icon">
                      <i className="fas fa-3x fa-file-alt" />
                    </div>
                    <p className="empty-title h5">Termo de castração não informado</p>
                    <p className="empty-subtitle">O usuário não precisará concordar com termos para solicitar castrações.</p>
                    <div className="empty-action">
                      <button type="button" onClick={() => setEditing(true)} className="btn btn-primary">
                        <i className="fas fa-plus" />
                        &nbsp;Adicionar termos
                      </button>
                    </div>
                  </div>
                )
            }
          </>
        </LoadManager>
        {
          editing && (
            <Edit onClose={() => setEditing(false)} terms={data?.terms || ''} onUpdate={onUpdate} />
          )
        }
      </Styles.Container>
  )
}

export default Current

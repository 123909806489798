import api from './api'

export default {
  list(params) {
    return api.get('/partner-licensings/:partnerLicensingId/chats/', { params })
  },
  messages(chatId) {
    return api.get(`partner-licensings/:partnerLicensingId/chats/${chatId}`)
  },
  addMessage(chatId, body) {
    return api.post(`partner-licensings/:partnerLicensingId/chats/${chatId}`, body)
  },
  readChat(chatId) {
    return api.post(`partner-licensings/:partnerLicensingId/chats/${chatId}/read`)
  },
  show(chatId) {
    return api.get(`/partner-licensings/:partnerLicensingId/chats/${chatId}/show`)
  },
}

import api from '../api'

export default {
  list(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/settings/clinical-care-items', { params })
  },
  create(body) {
    return api.post('/partner-licensings/:partnerLicensingId/settings/clinical-care-items', body)
  },
  update(id, body) {
    return api.put(`/partner-licensings/:partnerLicensingId/settings/clinical-care-items/${id}`, body)
  },
}

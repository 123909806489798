import React, { useCallback } from 'react'
import usePageTitle from '@/hooks/usePageTitle'
import { useHistory, useLocation } from 'react-router-dom'
import useQuery from '@/hooks/useQuery'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import adoptionFairs from '@/services/api/adoptionFairs'
import {
  Flexbox, Form, ListManager, Paginator,
} from '@/components'
import qs from 'qs'
import Row from './Row'

export default function Transfer({ title }) {
  usePageTitle({ title })
  const history = useHistory()
  const location = useLocation()
  const query = useQuery()
  const {
    loading, data, error,
  } = useAxiosRequest(adoptionFairs.list, query)

  const onSubmit = useCallback((params) => {
    const newQuery = { ...query, q: params }
    history.replace(`${location.pathname}?${qs.stringify(newQuery)}`)
  }, [history, location.pathname, query])

  return (
    <div className="modal active">
      <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
      <div className="modal-container">
        <div className="modal-header">
          <a
            type="button"
            className="btn btn-clear float-right"
            onClick={() => history.goBack()}
          />
          <div className="modal-title h5">
            Escolha para qual feira deseja transferir o pet
          </div>
        </div>
        <div className="modal-body">
          <Form.Formik
            initialValues={{
              title: query?.q?.title || '',
            }}
            onSubmit={onSubmit}
          >
            {
              () => (
                <Form.ValidationForm>
                  <Flexbox.Columns>
                    <Flexbox.Column>
                      <Form.Group>
                        <Form.ValidationField
                          className="input-sm"
                          name="title"
                          id="title"
                          placeholder="Buscar feira"
                        />
                      </Form.Group>
                    </Flexbox.Column>
                    <Flexbox.Column className="col-auto">
                      <button type="submit" className="btn btn-sm btn-primary btn-action">
                        <i className="fas fa-search" />
                      </button>
                    </Flexbox.Column>
                  </Flexbox.Columns>
                </Form.ValidationForm>
              )
            }
          </Form.Formik>
          <ListManager loading={loading} count={data?.count || 0} error={error}>
            <>
              <div className="pt-2">
                <div className="divider" />
              </div>
              <div className="table-scroll">
                <small>
                  <table className="table text-small table-striped table-hover">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Título</th>
                        <th width={300}>Status</th>
                        <th width={300}>Restrição</th>
                        <th width={1} className="text-right">Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data?.rows.map((fair) => (
                        <Row fair={fair} key={fair?.id} />
                      ))}
                    </tbody>
                  </table>
                </small>
              </div>
              {
                Boolean(data) && (
                  <div className="float-right my-2">
                    <Paginator paginatedList={data} />
                  </div>
                )
              }
            </>
          </ListManager>
        </div>
      </div>
    </div>
  )
}

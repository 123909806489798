import { Card, Flexbox } from '@/components'
import React from 'react'
import { Container, BrandLogo } from './styles'
import { version } from '../../../../package.json'

function About() {
  return (
    <Container className="grid-xl">
      <div className="flex-centered full-height">
        <Card.Base style={{
          height: 'auto', width: '40%', marginTop: 200, minWidth: '300px', maxWidth: '400px',
        }}
        >
          <Card.Header>
            <Flexbox.Columns className="align-center">
              <Flexbox.Column className="col-auto">
                <BrandLogo />
              </Flexbox.Column>
              <Flexbox.Column>
                <div className="h4">Petis Partner</div>
              </Flexbox.Column>
            </Flexbox.Columns>
          </Card.Header>
          <Card.Body>
            Plataforma do parceiro Petis
            <div>
              <a target="_blank" rel="noreferrer" href="https://petis.me">https://petis.me</a>
            </div>
            <div className="divider" />
            <div>
              Desenvolvido por:
            </div>
            <div>
              <b><a target="_blank" rel="noreferrer" href="https://paliari.com.br">Paliari Engenharia de Software</a></b>
            </div>
            <div className="divider" />
          </Card.Body>
          <Card.Footer className="text-right">
            Versão: <b>{version}</b>
          </Card.Footer>
        </Card.Base>
      </div>
    </Container>
  )
}

export default About

import { ListManager, Paginator } from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import clinics from '@/services/api/clinics'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import TransactionRow from '../components/TransactionRow'

function Transactions({ title }) {
  usePageTitle({ title })

  const query = useQuery()
  const { id } = useParams()
  const history = useHistory()
  const { data, error, loading } = useAxiosRequest(clinics.transactions, id, query)

  return (
    <div className="modal active" id="modal-id">
      <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
      <div className="modal-container">
        <div className="modal-header">
          <a
            type="button"
            className="btn btn-clear float-right"
            onClick={() => history.goBack()}
          />
          <div className="modal-title h5">Histórico de transações</div>
        </div>
        <div className="modal-body">
          <div className="content">

            <ListManager loading={loading} count={data?.count || 0} error={error}>
              <cite>Lista de todas as transações da clínica</cite>
              <div className="divider" />
              {
                data?.rows?.map((transaction) => (
                  <TransactionRow key={transaction?.id} transaction={transaction} />
                ))
              }
              {
                Boolean(data) && (
                  <div className="float-right my-2">
                    <Paginator paginatedList={data} />
                  </div>
                )
              }
            </ListManager>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Transactions

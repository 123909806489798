export const INITIAL_STATE = { batch: null }

export default function batchReducer(state, action) {
  switch (action.type) {
    case ('setBatch'): return { batch: action.payload }
    default: return INITIAL_STATE
  }
}

export function setBatch(payload) {
  return { type: 'setBatch', payload }
}

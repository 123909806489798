import React, { useReducer } from 'react'
import adoptionFairContext from '@/contexts/adoptionFairContext'
import adoptionFairReducer, { INITIAL_STATE } from '@/reducers/adoptionFair'
import AdoptionFairs from '@/views/app/AdoptionFairs'
import AdoptionFairsShow from '@/views/app/AdoptionFairs/Show'
import { Route, useRouteMatch } from 'react-router-dom'
import Adoptions from '@/views/app/AdoptionFairs/Show/Adoptions'
import Pets from '@/views/app/AdoptionFairs/Show/Pets'
import PetsShow from '@/views/app/AdoptionFairs/Show/Pets/Show'
import PetsShowComplaints from '@/views/app/AdoptionFairs/Show/Pets/Show/Complaints'
import Transfer from '@/views/app/AdoptionFairs/Show/Pets/Transfer'
import AddAnimal from '@/views/app/AdoptionFairs/Show/Pets/AddAnimal'
import AdoptionsRequested from '@/views/app/AdoptionFairs/Show/AdoptionsRequested'
import ApproveAdoptionRequested from '@/views/app/AdoptionFairs/Show/AdoptionsRequested/Actions/Approve'
import RejectAdoptionRequested from '@/views/app/AdoptionFairs/Show/AdoptionsRequested/Actions/Reject'
import Chats from '@/views/app/AdoptionFairs/Show/Pets/Chats'
import ChatMessages from '@/views/app/AdoptionFairs/Show/Pets/Chats/Messages'

function AdoptionFairsRoutes() {
  const { path } = useRouteMatch()
  const [state, dispatch] = useReducer(adoptionFairReducer, INITIAL_STATE)

  return (
    <div>
      <Route exact path={path}>
        <AdoptionFairs title="Petis - Parceiros / Feiras de adoção" />
      </Route>
      <adoptionFairContext.Provider value={{ state, dispatch }}>
        <Route path={`${path}/:id`}>
          <AdoptionFairsShow title="Petis - Parceiros / Feira de adoção" />
        </Route>
        <Route path={`${path}/:id/adoptions`}>
          <Adoptions title="Petis - Parceiros / Feira de adoção / Adoções" />
        </Route>
        <Route path={`${path}/:id/add-pet`}>
          <AddAnimal title="Petis - Parceiros / Feira de adoção / Adicionar pet" />
        </Route>
        <Route path={`${path}/:id/pets`}>
          <Pets title="Petis - Parceiros / Feira de adoção / Pets" />
        </Route>
        <Route exact path={`${path}/:id/pets/:petId`}>
          <PetsShow title="Petis - Parceiros / Feira de adoção / Pets / Pet" />
        </Route>
        <Route exact path={`${path}/:id/pets/:petId/transfer`}>
          <Transfer title="Petis - Parceiros / Feira de adoção / Pets / Transferir pet" />
        </Route>
        <Route exact path={`${path}/:id/pets/:petId/complaints`}>
          <PetsShowComplaints title="Petis - Parceiros / Feira de adoção / Pets / Pet / Denúncias" />
        </Route>
        <Route exact path={`${path}/:id/pets/:petId/chats`}>
          <Chats title="Petis - Parceiros / Feira de adoção / Pets / Chats" />
        </Route>
        <Route exact path={`${path}/:id/pets/:petId/chats/:chatId/messages`}>
          <ChatMessages title="Petis - Parceiros / Feira de adoção / Pets / Chat / Mensagens" />
        </Route>
        <Route path={`${path}/:id/adoptions-requested`}>
          <AdoptionsRequested title="Petis - Parceiros / Feira de adoção / Solicitações de adoção" />
        </Route>
        <Route path={`${path}/:id/adoptions-requested/:petId/approve`}>
          <ApproveAdoptionRequested title="Petis - Parceiros / Feira de adoção / Solicitações de adoção / Aprovar" />
        </Route>
        <Route path={`${path}/:id/adoptions-requested/:petId/reject`}>
          <RejectAdoptionRequested title="Petis - Parceiros / Feira de adoção / Solicitações de adoção / Rejeitar" />
        </Route>

      </adoptionFairContext.Provider>
    </div>
  )
}

export default AdoptionFairsRoutes

/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react'
import PropTypes from 'prop-types'

function DateSelect({ mapping, selected, onSelect }) {
  return (
    <div className="dropdown">
      <a href="#" className="btn btn-link dropdown-toggle" tabIndex="0">
        {selected.label}
        <i className="icon icon-caret" />
      </a>

      <ul className="menu" style={{ minWidth: 200 }}>
        {
          mapping.filter((i) => i.key !== selected.key).map((item) => (
            <li key={item.key} className="menu-item">
              <a className="c-hand" onClick={() => onSelect(item)}>
                {item.label}
              </a>
            </li>
          ))
        }
      </ul>
    </div>
  )
}

DateSelect.propTypes = {
  selected: PropTypes.objectOf(Object).isRequired,
  mapping: PropTypes.arrayOf(Object).isRequired,
  onSelect: PropTypes.func.isRequired,
}

export default DateSelect

/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import {
  AddressPicker, Flexbox, Form, LoadManager,
} from '@/components'
import useClinicalCare from '@/hooks/useClinicalCare'
import usePageTitle from '@/hooks/usePageTitle'
import yup from '@/lib/yupPt'
import { setClinicalCare } from '@/reducers/clinicalCare'
import clinicalCares from '@/services/api/clinicalCares'
import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { FileUploader } from 'react-drag-drop-files'
import upload from '@/services/api/upload'

const fileTypes = ['JPG', 'PNG', 'PDF']

const schema = yup.object().shape({
  description: yup.string().trim(),
  outsourcedTransport: yup.boolean(),
})
function Approve({ title }) {
  usePageTitle({ title })
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])
  const { id } = useParams()
  const { state: { clinicalCare }, dispatch } = useClinicalCare()
  const [address, setAddress] = useState()
  const history = useHistory()

  useEffect(() => {
    if (clinicalCare?.clinic?.address) {
      // eslint-disable-next-line no-unused-vars, no-shadow
      const { id, ...rest } = clinicalCare?.clinic?.address
      setAddress(rest)
    }
  }, [clinicalCare?.clinic?.address])

  function onSelectFiles(params) {
    const added = Object.values(params).filter((file) => !files.find((f) => f.name === file.name))
    setFiles([...files, ...(added)])
  }

  async function onSubmit(values) {
    try {
      setLoading(true)
      const attachments = []
      for (const file of files) {
        const { data: { url } } = await upload.sendFile(file)
        attachments.push(url)
      }
      const body = {
        ...values,
        address,
        attachments,
      }
      const { data } = await clinicalCares.approve(id, body)
      dispatch(setClinicalCare({ ...data, tutor: data?.animal?.tutor }))
      toast.success('Atendimento aprovado')
      setLoading(false)
      history.goBack()
    } catch (e) {
      setLoading(false)
      toast.error(e?.message)
    }
  }

  return (
    <fieldset disabled={loading}>
      <div className="modal active" id="modal-id">
        <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
        <div className="modal-container">
          <div className="modal-header">
            <a
              type="button"
              className="btn btn-clear float-right"
              onClick={() => history.goBack()}
            />
            <div className="modal-title h5">Aprovar atendimento #{id}</div>
          </div>
          <div className="modal-body">
            <div className="content">
              <LoadManager loading={!clinicalCare}>
                <>
                  <div className="mb-1">Confirma a aprovação para o atendimento de <b className="text-primary">{clinicalCare?.animal?.name}</b>?</div>
                  <div className="text-small mb-1">
                    Esta ação vai gerar um evento de aprovação,
                    em seguida será necessário levar o pet até a clínica
                  </div>
                  <div>
                    <Form.Label>Você pode informar o endereço de destino do pet</Form.Label>
                    <AddressPicker address={address} onChange={setAddress} title="Informe o endereço de destino do pet" />
                  </div>
                  <div>
                    <Form.Label>Você pode adicionar anexos à este evento</Form.Label>
                    <FileUploader
                      handleChange={onSelectFiles}
                      hoverTitle="Solte aqui"
                      multiple
                      types={fileTypes}
                    >
                      {
                        files.length === 0 ? (
                          <div className="c-hand bg-gray text-center text-dark" style={{ paddingTop: 30, paddingBottom: 30 }}>
                            <div className="empty-icon">
                              <i className="fas fa-paperclip fa-lg" />
                            </div>
                            <div className="empty-title h5">Selecione os anexos</div>
                            <div className="empty-subtitle">Clique ou arraste os anexos para adicionar</div>
                          </div>
                        ) : (
                          <div className="c-hand bg-gray text-center" style={{ paddingTop: 30, paddingBottom: 30 }}>
                            <div className="empty-title h5">Anexos adicionados: {files.length}</div>
                            <div className="empty-subtitle">Clique ou arraste anexos para adicionar mais</div>
                          </div>
                        )
                      }
                    </FileUploader>
                  </div>
                  <Form.Formik
                    initialValues={{
                      description: '',
                      outsourcedTransport: false,
                    }}
                    validationSchema={schema}
                    onSubmit={onSubmit}
                    validateOnMount
                  >
                    {
                      ({ touched, errors }) => (
                        <Form.ValidationForm>
                          <Flexbox.Columns>
                            <Flexbox.Column className="col-12">
                              <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="description">Observações</Form.Label>
                                <Form.ValidationField
                                  name="description"
                                  id="description"
                                >
                                  {
                                    ({ field }) => (
                                      <textarea
                                        name="description"
                                        className="form-input"
                                        rows={2}
                                        placeholder="Você pode adicionar alguma observação..."
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...field}
                                      />
                                    )
                                  }
                                </Form.ValidationField>
                                {
                                  touched?.description && errors?.description
                                    ? <Form.Hint className="text-error">{errors.description}</Form.Hint> : null
                                }
                              </Form.Group>
                            </Flexbox.Column>
                            <Flexbox.Column className="col-12">
                              <Form.Group>
                                <Form.Switch className="form-switch">
                                  <Form.ValidationField
                                    type="checkbox"
                                    name="outsourcedTransport"
                                    id="outsourcedTransport"
                                  />
                                  <i className="form-icon" />
                                  &nbsp;O Transporte será terceirizado
                                </Form.Switch>
                                <div style={{ marginTop: -8 }}>
                                  <small className="text-gray">
                                    Se ativo, significa que o
                                    pet foi transportado por algum terceirizado
                                  </small>
                                </div>
                              </Form.Group>
                            </Flexbox.Column>
                          </Flexbox.Columns>
                          <div className="modal-footer" style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <a
                              className="btn mr-2"
                              disabled={loading}
                              onClick={() => history.goBack()}
                            >
                              <i className="fas fa-chevron-left" />
                              &nbsp;Voltar
                            </a>
                            <button
                              className={`btn btn-success ${loading ? 'loading' : ''}`}
                              disabled={loading}
                              type="submit"
                            >
                              <i className="fas fa-check" />
                              &nbsp;Aprovar
                            </button>
                          </div>
                        </Form.ValidationForm>
                      )
                    }
                  </Form.Formik>
                </>
              </LoadManager>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  )
}

export default Approve

import React from 'react'
import PropTypes from 'prop-types'
import {
  Avatar, Card, ErrorBox, Flexbox,
} from '@/components'
import dateMask from '@/lib/masks/date'
import { ADOPTION } from '@/lib/enums'
import { statusColorMap } from '@/constants/adoption'

function AdoptionsCard({ adoptions }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Histórico de adoções</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-heart" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body style={{ maxHeight: 300, overflow: 'auto' }}>
        {
          adoptions?.length
            ? (
              <table className="table">
                <thead>
                  <tr>
                    <th>Solicitante</th>
                    <th>Tutor <small>(Antes da adoção)</small></th>
                    <th>Feira</th>
                    <th width={150}>Solicitado em</th>
                    <th width={200} className="text-right">Status</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    adoptions.map((adoption) => (
                      <tr key={adoption?.id}>
                        <td>
                          <Avatar
                            avatarSize="sm"
                            name={adoption?.newTutor?.fullName}
                            photoURL={adoption?.newTutor?.photoURL}
                          />
                          &nbsp;{adoption?.newTutor?.fullName}
                        </td>
                        <td>
                          <Avatar
                            avatarSize="sm"
                            name={adoption?.donor?.fullName}
                            photoURL={adoption?.donor?.photoURL}
                          />
                          &nbsp;{adoption?.donor?.fullName}
                        </td>
                        <td>
                          {
                            adoption?.fair
                              ? adoption?.fair?.title
                              : 'Não'
                          }
                        </td>
                        <td>{dateMask(adoption?.createdAt)}</td>
                        <td className={`text-right ${statusColorMap[adoption?.status]}`}>
                          {ADOPTION.status.t(adoption?.status)}
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            ) : (
              <ErrorBox
                error={{
                  icon: 'fas fa-heart fa-2x',
                  title: 'Nenhuma adoção',
                  message: 'Esse pet não possui histórico de adoções',
                }}
              />
            )
        }
      </Card.Body>
    </Card.Base>
  )
}

AdoptionsCard.propTypes = {
  adoptions: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default AdoptionsCard

export const INITIAL_STATE = { licensing: null }

export default function licensingReducer(state, action) {
  switch (action.type) {
    case ('setLicensing'): return { licensing: action.payload }
    default: return INITIAL_STATE
  }
}

export function setLicensing(payload) {
  return { type: 'setLicensing', payload }
}

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import dateMask from '@/lib/masks/date'
import useAuth from '@/hooks/useAuth'
import ImageViewer from '@/components/ImageViewer'
import Flexbox from '@/components/Flexbox'
import Avatar from '@/components/Avatar'
import { Image } from './styles'

function Row({
  comment, tutorId, clinicId, providerId,
}) {
  const { user } = useAuth()
  const author = comment?.author
  const [image, setImage] = useState('')

  const userHasAuthor = user?.id === comment?.authorId
  const isTutor = comment?.authorId === tutorId
  const isClinic = comment?.authorId === clinicId
  const isPartner = comment?.authorId === providerId || userHasAuthor

  return (
    <>
      {
        Boolean(image) && (
          <ImageViewer src={image} onClose={() => setImage('')} />
        )
      }
      <Flexbox.Columns className={`my-2 ${userHasAuthor || isPartner ? 'row-reverse ml-2' : 'mr-2'}`}>
        <Flexbox.Column className="col-auto">
          <Avatar
            name={author?.fullName}
            photoURL={author?.photoURL}
            alt="Imagem de usuário"
          />
        </Flexbox.Column>
        <Flexbox.Column>
          <div className="bg-gray p-2 s-rounded">
            <div className="pre">{comment?.description}</div>
            {
              Boolean(comment?.images) && (
                <Image
                  className="c-hand"
                  onClick={() => setImage(comment?.images)}
                  src={comment?.images}
                />
              )
            }
            <div>
              <div className="divider" />
              <small>
                <b>{author?.fullName}</b> em
                <b> {dateMask(comment?.createdAt)}</b> às
                <b> {new Date(comment?.createdAt).toLocaleTimeString()}</b>
              </small>
              <div>
                {isTutor && (
                  <small className="pr-2">
                    <i className="fas fa-user" />
                    &nbsp;Tutor
                  </small>
                )}
                {isClinic && (
                  <small className="pr-2">
                    <i className="fas fa-user-md" />
                    &nbsp;Clínica
                  </small>
                )}
                {isPartner && (
                  <small className="pr-2">
                    <i className="fas fa-user-astronaut" />
                    &nbsp;Administrador
                  </small>
                )}
              </div>
            </div>
          </div>
        </Flexbox.Column>
      </Flexbox.Columns>
    </>
  )
}

Row.propTypes = {
  comment: PropTypes.objectOf(Object).isRequired,
  tutorId: PropTypes.number,
  clinicId: PropTypes.number,
  providerId: PropTypes.number,
}

Row.defaultProps = {
  tutorId: null,
  clinicId: null,
  providerId: null,
}

export default Row

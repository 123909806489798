import { Avatar, Card, Flexbox } from '@/components'
import { ANIMAL } from '@/lib/enums'
import currency from '@/lib/masks/currency'
import dateMask from '@/lib/masks/date'
import documentNumberMask from '@/lib/masks/documentNumber'
import phoneNumber from '@/lib/masks/phoneNumber'
import rga from '@/lib/masks/rga'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'
import { Tag } from './styles'

function AnimalCard({ animal, castration }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Pet</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-paw" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>
            <Flexbox.Columns>
              <Flexbox.Column className="col-auto">
                <Avatar
                  avatarSize="lg"
                  name={animal?.name}
                  photoURL={animal?.photoURL}
                  smart={false}
                  alt="Imagem do animal"
                />
              </Flexbox.Column>
              <Flexbox.Column>
                <div className="text-primary text-bold">{animal?.name}</div>
                <div className="text-small">{rga(animal?.uid)}</div>
              </Flexbox.Column>
            </Flexbox.Columns>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <Link className="btn btn-link btn-sm" to={`/app/pets/${animal?.id}`}>
              <i className="fas fa-link" />
              &nbsp;Ver pet
            </Link>
          </Flexbox.Column>
        </Flexbox.Columns>
        <div className="divider" />
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Raça</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {animal?.breed?.description}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Espécie</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {animal?.category?.description}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Sexo</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {ANIMAL.gender.t(animal?.gender)}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Nascimento</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {dateMask(animal?.bornedAt)}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Porte</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {ANIMAL.size.t(animal?.size)}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Peso</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {animal?.weight ? currency(animal?.weight, '', ' Kg') : '-'}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Chip</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {animal?.chip?.uid || '-'}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column className="col-12">Observações</Flexbox.Column>
          <Flexbox.Column className="col-12 text-bold">
            {animal?.description || '-'}
          </Flexbox.Column>
        </Flexbox.Columns>
        {
          animal?.adoptions?.length ? (
            <div className="pt-2">
              <Flexbox.Columns className="mb-1">
                <Tag>
                  Adotado em Feira de adoção:
                  <b>&nbsp;{animal.adoptions[0]?.fair?.title}</b>
                </Tag>
              </Flexbox.Columns>
            </div>
          ) : null
        }
        {
          castration?.animalAgeInMonths <= 6 ? (
            <div className="pt-2">
              <Flexbox.Columns className="mb-1">
                <Tag>
                  Animal com até 6 meses de idade
                </Tag>
              </Flexbox.Columns>
            </div>
          ) : null
        }
        {
          castration?.animalAgeInMonths >= 84 ? (
            <div className="pt-2">
              <Flexbox.Columns className="mb-1">
                <Tag>
                  Animal com 7 anos de idade ou mais
                </Tag>
              </Flexbox.Columns>
            </div>
          ) : null
        }
      </Card.Body>
      {
        Boolean(animal.tutor) && (
          <Card.Footer>
            <div className="divider text-center" data-content="Detalhes do tutor atual" />
            <Flexbox.Columns className="align-center col-gapless">
              <Flexbox.Column className="col-auto">
                <Avatar avatarSize="lg" photoURL={animal?.tutor?.photoURL} name={animal?.tutor?.fullName} />
              </Flexbox.Column>
              <Flexbox.Column className="ml-2">
                <div><b>{animal?.tutor?.fullName}</b></div>
                <div>{documentNumberMask(animal?.tutor?.documentNumber)}</div>
              </Flexbox.Column>
            </Flexbox.Columns>
            <small>
              <Flexbox.Columns className="mt-2">
                <Flexbox.Column>E-mail:</Flexbox.Column>
                <Flexbox.Column className="col-auto">
                  {animal?.tutor?.email}
                </Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns className="mt-1">
                <Flexbox.Column>Fone:</Flexbox.Column>
                <Flexbox.Column className="col-auto">
                  {phoneNumber(animal?.tutor?.phoneNumber) || '-'}
                </Flexbox.Column>
              </Flexbox.Columns>
            </small>
          </Card.Footer>
        )
      }
    </Card.Base>
  )
}

AnimalCard.propTypes = {
  animal: PropTypes.objectOf(Object).isRequired,
  castration: PropTypes.objectOf(Object).isRequired,
}

export default AnimalCard

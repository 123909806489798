import {
  Flexbox, LoadManager, PageHeader, Share, Styles,
} from '@/components'
import {
  descriptions, iconClasses, singularStatusMapLabel, textClasses,
  descriptionsRestrict, singularRestrictMapLabel, textRestrict, iconRestrict,
} from '@/constants/adoptionFair'
import useAdoptionFair from '@/hooks/useAdoptionFair'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import dateMask from '@/lib/masks/date'
import { setAdoptionFair } from '@/reducers/adoptionFair'
import adoptionFairs from '@/services/api/adoptionFairs'
import React, { useEffect } from 'react'
import { NavLink, useParams } from 'react-router-dom'
import envs from '@/constants/envs'
import formatAddress from '@/lib/formatters/address'
import phoneNumber from '@/lib/masks/phoneNumber'
import Actions from './components/Actions'
import Terms from './components/Terms'

function Show({ title }) {
  usePageTitle({ title })
  const { id } = useParams()
  const { state: { adoptionFair }, dispatch } = useAdoptionFair()
  const { data, error, loading } = useAxiosRequest(adoptionFairs.show, id)

  useEffect(() => {
    if (data) dispatch(setAdoptionFair(data))
  }, [data, dispatch])

  return (
    <Styles.Container className="grid-xl">
      <div className="only-print">
        <header className="navbar">
          <section className="navbar-section">
            <div className="d-flex align-center">
              <Styles.Logo style={{ width: 20 }} />
              &nbsp;
              <b>Petis</b>
            </div>
          </section>
        </header>
        <div className="divider" />
        <PageHeader
          title="Feira de adoção"
          icon="fas fa-heart"
        />
      </div>
      <div className="no-print">
        <PageHeader
          title={`Feira de adoção #${id}`}
          subtitle="Controle o andamento da feira por este painel"
          icon="fas fa-heart"
        />
      </div>
      <LoadManager loading={loading} error={error}>
        {
          Boolean(adoptionFair) && (
            <>
              <div className="no-print">
                <Actions className="no-print" fair={adoptionFair} />
              </div>
              <div className="h3">{adoptionFair?.title}</div>
              <p className="pre">
                {adoptionFair?.description}
              </p>
              <div className="text-small no-print">
                <Flexbox.Columns>
                  <Flexbox.Column className="col-6 mb-2">
                    <div>
                      <cite>Criado em: <b>{dateMask(adoptionFair?.createdAt)}</b></cite>
                    </div>
                    <div>
                      <cite>Iniciado em: <b>{dateMask(adoptionFair?.startedAt)}</b></cite>
                    </div>
                  </Flexbox.Column>
                  <Flexbox.Column className="col-6 mb-2">
                    <div>
                      <cite>Iniciar em: <b>{dateMask(adoptionFair?.beginIn)}</b></cite>
                    </div>
                    <div>
                      <cite>
                        Encerrar em: <b>{dateMask(adoptionFair?.endIn)}</b>
                      </cite>
                    </div>
                  </Flexbox.Column>
                </Flexbox.Columns>
              </div>
              <div className="text-small only-print">
                <Flexbox.Columns>
                  <Flexbox.Column className="col-6 mb-2">
                    <div>
                      <cite>Início em: <b>{dateMask(adoptionFair?.beginIn)}</b></cite>
                    </div>
                    <div>
                      <cite>
                        Encerramento em: <b>{dateMask(adoptionFair?.endIn)}</b>
                      </cite>
                    </div>
                  </Flexbox.Column>
                </Flexbox.Columns>
              </div>
              <div className="my-2 py-2">
                Restrição da feira:
                <span className={textRestrict[adoptionFair?.restrict]}>
                  <span className="ml-2">
                    <i className={iconRestrict[adoptionFair?.restrict]} />
                    &nbsp;{singularRestrictMapLabel[adoptionFair?.restrict]}
                  </span>
                </span>
                <div className="text-small">
                  {descriptionsRestrict[adoptionFair?.restrict]}
                </div>
              </div>
              <div className="my-2 py-2">
                Status:
                <b className={textClasses[adoptionFair?.status]}>
                  <span className="ml-2">
                    <i className={iconClasses[adoptionFair?.status]} />
                    &nbsp;{singularStatusMapLabel[adoptionFair?.status]}
                  </span>
                </b>
              </div>
              {
                Boolean(descriptions[adoptionFair?.status]) && (
                  <div>
                    <cite>- {descriptions[adoptionFair?.status]}</cite>
                  </div>
                )
              }
              <div className="divider" />
              <Terms fair={adoptionFair} onFairSave={(fair) => dispatch(setAdoptionFair(fair))} />
              <div className="divider" />
              <div className="h6">Endereço</div>
              <div>
                {formatAddress(adoptionFair?.address)}
              </div>
              <div className="divider" />
              <div className="h6">Contato</div>
              {(adoptionFair?.phoneNumber || adoptionFair?.email) ? (
                <>
                {adoptionFair?.email && (
                    <div>
                      E-mail:
                      &nbsp;
                      {adoptionFair.email}
                    </div>
                  )}
                  {adoptionFair?.phoneNumber && (
                    <div>
                      Telefone:
                      &nbsp;
                      {phoneNumber(adoptionFair.phoneNumber)}
                    </div>
                  )}
                  <div className="divider" />
                </>
              ) : (
                <div>
                  <cite>Nenhum contato informado</cite>
                  <div className="divider" />
                </div>
              )}
              <Flexbox.Columns className="my-2 py-2">
                <Flexbox.Column className="col-auto">
                  Compartilhar feirinha:
                </Flexbox.Column>
                <Flexbox.Column>
                  <Share
                    url={`${envs.REACT_APP_BASE_WEB_SHOW_URL}/adoption-fairs/${id}`}
                    title={`A feirinha "${adoptionFair?.title}" está disponível no Petis para você adotar um novo amigo`}
                  />
                </Flexbox.Column>
              </Flexbox.Columns>
              <div className="divider" />
              <ul className="tab tab-block no-print">
                <li className="tab-item">
                  <NavLink to={`/app/adoption-fairs/${adoptionFair?.id}/pets`}>
                    <i className="fas fa-fw fa-paw" />
                    &nbsp;Pets na feira
                  </NavLink>
                </li>
                <li className="tab-item">
                  <NavLink to={`/app/adoption-fairs/${adoptionFair?.id}/adoptions`}>
                    <i className="fas fa-fw fa-heart" />
                    &nbsp;Adoções da feira
                  </NavLink>
                </li>
                <li className="tab-item">
                  <span
                    className={`${adoptionFair.countAdoptionRequested > 0 ? 'badge' : ''}`}
                    data-badge={adoptionFair.countAdoptionRequested}
                  >
                    <NavLink to={`/app/adoption-fairs/${adoptionFair?.id}/adoptions-requested`}>
                      <i className="fas fa-hand-holding-heart" />
                      &nbsp;Solicitações de adoção
                    </NavLink>
                  </span>
                </li>
              </ul>
            </>
          )
        }
      </LoadManager>
    </Styles.Container>
  )
}

export default Show

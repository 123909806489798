export const INITIAL_STATE = { clinicalCare: null }

export default function clinicalCareReducer(state, action) {
  switch (action.type) {
    case ('setClinicalCare'): return { clinicalCare: action.payload }
    default: return INITIAL_STATE
  }
}

export function setClinicalCare(payload) {
  return { type: 'setClinicalCare', payload }
}

/* eslint-disable eqeqeq */
/* eslint-disable no-plusplus */
export const validador = (valor) => (valor % 11 < 2 ? 0 : 11 - (valor % 11))

export default (cnpj) => {
  if (!cnpj) return true
  if (cnpj.length !== 14) {
    return false
  }
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  let soma = 0
  let pos = tamanho - 7
  const digitos = cnpj.substring(tamanho)

  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }
  const resultado = validador(soma)

  if (resultado != digitos.charAt(0)) {
    return false
  }
  tamanho += 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7
  for (let i = tamanho; i >= 1; i--) {
    soma += numeros.charAt(tamanho - i) * pos--
    if (pos < 2) {
      pos = 9
    }
  }

  return true
}

import dateMask from '@/lib/masks/date'
import React from 'react'
import PropTypes from 'prop-types'
import { Card, Flexbox } from '@/components'
import { singularRestrictMapLabel } from '@/constants/adoptionFair'
import formatAddress from '@/lib/formatters/address'

function FairCard({ fair }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Feira</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-heart" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>
            <Flexbox.Columns>
              <Flexbox.Column>
                <div className="text-bold">{fair?.title}</div>
                <div className="text-small">{fair?.description}</div>
              </Flexbox.Column>
            </Flexbox.Columns>
          </Flexbox.Column>
        </Flexbox.Columns>
        <div className="divider" />
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Criado em</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {dateMask(fair?.createdAt)}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Restrição da feira</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {singularRestrictMapLabel[fair?.restrict]}
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Body>
      <Card.Footer>
        {
          Boolean(fair?.address) && (
            <>
              <div className="divider" />
              {formatAddress(fair?.address)}
            </>
          )
        }
        <div className="divider" />
      </Card.Footer>
    </Card.Base>
  )
}

FairCard.propTypes = {
  fair: PropTypes.objectOf(Object).isRequired,
}

export default FairCard

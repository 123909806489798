import { Flexbox } from '@/components'
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { confirmAlert } from 'react-confirm-alert'
import DocumentForm from '../DocumentForm'

function DocumentRow({ document, onUpdated, onRemoved }) {
  const [updateActive, setUpdateActive] = useState(false)

  const onPressRemove = useCallback(() => {
    confirmAlert({
      title: 'Remover documento',
      message: `Você está certo que deseja remover '${document.title}'?`,
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim, remover',
          className: 'bg-error',
          onClick: () => onRemoved(document),
        },
      ],
    })
  }, [document, onRemoved])

  return (
    <div>
      {
        updateActive && (
          <DocumentForm
            initialValues={document}
            title="Alterar documento"
            active={updateActive}
            onClose={() => setUpdateActive(false)}
            onSubmit={onUpdated}
          />
        )
      }
      <div className="bg-gray s-rounded p-1 mb-2">
        <Flexbox.Columns>
          <Flexbox.Column>
            <div className="p-1">
              <div><b>{document.title}</b></div>
              <div><small>Mínimo de fotos: <b>{document.minAttachments}</b></small></div>
              <div>
                <div className="divider" />
                <small className="pre">{document.description}</small>
              </div>
            </div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <div className="p-1">
              <button
                type="button"
                className="btn btn-action btn-sm btn-primary tooltip tooltip-left mr-1"
                data-tooltip="Editar"
                onClick={() => setUpdateActive(true)}
              >
                <i className="fas fa-edit" />
              </button>
              <button
                type="button"
                className="btn btn-action btn-sm btn-error tooltip tooltip-left"
                data-tooltip="Remover"
                onClick={onPressRemove}
              >
                <i className="fas fa-trash" />
              </button>
            </div>
          </Flexbox.Column>
        </Flexbox.Columns>
      </div>
    </div>
  )
}

export default DocumentRow

DocumentRow.propTypes = {
  document: PropTypes.objectOf(Object).isRequired,
  onUpdated: PropTypes.func.isRequired,
  onRemoved: PropTypes.func.isRequired,
}

/* eslint-disable react/jsx-props-no-spreading */
import upload from '@/services/api/upload'
import { useField, useFormikContext } from 'formik'
import React, { useState } from 'react'
import { toast } from 'react-toastify'

export default function FileUpload(props) {
  const [uploading, setUploading] = useState(false)
  const [field] = useField(props)
  const { setFieldValue } = useFormikContext() ?? {}

  async function onSelectFile(e) {
    const selectedFile = e?.target?.files?.[0]
    try {
      setUploading(true)
      const { data } = await upload.sendFile(selectedFile)
      setFieldValue(field.name, data.url)
    } catch (error) {
      toast.error(error?.message)
    } finally {
      setUploading(false)
    }
  }

  if (uploading) {
    return (
      <small>
        <span className="loading" />
        <span className="ml-2 pl-2">Enviando arquivo...</span>
      </small>
    )
  }

  if (field.value) {
    return (
      <div className="p-relative" style={{ display: 'inline-block' }}>
        <a onClick={() => setFieldValue(field.name, '')} className="p-absolute text-error" style={{ right: -10, top: -10 }}>
          <i className="fas fa-times" />
        </a>
        {
          field?.value?.split('.')?.pop() === 'pdf'
          ? <i className="fas fa-file-pdf fa-3x" />
          : <img width={80} src={field.value} alt="selected file" />
        }
      </div>
    )
  }

  return (
    <input
      className="form-input"
      accept="image/*,application/pdf"
      {...props}
      {...field}
      onChange={onSelectFile}
      type="file"
    />
  )
}

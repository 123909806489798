import React from 'react'
import PropTypes from 'prop-types'
import { Card, Flexbox } from '@/components'
import { singularStatusMapLabel } from '@/constants/castration'

function CardItem({ item, statusList }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          {
            Boolean(item.month) && (
              <Flexbox.Column className="col-auto">
                <i className="fas fa-calendar fa-lg" />
              </Flexbox.Column>
            )
          }
          <Flexbox.Column>
            <div className="h5">{item?.monthString || 'Total de castrações'}</div>
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <table className="table table-hover text-small">
          <thead>
            <tr>
              <th>Status</th>
              <th width="100px" className="text-right">Quantidade</th>
            </tr>
          </thead>
          <tbody>
            {
              statusList.map((status) => (
                <tr key={status}>
                  <td>{singularStatusMapLabel[status]}</td>
                  <td className="text-right">{item[status] || 0}</td>
                </tr>
              ))
            }
          </tbody>
        </table>
      </Card.Body>
    </Card.Base>
  )
}

CardItem.propTypes = {
  item: PropTypes.objectOf(Object).isRequired,
  statusList: PropTypes.arrayOf(String).isRequired,
}

export default CardItem

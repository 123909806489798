import licensingContext from '@/contexts/licensingContext'
import licensingReducer, { INITIAL_STATE } from '@/reducers/licensing'
import Licensed from '@/views/app/protectors/Licensed'
import Requested from '@/views/app/protectors/Requested'
import Approve from '@/views/app/protectors/Show/Actions/Approve'
import Reject from '@/views/app/protectors/Show/Actions/Reject'
import Revoke from '@/views/app/protectors/Show/Actions/Revoke'
import Show from '@/views/app/protectors/Show'
import React, { useReducer } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

function ClinicsRoutes() {
  const { path } = useRouteMatch()
  const [state, dispatch] = useReducer(licensingReducer, INITIAL_STATE)

  return (
    <div>
      <Route exact path={`${path}/approved`}>
        <Licensed title="Petis - Parceiros / Protetores(as) licenciados(as)" />
      </Route>
      <Route exact path={`${path}/requested`}>
        <Requested title="Petis - Parceiros / Licenciar protetores(as)" />
      </Route>
      <licensingContext.Provider value={{ state, dispatch }}>
        <Route path={`${path}/:status/:id`}>
          <Show title="Petis - Parceiros / Exibir licença" />
        </Route>
        <Route path={`${path}/:status/:id/approve`}>
          <Approve title="Petis - Parceiros / Aprovar protetor(a)" />
        </Route>
        <Route path={`${path}/:status/:id/reject`}>
          <Reject title="Petis - Parceiros / Rejeitar protetor(a)" />
        </Route>
        <Route path={`${path}/:status/:id/revoke`}>
          <Revoke title="Petis - Parceiros / Revogar protetor(a)" />
        </Route>
      </licensingContext.Provider>
    </div>
  )
}

export default ClinicsRoutes

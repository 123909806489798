import envs from '@/constants/envs'
import GoogleMapReact from 'google-map-react'
import PropTypes from 'prop-types'
import React from 'react'
import { Container, Marker } from './styles'

function MapLocation({ location }) {
  return (
    <Container>
      <GoogleMapReact
        bootstrapURLKeys={{ key: envs.REACT_APP_GOOGLE_MAPS_API_KEY }}
        defaultCenter={{
          lat: location?.coordinates[1],
          lng: location?.coordinates[0],
        }}
        defaultZoom={16}
        draggable={false}
        options={{ fullscreenControl: null }}
      >
        <Marker
          lat={location?.coordinates[1]}
          lng={location?.coordinates[0]}
        />
      </GoogleMapReact>
    </Container>
  )
}

MapLocation.propTypes = {
  location: PropTypes.objectOf(Object).isRequired,
}

export default MapLocation

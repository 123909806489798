import { Flexbox, Form, QuickFilter } from '@/components'
import React, { useCallback, useEffect, useState } from 'react'
import categoriesService from '@/services/api/categories'
import { ANIMAL } from '@/lib/enums'

function CastrationsQuickFilter() {
  const [categories, setCategories] = useState([])
  const [breeds, setBreeds] = useState([])
  const [selectedCategory, setSelectedCategory] = useState('')

  async function fetchCategories() {
    const { data } = await categoriesService.list()
    setCategories(data)
  }

  const fetchBreeds = useCallback(async (categoryId) => {
    const { data } = await categoriesService.breedsByCategoryId(categoryId)
    setBreeds(data)
  }, [])

  useEffect(() => {
    fetchCategories()
  }, [])

  return (
    <QuickFilter
      initialValues={{
        animalGender: '', animalCategoryId: '', animalSize: '', animalBreedId: '', LicensingType: '',
      }}
      title="Filtrar castrações"
      onClear={() => setSelectedCategory('')}
      renderForm={({ setFieldValue }) => (
        <>
          <Flexbox.Column className="col-2 pt-2">
            <Form.Group>
              <Form.Label className="label-sm pt-2" htmlFor="animalCategoryId">Espécie do pet</Form.Label>
              <Form.ValidationField name="animalCategoryId">
                {
                  ({ field }) => (
                    <Form.Select
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={({ target: { value } }) => {
                        setSelectedCategory(value)
                        setFieldValue('animalCategoryId', value)
                        fetchBreeds(value)
                        setFieldValue('animalBreedId', '')
                      }}
                      id="animalCategoryId"
                      className="select-sm"
                      placeholder="Espécie do pet"
                    >
                      <option value="">Todos</option>
                      {
                        categories.map((category) => (
                          <option
                            key={category.id}
                            value={category.id}
                          >
                            {category.description}
                          </option>
                        ))
                      }
                    </Form.Select>
                  )
                }
              </Form.ValidationField>
            </Form.Group>
          </Flexbox.Column>
          <Flexbox.Column className="col-2 pt-2">
            <Form.Group>
              <Form.Label className="label-sm pt-2" htmlFor="animalBreedId">Raça do pet</Form.Label>
              <Form.ValidationField name="animalBreedId">
                {
                  ({ field }) => (
                    <Form.Select
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      onChange={({ target: { value } }) => setFieldValue('animalBreedId', value)}
                      id="animalBreedId"
                      className="select-sm"
                      disabled={!selectedCategory}
                    >
                      <option value="">
                        {selectedCategory ? 'Todas' : 'Selecione a espécie'}
                      </option>
                      {
                        breeds.map((breed) => (
                          <option
                            key={breed.id}
                            value={breed.id}
                          >
                            {breed.description}
                          </option>
                        ))
                      }
                    </Form.Select>
                  )
                }
              </Form.ValidationField>
            </Form.Group>
          </Flexbox.Column>
          <Flexbox.Column className="col-2 pt-2">
            <Form.Group>
              <Form.Label className="label-sm pt-2" htmlFor="animalGender">Gênero do pet</Form.Label>
              <Form.ValidationField name="animalGender">
                {
                  ({ field }) => (
                    <Form.Select
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      id="animalGender"
                      className="select-sm"
                      placeholder="Gênero do pet"
                    >
                      <option value="">Todos</option>
                      <option value="male">Macho</option>
                      <option value="female">Fêmea</option>
                    </Form.Select>
                  )
                }
              </Form.ValidationField>
            </Form.Group>
          </Flexbox.Column>
          <Flexbox.Column className="col-2 pt-2">
            <Form.Group>
              <Form.Label className="label-sm pt-2" htmlFor="animalSize">Tamanho do pet</Form.Label>
              <Form.ValidationField name="animalSize">
                {
                  ({ field }) => (
                    <Form.Select
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      id="animalSize"
                      className="select-sm"
                      placeholder="Tamanho do pet"
                    >
                      <option value="">Todos</option>
                      {
                        Object.values(ANIMAL.size.enum).map((value) => (
                          <option key={value} value={value}>{ANIMAL.size.t(value)}</option>
                        ))
                      }
                    </Form.Select>
                  )
                }
              </Form.ValidationField>
            </Form.Group>
          </Flexbox.Column>
          <Flexbox.Column className="col-2 pt-2">
            <Form.Group>
              <Form.Label className="label-sm pt-2" htmlFor="LicensingType">Licença do tutor</Form.Label>
              <Form.ValidationField name="LicensingType">
                {
                  ({ field }) => (
                    <Form.Select
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      id="LicensingType"
                      className="select-sm"
                      placeholder="Licença do tutor"
                    >
                      <option value="">Todos</option>
                      <option value="protector">Protetor</option>
                      <option value="ngo">ONG</option>
                    </Form.Select>
                  )
                }
              </Form.ValidationField>
            </Form.Group>
          </Flexbox.Column>
        </>
      )}
    />
  )
}

export default CastrationsQuickFilter

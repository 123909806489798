import useInterval from '@/hooks/useInterval'
import PropTypes from 'prop-types'
import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react'
import CommentForm from './CommentForm'
import Row from './Row'
import {
  Body, Container, Content, Header, ListContainer,
} from './styles'

function Messages({
  fetchList, addMessage, tutorId, clinicId, providerId, readChat,
}) {
  const [lastLength, setLastLength] = useState(0)
  const [list, setList] = useState([])
  const [active, setActive] = useState(false)
  const endListRef = useRef()

  function handleCommentAdded(comment) {
    setList([...list, comment])
  }

  useInterval(fetchList, 5000)

  useEffect(() => {
    fetchList().then(({ data }) => {
      setList(data)
      setLastLength(data?.length)
    })
  }, [fetchList])

  useEffect(() => {
    readChat()
  }, [readChat])

  useLayoutEffect(() => {
    if (list.length > 0 && list.length !== lastLength) {
      endListRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [lastLength, list])

  return (
    <Container className={`${active ? 'active' : ''}`}>
      <Header className={`${active ? 'active' : ''}`} onClick={() => setActive(!active)}>
        <div className="text-bold text-primary badge" data-badge={list.length}>Comentários</div>
        <i className="fas fa-chevron-up text-primary" />
      </Header>
      <Content className={`${active ? 'active' : ''}`}>
        <Body>
          <ListContainer>
            {
              list.map((comment) => (
                <Row
                  key={comment?.id}
                  tutorId={tutorId}
                  clinicId={clinicId}
                  providerId={providerId}
                  comment={comment}
                />
              ))
            }
            <span ref={endListRef} />
          </ListContainer>
          <CommentForm addMessage={addMessage} onCommentAdded={handleCommentAdded} />
        </Body>
      </Content>
    </Container>
  )
}

Messages.propTypes = {
  fetchList: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired,
  readChat: PropTypes.func,
  tutorId: PropTypes.number,
  clinicId: PropTypes.number,
  providerId: PropTypes.number,
}

Messages.defaultProps = {
  tutorId: null,
  clinicId: null,
  providerId: null,
  readChat: () => null,
}

export default Messages

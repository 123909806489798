import React from 'react'
import useAuth from '@/hooks/useAuth'
import documentNumberMask from '@/lib/masks/documentNumber'
import PropTypes from 'prop-types'

function Block({ hasAddress, hasTerms }) {
  const { licensing, signOut } = useAuth()
  const { licensed } = licensing

  return (
    <div className="modal-container">
      <div className="modal-header">
        <div className="modal-title h5">
          Manutenção necessária
        </div>
      </div>
      <div className="modal-body">
        <div className="h3">Atenção</div>
        <p>
          A conta principal precisa realizar uma manutenção antes de começar.
        </p>
        <p>
          Faça login com o usuário
          <b> {licensed?.fullName}</b>
          &nbsp;com o documento <b> {documentNumberMask(licensed?.documentNumber)}</b>.
        </p>
        <p>
          Será necessário o preenchimento dos seguintes itens:
        </p>
        <div className="divider" />
        <ul>
          {!hasAddress && <li>Atualizar o endereço do parceiro/prefeitura;</li>}
          {!hasTerms && <li>Atualizar os termos de castração;</li>}
        </ul>
        <div className="divider" />
        <p>
          Após logar com o usuário principal o Petis vai
          mostrar diretamente os formulários para atualizar os dados
        </p>
      </div>
      <div className="modal-footer">
        <button onClick={signOut} type="button" className="btn btn-error mr-2">
          <i className="fas fa-sign-out-alt" />
          &nbsp;Fazer logout
        </button>
        <button onClick={() => window.location.reload()} type="button" className="btn btn-primary">
          <i className="fas fa-redo-alt" />
          &nbsp;Atualizar página
        </button>
      </div>
    </div>
  )
}

Block.propTypes = {
  hasAddress: PropTypes.bool.isRequired,
  hasTerms: PropTypes.bool.isRequired,
}

export default Block

import api from './api'

export default {
  create(body) {
    return api.post('/partner-licensings/:partnerLicensingId/albums', body)
  },
  destroy(id) {
    return api.delete(`/partner-licensings/:partnerLicensingId/albums/${id}`)
  },
  attachPictures(id, body) {
    return api.post(`/partner-licensings/:partnerLicensingId/albums/${id}/attach-pictures`, body)
  },
  detachPicture(albumId, pictureId) {
    return api.delete(`/partner-licensings/:partnerLicensingId/albums/${albumId}/detach-picture/${pictureId}`)
  },
}

/* eslint-disable react/forbid-prop-types */
import Toast from '@/components/Toast'
import alertOptions from '@/lib/alertOptions'
import ptBr from 'date-fns/locale/pt-BR'
import React from 'react'
import { Provider as AlertProvider } from 'react-alert'
import { registerLocale } from 'react-datepicker'
import Routes from '../routes'
import GlobalStyle from '../styles/globals'

export default function App() {
  registerLocale('ptBR', ptBr)
  return (
    <>
        <AlertProvider
          template={Toast}
          position={alertOptions.position}
          timeout={alertOptions.timeout}
          offset={alertOptions.offset}
          transition={alertOptions.transition}
        >
          <Routes />
          <GlobalStyle />

        </AlertProvider>
    </>
  )
}

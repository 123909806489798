import React from 'react'
import { Avatar, Card, Flexbox } from '@/components'
import PropTypes from 'prop-types'
import dateMask from '@/lib/masks/date'
import documentNumberMask from '@/lib/masks/documentNumber'
import phoneNumber from '@/lib/masks/phoneNumber'
import formatAddress from '@/lib/formatters/address'

function PetisUserCard({ person, isNgo }) {
  return (
    <Card.Base>
      <Card.Body>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column className="col-auto">
            <Avatar
              avatarSize="xl"
              name={person?.displayName}
              photoURL={person?.photoURL}
              alt="Imagem de usuário"
            />
          </Flexbox.Column>
          <Flexbox.Column className="text-ellipsis">
            <div className="h5">{person?.fullName}</div>
            <div>{person?.email}</div>
          </Flexbox.Column>
        </Flexbox.Columns>
        <div className="divider" />
        <Flexbox.Columns>
          <Flexbox.Column>Fone</Flexbox.Column>
          <Flexbox.Column className="col-auto text-ellipsis text-bold">
            {phoneNumber(person?.phoneNumber) || 'Não informado'}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          <Flexbox.Column>Documento</Flexbox.Column>
          <Flexbox.Column className="col-auto text-ellipsis text-bold">
            {documentNumberMask(person?.documentNumber) || 'Não informado'}
          </Flexbox.Column>
        </Flexbox.Columns>
        {
          !isNgo && (
            <Flexbox.Columns>
              <Flexbox.Column>Data de nascimento</Flexbox.Column>
              <Flexbox.Column className="col-auto text-ellipsis text-bold">
                {person?.birthdayDate ? dateMask(person?.birthdayDate, { dateStyle: 'short' }) : 'Não informada'}
              </Flexbox.Column>
            </Flexbox.Columns>
          )
        }
        <Flexbox.Columns>
          <Flexbox.Column>Conta criada em</Flexbox.Column>
          <Flexbox.Column className="col-auto text-ellipsis text-bold">
            {dateMask(person?.createdAt, { hour: '2-digit', minute: '2-digit' })}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          <Flexbox.Column>Conta verificada?</Flexbox.Column>
          <Flexbox.Column className="col-auto text-ellipsis text-bold">
            {person?.emailVerified ? 'Sim' : 'Não'}
          </Flexbox.Column>
        </Flexbox.Columns>
        <div className="divider" />
        Endereço
        <div className="text-bold">
          {
            // eslint-disable-next-line no-nested-ternary
            person?.address
              ? formatAddress(person?.address)
              : (
                person?.city
                  ? `${person?.city?.name} - ${person?.city?.uf}`
                  : 'Não informado'
              )
          }
        </div>
      </Card.Body>
    </Card.Base>
  )
}

PetisUserCard.propTypes = {
  person: PropTypes.objectOf(PropTypes.any).isRequired,
  isNgo: PropTypes.bool.isRequired,
}

export default PetisUserCard

import { Styles } from '@/components'
import useInterval from '@/hooks/useInterval'
import React, { useState } from 'react'
import PropTypes from 'prop-types'

const CURRENT_TIMER = 60

function NewTokenButton({ onClick }) {
  const [timer, setTimer] = useState(CURRENT_TIMER)

  useInterval(() => {
    setTimer(timer - 1)
  }, 1000)

  function handleClick() {
    setTimer(CURRENT_TIMER)
    onClick()
  }

  if (timer > 0) return <small>Você poderá solicitar um novo código em <b>{timer} {timer > 1 ? 'segundos' : 'segundo'}</b></small>

  return (
    <Styles.Button className="btn-link" onClick={handleClick}>
      <i className="fas fa-sync-alt" />
      &nbsp;Reenviar código
    </Styles.Button>
  )
}

NewTokenButton.propTypes = {
  onClick: PropTypes.func,
}

NewTokenButton.defaultProps = {
  onClick: () => null,
}

export default NewTokenButton

/* eslint-disable react/jsx-props-no-spreading */
import { Filter, Form } from '@/components'
import React from 'react'

function FilterAnimals() {
  return (
    <Filter
      initialValues={{
        'animals.categoryId_eq': '',
        'animals.gender_eq': '',
      }}
      title="Filtrar castrações"
      renderForm={() => (
        <>
          <Form.Group>
            <Form.Label htmlFor="animals.categoryId_eq">Espécie do pet</Form.Label>
            <Form.ValidationField name="['animals.categoryId_eq']">
              {
                ({ field }) => (
                  <Form.Select
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    id="animals.categoryId_eq"
                    className="select-sm"
                    placeholder="Espécie do pet"
                  >
                    <option value="">Todos</option>
                    <option value="1">Cachorro</option>
                    <option value="2">Gato</option>
                  </Form.Select>
                )
              }
            </Form.ValidationField>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="animals.gender_eq">Gênero do pet</Form.Label>
            <Form.ValidationField name="['animals.gender_eq']">
              {
                ({ field }) => (
                  <Form.Select
                    {...field}
                    id="animals.gender_eq"
                    className="select-sm"
                    placeholder="Gênero do pet"
                  >
                    <option value="">Todos</option>
                    <option value="male">Macho</option>
                    <option value="female">Fêmea</option>
                  </Form.Select>
                )
              }
            </Form.ValidationField>
          </Form.Group>
        </>
      )}
    />
  )
}

export default FilterAnimals

/* eslint-disable func-names */
import React, { useState } from 'react'
import usePageTitle from '@/hooks/usePageTitle'
import {
  Flexbox,
  Form, LoadManager, PageHeader, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import params from '@/services/api/params'
import { toast } from 'react-toastify'
import yup from '@/lib/yupPt'

yup.addMethod(yup.number, 'cancelNonScheduledCastrationAfterDays', function () {
  return this.test('test-cancel-non-scheduled-castration-after-days', 'Deve ser no mínimo 20', function (value) {
    const { createError, path } = this
    if (value && value !== 0 && value < 20) {
      return createError({ path, message: 'Deve ser no mínimo 20' })
    }
    return true
  })
})
const schema = yup.object().shape({
  maxCastrationsPerCommonAccount: yup.number().min(0).required(),
  maxClinicInboxCastrations: yup.number().min(0).required(),
  cancelNonScheduledCastrationAfterDays: yup
    .number()
    .cancelNonScheduledCastrationAfterDays()
    .required(),
  cancelNonScheduledCastrationAfterDaysMessage: yup.string().when('cancelNonScheduledCastrationAfterDays', {
    is: (v) => v >= 20,
    then: yup.string().min(10).required(),
  }),
})

function Params({ title }) {
  usePageTitle({ title })
  const [updating, setUpdating] = useState(false)
  const { loading, data, error } = useAxiosRequest(params.list)

  async function onSubmit(body) {
    try {
      setUpdating(true)
      await params.update(body)
      toast.success('Parâmetros atualizados')
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setUpdating(false)
    }
  }

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Parâmetros"
        icon="fas fa-cogs"
        subtitle="Parâmetros do sistema"
      />
      <LoadManager loading={loading} error={error}>
        <Flexbox.Columns>
          <Flexbox.Column className="col-6 col-sm-12">
            {
              Boolean(data) && (
                <fieldset disabled={updating}>
                  <Form.Formik
                    initialValues={data}
                    onSubmit={onSubmit}
                    validationSchema={schema}
                    validateOnMount
                  >
                    {
                      ({
 errors, touched, isValid, values,
}) => (

                        <Form.ValidationForm>
                          <Form.Group className={`${touched?.maxCastrationsPerCommonAccount && errors?.maxCastrationsPerCommonAccount ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="maxCastrationsPerCommonAccount">Castrações por CPF <span className="text-error">*</span></Form.Label>
                            <Form.ValidationField
                              name="maxCastrationsPerCommonAccount"
                              id="maxCastrationsPerCommonAccount"
                              type="number"
                              placeholder="Castrações por CPF"
                            />
                            {
                              touched?.maxCastrationsPerCommonAccount
                                && errors?.maxCastrationsPerCommonAccount
                                ? <Form.Hint className="text-error">{errors.maxCastrationsPerCommonAccount}</Form.Hint> : null
                            }
                            <Form.Hint className="text-gray">
                              Este parâmetro define a quantidade máxima de castrações por
                              usuário comum, ONGs e protetores não são afetados.
                              <br />Castrações rejeitadas e canceladas não são consideradas
                              <br /><b>Para desabilitar deixe o parâmetro com o valor 0</b>
                            </Form.Hint>
                          </Form.Group>
                          <Form.Group className={`${touched?.maxClinicInboxCastrations && errors?.maxClinicInboxCastrations ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="maxClinicInboxCastrations">Máximo de castrações na caixa da clínica <span className="text-error">*</span></Form.Label>
                            <Form.ValidationField
                              name="maxClinicInboxCastrations"
                              id="maxClinicInboxCastrations"
                              type="number"
                              placeholder="Máximo de castrações na caixa da clínica"
                            />
                            {
                              touched?.maxClinicInboxCastrations
                                && errors?.maxClinicInboxCastrations
                                ? <Form.Hint className="text-error">{errors.maxClinicInboxCastrations}</Form.Hint> : null
                            }
                            <Form.Hint>
                              Este parâmetro define o máximo de castrações que podem ficar na caixa
                              das clínicas, caso todas as clínicas estejam com a caixa cheia as
                              castrações ficarão pré aprovadas enquanto não houver
                              clínica disponível
                            </Form.Hint>
                          </Form.Group>

                          <div className="divider" />
                          <Form.Group className={`${touched?.cancelNonScheduledCastrationAfterDays && errors?.cancelNonScheduledCastrationAfterDays ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="cancelNonScheduledCastrationAfterDays">Cancelar castrações aprovadas não agendadas após quantos dias <span className="text-error">*</span></Form.Label>
                            <Form.ValidationField
                              name="cancelNonScheduledCastrationAfterDays"
                              id="cancelNonScheduledCastrationAfterDays"
                              type="number"
                              min="0"
                              placeholder="Castrações por CPF"
                            />
                            {
                              touched?.cancelNonScheduledCastrationAfterDays
                                && errors?.cancelNonScheduledCastrationAfterDays
                                ? <Form.Hint className="text-error">{errors.cancelNonScheduledCastrationAfterDays}</Form.Hint> : null
                            }
                            <Form.Hint className="text-gray">
                              Se definido, as castrações que não forem agendadas após a quantidade
                              de dias informada, serão canceladas automaticamente
                              <br />Os usuários receberão um aviso pelo aplicativo antes
                              da castração ser cancelada
                              <br /><b>Para desabilitar deixe o parâmetro com o valor 0</b>
                            </Form.Hint>
                          </Form.Group>

                          {
                            values.cancelNonScheduledCastrationAfterDays >= 20 && (
                              <Form.Group className={`${touched?.cancelNonScheduledCastrationAfterDaysMessage && errors?.cancelNonScheduledCastrationAfterDaysMessage ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="cancelNonScheduledCastrationAfterDaysMessage">Mensagem automática de cancelamento da castração <span className="text-error">*</span></Form.Label>
                                <Form.ValidationField
                                  name="cancelNonScheduledCastrationAfterDaysMessage"
                                  component="textarea"
                                  rows="4"
                                  id="cancelNonScheduledCastrationAfterDaysMessage"
                                  placeholder="Por determinação da lei ##/#### a castração foi cancelada pois não houve agendamento ..."
                                />
                                {
                                  touched?.cancelNonScheduledCastrationAfterDaysMessage
                                    && errors?.cancelNonScheduledCastrationAfterDaysMessage
                                    ? <Form.Hint className="text-error">{errors.cancelNonScheduledCastrationAfterDaysMessage}</Form.Hint>
                                    : null
                                }

                                <Form.Hint className="text-gray">
                                  Isto será adicionado ao cancelamento automático da castração
                                </Form.Hint>
                              </Form.Group>
                            )
                          }

                          <div className="divider" />

                          <div className="text-right">
                            <button type="submit" disabled={!isValid} className={`btn btn-primary ${updating ? 'loading' : ''}`}>
                              <i className="fas fa-save" />
                              &nbsp;Salvar
                            </button>
                          </div>
                        </Form.ValidationForm>
                      )
                    }
                  </Form.Formik>
                </fieldset>
              )
            }
          </Flexbox.Column>
        </Flexbox.Columns>
      </LoadManager>
    </Styles.Container>
  )
}

export default Params

import api from './api'

export default {
  list(clinicId, params = {}) {
    return api.get(`/partner-licensings/:partnerLicensingId/clinical-cares/allowed-clinics/${clinicId}/items`, { params })
  },
  allowedItems(clinicId, params = {}) {
    return api.get(`/partner-licensings/:partnerLicensingId/clinical-cares/allowed-clinics/${clinicId}/allowed-items`, { params })
  },
  create(clinicId, body) {
    return api.post(`/partner-licensings/:partnerLicensingId/clinical-cares/allowed-clinics/${clinicId}/items`, body)
  },
  update(clinicId, body) {
    return api.put(`/partner-licensings/:partnerLicensingId/clinical-cares/allowed-clinics/${clinicId}/items/${body.id}`, body)
  },
  destroy(clinicId, id) {
    return api.delete(`/partner-licensings/:partnerLicensingId/clinical-cares/allowed-clinics/${clinicId}/items/${id}`)
  },
}

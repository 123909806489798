import api from './api'

export default {
  list(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/timeline-items', { params })
  },
  read(id) {
    return api.patch(`/partner-licensings/:partnerLicensingId/timeline-items/${id}/read`)
  },
}

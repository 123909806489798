/* eslint-disable camelcase */
function getComponent(gmapsAddress, componentName) {
  const components = gmapsAddress?.address_components

  return components?.find(({ types }) => types?.includes(componentName))
}

export default function extractGmapsAddress(gmapsAddress) {
  return {
    street: getComponent(gmapsAddress, 'route')?.long_name,
    formattedAddress: gmapsAddress?.formatted_address,
    district: getComponent(gmapsAddress, 'sublocality_level_1')?.long_name,
    placeId: gmapsAddress?.place_id,
    country: getComponent(gmapsAddress, 'country')?.long_name,
    state: getComponent(gmapsAddress, 'administrative_area_level_1')?.short_name,
    city: getComponent(gmapsAddress, 'administrative_area_level_2')?.long_name,
    number: getComponent(gmapsAddress, 'street_number')?.long_name,
    zipcode: getComponent(gmapsAddress, 'postal_code')?.long_name?.replace('-', ''),
  }
}

/* eslint-disable no-useless-catch */
/* eslint-disable camelcase */
import envs from '@/constants/envs'
import axios from 'axios'

export default {
  async geolocation(address) {
    const { data } = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
      params: { address, key: envs.REACT_APP_GOOGLE_MAPS_API_KEY },
    })

    if (data?.status === 'OK') {
      const { geometry } = data?.results?.[0]

      return geometry
    }
    throw new Error(data?.status)
  },

  async reverseGeocode({ latitude, longitude }) {
    try {
      const { data } = await axios.get('https://maps.googleapis.com/maps/api/geocode/json', {
        params: {
          latlng: `${latitude},${longitude}`, key: envs.REACT_APP_GOOGLE_MAPS_API_KEY,
        },
      })
      if (data?.status === 'OK') {
        return data?.results[0]
      }
      throw new Error(data?.status)
    } catch (error) {
      throw error
    }
  },
}

import React from 'react'
import PropTypes from 'prop-types'
import { Flexbox } from '@/components'

function Stars({ rate }) {
  return (
    <Flexbox.Columns className="col-gapless text-small">
      {
        Array(Math.floor(rate)).fill(null).map((_, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <Flexbox.Column key={index} className="col-auto mr-1">
            <i className="fas fa-star text-primary" />
          </Flexbox.Column>
        ))
          .concat(
            Number.isInteger(rate) ? [] : (
              <Flexbox.Column key="partial-star" className="col-auto mr-1">
                <i className="fas fa-star-half-alt text-primary" />
              </Flexbox.Column>
            ),
          )
          .concat(
            Array(5 - Math.ceil(rate)).fill(null).map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <Flexbox.Column key={`empty-star-${index}`} className="col-auto mr-1">
                <i className="fas fa-star text-gray" />
              </Flexbox.Column>
            )),
          )
      }
    </Flexbox.Columns>
  )
}

Stars.propTypes = {
  rate: PropTypes.number.isRequired,
}

export default Stars

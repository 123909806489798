import React from 'react'
import PropTypes from 'prop-types'
import { Flexbox } from '@/components'
import dateMask from '@/lib/masks/date'
import { LICENSING_STATUS_MAP, licensingStatusColors, licensingStatusIcons } from '@/constants/licensings'
import Approved from './Approved'
import Rejected from './Rejected'
import Revoked from './Revoked'

function Infos({ license }) {
  return (
    <>
      <div className="timeline-item">
        <div className="timeline-content">
          <div className="title">
            <div className="tile-content">
              <Flexbox.Columns className={`align-center col-gapless m-2 ${licensingStatusColors[license.status]}`}>
                <Flexbox.Column className="col-auto">
                  <i className={`fa-fw ${licensingStatusIcons[license.status]}`} />
                </Flexbox.Column>
                <Flexbox.Column className="text-bold ml-1">{LICENSING_STATUS_MAP[license.status]}</Flexbox.Column>
              </Flexbox.Columns>
              {
                license?.rejectedAt || license?.status === 'rejected' ? (
                  <Rejected license={license} />
                ) : null
              }
              {
                license?.revokedAt || license?.status === 'revoked' ? (
                  <Revoked license={license} />
                ) : null
              }
              {
                license?.approvedAt || license?.status === 'approved' ? (
                  <Approved license={license} />
                ) : null
              }
              <Flexbox.Columns className="align-center col-gapless">
                <Flexbox.Column className="col-auto">
                  <div className="text-small">Licença solicitada em</div>
                  <p className="tile-subtitle">
                    <span className="text-bold">
                      {dateMask(license?.createdAt, { dateStyle: 'full' })}&nbsp;
                    </span>
                    <small className="label label-sm label-rounded">
                      <i className="far fa-clock" />&nbsp;
                      {new Date(license?.createdAt).toLocaleTimeString()}
                    </small>
                  </p>
                </Flexbox.Column>
              </Flexbox.Columns>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

Infos.propTypes = {
  license: PropTypes.objectOf(Object).isRequired,
}

export default Infos

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import yup from '@/lib/yupPt'
import { validateCNPJ, validateCPF } from 'validations-br'
import { Flexbox, Form, Mask } from '@/components'
import { toast } from 'react-toastify'
import partnerBlacklists from '@/services/api/partnerBlacklists'

const schema = yup.object().shape({
  info: yup.string().trim(),
  description: yup.string().trim().required(),
  email: yup.string().email(),
  documentNumber: yup.string()
    .when('email', { is: (v) => !v || v?.length === 0, then: yup.string().required() })
    .test(
      'is-document-number',
      'Documento inválido',
      (value = '') => {
        if (!value) return true
        return value?.length < 12 ? validateCPF(value) : validateCNPJ(value)
      },
    ),
})
export default function FormButton({ onAdded }) {
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false)

  async function onSubmit(values) {
    try {
      setLoading(true)
      const { data } = await partnerBlacklists.create(values)
      onAdded(data)
      toast.success('Usuário bloqueado com sucesso')
      setActive(false)
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <a
        className="btn btn-primary"
        onClick={() => setActive(true)}
      >
        <i className="fas fa-plus" />
        &nbsp;Adicionar
      </a>
      {
        active && (
          <fieldset disabled={loading}>
            <Form.Formik
              initialValues={{
                info: '',
                description: '',
                email: '',
                documentNumber: '',
              }}
              validationSchema={schema}
              onSubmit={onSubmit}
              validateOnMount
            >

              {
                ({
                  errors, touched, isValid, setFieldValue,
                }) => (
                  <Form.ValidationForm>
                    <div className={`modal ${active ? 'active' : ''}`}>
                      <a type="button" className="modal-overlay" aria-label="Close" onClick={() => setActive(false)} />
                      <div className="modal-container">
                        <div className="modal-header">
                          <a
                            type="button"
                            className="btn btn-clear float-right"
                            onClick={() => setActive(false)}
                          />
                          <div className="modal-title h5">
                            Bloquear usuário
                          </div>
                        </div>
                        <div className="modal-body" style={{ minHeight: 480 }}>
                          <div>
                            Caso o sistema encontre algum usuário cadastrado ele vai vinculá-lo ao
                            bloqueio.
                          </div>
                          <div>
                            Qualquer usuário que tenha o E-mail ou CPF/CNPJ bloqueado não poderá
                            solicitar castrações
                          </div>
                          <div className="divider" />
                          <Flexbox.Columns>
                            <Flexbox.Column className="col-12">
                              <Form.Group className={`${touched?.documentNumber && errors?.documentNumber ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="documentNumber">CPF/CNPJ <small>(Obrigatório caso não haja e-mail)</small></Form.Label>
                                <Form.ValidationField name="documentNumber">
                                  {
                                    ({ field }) => (
                                      <Mask.CpfCnpj
                                        id="documentNumber"
                                        placeholder="CPF/CNPJ"
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...field}
                                        onAccept={(v) => setFieldValue('documentNumber', v)}
                                      />
                                    )
                                  }
                                </Form.ValidationField>
                                {
                                  touched?.documentNumber && errors?.documentNumber
                                    ? <Form.Hint className="text-error">{errors.documentNumber}</Form.Hint> : null
                                }
                              </Form.Group>
                            </Flexbox.Column>
                            <Flexbox.Column className="col-12">
                              <Form.Group className={`${touched?.email && errors?.email ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="email">E-mail <small>(Obrigatório caso não haja CPF/CNPJ)</small></Form.Label>
                                <Form.ValidationField name="email" type="email" placeholder="E-mail" />
                                {
                                  touched?.email && errors?.email
                                    ? <Form.Hint className="text-error">{errors.email}</Form.Hint> : null
                                }
                              </Form.Group>
                            </Flexbox.Column>
                            <Flexbox.Column className="col-12">
                              <Form.Group className={`${touched?.info && errors?.info ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="description">Motivo do bloqueio <span className="text-error">*</span> <small>(Aparece para o usuário no App)</small></Form.Label>
                                <Form.ValidationField
                                  name="description"
                                  id="description"
                                >
                                  {
                                    ({ field }) => (
                                      <textarea
                                        name="description"
                                        className="form-input"
                                        rows={3}
                                        placeholder="Motivo do bloqueio"
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...field}
                                      />
                                    )
                                  }
                                </Form.ValidationField>
                                {
                                  touched?.description && errors?.description
                                    ? <Form.Hint className="text-error">{errors.description}</Form.Hint> : null
                                }
                              </Form.Group>
                            </Flexbox.Column>
                            <Flexbox.Column className="col-12">
                              <Form.Group className={`${touched?.info && errors?.info ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="info">Observações <small>(Aparece apenas no painel admin)</small></Form.Label>
                                <Form.ValidationField
                                  name="info"
                                  id="info"
                                >
                                  {
                                    ({ field }) => (
                                      <textarea
                                        name="info"
                                        className="form-input"
                                        rows={3}
                                        placeholder="Observações"
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...field}
                                      />
                                    )
                                  }
                                </Form.ValidationField>
                                {
                                  touched?.info && errors?.info
                                    ? <Form.Hint className="text-error">{errors.info}</Form.Hint> : null
                                }
                              </Form.Group>
                            </Flexbox.Column>
                          </Flexbox.Columns>
                        </div>
                        <div className="modal-footer">
                          <button type="submit" disabled={!isValid || loading} className="btn btn-primary">
                            <i className="fas fa-user-slash" />
                            &nbsp;Bloquear usuário
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form.ValidationForm>
                )
              }
            </Form.Formik>
          </fieldset>
        )
      }
    </>
  )
}

FormButton.propTypes = {
  onAdded: PropTypes.func.isRequired,
}

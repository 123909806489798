import { Styles } from '@/components'
import { batchUrl } from '@/lib/urlMaker'
import PropTypes from 'prop-types'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import allowedActions from './allowedActions'

function Actions({ batch }) {
  const actions = allowedActions(batch?.status)
  const { pathname } = useLocation()

  return (
    <Styles.ActionsContainer>
      {
        actions.includes('approve') && (
          <Link className="btn btn-sm btn-success mx-1" to={`${pathname}/approve`}>
            <i className="fas fa-check" />
            &nbsp;Aprovar
          </Link>
        )
      }
      {
        actions.includes('reject') && (
          <Link className="btn btn-sm btn-error mx-1" to={`${pathname}/reject`}>
            <i className="fas fa-ban" />
            &nbsp;Rejeitar
          </Link>
        )
      }
      <a className="btn btn-sm btn-link mx-1" href={batchUrl(batch)} target="_blank" rel="noopener noreferrer">
        <i className="fas fa-print" />
        &nbsp;Imprimir
      </a>
    </Styles.ActionsContainer>
  )
}

Actions.propTypes = {
  batch: PropTypes.objectOf(Object).isRequired,
}

export default Actions

import {
  Avatar,
  Card,
  Flexbox,
  ListManager, PageHeader,
  Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import reports from '@/services/api/reports'
import React from 'react'

function CommentsByClinicsInApprovedCastrations({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const { data, loading, error } = useAxiosRequest(
    reports.commentsByClinicsInApprovedCastrations,
    query,
  )

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Comentários das clínicas por castração aprovada"
        subtitle="Resumo de quantos comentários das clínicas por castração aprovada"
        icon="fas fa-comment"
      />

      <ListManager loading={loading} count={data?.length || 0} error={error}>
        {
          Boolean(data) && (
            <>
              <div className="p-2 bg-gray s-rounded">
                <div className="text-small">
                  <b>Nº castrações: </b>
                  <span>É utilizado para agrupar a quantidade de comentários da clínica</span>
                </div>
                <div className="text-small">
                  <b>Nº comentários: </b>
                  <span>Conta a quantidade de comentários da clínica nas castrações</span>
                </div>
              </div>

              {
                <Flexbox.Columns>
                  {
                    data.map((item) => (
                      <Flexbox.Column key={item.clinicId} className="col-6 col-sm-12 my-2">
                        <Card.Base>
                          <Card.Header>
                            <Flexbox.Columns className="align-center col-gapless">
                              <Flexbox.Column className="col-auto">
                                <Avatar
                                  avatarSize="lg"
                                  name={item?.clinicName}
                                  photoURL={item?.clinicPhotoURL}
                                  alt="Imagem de usuário"
                                />
                              </Flexbox.Column>
                              <Flexbox.Column className="ml-2">
                                <b>{item?.clinicName}</b>
                              </Flexbox.Column>
                            </Flexbox.Columns>
                          </Card.Header>
                          <Card.Body>
                            <table className="table table-hover text-small">
                              <thead>
                                <tr>
                                  <th>Nº castrações</th>
                                  <th>Nº comentários</th>
                                </tr>
                              </thead>
                              <tbody>
                                {
                                  item.counts.map((count) => (
                                    <tr>
                                      <td>{count.countCastrations}</td>
                                      <td>{count.countComments}</td>
                                    </tr>
                                  ))
                                }
                              </tbody>
                            </table>
                          </Card.Body>
                        </Card.Base>
                      </Flexbox.Column>
                    ))
                  }
                </Flexbox.Columns>
              }
              {/* <table className="table table-hover text-small">
                <thead>
                  <tr>
                    <th>Clínica</th>
                    <th className="text-right" width="200px">Nº castrações</th>
                    <th className="text-right" width="200px">Nº comentários</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.rows.map((item) => (
                      <tr key={`${item.clinicId}${item.countComments}`}>
                        <td>
                          <Avatar
                            avatarSize="sm"
                            className="mr-1"
                            name={item.clinicName}
                            photoURL={item.clinicPhotoURL}
                            alt="Imagem de usuário"
                          />
                          {item.clinicName}
                        </td>
                        <td className="text-right">{item.countCastrations}</td>
                        <td className="text-right">{item.countComments}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table> */}
            </>
          )
        }
      </ListManager>
    </Styles.Container>
  )
}

export default CommentsByClinicsInApprovedCastrations

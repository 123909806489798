import {
  Filter, Form, ListManager, PageHeader, Paginator, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import adoptionFairs from '@/services/api/adoptionFairs'
import React, { useCallback } from 'react'
import Create from './components/Form'
import Row from './components/Row'

function AdoptionFairs({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const {
    loading, data, setData, error,
  } = useAxiosRequest(adoptionFairs.list, query)

  const onCreateFair = useCallback((fair) => {
    setData({
      ...data,
      rows: [fair, ...data?.rows],
    })
  }, [data, setData])

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Feiras de adoção"
        subtitle="Organize aqui as feiras de adoção"
        icon="fas fa-heart"
        rightAction={() => (
          <>
            <Filter
              initialValues={{
                title: '', status: undefined, description: '',
              }}
              title="Filtrar feiras"
              renderForm={() => (
                <>
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="title">Título da feira</Form.Label>
                    <Form.ValidationField
                      name="title"
                      className="input-sm"
                      autoFocus
                      id="title"
                      autoComplete="none"
                      placeholder="Feira 1"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="description">Descrição da feira</Form.Label>
                    <Form.ValidationField
                      name="description"
                      className="input-sm"
                      id="description"
                      autoComplete="none"
                      placeholder="Feira no Jardim Teste"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="status">Status da feira</Form.Label>
                    <Form.ValidationField name="status">
                      {
                        ({ field }) => (
                          <Form.Select
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            id="status"
                            className="select-sm"
                            placeholder="Status da feira"
                          >
                            <option value="">Todos os status</option>
                            <option value="draft">Rascunho</option>
                            <option value="created">Criadas</option>
                            <option value="running">Em andamento</option>
                            <option value="closed">Encerradas</option>
                            <option value="canceled">Canceladas</option>
                          </Form.Select>
                        )
                      }
                    </Form.ValidationField>
                  </Form.Group>
                </>
              )}
            />
            <Create onFairSave={onCreateFair} />
          </>
        )}
      />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        <>
          <div className="table-scroll">
            <table className="table text-small table-striped table-hover">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Título</th>
                  <th width={200}>Criado em</th>
                  <th width={200}>Agendada para</th>
                  <th width={200}>Iniciada em</th>
                  <th width={300}>Status</th>
                  <th width={300}>Restrição</th>
                  <th width={1} className="text-right">Ações</th>
                </tr>
              </thead>
              <tbody>
                {data?.rows.map((fair) => (
                  <Row fair={fair} key={fair?.id} />
                ))}
              </tbody>
            </table>
          </div>
          {
            Boolean(data) && (
              <div className="float-right my-2">
                <Paginator paginatedList={data} />
              </div>
            )
          }
        </>
      </ListManager>
    </Styles.Container>
  )
}

export default AdoptionFairs

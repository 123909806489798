import React from 'react'
import PropTypes from 'prop-types'
import dateMask from '@/lib/masks/date'
import {
  iconClasses, singularStatusMapLabel, textClasses,
  singularRestrictMapLabel, textRestrict,
} from '@/constants/adoptionFair'
import { Link } from 'react-router-dom'

// import { Container } from './styles';

function Row({ fair }) {
  return (
    <tr>
      <td>{fair?.id}</td>
      <td style={{ maxWidth: 300 }} className="text-ellipsis">{fair?.title}</td>
      <td>{dateMask(fair?.createdAt, { hour: '2-digit', minute: '2-digit' })}</td>
      <td>{dateMask(fair?.beginIn, { hour: '2-digit', minute: '2-digit' })}</td>
      <td>{dateMask(fair?.startedAt, { hour: '2-digit', minute: '2-digit' })}</td>
      <td>
        <div className={textClasses[fair?.status]}>
          <i className={`fa-fw ${iconClasses[fair?.status]}`} />
          {singularStatusMapLabel[fair?.status]}
        </div>
      </td>
      <td>
        <div className={textRestrict[fair?.restrict]}>
          {singularRestrictMapLabel[fair?.restrict]}
        </div>
      </td>
      <td className="text-right">
        <Link
          className="btn btn-action btn-sm btn-primary"
          to={`/app/adoption-fairs/${fair.id}/pets`}
        >
          <i className="fas fa-folder-open" />
        </Link>
      </td>
    </tr>
  )
}

Row.propTypes = {
  fair: PropTypes.objectOf(Object).isRequired,
}

export default Row

import { Formik } from 'formik'
import React from 'react'
import 'react-confirm-alert/src/react-confirm-alert.css'
import CookieConsent from 'react-cookie-consent'
import ReactDOM from 'react-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './styles'
import App from './views/app'

ReactDOM.render(
  <React.StrictMode>
    <Formik>
      <App />
    </Formik>
    <ToastContainer />
    <CookieConsent
      buttonText="Eu entendo"
      buttonStyle={{ color: '#fff', backgroundColor: '#e49042', fontSize: '13px' }}
    >
      Nosso site usa cookies para melhorar a experiência do usuário.{' '}
      <div>
        <small>
          Consulte nossos&nbsp;
          <a
            className="c-hand"
            href="https://petis.me/termos/"
            target="_blank"
            rel="noreferrer"
          >
            termos de uso
          </a> para saber mais
        </small>
      </div>
    </CookieConsent>
  </React.StrictMode>,
  document.getElementById('root'),
)

export const sizeMap = {
  toy: 'Toy',
  small: 'Pequeno',
  medium: 'Médio',
  big: 'Grande',
  giant: 'Gigante',
}

export const genderMap = {
  male: 'Macho',
  female: 'Fêmea',
}

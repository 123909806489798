import { Avatar, Flexbox, ImageViewer } from '@/components'
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import dateMask from '@/lib/masks/date'
import useAuth from '@/hooks/useAuth'
import { Image } from './styles'

function Row({ comment, castration }) {
  const { user } = useAuth()
  const author = comment?.author
  const [image, setImage] = useState('')

  const userHasAuthor = user?.id === comment?.authorId
  const isTutor = comment?.authorId === castration?.tutorId
  const isClinic = comment?.authorId === castration?.clinic?.id
  const isPartner = comment?.authorId === castration?.provider?.id || userHasAuthor

  return (
    <>
      {
        Boolean(image) && (
          <ImageViewer src={image} onClose={() => setImage('')} />
        )
      }
      <Flexbox.Columns className={`my-2 ${userHasAuthor ? 'row-reverse ml-2' : 'mr-2'}`}>
        <Flexbox.Column className="col-auto">
          <Avatar
            name={author?.fullName}
            photoURL={author?.photoURL}
            alt="Imagem de usuário"
          />
        </Flexbox.Column>
        <Flexbox.Column>
          <div className="bg-gray p-2 s-rounded">
            <div className="pre">{comment?.description}</div>
            {
              Boolean(comment?.attachmentURL) && (
                <Image
                  className="c-hand"
                  onClick={() => setImage(comment?.attachmentURL)}
                  src={comment?.attachmentURL}
                />
              )
            }
            <div>
              <div className="divider" />
              <small>
                <b>{author?.fullName}</b> em
                <b> {dateMask(comment?.createdAt)}</b>
              </small>
              <div>
                {isTutor && (
                  <small>
                    <i className="fas fa-user" />
                    &nbsp;Tutor
                  </small>
                )}
                {isClinic && (
                  <small>
                    <i className="fas fa-user-md" />
                    &nbsp;Clínica
                  </small>
                )}
                {isPartner && (
                  <small>
                    <i className="fas fa-user-astronaut" />
                    &nbsp;Administrador
                  </small>
                )}
              </div>
            </div>
          </div>
        </Flexbox.Column>
      </Flexbox.Columns>
    </>
  )
}

Row.propTypes = {
  comment: PropTypes.objectOf(Object).isRequired,
  castration: PropTypes.objectOf(Object).isRequired,
}

export default Row

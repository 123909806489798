import {
  Avatar, Card, Flexbox, ListManager, Paginator,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import useQuery from '@/hooks/useQuery'
import petisUsers from '@/services/api/petisUsers'
import PropTypes from 'prop-types'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import NewAnimal from '../NewAnimal'

function AnimalsCard({ adoptions }) {
  const { id } = useParams()
  const query = useQuery()
  const {
    data, loading, error, fetchData,
  } = useAxiosRequest(petisUsers.animals, id, { ...(query || {}), take: 10 })

  const rows = data?.rows || []

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Animais do usuário</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-paw" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <ListManager
          emptyMessage="Esse usuário não possui nenhum pet cadastrado"
          emptyIcon="fas fa-paw"
          loading={loading}
          count={data?.count || 0}
          error={error}
        >
          <>
            <table className="table text-small table-striped table-hover">
              <thead>
                <tr>
                  <th>Nome</th>
                  <th>Espécie/Raça</th>
                  <th>Castrado?</th>
                  <th>Adotado?</th>
                  <th className="text-right" width="1">Ações</th>
                </tr>
              </thead>
              <tbody>
                {
                  rows.map((animal) => (
                    <tr key={animal?.id}>
                      <td>
                        <Avatar
                          avatarSize="sm"
                          name={animal?.name}
                          photoURL={animal?.photoURL}
                        />
                        &nbsp;{animal?.name}
                      </td>
                      <td>
                        <small>
                          {animal?.category?.description} - {animal?.breed?.description}
                        </small>
                      </td>
                      <td>
                        {animal?.isCastrated ? 'Sim' : 'Não'}
                      </td>
                      <td>
                        {adoptions?.some((adoption) => adoption.animalId === animal?.id) ? 'Sim' : 'Não'}
                      </td>
                      <td className="text-right">
                        <Link
                          to={`/app/pets/${animal?.id}`}
                          className="btn btn-action btn-sm btn-primary"
                        >
                          <i className="fas fa-eye" />
                        </Link>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            {
              Boolean(data) && (
                <div className="float-right my-2">
                  {Boolean(data) && <Paginator paginatedList={data} />}
                </div>
              )
            }
          </>
        </ListManager>
      </Card.Body>
      <Card.Footer>
        <NewAnimal onConfirm={() => fetchData()} />
      </Card.Footer>
    </Card.Base>
  )
}

AnimalsCard.propTypes = {
  adoptions: PropTypes.arrayOf(Object),
}

AnimalsCard.defaultProps = {
  adoptions: [],
}

export default AnimalsCard

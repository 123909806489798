/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import {
  AddressPicker, Flexbox, Form,
  PageHeader, Styles,
} from '@/components'
import usePageTitle from '@/hooks/usePageTitle'
import yup from '@/lib/yupPt'
import mistreatments from '@/services/api/mistreatments'
import upload from '@/services/api/upload'
import React, { useCallback, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

const fileTypes = ['JPG', 'PNG', 'JPEG']

const schema = yup.object().shape({
  description: yup.string().trim().required(),
})
function ClinicalCaresForm({ title }) {
  const [address, setAddress] = useState(null)
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  usePageTitle({ title })

  const onSubmit = useCallback(async (values) => {
    try {
      setLoading(true)
      const pictures = []
      for (const file of files) {
        const { data: { url } } = await upload.sendFile(file)
        pictures.push({ photoURL: url })
      }
      const body = {
        ...values,
        address,
      }
      const { data } = await mistreatments.create(body)
      history.replace(`/app/mistreatments/show/${data.id}`)
    } catch (e) {
      toast.error(e?.message)
      setLoading(false)
    }
  }, [address, files, history])

  function onSelectFiles(params) {
    const added = Object.values(params).filter((file) => !files.find((f) => f.name === file.name))
    setFiles([...files, ...(added)])
  }

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Nova denúncia"
        icon="fas fa-bullhorn"
        subtitle="Informe os dados para criar a denúncia"
      />
      <fieldset disabled={loading}>
        <Form.Group>
          <Form.Label htmlFor="animal">Informe o endereço da denúncia  <span className="text-error">*</span></Form.Label>
          <AddressPicker title="Informe o local do pet" address={address} onChange={setAddress} />
        </Form.Group>
        <div className="divider" />
        <Form.Formik
          initialValues={{
            description: '',
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {
            ({
              errors, touched, isValid,
            }) => (
              <Form.ValidationForm>
                <Flexbox.Columns>
                  <Flexbox.Column className="col-12">
                    <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="description">Descreva o caso, conte o que aconteceu <span className="text-error">*</span></Form.Label>
                      <Form.ValidationField
                        name="description"
                        id="description"
                      >
                        {
                          ({ field }) => (
                            <textarea
                              name="description"
                              className="form-input"
                              rows={5}
                              placeholder="Exemplo: Violência contra o pet, falta de comida, abandono"
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...field}
                            />
                          )
                        }
                      </Form.ValidationField>
                      {
                        touched?.description && errors?.description
                          ? <Form.Hint className="text-error">{errors.description}</Form.Hint> : null
                      }
                    </Form.Group>
                  </Flexbox.Column>
                  <Flexbox.Column className="col-12">
                    <Form.Label>Fotos da denúncia</Form.Label>
                    <FileUploader
                      handleChange={onSelectFiles}
                      hoverTitle="Solte aqui"
                      multiple
                      types={fileTypes}
                    >
                      {
                        files.length === 0 ? (
                          <div className="c-hand bg-gray text-center text-dark" style={{ paddingTop: 30, paddingBottom: 30 }}>
                            <div className="empty-icon">
                              <i className="fas fa-paperclip fa-lg" />
                            </div>
                            <div className="empty-title h5">Selecione os anexos</div>
                            <div className="empty-subtitle">Clique ou arraste os anexos para adicionar</div>
                          </div>
                        ) : (
                          <div className="c-hand bg-gray text-center" style={{ paddingTop: 30, paddingBottom: 30 }}>
                            <div className="empty-title h5">Anexos adicionados: {files.length}</div>
                            <div className="empty-subtitle">Clique ou arraste anexos para adicionar mais</div>
                          </div>
                        )
                      }
                    </FileUploader>
                  </Flexbox.Column>
                  <Flexbox.Column className="col-12 my-2">
                    <div className="divider" />
                  </Flexbox.Column>
                </Flexbox.Columns>
                <div className="text-right mt-2">
                  <button type="submit" className={`btn btn-primary ${loading ? 'loading' : ''}`} disabled={!isValid || !address}>
                    <i className="fas fa-check" />
                    &nbsp;Criar denúncia
                  </button>
                </div>
              </Form.ValidationForm>
            )
          }
        </Form.Formik>
      </fieldset>
    </Styles.Container>
  )
}

export default ClinicalCaresForm

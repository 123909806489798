import { useContext } from 'react'
import castrationContext from '@/contexts/castrationContext'

/**
 * @typedef {Object} CastrationReducer
 * @property {Object} state - State of castration
 * @property {Function} dispatch - dispatch
 */

/**
 * @returns {CastrationReducer}
 */
 export default function useCastration() {
  return useContext(castrationContext)
}

import { ListManager, PageHeader, Styles } from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import partnerPermissions from '@/services/api/settings/partnerPermissions'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import Row from './Row'

function List({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const { pathname } = useLocation()
  const {
    data, loading, error, setData, fetchData,
  } = useAxiosRequest(partnerPermissions.list, query)

  function handleDelete(permission) {
    setData({
      ...data,
      rows: data?.rows?.filter(({ id }) => id !== permission?.id),
    })
  }

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Meus usuários"
        icon="fas fa-users"
        subtitle="Defina o papel de cada usuário que você cadastrou"
        rightAction={() => (
          error
            ? null
            : (
              <Link to={`${pathname}/create`} className="btn btn-primary">
                <i className="fas fa-plus" />
                &nbsp;Adicionar
              </Link>
            )
        )}
      />
      <ListManager count={data?.rows?.length || 0} loading={loading} error={error}>
        {
          Boolean(data) && (
            <small>
              <table className="table table-stripped">
                <thead>
                  <tr>
                    <th>Usuário</th>
                    <th width="150px">Adicionado em</th>
                    <th width="250px">Permissões</th>
                    <th className="text-right" width="1">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.rows.map((permission) => (
                      <Row
                        key={permission?.id}
                        permission={permission}
                        onDelete={handleDelete}
                        onUpdate={() => fetchData()}
                      />
                    ))
                  }
                </tbody>
              </table>
            </small>
          )
        }
      </ListManager>
    </Styles.Container>
  )
}

export default List

import {
  Flexbox, ListManager, PageHeader,
  Styles, Card,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import reports from '@/services/api/reports'
import React from 'react'
import FilterAdoptions from '../components/FilterAdoptions'

function MonthlyAdoptionsByFair({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const { data, loading, error } = useAxiosRequest(reports.monthlyAdoptionsByFair, query)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Adoções mensais por feira"
        subtitle="Quantidade de adoções confirmadas por mês nas feiras do município e online"
        icon="fab fa-gratipay"
        rightAction={() => <FilterAdoptions />}
      />
      <ListManager count={data?.count || 0} loading={loading} error={error}>
        <Flexbox.Columns>
          <Flexbox.Column className="col-sm-12 my-2">
            <Card.Base>
              <Card.Header>
                <Flexbox.Columns className="align-center col-gapless">
                  <Flexbox.Column className="ml-2">
                    <b>
                      Quantidade total de adoções confirmadas por ano
                    </b>
                  </Flexbox.Column>
                </Flexbox.Columns>
              </Card.Header>
              <Card.Body>
                <div className="divider" />
                <table className="table table-hover text-small">
                  <thead>
                    <tr>
                      <th>Ano</th>
                      <th width="100px" className="text-right">Quantidade</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data?.results?.map((item) => (
                        <tr key={item?.year}>
                          <td>{item?.year}</td>
                          <td className="text-right">{item?.AnnualAdoptionsCount}</td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </Card.Body>
            </Card.Base>
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          {
            Boolean(data) && data.rows.map((item) => (
              <Flexbox.Column key={item?.month} className="col-6 col-sm-12 my-2">
                <Card.Base>
                  <Card.Header>
                    <Flexbox.Columns className="align-center">
                      {
                        Boolean(item.month) && (
                          <Flexbox.Column className="col-auto">
                            <i className="fas fa-calendar fa-lg" />
                          </Flexbox.Column>
                        )
                      }
                      <Flexbox.Column>
                        <div className="h5">{item?.monthString}</div>
                      </Flexbox.Column>
                    </Flexbox.Columns>
                  </Card.Header>
                  <Card.Body>
                    <table className="table table-hover text-small">
                      <thead>
                        <tr>
                          <th>Feira</th>
                          <th width="100px" className="text-right">Adoções confirmadas</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          item.fairs.map((fair) => (
                            <tr key={fair?.fairId}>
                              <td>{fair.fairTitle}</td>
                              <td className="text-right">{fair.adoptionCount}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                      <tfoot>
                        <tr>
                          <td />
                          <td className="text-bold text-primary text-right">
                            Total: {item.adoptionsTotal}
                          </td>
                        </tr>
                      </tfoot>
                    </table>
                  </Card.Body>
                </Card.Base>
              </Flexbox.Column>
            ))
          }
        </Flexbox.Columns>
      </ListManager>
    </Styles.Container>
  )
}

export default MonthlyAdoptionsByFair

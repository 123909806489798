import styled from 'styled-components'

export const Image = styled.img.attrs({
  className: 'img-responsive s-rounded',
})`
  max-width: 100px;
  transition: all .2s ease-in-out;
  margin-bottom: 5px;  

  :hover {
    transform: scale(1.02);
    box-shadow: 0 0.25rem 1rem rgba(48,55,66,.2);
  }
`

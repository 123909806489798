import {
  Avatar, Flexbox, ListManager, Paginator, Share, Styles,
} from '@/components'
import useAdoptionFair from '@/hooks/useAdoptionFair'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import adoptionFairs from '@/services/api/adoptionFairs'
import React, { useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import envs from '@/constants/envs'
import PetsQuickFilter from './QuickFilter'

function Pets({ title }) {
  usePageTitle({ title })
  const location = useLocation()
  const { id } = useParams()
  const query = useQuery()
  const {
    loading, data, error, fetchData,
  } = useAxiosRequest(adoptionFairs.animals, id, query)
  const { state: { adoptionFair } } = useAdoptionFair()

  useEffect(() => {
    if (location) fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  if (!adoptionFair) return null

  return (
    <Styles.Container className="grid-xl no-print">
      <Flexbox.Columns className="align-center">
        <Flexbox.Column>
          <PetsQuickFilter />
        </Flexbox.Column>
        <Flexbox.Column className="col-auto">
          <Link
            to="add-pet"
            className="btn btn-sm btn-primary"
          >
            <i className="fas fa-plus" />
            &nbsp;Adicionar pet
          </Link>
        </Flexbox.Column>
      </Flexbox.Columns>
      <ListManager
        emptyMessage="Nenhum pet na feira"
        emptyIcon="fas fa-paw"
        emptySubtitle="Os pets vão aparecer aqui"
        loading={loading}
        count={data?.count || 0}
        error={error}
      >
        <>
          <table className="table text-small table-striped table-hover">
            <thead>
              <tr>
                <th>Pet</th>
                <th>Adicionado por</th>
                <th>Denúncias</th>
                <th>Adotado?</th>
                <th className="text-right" width={100}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {
                data && data.rows.map((animalFair) => (
                  <tr key={animalFair.id}>
                    <td>
                      <Link to={`/app/pets/${animalFair?.animalId}`}>
                        <Avatar
                          avatarSize="sm"
                          className="mr-1"
                          name={animalFair?.animal?.name}
                          photoURL={animalFair?.animal?.photoURL}
                          alt="Imagem de usuário"
                        />
                        {animalFair?.animal?.name}
                      </Link>
                      <div className="mt-2">
                        <Share
                          url={`${envs.REACT_APP_BASE_WEB_SHOW_URL}/adoption-fairs/${id}/animals/${animalFair?.id}`}
                          title={`O pet ${animalFair?.animal?.name} está disponível para adoção na feirinha "${adoptionFair?.title}" no Petis, venha conferir`}
                        />
                      </div>
                    </td>
                    <td>
                      <Avatar
                        avatarSize="sm"
                        className="mr-1"
                        name={animalFair?.person?.fullName}
                        photoURL={animalFair?.person?.photoURL}
                        alt="Imagem de usuário"
                      />
                      {animalFair?.person?.fullName}
                    </td>
                    <td>
                      {
                        animalFair?.complaints
                          ? `${animalFair.complaints} ${animalFair?.complaints !== 1 ? 'denúncias' : 'denúncia'}`
                          : 'Nenhuma'
                      }
                    </td>
                    <td>{animalFair?.adopted ? 'Sim' : 'Não'}</td>
                    <td>
                      <div className="d-flex align-center" style={{ justifyContent: 'flex-end' }}>
                        {
                          animalFair.chats > 0 && (
                            <Link to={`pets/${animalFair?.id}/chats`} className="text-dark">
                              <div className="tooltip tooltip-left" data-tooltip="Chats">
                                <span className="badge mr-2" data-badge={animalFair.chats}>
                                  <i className="fas fa-comment" />
                                </span>
                              </div>
                            </Link>
                          )
                        }
                        {
                          !animalFair?.adopted && (
                            <Link
                              to={`pets/${animalFair?.id}/transfer`}
                              data-tooltip="Transferir pet"
                              className="btn btn-action btn-sm btn-error mr-1 tooltip tooltip-top"
                            >
                              <i className="fas fa-exchange-alt" />
                            </Link>
                          )
                        }
                        <Link
                          to={`pets/${animalFair?.id}`}
                          data-tooltip="Exibir"
                          className="btn btn-action btn-sm btn-primary tooltip tooltip-top"
                        >
                          <i className="fas fa-eye" />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          {
            Boolean(data) && (
              <div className="float-right my-2">
                <Paginator paginatedList={data} />
              </div>
            )
          }
        </>
      </ListManager>
    </Styles.Container>
  )
}

export default Pets

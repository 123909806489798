import React, { useCallback, useEffect, useState } from 'react'
import useAuth from '@/hooks/useAuth'
import partnerLicensing from '@/services/api/partnerLicensing'
import Address from './Address'
import Terms from './Terms'
import Block from './Block'

function CompleteLicensingContainer({ children }) {
  const [loading, setLoading] = useState(false)
  const {
    user, licensingId, licensing, setLicensing,
  } = useAuth()

  const hasAddress = Boolean(licensing?.licensed?.address)
  const hasTerms = Boolean(licensing?.terms)
  const modalOpened = !loading && (!hasAddress || !hasTerms)

  const fetchLicensing = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await partnerLicensing.show(licensingId)
      setLicensing(data)
    } finally {
      setLoading(false)
    }
  }, [licensingId, setLicensing])

  useEffect(() => {
    fetchLicensing()
  }, [licensingId, fetchLicensing])

  function renderAction() {
    if (!licensing) return null
    if (modalOpened && (user?.id !== licensing?.licensedId)) {
      return <Block hasAddress={hasAddress} hasTerms={hasTerms} />
    }
    if (!hasAddress) return <Address />
    if (!hasTerms) return <Terms />

    return null
  }

  return (
    <>
      <div className={`modal ${modalOpened ? 'active' : ''}`}>
        <a type="button" className="modal-overlay" aria-label="Close" />
        {renderAction()}
      </div>
      {children}
    </>
  )
}

export default CompleteLicensingContainer

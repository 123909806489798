import React, { useCallback, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'
import clinicalCareItemClinics from '@/services/api/clinicalCareItemClinics'

function Remove({ item, onRemoved }) {
  const [loading, setLoading] = useState(false)

  const remove = useCallback(async () => {
    try {
      setLoading(true)
      await clinicalCareItemClinics.destroy(item.clinicId, item.id)
      onRemoved(item)
      toast.success(`${item?.clinicalCareItemProvider?.title} removido com sucesso`)
    } catch (e) {
      toast.error(e?.message)
      setLoading(false)
    }
  }, [item, onRemoved])

  function confirm() {
    confirmAlert({
      title: 'Excluir item',
      message: `Você está certo que deseja excluir o item ${item?.clinicalCareItemProvider?.title} da clínica?`,
      buttons: [
        { label: 'Não' },
        {
          label: 'Excluir',
          className: 'bg-error',
          onClick: remove,
        },
      ],
    })
  }

  return (
    <button
      data-tooltip="Remover"
      onClick={confirm}
      disabled={loading}
      type="button"
      className="btn btn-error btn-sm btn-action tooltip tooltip-left ml-2"
    >
      {
        loading
        ? <i className="loading" />
        : <i className="fas fa-trash" />
      }
    </button>
  )
}

Remove.propTypes = {
  item: PropTypes.objectOf(Object).isRequired,
  onRemoved: PropTypes.func.isRequired,
}

export default Remove

import mistreatmentContext from '@/contexts/mistreatmentContext'
import { useContext } from 'react'

/**
 * @typedef {Object} MistreatmentReducer
 * @property {Object} state - State of castration
 * @property {Function} dispatch - dispatch
 */

/**
 * @returns {MistreatmentReducer}
 */
 export default function useMistreatment() {
  return useContext(mistreatmentContext)
}

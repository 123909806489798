/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'

function ItemCol({ label, value }) {
  return (
    <div>
      <div className="text-small">{label}</div>
      <div>{value || '-'}</div>
    </div>
  )
}

ItemCol.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.any,
}

ItemCol.defaultProps = {
  value: '',
}

export default ItemCol

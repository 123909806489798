import {
  Avatar,
  Card,
  Flexbox,
  ListManager, PageHeader, Paginator, Styles,
} from '@/components'
import { singularStatusMapLabel } from '@/constants/castration'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import documentNumberMask from '@/lib/masks/documentNumber'
import reports from '@/services/api/reports'
import React from 'react'
import FilterCastrations from '../components/FilterCastrations'

function CastrationsByNGO({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const { data, loading, error } = useAxiosRequest(reports.castrationsByNGO, query)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Castrações por ONG"
        subtitle="Mostra a quantidade de castrações solicitadas pelas ONG's"
        icon="fas fa-building"
        rightAction={() => <FilterCastrations />}
      />
      <ListManager count={data?.count || 0} loading={loading} error={error}>
        <Flexbox.Columns>
          {
            Boolean(data) && data.rows.map((item) => (
              <Flexbox.Column key={item.tutorId} className="col-6 col-sm-12 my-2">
                <Card.Base>
                  <Card.Header>
                    <Flexbox.Columns className="align-center col-gapless">
                      <Flexbox.Column className="col-auto">
                        <Avatar
                          avatarSize="lg"
                          name={item?.tutorFullName}
                          photoURL={item?.tutorAvatar}
                          smart={false}
                          alt="Imagem de usuário"
                        />
                      </Flexbox.Column>
                      <Flexbox.Column className="ml-2">
                        <b>{item?.tutorFullName}</b>
                        {
                          Boolean(item?.tutorDocumentNumber) && (
                            <div>
                              <small>{documentNumberMask(item?.tutorDocumentNumber)}</small>
                            </div>
                          )
                        }
                      </Flexbox.Column>
                    </Flexbox.Columns>
                  </Card.Header>
                  <Card.Body>
                    <div className="text-right">
                      <span>Total: <b>{item?.total}</b></span>
                    </div>
                    <div className="divider" />
                    <table className="table table-hover text-small">
                      <thead>
                        <tr>
                          <th>Status</th>
                          <th width="100px" className="text-right">Quantidade</th>
                        </tr>
                      </thead>
                      <tbody>
                        {
                          data.statusList.map((status) => (
                            <tr key={status}>
                              <td>{singularStatusMapLabel[status]}</td>
                              <td className="text-right">{item[status] || 0}</td>
                            </tr>
                          ))
                        }
                      </tbody>
                    </table>
                  </Card.Body>
                </Card.Base>
              </Flexbox.Column>
            ))
          }
        </Flexbox.Columns>
        {
          Boolean(data) && (
            <div className="float-right my-2">
              <Paginator paginatedList={data} />
            </div>
          )
        }
      </ListManager>
    </Styles.Container>
  )
}

export default CastrationsByNGO

import styled from 'styled-components'

export const ImageContainer = styled.div`
  height: 100px;
  width: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  padding: 10px;
  border-radius: 5px;
  position: relative;
`

export const Image = styled.img`
  width: 100%;
`

export const ButtonRemove = styled.button.attrs({ type: 'button' })`
  position: absolute;
  right: -5px;
  top: -5px;  
`

import {
  LoadManager, PageHeader, Styles, UserCard,
} from '@/components'
import { resultColorMap, statusColorMap } from '@/constants/mistreatment'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import useMistreatment from '@/hooks/useMistreatment'
import usePageTitle from '@/hooks/usePageTitle'
import { MISTREATMENT } from '@/lib/enums'
import dateMask from '@/lib/masks/date'
import { setMistreatment } from '@/reducers/mistreatment'
import mistreatments from '@/services/api/mistreatments'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import useReadTimelineItem from '@/hooks/useReadTimelineItem'
import Actions from './components/Actions'
import CardDetails from './components/CardDetails'

// import { Container } from './styles';

function Show({ title }) {
  usePageTitle({ title })
  useReadTimelineItem()
  const { id } = useParams()
  const { dispatch, state: { mistreatment } } = useMistreatment()

  const { data, loading, error } = useAxiosRequest(mistreatments.show, id)

  useEffect(() => {
    if (data) dispatch(setMistreatment(data))
  }, [data, dispatch])

  return (
    <Styles.Container className="grid-xl">
      <PageHeader title="Denúncia de maus tratos" subtitle="Detalhes da denúncia de maus tratos" icon="fas fa-bullhorn" />
      <LoadManager loading={loading} error={error}>
        {
          Boolean(mistreatment) && (
            <>
              <Actions mistreatment={mistreatment} />
              <div className="my-2">
                Status:
                <b className={statusColorMap?.[mistreatment.status]}>
                  &nbsp;{MISTREATMENT.status.t(mistreatment.status)}
                </b>
              </div>
              <div className="my-2">
                Resultado:
                <b className={resultColorMap?.[mistreatment.result]}>
                  &nbsp;{MISTREATMENT.result.t(mistreatment.result)}
                </b>
              </div>
              <div className="my-2">
                Anônima:
                <b>
                  &nbsp;{mistreatment.anonymous ? 'Sim' : 'Não'}
                </b>
              </div>
              <div className="my-2">
                Criado em: <b>{dateMask(mistreatment.createdAt)}</b>
              </div>
              <div className="my-2">
                Última atualização em: <b>{dateMask(mistreatment.updatedAt)}</b>
              </div>
              {
                mistreatment?.anonymous ? null
              : <UserCard title="Denunciante" user={mistreatment.author} />
              }
              <div className="mt-2">
                <CardDetails mistreatment={mistreatment} />
              </div>
            </>
          )
        }
      </LoadManager>
    </Styles.Container>
  )
}

export default Show

const mapping = {
  requested: {
    title: 'Lote solicitado. \nAnalise antes de aprová-lo!',
    icon: '',
  },
  rejected: {
    title: 'Lote rejeitado.',
    bgIconClass: 'bg-error',
    icon: 'fas fa-ban',
  },
  approved: {
    title: 'Lote aprovado.',
    bgIconClass: 'bg-success',
    icon: 'fas fa-check',
  },
}

export default (status) => mapping[status] || null

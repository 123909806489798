import React from 'react'
import PropTypes from 'prop-types'
import { Flexbox, Form } from '@/components'

export default function AdoptionData({
  touched, errors, setFieldValue,
}) {
  return (
    <div>
      <div className="h5">Informe os dados da adoção</div>
      <p>
        Isso ficará visível na tela de visualização da adoção
      </p>
      <Flexbox.Columns>
        <Flexbox.Column className="col-12">
          <Form.Group className={`${touched?.adoptedAt && errors?.adoptedAt ? 'has-error' : ''}`}>
            <Form.Label htmlFor="adoptedAt">Adotado em <span className="text-error">*</span></Form.Label>
            <Form.ValidationField name="adoptedAt">
              {
                ({ field }) => (
                  <Form.DatePickerField
                    locale="pt-BR"
                    name="adoptedAt"
                    placeholderText="Adotado em"
                    timeInputLabel="Hora"
                    onChange={(value) => setFieldValue('adoptedAt', value)}
                    selected={field.value}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    className="form-input"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                  />
                )
              }
            </Form.ValidationField>
            {
              touched?.adoptedAt && errors?.adoptedAt
                ? <Form.Hint className="text-error">{errors.adoptedAt}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>
        <Flexbox.Column className="col-12">
          <Form.Group className={`${touched?.terms && errors?.terms ? 'has-error' : ''}`}>
            <Form.Label htmlFor="terms">Termos da adoção</Form.Label>
            <Form.ValidationField
              name="terms"
              id="terms"
            >
              {
                ({ field }) => (
                  <textarea
                    name="terms"
                    className="form-input"
                    rows={5}
                    placeholder="Termos da adoção"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                  />
                )
              }
            </Form.ValidationField>
            {
              touched?.terms && errors?.terms
                ? <Form.Hint className="text-error">{errors.terms}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>
        <Flexbox.Column className="col-12">
          <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
            <Form.Label htmlFor="description">Observações</Form.Label>
            <Form.ValidationField
              name="description"
              id="description"
            >
              {
                ({ field }) => (
                  <textarea
                    name="description"
                    className="form-input"
                    rows={3}
                    placeholder="Observações"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                  />
                )
              }
            </Form.ValidationField>
            {
              touched?.description && errors?.description
                ? <Form.Hint className="text-error">{errors.description}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>

        <Flexbox.Column className="col-12">
          <Form.Group className={`${touched?.attachmentURL && errors?.attachmentURL ? 'has-error' : ''}`}>
            <Form.Label htmlFor="attachmentURL">Anexo do termo de adoção</Form.Label>
            <Form.ValidationField name="attachmentURL">
              {
                ({ field }) => (
                  <Form.FileUploadField
                    name="attachmentURL"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                  />
                )
              }
            </Form.ValidationField>
            {
              touched?.attachmentURL && errors?.attachmentURL
                ? <Form.Hint className="text-error">{errors.attachmentURL}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>
      </Flexbox.Columns>
    </div>
  )
}

AdoptionData.propTypes = {
  touched: PropTypes.objectOf(Object).isRequired,
  errors: PropTypes.objectOf(Object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

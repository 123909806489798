import { Flexbox, Form } from '@/components'
import yup from '@/lib/yupPt'
import PropTypes from 'prop-types'
import React from 'react'

const schema = yup.object().shape({
  name: yup.string().trim().required(),
})

function MemberForm({
  active, onClose, initialValues, onSubmit, title,
}) {
  return (
    <Form.Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      validateOnMount
    >
      {
        ({
          touched, errors, isValid,
        }) => (
          <Form.ValidationForm>
            <div className={`modal ${active ? 'active' : ''}`}>
              <a type="button" className="modal-overlay" aria-label="Close" onClick={onClose} />
              <div className="modal-container">
                <div className="modal-header">
                  <a
                    type="button"
                    className="btn btn-clear float-right"
                    onClick={onClose}
                  />
                  <div className="modal-title h5">
                    {title}
                  </div>
                </div>
                <div className="modal-body">
                  <Flexbox.Columns className="p-2 align-center">
                    <Flexbox.Column className="col-12">
                      <Form.Group className={`${touched?.name && errors?.name ? 'has-error' : ''}`}>
                        <Form.Label htmlFor="name">Nome <span className="text-error">*</span></Form.Label>
                        <Form.ValidationField
                          name="name"
                          id="name"
                          placeholder="Nome do membro..."
                        />
                        {
                          touched?.name && errors?.name
                            ? <Form.Hint className="text-error">{errors.name}</Form.Hint> : null
                        }
                      </Form.Group>
                    </Flexbox.Column>
                  </Flexbox.Columns>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary" disabled={!isValid}>
                    <i className="fas fa-check" />
                    &nbsp;Confirmar
                  </button>
                </div>
              </div>
            </div>
          </Form.ValidationForm>
        )
      }
    </Form.Formik>
  )
}

export default MemberForm

MemberForm.propTypes = {
  active: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(Object),
}

MemberForm.defaultProps = {
  initialValues: {
    name: '',
  },
}

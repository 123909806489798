import styled from 'styled-components'

const borderColor = '#ddd'
const borderRadius = 5

export const Container = styled.div`
  width: 40%;
  min-width: 300px;
  max-width: 500px;
  position: fixed;
  bottom: 0;
  right: 20px;
  box-shadow: 0 0.25rem 1rem rgba(48,55,66,.3);
  border: 1px solid ${borderColor};
  border-top-left-radius: ${borderRadius}px;
  border-top-right-radius: ${borderRadius}px;
  overflow: hidden;
  z-index: 300;
`

export const Header = styled.div`
  display: flex;
  align-items: center;
  padding: 8px 15px;
  justify-content: space-between;
  background-color: #fafafa;
  border-bottom: 1px solid ${borderColor};
  cursor: pointer;

  &:hover {
    background-color: #f2f2f2;
  }

  i {
    transition: all .3s ease
  }

  &.active {
    i {
      transform: rotate(-180deg)
    }
  }
`

export const Content = styled.div`
  max-height: 0;
  transition: max-height .4s ease-in-out;
  overflow: hidden;

  &.active {
    height: 50vh;
    max-height: 600px;
    background-color: #fff;
  }
`

export const Body = styled.div`
  background-color: #fff;
  height: 50vh;
  max-height: 600px;
  display: flex;
  flex-direction: column;
`

export const ListContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  padding: 0 10px;
`

import {
 Flexbox, LoadManager, PageHeader, Styles, UserCard,
} from '@/components'
import { singularStatusMapLabel } from '@/constants/batches'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import useBatch from '@/hooks/useBatch'
import usePageTitle from '@/hooks/usePageTitle'
import { setBatch } from '@/reducers/batch'
import batches from '@/services/api/batches'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import Actions from './components/Actions'
import Castrations from './components/Castrations'
import Events from './components/Events'

function Show({ title }) {
  usePageTitle({ title })
  const { id } = useParams()
  const { state: { batch }, dispatch } = useBatch()
  const { data, loading, error } = useAxiosRequest(batches.show, id)

  useEffect(() => {
    if (data) dispatch(setBatch(data))
  }, [data, dispatch])

  return (
    <Styles.Container className="grid-xl">
      <PageHeader title={`Lote #${id}`} icon="fas fa-box" subtitle={`Código: ${batch?.uid}`} />
      <LoadManager loading={loading} error={error}>
      {
          Boolean(batch) && (
            <>
              <Actions batch={batch} />
              <div className="my-2">
                Situação: <b>{singularStatusMapLabel[batch?.status]}</b>
              </div>
              <div className="divider" />
              <Flexbox.Columns>
                <Flexbox.Column className="col-12 mb-2">
                  <h1 className="h5">Clínica e fornecedor</h1>
                </Flexbox.Column>
                <Flexbox.Column className="col-5 col-lg-6 col-sm-12 my-2">
                  <UserCard title="Clínica" user={batch?.person} />
                </Flexbox.Column>
                <Flexbox.Column className="col-2 text-center hide-lg flex-centered my-2">
                  <i className="fas fa-arrow-right fa-2x" />
                </Flexbox.Column>
                <Flexbox.Column className="col-5 col-lg-6 col-sm-12 my-2">
                  <UserCard title="Fornecedor" user={batch?.provider} />
                </Flexbox.Column>
              </Flexbox.Columns>

              <div className="divider" />
              <div className="mb-2 pb-2">
                <h1 className="h5">Castrações do lote ({batch?.amount})</h1>
                <Castrations castrations={batch?.castrations} />
              </div>
              <div className="divider" />
              <h1 className="h5 mb-2 mt-2 pt-2">Eventos do lote</h1>
              <Flexbox.Columns>
                <Flexbox.Column className="col-9 col-sm-12">
                  <Events events={batch?.events} />
                </Flexbox.Column>
              </Flexbox.Columns>
            </>
          )
      }
      </LoadManager>
    </Styles.Container>
  )
}

export default Show

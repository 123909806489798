export default {
  recordNotFound: {
    icon: 'fas fa-3x text-warning fa-search',
    title: '404',
    message: 'Este registro não existe ou nunca existiu',
  },
  requiresPartnerACL: {
    icon: 'fas fa-3x text-error fa-lock',
    title: 'Acesso restrito',
    message: 'Apenas o usuário mestre pode gerenciar esta página',
  },
  forbidden: {
    icon: 'fas fa-3x text-error fa-lock',
    title: 'Acesso não permitido',
  },
}

import {
  Card, Flexbox, Styles, Footer,
} from '@/components'

import React from 'react'
import { AppContainer, Logo, Overflow } from './styles'

function Home() {
  return (
    <>
      <AppContainer>
        <Overflow />
        <Card.Base style={{ maxWidth: 350, height: 'auto' }}>
          <Card.Header>
            <Flexbox.Columns className="align-center">
              <Flexbox.Column className="col-auto">
                <Logo />
              </Flexbox.Column>
              <Flexbox.Column>
                Petis - Bem vindo
              </Flexbox.Column>
            </Flexbox.Columns>
          </Card.Header>
          <Card.Body className="text-center my-2">
            Faça o gerenciamento das castrações da maneira mais fácil e moderna.
          </Card.Body>
          <Card.Footer>
            <Styles.Link to="/login" className="btn-block btn-primary">
              Fazer login
            </Styles.Link>
            <Styles.Link to="/request" className="btn-block btn-link mt-2">
              Solicitar adesão
            </Styles.Link>
          </Card.Footer>
        </Card.Base>
      </AppContainer>
      <Footer />
    </>
  )
}

export default Home

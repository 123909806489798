import { useContext } from 'react'
import authContext from '../contexts/authContext'

/**
 * @typedef {Object} AuthReturns
 * @property {Object} user - User
 * @property {Object} licensing - Licensing
 * @property {string} token - Token
 * @property {number} licensingId - LicensingId
 * @property {Function} signIn - SignIn function
 * @property {Function} signOut - SignOut function
 * @property {Function} refreshUser - RefreshUser function
 * @property {Function} setLicensingId - SetLicensingId function
 * @property {Function} setLicensing - SetLicensingId function
 */

/**
 * @returns {AuthReturns}
 */
export default function useAuth() {
  return useContext(authContext)
}

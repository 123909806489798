import PropTypes from 'prop-types'
import React, { useState } from 'react'
import MemberForm from '../MemberForm'

function MemberFormAdd({ onAdded, loading }) {
  const [active, setActive] = useState(false)

  return (
    <div>
      {
        active && (
          <MemberForm
            title="Adicionar Membro"
            active={active}
            onClose={() => setActive(false)}
            onSubmit={(v) => {
              onAdded(v)
              setActive(false)
            }}
          />
        )
      }
      <button
        disabled={loading}
        onClick={() => setActive(true)}
        type="button"
        className={`btn btn-primary btn-sm ${loading ? 'loading' : ''}`}
      >
        <i className="fas fa-plus" />
        &nbsp;Incluir membro
      </button>
    </div>
  )
}

export default MemberFormAdd

MemberFormAdd.propTypes = {
  onAdded: PropTypes.func.isRequired,
  loading: PropTypes.bool,
}

MemberFormAdd.defaultProps = {
  loading: false,
}

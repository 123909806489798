import adoptionFairs from '@/services/api/adoptionFairs'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import useModalAction from '../useModalAction'

function Publish({ fair, onPublish }) {
  const [loading, setLoading] = useState(false)
  const { active, close, open } = useModalAction()

  const onConfirm = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await adoptionFairs.publish(fair.id)
      toast.success('Feira publicada')
      setLoading(false)
      close()
      onPublish(data)
    } catch (e) {
      toast.error(e?.message)
      setLoading(false)
    }
  }, [close, fair.id, onPublish])

  return (
    <>
      <button type="button" disabled={!fair?.terms} className="btn btn-sm btn-success mx-1" onClick={open}>
        <i className="fas fa-cloud-upload-alt" />
        &nbsp;Publicar feira
      </button>
      <div className={`modal ${active ? 'active' : ''}`}>
        <a type="button" className="modal-overlay" aria-label="Close" onClick={close} />
        <div className="modal-container">
          <div className="modal-header">
            <a
              type="button"
              className="btn btn-clear float-right"
              onClick={close}
            />
            <div className="modal-title h5">
              Publicar a feira de adoção
            </div>
          </div>
          <div className="modal-body">
            <div className="h3">Atenção</div>
            <p>
              A partir do momento que a feira for publicada, todos os usuários poderão visualizá-la.
            </p>
            <p>
              Você tem certeza que deseja <b>publicar</b> esta feira de adoção?
              <b><br />{fair?.title}</b>
            </p>
          </div>
          <div className="modal-footer">
            <button
              className={`btn btn-success ${loading ? 'loading' : ''}`}
              disabled={loading || !fair?.terms}
              onClick={onConfirm}
              type="button"
            >
              <i className="fas fa-cloud-upload-alt" />
              &nbsp;Publicar feira
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

Publish.propTypes = {
  fair: PropTypes.objectOf(Object).isRequired,
  onPublish: PropTypes.func,
}

Publish.defaultProps = {
  onPublish: () => { },
}

export default Publish

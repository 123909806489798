import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import adoptionFairs from '@/services/api/adoptionFairs'
import { toast } from 'react-toastify'
import useModalAction from '../useModalAction'

function Start({ fair, onStart }) {
  const [loading, setLoading] = useState(false)
  const { active, close, open } = useModalAction()

  const onConfirm = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await adoptionFairs.start(fair.id)
      toast.success('Feira iniciada')
      setLoading(false)
      close()
      onStart(data)
    } catch (e) {
      toast.error(e?.message)
      setLoading(false)
    }
  }, [close, fair.id, onStart])

  return (
    <>

      <button type="button" className="btn btn-sm btn-success mx-1" onClick={open}>
        <i className="fas fas fa-play" />
        &nbsp;Iniciar feira
      </button>
      <div className={`modal ${active ? 'active' : ''}`}>
        <a type="button" className="modal-overlay" aria-label="Close" onClick={close} />
        <div className="modal-container">
          <div className="modal-header">
            <a
              type="button"
              className="btn btn-clear float-right"
              onClick={close}
            />
            <div className="modal-title h5">
              Iniciar a feira de adoção
            </div>
          </div>
          <div className="modal-body">
            <div className="h3">Atenção</div>
            <p>
              A partir do momento que a feira iniciar,
              os pets adicionados a ela poderão ser adotados.
            </p>
            <p>
              Você tem certeza que deseja <b>iniciar</b> esta feira de adoção?
              <b><br />{fair?.title}</b>
            </p>
          </div>
          <div className="modal-footer">
            <button
              className={`btn btn-success ${loading ? 'loading' : ''}`}
              disabled={loading}
              onClick={onConfirm}
              type="button"
            >
              <i className="fas fa-play" />
              &nbsp;Iniciar feira
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

Start.propTypes = {
  fair: PropTypes.objectOf(Object).isRequired,
  onStart: PropTypes.func,
}

Start.defaultProps = {
  onStart: () => { },
}

export default Start

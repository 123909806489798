/* eslint-disable react/jsx-props-no-spreading */
import { Flexbox, Form } from '@/components'
import yup from '@/lib/yupPt'
import clinicalCareItems from '@/services/api/settings/clinicalCareItems'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  title: yup.string().trim().required(),
  description: yup.string().trim().min(5),
})
export default function FormClinicalCareItem({ item, onClose, onSave }) {
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async (values) => {
    try {
      setLoading(true)
      if (item) {
        await clinicalCareItems.update(item.id, values)
      } else {
        await clinicalCareItems.create(values)
      }
      onSave()
      toast.success('Item clínico salvo')
    } catch (e) {
      setLoading(false)
      toast.error(e?.message)
    }
  }, [item, onSave])

  return (
    <fieldset disabled={loading}>
      <Form.Formik
        initialValues={{
          title: item?.title || '',
          description: item?.description || '',
          price: item?.price || 0,
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
        validateOnMount
      >
        {
          ({
            errors, touched, isValid,
          }) => (
            <Form.ValidationForm>
              <div className="modal active">
                <a type="button" className="modal-overlay" aria-label="Close" onClick={onClose} />
                <div className="modal-container">
                  <div className="modal-header">
                    <a
                      type="button"
                      className="btn btn-clear float-right"
                      onClick={onClose}
                    />
                    <div className="modal-title h5">
                      {item ? 'Editar item clínico' : 'Novo item clínico'}
                    </div>
                  </div>
                  <div className="modal-body">
                    <Flexbox.Columns>
                      <Flexbox.Column className="col-12">
                        <Form.Group className={`${touched?.title && errors?.title ? 'has-error' : ''}`}>
                          <Form.Label htmlFor="title">Título <span className="text-error">*</span></Form.Label>
                          <Form.ValidationField
                            name="title"
                            id="title"
                            placeholder="Medicamento / Procedimento / Exame"
                          />
                          {
                            touched?.title && errors?.title
                              ? <Form.Hint className="text-error">{errors.title}</Form.Hint> : null
                          }
                        </Form.Group>
                      </Flexbox.Column>
                      <Flexbox.Column className="col-12">
                        <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                          <Form.Label htmlFor="description">Descrição</Form.Label>
                          <Form.ValidationField
                            name="description"
                            id="description"
                            placeholder="Descreva este item"
                          />
                          {
                            touched?.description && errors?.description
                              ? <Form.Hint className="text-error">{errors.description}</Form.Hint> : null
                          }
                        </Form.Group>
                      </Flexbox.Column>

                    </Flexbox.Columns>
                  </div>
                  <div className="modal-footer">
                    <button type="submit" className="btn btn-primary" disabled={!isValid}>
                      <i className="fas fa-check" />
                      &nbsp;Salvar
                    </button>
                  </div>
                </div>
              </div>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </fieldset>
  )
}

FormClinicalCareItem.propTypes = {
  item: PropTypes.objectOf(Object),
  onClose: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
}

FormClinicalCareItem.defaultProps = {
  item: null,
}

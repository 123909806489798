import { Avatar, Flexbox, ImageViewer } from '@/components'
import { singularStatusMapLabel } from '@/constants/castration'
import { CASTRATION_CORRECTION_REQUIRED } from '@/lib/enums'
import extractCityUid from '@/lib/extractCityUid'
import extractGmapsAddress from '@/lib/extractGmapsAddress'
import dateMask from '@/lib/masks/date'
import gmaps from '@/services/gmaps'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import MapLocation from './components/MapLocation'
import mapEvents from './mapEvents'
import { ImageEvent } from './styles'

function TimelineItem({ castrationCity, event, index }) {
  const [image, setImage] = useState('')
  const [loadingAddress, setLoadingAddress] = useState(false)
  const [eventAddress, setEventAddress] = useState(null)
  const { pathname } = useLocation()
  const mappedEvent = mapEvents(event?.status)

  const { location } = event

  const fetchEventCity = useCallback(async () => {
    if (!location) return
    try {
      setLoadingAddress(true)
      const [longitude, latitude] = location?.coordinates
      const data = await gmaps.reverseGeocode({ latitude, longitude })
      setEventAddress({
        ...extractGmapsAddress(data),
        uid: extractCityUid(data?.address_components),
      })
    } finally {
      setLoadingAddress(false)
    }
  }, [location])

  useEffect(() => {
    fetchEventCity()
  }, [fetchEventCity])

  if (!mappedEvent) return null

  return (
    <>
      {
        Boolean(image) && (
          <ImageViewer src={image} onClose={() => setImage('')} />
        )
      }
      <div className="timeline-item">
        <div className="timeline-left">
          <span
            className={`timeline-icon tooltip tooltip-right ${mappedEvent?.bgIconClass} ${mappedEvent?.icon ? 'icon-lg' : ''}`}
            data-tooltip={singularStatusMapLabel[event.status]}
          >
            <i className={`${mappedEvent?.icon}`} />
          </span>
        </div>
        <div className="timeline-content">
          <div className="tile">
            <div className="tile-content">
              <p className="tile-subtitle">
                <span className="text-bold">
                  {dateMask(event?.createdAt, { dateStyle: 'full' })}&nbsp;
                </span>
                <small className="label label-sm label-rounded">
                  <i className="far fa-clock" />&nbsp;
                  {new Date(event?.createdAt).toLocaleTimeString()}
                </small>
              </p>
              <p className="tile-title pre">{mappedEvent?.title}</p>
              {
                Boolean(event?.description) && (
                  <p className="tile-title">
                    <cite className="pre">{event?.description}</cite>
                  </p>
                )
              }
              {
                event?.correctionRequired?.length > 0 && (
                  <div className="mb-2 pb-2">
                    <div>
                      <small>Itens definidos como obrigatórios à correção: </small>
                    </div>
                    {
                       event?.correctionRequired?.map((item) => (
                        <div>
                          <small>
                            - {CASTRATION_CORRECTION_REQUIRED.type.t(item)}
                          </small>
                        </div>
                       ))
                      }
                  </div>
                )
              }
              {
                (event?.photoURL || event?.location) && (
                  <div className="tile-title mb-2 pb-2">
                    <Flexbox.Columns>
                      {
                        Boolean(event?.photoURL) && (
                          <Flexbox.Column className="col-auto">
                            <a onClick={() => setImage(event?.photoURL)} className="c-hand">
                              <ImageEvent src={event?.photoURL} alt={`Imagem do evento ${event?.id}`} />
                            </a>
                          </Flexbox.Column>
                        )
                      }
                      {
                        Boolean(event?.location) && Boolean(event?.photoURL) && (
                          <Flexbox.Column className="col-auto flex-centered">
                            <i className="fas fa-arrow-right" />
                          </Flexbox.Column>
                        )
                      }
                      {
                        Boolean(event?.location) && (
                          <Flexbox.Column className="col-auto">
                            <MapLocation
                              sameCity={eventAddress?.uid === castrationCity?.uid}
                              location={event?.location}
                            />
                          </Flexbox.Column>
                        )
                      }
                      {
                        Boolean(location) && (
                          <Flexbox.Column className="col-12 mt-2">
                            {
                              // eslint-disable-next-line no-nested-ternary
                              loadingAddress
                                ? <small><span className="loading mr-2 pr-2" /> Buscando informações de localização...</small>
                                : (
                                  eventAddress?.uid === castrationCity?.uid
                                    ? (
                                      <small className="text-success">
                                        <i className="fas fa-map-marker" />
                                        &nbsp;O evento foi realizado na mesma cidade do fornecedor:
                                        &nbsp;<b>{eventAddress?.city} - {eventAddress?.state}</b>
                                      </small>
                                    )
                                    : (
                                      <small className="text-error">
                                        <i className="fas fa-map-marker" />
                                        &nbsp;O evento foi realizado em
                                        &nbsp;uma cidade diferente da do fornecedor:
                                        &nbsp;<b>{eventAddress?.city} - {eventAddress?.state}</b>
                                      </small>
                                    )
                                )
                            }
                          </Flexbox.Column>
                        )
                      }
                    </Flexbox.Columns>
                  </div>
                )
              }
              {
                Boolean(event?.selfieURL) && (
                  <>
                    <div>Selfie com documento:</div>
                    <div className="mb-2 pb-2">
                      <a onClick={() => setImage(event?.selfieURL)} className="c-hand">
                        <img src={event?.selfieURL} style={{ maxHeight: 150 }} className="img-responsive" alt="selfie" />
                      </a>
                    </div>
                  </>
                )
              }
              <Flexbox.Columns className="align-center col-gapless">
                <Flexbox.Column className="col-auto">
                  <Avatar
                    avatarSize="sm"
                    name={event?.author?.fullName}
                    photoURL={event?.author?.photoURL}
                    alt="Imagem de usuário"
                  />
                </Flexbox.Column>
                <Flexbox.Column className="text-small ml-2">{event?.author?.fullName}</Flexbox.Column>
              </Flexbox.Columns>
              <div className="mt-2 pt-2">
                <Link to={`${pathname}/events/${index}`} className="c-hand text-small">Exibir detalhes do evento</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

TimelineItem.propTypes = {
  event: PropTypes.objectOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  castrationCity: PropTypes.objectOf(Object),
}

TimelineItem.defaultProps = {
  castrationCity: null,
}

export default TimelineItem

/* eslint-disable react/jsx-props-no-spreading */
import { Filter, Form } from '@/components'
import React from 'react'

function FilterAdoptions() {
  return (
    <Filter
      initialValues={{
        animalCategoryId: '',
        animalGender: '',
        adoptedAtPeriod: [null, null],
      }}
      title="Filtrar adoções"
      renderForm={({ values }) => (
        <>
          <div className="divider text-center" data-content="Período" />
          <Form.Group>
            <Form.Label className="text-sm" htmlFor="adoptedAtPeriod">Adotados de - até</Form.Label>
            <Form.DatePickerField
              placeholderText="Data início - Data fim"
              dateFormat="dd/MM/yyyy"
              startDate={values.adoptedAtPeriod[0] ? new Date(values.adoptedAtPeriod[0]) : null}
              endDate={values.adoptedAtPeriod[1] ? new Date(values.adoptedAtPeriod[1]) : null}
              className="form-input input-sm"
              selectsRange
              isClearable
              name="adoptedAtPeriod"
            />
          </Form.Group>
          <div className="divider text-center" data-content="Animal" />
          <Form.Group>
            <Form.Label htmlFor="animalCategoryId">Espécie do pet</Form.Label>
            <Form.ValidationField name="animalCategoryId">
              {
                ({ field }) => (
                  <Form.Select
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    id="animalCategoryId"
                    className="select-sm"
                    placeholder="Espécie do pet"
                  >
                    <option value="">Todos</option>
                    <option value="1">Cachorro</option>
                    <option value="2">Gato</option>
                  </Form.Select>
                )
              }
            </Form.ValidationField>
          </Form.Group>
          <Form.Group>
            <Form.Label htmlFor="animalGender">Gênero do pet</Form.Label>
            <Form.ValidationField name="animalGender">
              {
                ({ field }) => (
                  <Form.Select
                    {...field}
                    id="animalGender"
                    className="select-sm"
                    placeholder="Gênero do pet"
                  >
                    <option value="">Todos</option>
                    <option value="male">Macho</option>
                    <option value="female">Fêmea</option>
                  </Form.Select>
                )
              }
            </Form.ValidationField>
          </Form.Group>
        </>
      )}
    />
  )
}

export default FilterAdoptions

import {
  Avatar,
  Flexbox,
  Form,
  ListManager,
  PageHeader, QuickFilter, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import currency from '@/lib/masks/currency'
import documentNumberMask from '@/lib/masks/documentNumber'
import clinicalCareItemClinics from '@/services/api/clinicalCareItemClinics'
import React, { useCallback } from 'react'
import { useParams } from 'react-router-dom'
import useQuery from '@/hooks/useQuery'
import Add from './Add'
import Update from './Update'
import Remove from './Remove'

function Items({ title }) {
  usePageTitle({ title })
  const { id } = useParams()
  const query = useQuery()
  const {
    data, error, loading, setData,
  } = useAxiosRequest(clinicalCareItemClinics.list, id, query)

  const onUpdated = useCallback((item) => {
    setData({
      ...data,
      rows: data.rows.map((i) => (i.id === item.id ? item : i)),
    })
  }, [data, setData])

  const onRemoved = useCallback((item) => {
    setData({
      ...data,
      rows: data.rows.filter((i) => i.id !== item.id),
      count: data.count - 1,
    })
  }, [data, setData])

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Gerenciar itens clínicos da clínica"
        icon="fas fa-star-of-life"
        rightAction={() => (
          <fieldset disabled={loading}>

            <Add
              clinicId={id}
              onAdded={(item) => setData({
                ...data,
                count: data.count + 1,
                rows: [item, ...data.rows],
              })}
            />
          </fieldset>
        )}
      />
      {
        Boolean(data?.clinic) && (
          <>
            <Flexbox.Columns className="align-center">
              <Flexbox.Column className="col-auto">
                <Avatar avatarSize="xl" name={data.clinic.fullName} photoURL={data.clinic.photoURL} />
              </Flexbox.Column>
              <Flexbox.Column>
                <div><b>{data.clinic.fullName}</b> <small>({data.clinic.displayName})</small></div>
                <div>{documentNumberMask(data.clinic.documentNumber)}</div>
              </Flexbox.Column>
            </Flexbox.Columns>
            <div className="divider" />
          </>
        )
      }
      <QuickFilter
        initialValues={{
          'clinicalCareItemProvider.title_cont': '',
          'clinicalCareItemProvider.description_cont': '',
        }}
        queryAttribute="q"
        title="Filtrar pets"
        renderForm={() => (
          <>
            <Flexbox.Column className="mt-2">
              <Form.Group>
                <Form.Label className="label-sm pt-2" htmlFor="title_cont">Título</Form.Label>
                <Form.ValidationField
                  name="['clinicalCareItemProvider.title_cont']"
                  className="input-sm"
                  id="title_cont"
                  autoComplete="none"
                  placeholder="Título do item"
                />
              </Form.Group>
            </Flexbox.Column>

            <Flexbox.Column className="mt-2">
              <Form.Group>
                <Form.Label className="label-sm pt-2" htmlFor="description_cont">Descrição</Form.Label>
                <Form.ValidationField
                  name="['clinicalCareItemProvider.description_cont']"
                  className="input-sm"
                  id="description_cont"
                  autoComplete="none"
                  placeholder="Descrição do item"
                />
              </Form.Group>
            </Flexbox.Column>
          </>
        )}
      />
      <ListManager
        emptyIcon="fas fa-star-of-life"
        emptyMessage="Nenhum item"
        emptySubtitle="Essa clínica ainda não possui nenhum item cadastrado"
        count={data?.count || 0}
        loading={loading}
        error={error}
      >
        {
          Boolean(data) && (
            <>
              <h1 className="h4">Lista dos itens</h1>
              <table className="table">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th className="text-right">Valor unitário</th>
                    <th className="text-right">Estoque disponível</th>
                    <th className="text-right" width="100">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.rows.map((i) => (
                      <tr key={i.id}>

                        <td>
                          <div><b>{i?.clinicalCareItemProvider?.title}</b></div>
                          <div>{i?.clinicalCareItemProvider?.description}</div>
                        </td>
                        <td className="text-right">{currency(i.price / 100, 'R$ ')}</td>
                        <td className="text-right">{i.balance}</td>
                        <td className="text-right">
                          <div className="d-flex" style={{ justifyContent: 'flex-end' }}>
                            <Update clinicItem={i} onUpdated={onUpdated} />
                            <Remove onRemoved={onRemoved} item={i} />
                          </div>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </>
          )
        }
      </ListManager>
    </Styles.Container>
  )
}

export default Items

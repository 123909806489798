import React, { useCallback, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Button } from '@/components/styles'
import { toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert'
import adoptionsRequested from '@/services/api/adoptionsRequested'

function Actions() {
  const { petId } = useParams()
  const history = useHistory()
  const [approving, setApproving] = useState(false)
  const [rejecting, setRejecting] = useState(false)

  const onReject = useCallback(async () => {
    try {
      setRejecting(true)
      await adoptionsRequested.reject(petId)
      toast.error('Solicitação de adoção rejeitada')
      history.goBack()
      setRejecting(false)
    } catch (e) {
      toast.error(e?.message)
      setRejecting(false)
    }
  }, [history, petId])

  const handleReject = useCallback(async () => {
    confirmAlert({
      title: 'Rejeitar solicitação de adoção',
      message: 'Você está certo disso?',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim',
          className: 'bg-error',
          onClick: onReject,
        },
      ],
    })
  }, [onReject])

  const onApprove = useCallback(async () => {
    try {
      setApproving(true)
      await adoptionsRequested.approve(petId)
      toast.success('Solicitação de adoção aprovada')
      history.goBack()
      setApproving(false)
    } catch (e) {
      toast.error(e?.message)
      setApproving(false)
    }
  }, [history, petId])

  const handleApprove = useCallback(async () => {
    confirmAlert({
      title: 'Aprovar solicitação de adoção',
      message: 'Você está certo disso?',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim',
          className: 'bg-success',
          onClick: onApprove,
        },
      ],
    })
  }, [onApprove])

  return (
    <>
      <Button type="button" onClick={handleApprove} disabled={approving} className="btn-sm btn-success ml-2">
        <i className="fas fa-check" />
        &nbsp;Aprovar adoção
      </Button>
      <Button onClick={handleReject} disabled={rejecting} type="button" className="btn-sm btn-error ml-2">
        <i className="fas fa-ban" />
        &nbsp;Rejeitar adoção
      </Button>
    </>
  )
}

export default Actions

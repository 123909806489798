import {
  Flexbox, Form, ListManager, ManualPaginator,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import useModalAction from '@/views/app/AdoptionFairs/Show/components/Actions/useModalAction'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import clinicalCareItemClinics from '@/services/api/clinicalCareItemClinics'
import FormItem from './FormItem'

function Add({ clinicId, onAdded }) {
  const {
    active, close, loading, setLoading, open,
  } = useModalAction()
  const [item, setItem] = useState(null)
  const [params, setParams] = useState({
    page: 1,
    take: 5,
    q: { active_eq: 1 },
  })

  const {
 data, loading: loadingItems, fetchData, error,
} = useAxiosRequest(
    clinicalCareItemClinics.allowedItems, clinicId, params,
  )

  const clear = useCallback(() => {
    setItem(null)
    setParams({ page: 1, take: 5, q: { active_eq: 1 } })
  }, [])
  const clearAndClose = useCallback(() => {
    clear()
    close()
  }, [clear, close])

  const onConfirm = useCallback(async (values) => {
    try {
      setLoading(true)
      const res = await clinicalCareItemClinics.create(clinicId, values)
      onAdded(res.data)
      clearAndClose()
      toast.success(`${res.data?.clinicalCareItemProvider?.title} adicionado com sucesso`)
      fetchData()
    } catch (e) {
      toast.error(e.message)
    } finally {
      setLoading(false)
    }
  }, [clearAndClose, clinicId, fetchData, onAdded, setLoading])

  return (
    <>
      <button onClick={open} type="button" className="btn btn-primary">
        <i className="fas fa-plus" />
        &nbsp;Adicionar
      </button>

      <div className={`modal text-left ${active ? 'active' : ''}`}>
        <a type="button" className="modal-overlay" aria-label="Close" onClick={clearAndClose} />
        <div className="modal-container">
          <div className="modal-header">
            <a
              type="button"
              className="btn btn-clear float-right"
              onClick={clearAndClose}
            />
            <div className="modal-title h5">
              Adicionar item clínico
            </div>
          </div>
          <div className="modal-body">
            {
              !item ? (
                <>
                  <div>Escolha o item que deseja adicionar para a clínica</div>
                  <Form.Formik
                    initialValues={{ title_cont: '', description_cont: '' }}
                    onSubmit={(values) => setParams({ ...params, q: { ...params.q, ...values } })}
                  >
                    {
                      () => (
                        <Form.ValidationForm className="my-2">
                          <Flexbox.Columns>
                            <Flexbox.Column>
                              <Form.ValidationField name="title_cont" placeholder="Título do item" className="input-sm" />
                            </Flexbox.Column>
                            <Flexbox.Column>
                              <Form.ValidationField name="description_cont" placeholder="Descrição do item" className="input-sm" />
                            </Flexbox.Column>
                            <Flexbox.Column className="col-auto">
                              <button type="submit" data-tooltip="Filtrar" className="btn btn-sm btn-primary tooltip tooltip-left">
                                <i className="fas fa-filter" />
                              </button>
                            </Flexbox.Column>
                          </Flexbox.Columns>
                        </Form.ValidationForm>
                      )
                    }
                  </Form.Formik>
                  <ListManager loading={loadingItems} count={data?.count || 0} error={error}>
                    {Boolean(data) && (
                      <>
                        <small>
                          <table className="table">
                            <thead>
                              <tr>
                                <th>Item</th>
                                <th width="1" className="text-right">Ações</th>
                              </tr>
                            </thead>
                            <tbody>
                              {
                                data.rows.map((i) => (
                                  <tr key={i.id}>
                                    <td>
                                      <div><b>{i.title}</b></div>
                                      <div>{i.description}</div>
                                    </td>
                                    <td className="text-right">
                                      <a
                                        onClick={() => setItem(i)}
                                        data-tooltip="Adicionar item"
                                        className="btn btn-action btn-sm btn-primary tooltip tooltip-left"
                                      >
                                        <i className="fas fa-check" />
                                      </a>
                                    </td>
                                  </tr>
                                ))
                              }
                            </tbody>
                          </table>
                        </small>
                        <ManualPaginator
                          paginatedList={data}
                          setPage={(page) => setParams({ ...params, page })}
                        />
                      </>
                    )}
                  </ListManager>
                </>
              ) : <FormItem item={item} loading={loading} onCancel={clear} onConfirm={onConfirm} />
            }
          </div>
        </div>
      </div>
    </>
  )
}

Add.propTypes = {
  onAdded: PropTypes.func.isRequired,
  clinicId: PropTypes.string.isRequired,
}

export default Add

/* eslint-disable guard-for-in */
/* eslint-disable no-restricted-syntax */
import useQuery from '@/hooks/useQuery'
import PropTypes from 'prop-types'
import qs from 'qs'
import React, { useCallback, useRef, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import Form from '../Form'
import * as Styles from '../styles'
import { Actions } from './styles'
import Flexbox from '../Flexbox'

function fillValues(hash, values) {
  const filled = { ...hash }
  for (const key of Object.keys(hash)) {
    filled[key] = values[key] || hash[key]
  }

  return filled
}

function QuickFilter({
  initialValues, renderForm, queryAttribute, onClear,
}) {
  const formikRef = useRef()
  const query = useQuery()
  const history = useHistory()
  const location = useLocation()

  const queryValue = query?.[queryAttribute] || {}
  // eslint-disable-next-line no-unused-vars
  const [active, setActive] = useState(false)

  const onSubmit = useCallback((params) => {
    const newQuery = { ...query, [queryAttribute]: params }
    history.replace(`${location.pathname}?${qs.stringify(newQuery)}`)
    setActive(false)
  }, [history, location.pathname, query, queryAttribute])

  const filterLength = Object.keys(queryValue).filter((key) => {
    const field = queryValue[key]
    if (!field) return false
    if (typeof field === 'object') {
      if (!field[0] && !field[1]) return false
    }
    return true
  }).length

  const filtered = filterLength > 0

  function clear() {
    formikRef.current.resetForm()
    const newQuery = { ...query, [queryAttribute]: {} }
    history.replace(`${location.pathname}?${qs.stringify(newQuery)}`)
    setActive(false)
    if (onClear) onClear()
  }

  return (
    <>
      <Form.Formik
        innerRef={formikRef}
        initialValues={fillValues(initialValues, queryValue)}
        onSubmit={onSubmit}
      >
        {
          (options) => (
            <Form.ValidationForm>
              <Flexbox.Columns className="mb-2">
                {renderForm(options)}
                <Actions>
                  <div className="text-right">
                    <Styles.Button type="submit" className="btn-primary btn-sm">
                      <i className="fas fa-filter fa-sm" />
                      &nbsp;Filtrar
                    </Styles.Button>
                    {
                      filtered
                      && (
                        <Styles.Button type="button" onClick={clear} className="btn btn-link btn-sm mr-1">
                          <i className="fas fa-times" />
                          &nbsp;Limpar
                        </Styles.Button>
                      )
                    }
                  </div>
                </Actions>
              </Flexbox.Columns>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </>
  )
}

QuickFilter.propTypes = {
  initialValues: PropTypes.objectOf(PropTypes.any),
  renderForm: PropTypes.func,
  queryAttribute: PropTypes.string,
  onClear: PropTypes.func,
}

QuickFilter.defaultProps = {
  initialValues: {},
  renderForm: () => null,
  queryAttribute: 's',
  onClear: null,
}

export default QuickFilter

import {
  Card, Flexbox, Form, Styles,
} from '@/components'
import usePageTitle from '@/hooks/usePageTitle'
import yup from '@/lib/yupPt'
import session from '@/services/auth/session'
import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { AppContainer, Logo, Overflow } from './styles'

const schema = yup.object().shape({
  login: yup.string().required(),
})
function ResetPassword({ title }) {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)
  const history = useHistory()

  usePageTitle({ title })

  async function submit({ login }) {
    try {
      setLoading(true)
      setError(false)
      await session.hasLocalAuthentication(login)
      history.push({
        pathname: '/reset-password/token',
        state: { login },
      })
    } catch (e) {
      setError(e)
    }
  }

  return (
    <AppContainer>
      <Overflow />
      <fieldset disabled={loading}>
        <Form.Formik
          initialValues={{
            login: '',
          }}
          validationSchema={schema}
          onSubmit={submit}
          validateOnMount
        >
          {
            ({ errors, touched, isValid }) => (
              <Form.ValidationForm>
                <Card.Base style={{ minWidth: 300, maxWidth: 400 }}>
                  <Card.Header>
                    <Flexbox.Columns className="align-center">
                      <Flexbox.Column className="col-auto">
                        <Logo />
                      </Flexbox.Column>
                      <Flexbox.Column>
                        Petis - Recuperar senha
                      </Flexbox.Column>
                    </Flexbox.Columns>
                  </Card.Header>
                  <Card.Body>
                    {
                      error ? (
                        <Styles.Toast className="toast-error">
                          {error?.message}
                        </Styles.Toast>
                      ) : null
                    }

                    <p className="text-center mt-2">
                      Informe o E-mail ou CPF/CNPJ do usuário para
                      <b> enviarmos um e-mail</b> de recuperação
                    </p>

                    <Form.Group className={`${touched?.login && errors?.login ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="login">E-mail ou CPF/CNPJ <span className="text-error">*</span></Form.Label>
                      <Form.ValidationField
                        name="login"
                        autoFocus
                        id="login"
                        autoComplete="off"
                        placeholder="E-mail ou CPF/CNPJ"
                      />
                      {
                        touched?.login && errors?.login
                          ? <Form.Hint>{errors.login}</Form.Hint> : null
                      }
                    </Form.Group>
                  </Card.Body>
                  <Card.Footer>
                    <Flexbox.Columns className="align-center">
                      <Flexbox.Column>
                        <Styles.Link className="btn-link" to="/login">
                          Voltar ao login
                        </Styles.Link>
                      </Flexbox.Column>
                      <Flexbox.Column className="col-auto">
                        <Styles.Button type="submit" className="btn-primary" disabled={!isValid}>
                          <i className="fas fa-envelope" />
                            &nbsp;Enviar e-mail
                        </Styles.Button>
                      </Flexbox.Column>
                    </Flexbox.Columns>
                  </Card.Footer>
                </Card.Base>

              </Form.ValidationForm>
            )
          }
        </Form.Formik>
      </fieldset>
    </AppContainer>
  )
}

export default ResetPassword

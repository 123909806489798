import { useContext } from 'react'
import batchContext from '@/contexts/batchContext'

/**
 * @typedef {Object} BatchReducer
 * @property {Object} state - State of batch
 * @property {Function} dispatch - dispatch
 */

/**
 * @returns {BatchReducer}
 */
 export default function useBatch() {
  return useContext(batchContext)
}

import { useCallback, useState } from 'react'

export default function useModalAction() {
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false)

  const open = useCallback(() => setActive(true), [])

  const close = useCallback(() => setActive(false), [])

  return {
    active,
    open,
    close,
    loading,
    setLoading,
  }
}

import api from './api'

export default {
  list(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/mistreatments', { params })
  },

  create(body) {
    return api.post('/partner-licensings/:partnerLicensingId/mistreatments', body)
  },

  show(id) {
    return api.get(`/partner-licensings/:partnerLicensingId/mistreatments/${id}`)
  },

  startCheck(id, body = {}) {
    return api.post(`/partner-licensings/:partnerLicensingId/mistreatments/${id}/start-check`, body)
  },

  cancel(id, body = {}) {
    return api.post(`/partner-licensings/:partnerLicensingId/mistreatments/${id}/cancel`, body)
  },

  reject(id, body = {}) {
    return api.post(`/partner-licensings/:partnerLicensingId/mistreatments/${id}/reject`, body)
  },

  done(id, body = {}) {
    return api.post(`/partner-licensings/:partnerLicensingId/mistreatments/${id}/done`, body)
  },
}

export const INITIAL_STATE = { adoptionFair: null }

export default function adoptionFairReducer(state, action) {
  switch (action.type) {
    case ('setAdoptionFair'): return { adoptionFair: action.payload }
    default: return INITIAL_STATE
  }
}

export function setAdoptionFair(payload) {
  return { type: 'setAdoptionFair', payload }
}

import React from 'react'
import List from '@/views/app/Animals'
import Show from '@/views/app/Animals/Show'
import { Route, useRouteMatch } from 'react-router-dom'

function BatchesRoutes() {
  const { path } = useRouteMatch()

  return (
    <div>
        <Route path={`${path}`} exact>
          <List title="Petis - Parceiros / Pets" />
        </Route>
        <Route path={`${path}/:id`}>
          <Show title="Petis - Parceiros / Histórico do pet" />
        </Route>
    </div>
  )
}

export default BatchesRoutes

import {
  Card as Base, Body, Header, Footer, Image,
} from './styles'

export default {
  Base,
  Body,
  Footer,
  Header,
  Image,
}

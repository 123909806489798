import React from 'react'
import PropTypes from 'prop-types'
import { LICENSING_TYPE_TRANSLATION } from '@/constants/licensingType'
import mapErrors from './mapErrors'
import { Empty } from './styles'

function ErrorBox({ error, children }) {
  const mappedError = mapErrors[error?.code]
    ? { ...error, ...mapErrors[error?.code] }
    : { icon: 'fas fa-bug fa-3x text-error', title: 'Algo deu errado', ...error }

  return (
    <Empty>
      <div className="empty-icon">
        <i className={mappedError.icon} />
      </div>
      <p className="empty-title h5">{mappedError.title}</p>
      <p className="empty-subtitle">{mappedError.message || error.message}</p>
      {
        Boolean(error?.additionalProperties?.allowedTypes) && (
          (error.additionalProperties.allowedTypes || []).map((type) => (
            <div key={type}>
              - {LICENSING_TYPE_TRANSLATION[type]}
            </div>
          ))
        )
      }
      {children}
    </Empty>
  )
}

ErrorBox.propTypes = {
  error: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default ErrorBox

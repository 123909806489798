import api from './api'

export default {
  show(petId) {
    return api.get(`/partner-licensings/:partnerLicensingId/adoption-requested/${petId}`)
  },
  approve(petId) {
    return api.patch(`/partner-licensings/:partnerLicensingId/adoption-requested/${petId}/approve`)
  },
  reject(petId) {
    return api.patch(`/partner-licensings/:partnerLicensingId/adoption-requested/${petId}/reject`)
  },
  messages(petId, animalFairId) {
    return api.get(`/partner-licensings/:partnerLicensingId/adoption-requested/${petId}/animal-fairs/${animalFairId}/messages`)
  },
  addMessage(petId, animalFairId, body) {
    return api.post(`/partner-licensings/:partnerLicensingId/adoption-requested/${petId}/animal-fairs/${animalFairId}/messages`, body)
  },
  readChat(petId, animalFairId) {
    return api.post(`partner-licensings/:partnerLicensingId/adoption-requested/${petId}/animal-fairs/${animalFairId}/read`)
  },
}

/* eslint-disable react/jsx-props-no-spreading */
import cities from '@/services/api/cities'
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useAlert } from 'react-alert'
import Autocomplete from '../Base'
import Row from './Row'
import Chip from './Chip'

function City({ onSelect, selected, ...props }) {
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const alert = useAlert()

  const search = useCallback((async (name) => {
    try {
      setLoading(true)
      const { data } = await cities.list({ q: { name } })
      setList(data?.rows)
    } catch (e) {
      alert.error('Erro ao recuperar lista de cidades.')
    } finally {
      setLoading(false)
    }
  }), [alert])

  const handleSelect = useCallback((city) => {
    onSelect(city)
    setList([])
  }, [onSelect])

  return (
    <Autocomplete
      loading={loading}
      onSelect={handleSelect}
      selected={selected}
      list={list}
      onSearch={search}
      ItemComponent={Row}
      ChipComponent={
        selected
          ? () => <Chip item={selected} onRemove={() => onSelect(null)} />
          : null
      }
      inputAttrs={{ placeholder: 'Busque a cidade' }}
      {...props}
    />
  )
}

City.propTypes = {
  selected: PropTypes.objectOf(PropTypes.any),
  onSelect: PropTypes.func.isRequired,
}

City.defaultProps = {
  selected: null,
}

export default City

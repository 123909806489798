import { Card, Flexbox } from '@/components'
import PropTypes from 'prop-types'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import TransactionRow from '../TransactionRow'

function TransactionsCard({ transactions }) {
  const { pathname } = useLocation()

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">
              Últimas transações
            </div>
            <cite>5 últimas transações realizadas para essa clínica</cite>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-exchange-alt" />
          </Flexbox.Column>
        </Flexbox.Columns>
        <div className="divider" />
      </Card.Header>
      <Card.Body>
        {
          transactions?.map((transaction) => (
            <TransactionRow key={transaction?.id} transaction={transaction} />
          ))
        }
      </Card.Body>
      <Card.Footer className="text-right">
        <Link to={`${pathname}/transactions`} className="btn btn-sm btn-primary">
          <i className="fas fa-folder-open" />
          &nbsp;Ver todas
        </Link>
      </Card.Footer>
    </Card.Base>
  )
}

TransactionsCard.propTypes = {
  transactions: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default TransactionsCard

import api from './api'

export default {
  list() {
    return api.get('/partner-licensings')
  },
  show(id) {
    return api.get(`/partner-licensings/show/${id}`)
  },
}

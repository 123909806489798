import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import mapStatus from './mapStatus'

function Row({ item }) {
  const mappedStatus = mapStatus(item?.status)

  return (
    <tr>
      <td>
        <i className={mappedStatus?.icon} />
        &nbsp;{mappedStatus?.label}
      </td>
      <td className="text-center">{item?.count}</td>
      <td className="text-right">
        <Link
          className="btn btn-action btn-sm btn-primary"
          to={`/app/batches/status/${item.status}`}
        >
          <i className="fas fa-folder-open" />
        </Link>
      </td>
    </tr>
  )
}

Row.propTypes = {
  item: PropTypes.objectOf(Object).isRequired,
}

export default Row

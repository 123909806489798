const mapping = {
  requested: {
    title: 'Lotes aguardando aprovação',
    subtitle: 'Lotes que as clínicas solicitaram',
    icon: 'fas fa-question',
  },
  rejected: {
    title: 'Lotes rejeitados',
    subtitle: 'Lotes que você rejeitou por algum motivo',
    icon: 'fas fa-thumbs-down',
  },
  approved: {
    title: 'Lotes aprovadas',
    subtitle: 'Lotes que você aprovou',
    icon: 'fas fa-check',
  },
  all: {
    title: 'Todos os lotes',
    icon: 'fas fa-box',
  },
}

export default (status) => mapping[status] || mapping.all

import { PageHeader, Styles } from '@/components'
import envs from '@/constants/envs'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import convertBounds from '@/lib/convertBounds'
import people from '@/services/api/people'
import reports from '@/services/api/reports'
import GoogleMapReact from 'google-map-react'
import React, {
  useCallback, useEffect, useMemo, useState,
} from 'react'
import FilterCastrations from '../components/FilterCastrations'
import { MapContainer } from './styles'

function CastrationsTermalMap({ title }) {
  usePageTitle({ title })
  const [report, setReport] = useState([])
  const [mapOptions, setMapOptions] = useState(null)
  const [partner, setPartner] = useState(null)
  const query = useQuery()

  const positions = report.map(({ latitude, longitude, count }) => ({
    lat: latitude,
    lng: longitude,
    weight: Number(count),
  }))

  const heatmap = {
    positions,
    options: {
      radius: 20,
      opacity: 1,
    },
  }

  const params = useMemo(() => {
    let obj = {}
    if (mapOptions) {
      const { zoom } = mapOptions
      const bounds = convertBounds(mapOptions?.bounds)
      obj = { ...obj, zoom, bounds }
    }
    return { ...query, ...obj }
  }, [mapOptions, query])

  async function fetchPartner() {
    const { data } = await people.partner()
    setPartner(data)
  }

  const fetchHeatmap = useCallback(async () => {
    const { data } = await reports.termalMap(params)
    setReport(data)
  }, [params])

  const center = partner?.location ? {
    lat: partner?.location?.coordinates[1],
    lng: partner?.location?.coordinates[0],
  } : {}

  useEffect(() => {
    fetchHeatmap()
  }, [fetchHeatmap, params])

  useEffect(() => {
    fetchPartner()
  }, [])

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Mapa térmico de castrações"
        subtitle="Mostra as áreas com maior frequência de solicitações"
        icon="fas fa-map"
        rightAction={() => <FilterCastrations />}
      />
      <MapContainer className="bg-red">
        <GoogleMapReact
          center={query?.lat && query?.lng ? { lat: query.lat, lng: query.lng } : center}
          bootstrapURLKeys={{
            key: envs.REACT_APP_GOOGLE_MAPS_API_KEY,
            libraries: ['visualization'],
          }}
          onChange={setMapOptions}
          heatmap={heatmap}
          defaultZoom={query?.zoom ? Number(query.zoom) : 12}
        />
      </MapContainer>
    </Styles.Container>
  )
}

export default CastrationsTermalMap

import styled from 'styled-components'
import logo from '@/assets/img/logo.png'

export const Container = styled.div.attrs({
  className: 'container',
})``

export const BrandLogo = styled.img.attrs({
  src: logo,
})`
  width: 1.8rem;
`

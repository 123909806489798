import { LoadManager, PageHeader, Styles } from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import castrations from '@/services/api/castrations'
import React from 'react'
import Row from './components/Row'
import mapStatus from './components/Row/mapStatus'

function Castrations({ title }) {
  const {
    data, error, loading,
  } = useAxiosRequest(castrations.countStatus)
  const list = data
    ?.map((item) => ({ ...item, ...mapStatus(item.status) }))
    ?.sort((a, b) => a.sequence - b.sequence)

  usePageTitle({ title })

  return (
    <Styles.Container className="grid-xl">
      <PageHeader title="Contagem das castrações" subtitle="Totais de castração por status" icon="fas fa-cut" />
      <LoadManager loading={loading} error={error}>
        <table className="table table-striped">
          <thead>
            <tr>
              <th>Status</th>
              <th width="130px">Quantidade</th>
              <th width="1">Ações</th>
            </tr>
          </thead>
          <tbody>
            {list?.map((item) => <Row key={item.status} item={item} />)}
          </tbody>
        </table>
      </LoadManager>
    </Styles.Container>
  )
}

export default Castrations

import {
  Avatar,
  Card, Flexbox, ListManager, PageHeader, Styles,
} from '@/components'
import { singularStatusMapLabel } from '@/constants/castration'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import documentNumberMask from '@/lib/masks/documentNumber'
import reports from '@/services/api/reports'
import React from 'react'
import FilterCastrations from '../components/FilterCastrations'

function CastrationsByClinic({ title }) {
  usePageTitle({ title })

  const query = useQuery()
  const { data, loading, error } = useAxiosRequest(reports.castrationsByClinic, query)

  const statusList = data?.statusList || []

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Castrações por clínica"
        subtitle="Quantidade de castrações por clínica"
        icon="fas fa-clinic-medical"
        rightAction={() => <FilterCastrations />}
      />
      <ListManager count={data?.rows?.length || 0} loading={loading} error={error}>
        {
          Boolean(data) && (
            <Flexbox.Columns>
              {
                data.rows.map((item) => (
                  <Flexbox.Column key={item.fullName} className="col-6 col-sm-12 my-2">
                    <Card.Base>
                      <Card.Header>
                        <Flexbox.Columns className="align-center col-gapless">
                          {
                            Boolean(item?.fullName) && (
                              <Flexbox.Column className="col-auto">
                                <Avatar
                                  avatarSize="lg"
                                  name={item?.fullName}
                                  photoURL={item?.photoURL}
                                  alt="Imagem de usuário"
                                />
                              </Flexbox.Column>
                            )
                          }
                          <Flexbox.Column className="ml-2">
                            <b>{item?.fullName || 'Castrações sem clínica'}</b>
                            {
                              Boolean(item?.fullName) && (
                                <div>
                                  <small>{documentNumberMask(item?.documentNumber)}</small>
                                </div>
                              )
                            }
                          </Flexbox.Column>
                        </Flexbox.Columns>
                      </Card.Header>
                      <Card.Body>
                        <table className="table table-hover text-small">
                          <thead>
                            <tr>
                              <th>Status</th>
                              <th width="100px" className="text-right">Quantidade</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              statusList.map((status) => (
                                <tr key={status}>
                                  <td>{singularStatusMapLabel[status]}</td>
                                  <td className="text-right">{item[status] || 0}</td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                      </Card.Body>
                    </Card.Base>
                  </Flexbox.Column>
                ))
              }
            </Flexbox.Columns>
          )
        }
      </ListManager>
    </Styles.Container>
  )
}

export default CastrationsByClinic

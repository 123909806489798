import styled from 'styled-components'

export const Container = styled.div`
  position: fixed;
  z-index: 999;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, .8);
`

export const ImageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const CloseContainer = styled.a`
  position: fixed;
  right: 0;
  top: 0;
  border-bottom-left-radius: 50%;
  background-color: rgba(0, 0, 0, .6);
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  :hover {
    text-decoration: none;
    cursor: pointer;
  }
`

import { Formik as FormikBase, Field as FormikField, Form as FormikForm } from 'formik'
import MaskedInput from 'react-text-mask'
import styled from 'styled-components'

export const Group = styled.div.attrs(({ className }) => ({ className: `form-group ${className}` }))``

export const HasIconLeft = styled.div.attrs(({ className }) => ({ className: `has-icon-left ${className}` }))``

export const HasIconRight = styled.div.attrs(({ className }) => ({ className: `has-icon-right ${className}` }))``

export const Icon = styled.i.attrs(({ className }) => ({ className: `form-icon ${className}` }))``

export const Label = styled.label.attrs(({ className }) => ({ className: `form-label ${className}` }))``

export const Input = styled.input.attrs(({ className }) => ({ className: `form-input ${className}` }))``

export const Textarea = styled.textarea.attrs(({ className }) => ({ className: `form-input ${className}` }))``

export const Select = styled.select.attrs(({ className }) => ({ className: `form-select ${className}` }))``

export const Hint = styled.div.attrs(({ className }) => ({ className: `form-input-hint ${className}` }))``

export const Switch = styled.label.attrs(({ className }) => ({ className: `form-switch ${className}` }))``

export const Check = styled.label.attrs(({ className }) => ({ className: `form-checkbox ${className}` }))``

export const InputMask = styled(MaskedInput).attrs(({ className }) => ({
  className: `form-input ${className}`,
}))``

// -- Validation --
export const Formik = styled(FormikBase).attrs(({ onSubmit, ...rest }) => ({
  onSubmit: (values, options) => {
    const trimValues = Object.keys(values).reduce((acc, key) => {
      const value = values[key]
      if (typeof value === 'string') {
        acc[key] = value.trim()
      } else {
        acc[key] = value
      }
      return acc
    }, {})
    onSubmit(trimValues, options)
  },
  ...rest,
}))``

export const ValidationField = styled(FormikField).attrs(({ className, ...rest }) => ({ className: `form-input ${className}`, ...rest }))``

export const ValidationForm = styled(FormikForm)``
// -- End validation --

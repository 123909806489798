import { Styles } from '@/components'
import React from 'react'
import PropTypes from 'prop-types'
import { Link, useLocation } from 'react-router-dom'
import allowedActions from './allowedActions'

function Actions({ licensing }) {
  const { pathname } = useLocation()
  const actions = allowedActions(licensing?.status)

  if (!actions.length) return null

  return (
    <Styles.ActionsContainer>
      {
        actions?.includes('approve') && (
          <Link to={`${pathname}/approve`} className="btn btn-success mx-1">
            <i className="fas fa-check" />
            &nbsp;Aprovar
          </Link>
        )
      }
      {
        actions?.includes('reject') && (
          <Link to={`${pathname}/reject`} className="btn btn-error mx-1">
            <i className="fas fa-ban" />
            &nbsp;Rejeitar
          </Link>
        )
      }
      {
        actions?.includes('revoke') && (
          <Link to={`${pathname}/revoke`} className="btn btn-error mx-1">
            <i className="fas fa-ban" />
            &nbsp;Revogar
          </Link>
        )
      }
    </Styles.ActionsContainer>
  )
}

Actions.propTypes = {
  licensing: PropTypes.objectOf(Object).isRequired,
}

export default Actions

import {
  Card,
  Flexbox, Form, Mask, UserCard,
} from '@/components'
import { cnpj } from '@/lib/validators'
import yup from '@/lib/yupPt'
import clinics from '@/services/api/clinics'
import people from '@/services/api/people'
import useModalAction from '@/views/app/AdoptionFairs/Show/components/Actions/useModalAction'
import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'

const schema = yup.object().shape({
  documentNumber: yup
    .string()
    .required()
    .test('cnpj', 'CNPJ inválido', cnpj),
})
function Add({ onAdded }) {
  const {
    active, close, loading, setLoading, open,
  } = useModalAction()
  const [licensed, setLicensed] = useState(null)

  const clearAndClose = useCallback(() => {
    setLicensed(null)
    close()
  }, [close])

  const onConfirm = useCallback(async () => {
    try {
      setLoading(true)
      const { data } = await clinics.createClinicalCareClinic({ licensed })
      toast.success('Clínica licenciada com sucesso')
      onAdded(data)
      clearAndClose()
    } catch (e) {
      toast.error(e.message)
    } finally {
      setLoading(false)
    }
  }, [clearAndClose, licensed, onAdded, setLoading])

  const onFetchClinic = useCallback(async ({ documentNumber }, options) => {
    try {
      setLoading(true)
      const { data } = await people.findByDocumentNumber(documentNumber)
      options.resetForm()
      setLicensed(data)
    } catch (e) {
      toast.error(e.message)
    } finally {
      setLoading(false)
    }
  }, [setLoading])

  return (
    <>
      <button onClick={open} type="button" className="btn btn-primary">
        <i className="fas fa-plus" />
        &nbsp;Adicionar
      </button>

      <div className={`modal ${active ? 'active' : ''}`}>
        <a type="button" className="modal-overlay" aria-label="Close" onClick={clearAndClose} />
        <div className="modal-container">
          <div className="modal-header">
            <a
              type="button"
              className="btn btn-clear float-right"
              onClick={clearAndClose}
            />
            <div className="modal-title h5">
              Licenciamento de clínica
            </div>
          </div>
          <div className="modal-body">
            {
              !licensed
                ? (
                  <Form.Formik
                    initialValues={{ documentNumber: '' }}
                    validationSchema={schema}
                    validateOnMount
                    onSubmit={onFetchClinic}
                  >
                    {
                      ({
                        touched, errors, setFieldValue, isValid,
                      }) => (
                        <Form.ValidationForm>
                          <Form.Group className={`${touched?.documentNumber && errors?.documentNumber ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="documentNumber">Qual é o CNPJ da clíncia?</Form.Label>
                            <Flexbox.Columns>
                              <Flexbox.Column>
                                <Form.ValidationField name="documentNumber">
                                  {
                                    ({ field }) => (
                                      <Mask.Cnpj
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...field}
                                        className="form-input"
                                        placeholder="CNPJ da clínica"
                                        id="documentNumber"
                                        onAccept={(value) => {
                                          setFieldValue('documentNumber', value)
                                        }}
                                      />
                                    )
                                  }
                                </Form.ValidationField>
                              </Flexbox.Column>
                                <Flexbox.Column className="col-auto">
                                  <button disabled={!isValid || loading} type="submit" className="btn btn-primary btn-action">
                                    <i className="fas fa-search" />
                                  </button>
                                </Flexbox.Column>
                            </Flexbox.Columns>
                            {
                              touched?.documentNumber && errors?.documentNumber
                                ? <Form.Hint className="text-error">{errors.documentNumber}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Form.ValidationForm>
                      )
                    }
                  </Form.Formik>
                ) : (
                  <UserCard
                    title="Detalhes da clínica"
                    user={licensed}
                    renderFooter={() => (
                      <Card.Footer className="text-right">
                        <a className="btn btn-link text-error" onClick={() => setLicensed(null)}>
                          <i className="fas fa-times" />
                          &nbsp;Cancelar
                        </a>
                      </Card.Footer>
                  )}
                  />
                )
            }
          </div>
          <div className="modal-footer">
            {
              Boolean(licensed) && (
                <button
                  className={`btn btn-primary ${loading ? 'loading' : ''}`}
                  disabled={loading}
                  onClick={onConfirm}
                  type="button"
                >
                  <i className="fas fa-check" />
                  &nbsp;Confirmar
                </button>
              )
            }
          </div>
        </div>
      </div>
    </>
  )
}

Add.propTypes = {
  onAdded: PropTypes.func.isRequired,
}

export default Add

import React from 'react'
import List from '@/views/app/PetisUsers'
import Show from '@/views/app/PetisUsers/Show'
import { Route, useRouteMatch } from 'react-router-dom'

function PetisUsersRoutes() {
  const { path } = useRouteMatch()

  return (
    <div>
        <Route path={`${path}`} exact>
          <List title="Petis - Parceiros / Usuários do Petis" />
        </Route>
        <Route path={`${path}/:id`}>
          <Show title="Petis - Parceiros / Mais informações do usuário" />
        </Route>
    </div>
  )
}

export default PetisUsersRoutes

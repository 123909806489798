/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import {
  Flexbox, Form, LoadManager,
} from '@/components'
import { clinicalCareStatus } from '@/constants/clinicalCares'
import useClinicalCare from '@/hooks/useClinicalCare'
import usePageTitle from '@/hooks/usePageTitle'
import yup from '@/lib/yupPt'
import { setClinicalCare } from '@/reducers/clinicalCare'
import clinicalCares from '@/services/api/clinicalCares'
import upload from '@/services/api/upload'
import React, {
 useEffect, useMemo, useRef, useState,
} from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const fileTypes = ['JPG', 'PNG', 'PDF']

function Done({ title }) {
  usePageTitle({ title })
  const [openNext, setOpenNext] = useState(false)
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])
  const { id } = useParams()
  const { state: { clinicalCare }, dispatch } = useClinicalCare()
  const history = useHistory()
  const formRef = useRef()

  function onSelectFiles(params) {
    const added = Object.values(params).filter((file) => !files.find((f) => f.name === file.name))
    setFiles([...files, ...(added)])
  }

  const schema = useMemo(() => {
    if (!openNext) {
      return yup.object().shape({
        description: yup.string().trim(),
      })
    }
    return yup.object().shape({
      description: yup.string().trim(),
      nextOpenData: yup.object().shape({
        scheduledTo: yup.date().required(),
        status: yup.string().required(),
      }),
    })
  }, [openNext])

  useEffect(() => {
    formRef.current?.validateForm()
  }, [schema])

  async function onSubmit(values) {
    try {
      setLoading(true)
      const attachments = []
      for (const file of files) {
        const { data: { url } } = await upload.sendFile(file)
        attachments.push(url)
      }
      const { nextOpenData, ...v } = values
      const body = {
        ...v,
        attachments,
      }
      if (openNext) body.nextOpenData = nextOpenData
      const { data } = await clinicalCares.done(id, body)
      dispatch(setClinicalCare({ ...data, tutor: data?.animal?.tutor }))
      toast.success('Atendimento concluído')
      setLoading(false)
      history.goBack()
    } catch (e) {
      setLoading(false)
      toast.error(e?.message)
    }
  }

  if (!clinicalCare) return null

  return (
    <fieldset disabled={loading}>
      <Form.Formik
        innerRef={formRef}
        initialValues={{
          description: '',
          nextOpenData: {
            scheduledTo: '',
            status: '',
            description: clinicalCare?.description ?? '',
          },
        }}
        validationSchema={schema}
        onSubmit={onSubmit}
        validateOnMount
      >
        {
          ({
            touched, errors, setFieldValue, isValid,
          }) => (
            <Form.ValidationForm>
              <div className="modal active" id="modal-id">
                <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
                <div className="modal-container">
                  <div className="modal-header">
                    <a
                      type="button"
                      className="btn btn-clear float-right"
                      onClick={() => history.goBack()}
                    />
                    <div className="modal-title h5">Concluir atendimento</div>
                  </div>
                  <div className="modal-body">
                    <div className="content">
                      <LoadManager loading={!clinicalCare}>
                        <>
                          <div className="mb-1">Confirma a conclusão para o atendimento de <b className="text-primary">{clinicalCare?.animal?.name}</b>?</div>
                          <div className="text-small mb-1">
                            Esta ação vai gerar um evento de conclusão,
                            isto não será reversível
                          </div>
                          <div>
                            <Form.Label>Você pode adicionar anexos à este evento</Form.Label>
                            <FileUploader
                              handleChange={onSelectFiles}
                              hoverTitle="Solte aqui"
                              multiple
                              types={fileTypes}
                            >
                              {
                                files.length === 0 ? (
                                  <div className="c-hand bg-gray text-center text-dark" style={{ paddingTop: 30, paddingBottom: 30 }}>
                                    <div className="empty-icon">
                                      <i className="fas fa-paperclip fa-lg" />
                                    </div>
                                    <div className="empty-title h5">Selecione os anexos</div>
                                    <div className="empty-subtitle">Clique ou arraste os anexos para adicionar</div>
                                  </div>
                                ) : (
                                  <div className="c-hand bg-gray text-center" style={{ paddingTop: 30, paddingBottom: 30 }}>
                                    <div className="empty-title h5">Anexos adicionados: {files.length}</div>
                                    <div className="empty-subtitle">Clique ou arraste anexos para adicionar mais</div>
                                  </div>
                                )
                              }
                            </FileUploader>
                          </div>

                          <Flexbox.Columns>
                            <Flexbox.Column className="col-12">
                              <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="description">Observações</Form.Label>
                                <Form.ValidationField
                                  name="description"
                                  id="description"
                                >
                                  {
                                    ({ field }) => (
                                      <textarea
                                        name="description"
                                        className="form-input"
                                        rows={2}
                                        placeholder="Você pode adicionar alguma observação..."
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...field}
                                      />
                                    )
                                  }
                                </Form.ValidationField>
                                {
                                  touched?.description && errors?.description
                                    ? <Form.Hint className="text-error">{errors.description}</Form.Hint> : null
                                }
                              </Form.Group>
                            </Flexbox.Column>
                          </Flexbox.Columns>
                          <div className="mt-2">
                            <div className="divider" />
                          </div>
                          <Flexbox.Columns>
                            <Flexbox.Column className="col-6 col-sm-12 mt-2 pt-2">
                              <Form.Group>
                                <Form.Switch className="form-switch">
                                  <input type="checkbox" value={openNext} onChange={() => setOpenNext(!openNext)} />
                                  <i className="form-icon" />
                                  &nbsp;Criar atendimento de retorno
                                </Form.Switch>

                              </Form.Group>
                            </Flexbox.Column>
                          </Flexbox.Columns>
                          {
                            openNext && (
                              <>
                                <Flexbox.Columns>
                                  <Flexbox.Column className="col-4 col-sm-12">
                                    <Form.Group>
                                      <Form.Label htmlFor="nextOpenData.scheduledTo">Agendar para</Form.Label>
                                      <Form.ValidationField
                                        name="nextOpenData.scheduledTo"
                                        id="nextOpenData.scheduledTo"
                                      >
                                        {
                                          ({ field }) => (
                                            <Form.DatePickerField
                                              name="nextOpenData.scheduledTo"
                                              dateFormat="dd/MM/yyyy HH:mm"
                                              showTimeSelect
                                              minDate={new Date()}
                                              className="form-input"
                                              id="nextOpenData.scheduledTo"
                                              placeholderText="Agendar para"
                                              selected={field.value || null}
                                              {...field}
                                            />
                                          )
                                        }

                                      </Form.ValidationField>
                                    </Form.Group>
                                  </Flexbox.Column>
                                  <Flexbox.Column className="col-8 col-sm-12">
                                    <Form.Group className={`${touched?.nextOpenData?.status && errors?.nextOpenData?.status ? 'has-error' : ''}`}>
                                      <Form.Label htmlFor="nextOpenData.status">Status <span className="text-error">*</span></Form.Label>
                                      <Form.ValidationField name="nextOpenData.status">
                                        {
                                          ({ field }) => (
                                            <Form.Select
                                              {...field}
                                              onChange={({ target: { value } }) => setFieldValue('nextOpenData.status', value)}
                                              id="nextOpenData.status"
                                            >
                                              <option value={undefined}>Escolha o status</option>
                                              <option value="screening">{clinicalCareStatus.screening}</option>
                                              <option value="forwarded">{clinicalCareStatus.forwarded}</option>
                                            </Form.Select>
                                          )
                                        }
                                      </Form.ValidationField>
                                      {
                                        touched?.nextOpenData?.status
                                          && errors?.nextOpenData?.status
                                          ? <Form.Hint className="text-error">{errors?.nextOpenData?.status}</Form.Hint> : null
                                      }
                                    </Form.Group>
                                  </Flexbox.Column>
                                  <Flexbox.Column className="col-12">
                                    <Form.Group className={`${touched?.nextOpenData?.description && errors?.nextOpenData?.description ? 'has-error' : ''}`}>
                                      <Form.Label htmlFor="nextOpenData.description">Informe os sintomas, situação do pet e qualquer informação pertinente <span className="text-error">*</span></Form.Label>
                                      <Form.ValidationField
                                        name="nextOpenData.description"
                                        id="nextOpenData.description"
                                      >
                                        {
                                          ({ field }) => (
                                            <textarea
                                              name="nextOpenData.description"
                                              className="form-input"
                                              rows={5}
                                              placeholder={
                                                `Exemplo: O pet foi resgatado, está apresentando os seguintes sintomas:
- Febre
- Apatia
- Falta de apetite`
                                              }
                                              {...field}
                                            />
                                          )
                                        }
                                      </Form.ValidationField>
                                      {
                                        touched?.nextOpenData?.description
                                          && errors?.nextOpenData?.description
                                          ? <Form.Hint className="text-error">{errors.nextOpenData?.description}</Form.Hint> : null
                                      }
                                    </Form.Group>
                                  </Flexbox.Column>
                                </Flexbox.Columns>
                              </>
                            )
                          }
                        </>
                      </LoadManager>
                    </div>
                  </div>
                  <div className="modal-footer" style={{ paddingLeft: 0, paddingRight: 0 }}>
                    <a
                      className="btn mr-2"
                      disabled={loading}
                      onClick={() => history.goBack()}
                    >
                      <i className="fas fa-chevron-left" />
                      &nbsp;Voltar
                    </a>
                    <button
                      className={`btn btn-success ${loading ? 'loading' : ''}`}
                      disabled={loading || !isValid}
                      type="submit"
                    >
                      <i className="fas fa-check" />
                      &nbsp;Concluir atendimento
                    </button>
                  </div>
                </div>
              </div>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </fieldset>
  )
}

export default Done

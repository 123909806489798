import { Card, Flexbox, Styles } from '@/components'
import useAuth from '@/hooks/useAuth'
import usePageTitle from '@/hooks/usePageTitle'
import partnerLicensing from '@/services/api/partnerLicensing'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { AppContainer, Logo, Overflow } from './styles'

function SelectLicensing({ title }) {
  const [loading, setLoading] = useState(false)
  const [list, setList] = useState([])
  const auth = useAuth()
  const [selectedId, setSelectedId] = useState(auth.licensingId || null)

  const history = useHistory()
  const location = useLocation()
  const from = location.state?.from || '/app'

  usePageTitle({ title })

  async function fetchList() {
    try {
      setLoading(true)
      const { data } = await partnerLicensing.list()
      setList(data)
    } finally {
      setLoading(false)
    }
  }

  function submit() {
    auth.setLicensingId(selectedId)
    history.replace({ pathname: from })
  }

  useEffect(() => {
    fetchList()
  }, [])

  return (
    <AppContainer>
      <Overflow />
      <Card.Base style={{ minWidth: 350, height: 'auto' }}>
        <Card.Header>
          <Flexbox.Columns className="align-center">
            <Flexbox.Column className="col-auto">
              <Logo />
            </Flexbox.Column>
            <Flexbox.Column>
              Petis - Escolha o fornecedor
            </Flexbox.Column>
          </Flexbox.Columns>
        </Card.Header>
        <Card.Body style={{ maxHeight: 400, overflowY: 'auto' }}>
          {
            loading
              ? <Styles.Loading className="loading-lg" />
              : (
                <>
                  <small className="my-2">Escolha qual dos fornecedores deseja acessar</small>
                  <div className="divider" />
                  <div className="form-group">
                    {
                      list.map((licensing) => (
                        <label key={licensing?.id} htmlFor={`input-${licensing.id}`} className="form-radio">
                          <input
                            id={`input-${licensing.id}`}
                            type="radio"
                            name="licensing"
                            checked={selectedId === licensing?.id}
                            onChange={() => setSelectedId(licensing?.id)}
                          />
                          <i className="form-icon" />
                          {licensing?.licensed?.fullName}
                        </label>
                      ))
                    }
                  </div>
                  <div className="divider" />
                </>
              )
          }
        </Card.Body>
        {
          Boolean(!loading && list.length > 0)
          && (
            <Card.Footer className="text-right">
              <Styles.Button onClick={submit} disabled={!selectedId} className="btn-primary">
                Selecionar
              </Styles.Button>
            </Card.Footer>
          )
        }
      </Card.Base>
    </AppContainer>
  )
}

export default SelectLicensing

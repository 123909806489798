import {
  Flexbox, LoadManager, PageHeader, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import manualAdoptions from '@/services/api/manualAdoptions'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { confirmAlert } from 'react-confirm-alert'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import AnimalCard from './AnimalCard'
import TutorCard from './TutorCard'
import DetailsCard from './DetailsCard'

export default function Show({ title }) {
  const { id } = useParams()
  usePageTitle({ title })
  const [removing, setRemoving] = useState(false)
  const history = useHistory()

  const { data, loading, error } = useAxiosRequest(manualAdoptions.show, id)

  async function removeAdoption() {
    try {
      setRemoving(true)
      await manualAdoptions.destroy(id)
      toast.success('Adoção removida com sucesso')
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
      setRemoving(false)
    }
  }

  function handleRemove() {
    confirmAlert({
      title: 'Excluir adoção',
      message: 'Você está certo que deseja excluir esta adoção?',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim, excluir',
          className: 'bg-error',
          onClick: removeAdoption,
        },
      ],
    })
  }

  return (
    <Styles.Container className="grid-xl">
      <PageHeader title={`Adoção manual #${id}`} icon="fas fa-dog" subtitle="Você está vendo uma adoção manual" />
      <LoadManager loading={loading} error={error}>
        {
          Boolean(data) && (
            <div>
              <Styles.ActionsContainer>
                <a onClick={handleRemove} disabled={removing} className={`btn btn-error btn-sm ${removing && 'loading'}`}>
                  <i className="fas fa-trash" />
                  &nbsp;Excluir adoção
                </a>
              </Styles.ActionsContainer>
              <Flexbox.Columns>
                <Flexbox.Column className="col-6 col-sm-12 my-2">
                  <AnimalCard data={data} />
                </Flexbox.Column>
                <Flexbox.Column className="col-6 col-sm-12 my-2">
                  <TutorCard data={data} />
                </Flexbox.Column>
                <Flexbox.Column className="col-12 my-2">
                  <DetailsCard data={data} />
                </Flexbox.Column>
              </Flexbox.Columns>
            </div>
          )
        }
      </LoadManager>
    </Styles.Container>
  )
}

import React, { useReducer } from 'react'
import List from '@/views/app/ClinicalCares'
import Form from '@/views/app/ClinicalCares/Form'
import { Route, useRouteMatch } from 'react-router-dom'
import clinicalCareContext from '@/contexts/clinicalCareContext'
import clinicalCareReducer, { INITIAL_STATE } from '@/reducers/clinicalCare'
import Show from '@/views/app/ClinicalCares/Show'
import Death from '@/views/app/ClinicalCares/Show/Death'
import Cancel from '@/views/app/ClinicalCares/Show/Cancel'
import EventDetails from '@/views/app/ClinicalCares/Show/EventDetails'
import Approve from '@/views/app/ClinicalCares/Show/Approve'
import Pullout from '@/views/app/ClinicalCares/Show/Pullout'
import Done from '@/views/app/ClinicalCares/Show/Done'
import Clinics from '@/views/app/ClinicalCares/Clinics'
import Items from '@/views/app/ClinicalCares/Clinics/Items'
import Release from '@/views/app/ClinicalCares/Show/Release'
import ChangeClinic from '@/views/app/ClinicalCares/Show/ChangeClinic'

function ClinicalCaresRoutes() {
  const { path } = useRouteMatch()
  const [state, dispatch] = useReducer(clinicalCareReducer, INITIAL_STATE)

  return (
    <div>
      <Route path={`${path}/list`} exact>
        <List title="Petis - Parceiros / Atendimentos clínicos" />
      </Route>
      <Route path={`${path}/clinics`} exact>
        <Clinics title="Petis - Parceiros / Atendimentos clínicos / Clínicas" />
      </Route>
      <Route path={`${path}/clinics/:id/items`} exact>
        <Items title="Petis - Parceiros / Atendimentos clínicos / Clínicas / Gerenciar itens clínicos" />
      </Route>
      <Route path={`${path}/form/add`}>
        <Form title="Petis - Parceiros / Atendimentos clínicos / Novo" />
      </Route>
      <clinicalCareContext.Provider value={{ state, dispatch }}>
        <Route path={`${path}/show/:id`}>
          <Show title="Petis - Parceiros / Atendimentos clínicos / Atendimento clínico" />
        </Route>
        <Route path={`${path}/show/:id/approve`}>
          <Approve title="Petis - Parceiros / Atendimentos clínicos / Atendimento clínico / Aprovar" />
        </Route>
        <Route path={`${path}/show/:id/change-clinic`}>
          <ChangeClinic title="Petis - Parceiros / Atendimentos clínicos / Atendimento clínico / Trocar clínica" />
        </Route>
        <Route path={`${path}/show/:id/pullout`}>
          <Pullout title="Petis - Parceiros / Atendimentos clínicos / Atendimento clínico / Retirar" />
        </Route>
        <Route path={`${path}/show/:id/release`}>
          <Release title="Petis - Parceiros / Atendimentos clínicos / Atendimento clínico / Alta médica" />
        </Route>
        <Route path={`${path}/show/:id/done`}>
          <Done title="Petis - Parceiros / Atendimentos clínicos / Atendimento clínico / Concluir" />
        </Route>
        <Route path={`${path}/show/:id/cancel`}>
          <Cancel title="Petis - Parceiros / Atendimentos clínicos / Atendimento clínico / Cancelar" />
        </Route>
        <Route path={`${path}/show/:id/death`}>
          <Death title="Petis - Parceiros / Atendimentos clínicos / Atendimento clínico / Falecimento" />
        </Route>
        <Route path={`${path}/show/:id/events/:eventIndex`}>
          <EventDetails title="Petis - Parceiros / Atendimentos clínicos / Atendimento clínico / Detalhes do evento" />
        </Route>
      </clinicalCareContext.Provider>
    </div>
  )
}

export default ClinicalCaresRoutes

import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Flexbox } from '@/components'
import dateMask from '@/lib/masks/date'

function Revoked({ license }) {
  return (
    <>
      <Flexbox.Columns className="align-center col-gapless pb-2">
        <Flexbox.Column className="col-auto">
          <div className="text-small">Licença revogada em</div>
          {
            license?.revokedAt ? (
              <span>
                <span className="text-bold">
                  {dateMask(license?.revokedAt, { dateStyle: 'full' })}&nbsp;
                </span>
                <small className="label label-sm label-rounded">
                  <i className="far fa-clock" />&nbsp;
                  {new Date(license?.revokedAt).toLocaleTimeString()}
                </small>
              </span>
            ) : (
              <span className="text-gray text-small text-italic">Não há registro</span>
            )
          }
        </Flexbox.Column>
      </Flexbox.Columns>
      <Flexbox.Columns className="align-center col-gapless pb-2">
        <Flexbox.Column className="col-auto">
          <div className="text-small">Licença revogada por</div>
          {
            license?.revokedBy ? (
              <>
                <Avatar
                  avatarSize="sm"
                  name={license?.revokedBy?.fullName}
                  photoURL={license?.revokedBy?.photoURL}
                  alt="Imagem de usuário"
                />
                <span className="text-bold pl-1">{license?.revokedBy?.fullName}</span>
              </>
            ) : (
              <span className="text-gray text-small text-italic">Não há registro</span>
            )
          }
        </Flexbox.Column>
      </Flexbox.Columns>
      <Flexbox.Columns className="align-center col-gapless pb-2">
        <Flexbox.Column className="col-auto">
          <div className="text-small">Motivo da revogação</div>
          {
            license?.revokedReason ? (
              <span className="text-error text-small text-italic">
                {license?.revokedReason}
              </span>
            ) : (
              <span className="text-gray text-small text-italic">Não há registro</span>
            )
          }
        </Flexbox.Column>
      </Flexbox.Columns>
      <div className="divider" />
    </>
  )
}

Revoked.propTypes = {
  license: PropTypes.objectOf(Object).isRequired,
}

export default Revoked

import {
  Avatar, Flexbox, Form, ListManager, PageHeader, Paginator, QuickFilter, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import reports from '@/services/api/reports'
import React from 'react'
import { singularStatusMapLabel } from '@/constants/castration'
import { Link } from 'react-router-dom'
import FilterCastrations from '../components/FilterCastrations'
import mapStatus from '../../Castrations/components/Row/mapStatus'

function CastrationsClinicTime({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const { data, loading, error } = useAxiosRequest(reports.castrationsByUser, query)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Castrações por usuário"
        subtitle="Quantidade de castrações por usuários do seu município"
        icon="fas fa-cut"
        rightAction={() => <FilterCastrations />}
      />
      <QuickFilter
        initialValues={{
          'tutor.fullName_cont': '',
          status_eq: '',
        }}
        title="Filtrar"
        renderForm={() => (
          <>
            <Flexbox.Column className="col-3 mt-2">
              <Form.Group>
                <Form.Label className="label-sm pt-2" htmlFor="tutorFullName">Nome do usuário</Form.Label>
                <Form.ValidationField
                  name="['tutor.fullName_cont']"
                  className="input-sm"
                  id="tutorFullName"
                  autoComplete="none"
                  placeholder="Fulano da Silva"
                />
              </Form.Group>
            </Flexbox.Column>
            <Flexbox.Column className="col-3 mt-2">
              <Form.Group>
                <Form.Label className="label-sm pt-2" htmlFor="status_eq">Status da castração</Form.Label>
                <Form.ValidationField name="status_eq">
                  {
                    ({ field }) => (
                      <Form.Select
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...field}
                        id="status_eq"
                        className="select-sm"
                        placeholder="Status da castração"
                      >
                        <option value="">Todos</option>
                        {
                          Object.keys(singularStatusMapLabel).map((status) => (
                            <option key={status} value={status}>
                              {singularStatusMapLabel[status]}
                            </option>
                          ))
                        }
                      </Form.Select>
                    )
                  }
                </Form.ValidationField>
              </Form.Group>
            </Flexbox.Column>
          </>
        )}
      />
      <ListManager count={data?.count || 0} loading={loading} error={error}>
        {
          Boolean(data) && (
            <>
              <div className="p-2 bg-gray s-rounded">
                <div className="text-small">
                  <span>Quantidade total de usuários do município: </span>
                  <b>{data?.totalUsers}</b>
                </div>
                <div className="text-small">
                  <span>Quantidade de usuários do município que
                    já solicitaram castração pelo menos uma vez:&nbsp;
                  </span>
                  <b>{data?.count}</b>
                </div>
              </div>
              <table className="table table-hover text-small">
                <thead>
                  <tr>
                    <th>Usuário</th>
                    {
                      data.statusList.map((status) => (
                        <th key={status} className="tooltip tooltip-top" data-tooltip={`${mapStatus(status).label}`}>
                          <i className={`s-rounded ${mapStatus(status).icon}`} />
                        </th>
                      ))
                    }
                    <th className="text-right" width="1">
                      Total
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.rows.map((item) => (
                      <tr key={item?.tutorId}>
                        <td>
                          <Link to={`/app/petis-users/${item?.tutorId}`}>
                            <Avatar
                              avatarSize="sm"
                              className="mr-1"
                              name={item?.tutorFullName}
                              photoURL={item?.tutorAvatar}
                              alt="Imagem de usuário"
                            />
                            {item?.tutorFullName}
                          </Link>

                        </td>
                        {
                          data.statusList.map((status) => (
                            <td key={status}>{item[status] || 0}</td>
                          ))
                        }
                        <td>{item?.total}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              {
                Boolean(data) && (
                  <div className="float-right my-2">
                    <Paginator paginatedList={data} />
                  </div>
                )
              }
            </>
          )
        }
      </ListManager>
    </Styles.Container>
  )
}

export default CastrationsClinicTime

import { primaryColor } from '@/styles/variables'
import styled from 'styled-components'

export const Badge = styled.div`
  background-color: ${primaryColor};
  color: #fff;
  position: absolute;
  right: 0;
  border-radius: 5px;
  padding: 1px 6px;
  font-size: .5rem;
  font-weight: bold;
  top: 0;
  transform: translate(95%, -50%);
`

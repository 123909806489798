import React from 'react'
import yup from '@/lib/yupPt'
import PropTypes from 'prop-types'
import { Flexbox, Form } from '@/components'
import CurrencyFormat from 'react-currency-format'

const schema = yup.object().shape({
  price: yup.number().min(0).required(),
  balance: yup.number().min(1).integer().required(),
})
function FormItem({
  loading, onConfirm, onCancel, item,
}) {
  return (
    <>
      <div className="bg-gray p-2 s-rounded">
        <Flexbox.Columns className="align-center">
          <Flexbox.Column className="col-auto">
            <i className="fas fa-star-of-life fa-2x" />
          </Flexbox.Column>
          <Flexbox.Column>
            <div><b>{item.title}</b></div>
            <div>{item.description}</div>
          </Flexbox.Column>
        </Flexbox.Columns>
      </div>
      <Form.Formik
        initialValues={{ price: 0, balance: 0 }}
        validationSchema={schema}
        validateOnMount
        onSubmit={(values) => onConfirm({ ...values, clinicalCareItemProviderId: item.id })}
      >

        {
          ({
            touched, errors, setFieldValue, isValid,
          }) => (
            <Form.ValidationForm>
              <Flexbox.Columns>
                <Flexbox.Column className="col-6">
                  <Form.Group className={`${touched?.price && errors?.price ? 'has-error' : ''}`}>
                    <Form.Label htmlFor="price">Preço unitário</Form.Label>
                    <Form.ValidationField
                      name="price"
                      id="price"
                    >
                      {
                        ({ field }) => (
                          <CurrencyFormat
                            className="form-input"
                            thousandSeparator="."
                            decimalSeparator=","
                            prefix="R$ "
                            placeholder="R$ 0,00"
                            value={field.value ? field.value / 100 : ''}
                            fixedDecimalScale
                            decimalScale={2}
                            allowNegative={false}
                            onValueChange={
                              ({ value }) => setFieldValue('price', Number(value) * 100)
                            }
                          />
                        )
                      }
                    </Form.ValidationField>
                    {
                      touched?.price && errors?.price
                        ? <Form.Hint className="text-error">{errors.price}</Form.Hint> : null
                    }
                  </Form.Group>
                </Flexbox.Column>
                <Flexbox.Column className="col-6">
                  <Form.Group className={`${touched?.balance && errors?.balance ? 'has-error' : ''}`}>
                    <Form.Label htmlFor="balance">Quantidade <span className="text-error">*</span></Form.Label>
                    <Form.ValidationField
                      type="number"
                      placeholder="Quantidade disponível"
                      name="balance"
                      id="balance"
                    />
                    {
                      touched?.balance && errors?.balance
                        ? <Form.Hint className="text-error">{errors.balance}</Form.Hint> : null
                    }
                  </Form.Group>
                </Flexbox.Column>
              </Flexbox.Columns>
              <div className="text-right my-2">
                <a onClick={onCancel} className="btn btn-error mr-2">
                  <i className="fas fa-times" />
                  &nbsp;Cancelar
                </a>
                <button type="submit" disabled={loading || !isValid} className={`btn btn-primary ${loading ? 'loading' : ''}`}>
                  <i className="fas fa-check" />
                  &nbsp;Salvar
                </button>
              </div>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </>
  )
}

FormItem.propTypes = {
  loading: PropTypes.bool.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  item: PropTypes.objectOf(Object).isRequired,
}

export default FormItem

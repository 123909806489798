import React from 'react'
import usePageTitle from '@/hooks/usePageTitle'
import {
  Link, useHistory, useParams,
} from 'react-router-dom'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import {
  Paginator, Avatar, Flexbox, Form, QuickFilter, ListManager,
} from '@/components'
import animalFairs from '@/services/api/animalFairs'
import dateMask from '@/lib/masks/date'
import useQuery from '@/hooks/useQuery'

function Chats({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const { petId } = useParams()
  const history = useHistory()
  const {
    data, error, loading,
  } = useAxiosRequest(animalFairs.chats, petId, query)

  return (
    <div className="modal active">
      <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
      <div className="modal-container">
        <div className="modal-header">
          <a
            type="button"
            className="btn btn-clear float-right"
            onClick={() => history.goBack()}
          />
          <div className="modal-title h5">
            Chats do pet:&nbsp;
            <span className="text-primary">
              {data?.resource?.animal?.name}
            </span>
          </div>
          <div className="mt-2">
            <QuickFilter
              initialValues={{ 'people.fullName_cont': '' }}
              title="Filtrar castrações"
              renderForm={() => (
                <Flexbox.Column className="pt-2">
                  <Form.Group>
                    <Form.Label className="label-sm pt-2" htmlFor="people.fullName_cont">Pesquisa</Form.Label>
                    <Form.ValidationField
                      name="['people.fullName_cont']"
                      className="input-sm"
                      placeholder="Busque pelo nome do participante do chat"
                      id="['people.fullName_cont']"
                      autoComplete="none"
                    />
                  </Form.Group>
                </Flexbox.Column>
              )}
            />
            <ListManager loading={loading} count={data?.count || 0} error={error}>
              {
                Boolean(data) && (
                  <div>
                    <small>
                      <table className="table text-small table-striped table-hover">
                        <thead>
                          <tr>
                            <th>Última mensagem</th>
                            <th>Participantes</th>
                            <th width={1} className="text-right">Ações</th>
                          </tr>
                        </thead>
                        <tbody>
                          {data.rows.map((chat) => (
                            <tr key={chat?.id}>
                              <td>
                                <Flexbox.Columns className="align-center col-gapless">
                                  <Flexbox.Column className="col-auto">
                                    <Avatar
                                      className="mr-1"
                                      name={chat?.lastMessage?.author?.fullName}
                                      photoURL={chat?.lastMessage?.author?.photoURL}
                                      alt="Imagem do autor"
                                    />
                                  </Flexbox.Column>
                                  <Flexbox.Column className="ml-1 text-bold">
                                    {chat?.lastMessage?.author?.fullName}
                                    <span className={`${chat?.lastMessage?.readAt === null
                                      && chat?.lastMessage?.authorId !== data?.partner?.id
                                      ? 'badge ml-2' : ''}`}
                                    />
                                    <div>
                                      <small>
                                        {chat?.lastMessage?.authorId === data?.partner?.id
                                          || chat?.lastMessage?.authorId === data?.person?.id
                                          ? 'Você: '
                                          : null}
                                        {chat?.lastMessage?.description?.length > 50
                                          ? `${chat?.lastMessage?.description.substring(0, 50)}...`
                                          : chat?.lastMessage?.description}
                                        {
                                          chat?.lastMessage?.images?.length
                                            ? <><i className="fas fa-image ml-1" />&nbsp;Imagem</>
                                            : null
                                        }
                                      </small>
                                    </div>
                                    <small>
                                      {dateMask(chat?.lastMessage?.createdAt)}
                                    </small>
                                  </Flexbox.Column>
                                </Flexbox.Columns>
                              </td>
                              <td>
                                <Flexbox.Columns className="align-center col-gapless">
                                  <Flexbox.Column>
                                    <div>
                                      {
                                        chat.people.map((person) => (
                                          <div key={person.id}>
                                            <Link to={`/app/petis-users/${person?.id}`} className="text-dark">
                                              <Avatar
                                                avatarSize="xs"
                                                className="mr-1"
                                                name={person?.fullName}
                                                photoURL={person?.photoURL}
                                                alt="Imagem do autor"
                                              />
                                              {person?.fullName}
                                            </Link>
                                          </div>
                                        ))
                                      }
                                    </div>
                                  </Flexbox.Column>
                                </Flexbox.Columns>
                              </td>
                              <td>
                                <div className="d-flex align-center" style={{ justifyContent: 'flex-end' }}>
                                  <Link
                                    to={`chats/${chat.id}/messages`}
                                    className="btn btn-action btn-sm btn-primary"
                                  >
                                    <i className="fas fa-eye" />
                                  </Link>
                                </div>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </small>
                    <Paginator paginatedList={data} />
                  </div>
                )
              }
            </ListManager>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Chats

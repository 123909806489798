import {
  Card, Flexbox, LicensingDocumentsCard, LoadManager, PageHeader, Styles, UserCard,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import useLicensing from '@/hooks/useLicensing'
import usePageTitle from '@/hooks/usePageTitle'
import { LICENSING } from '@/lib/enums'
import { setLicensing } from '@/reducers/licensing'
import protectors from '@/services/api/protectors'
import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import formatAddress from '@/lib/formatters/address'
import Actions from './Actions'
import Infos from './Infos'

function Show({ title }) {
  usePageTitle({ title })
  const { id } = useParams()
  const { state: { licensing }, dispatch } = useLicensing()
  const { data, error, loading } = useAxiosRequest(protectors.show, id)

  useEffect(() => {
    dispatch(setLicensing(data))
  }, [data, dispatch])

  return (
    <Styles.Container className="grid-xl">
      <PageHeader icon="fas fa-hand-holding-heart" title={`Licença #${id}`} />
      <LoadManager loading={loading} error={error}>
        {
          Boolean(licensing) && (
            <>
              <Actions licensing={licensing} />
              <div className="mb-2 py-2">
                <div>
                  Tipo de licença: <b>{LICENSING.type.t(licensing?.type)}</b>
                </div>
                <div>
                  Situação: <b>{LICENSING.status.t(licensing?.status)}</b>
                </div>
              </div>
              <UserCard
                title="Pessoa"
                user={licensing?.licensed}
                renderFooter={() => (
                  <Card.Footer>
                    <div className="divider" />
                    {
                      // eslint-disable-next-line no-nested-ternary
                      licensing?.licensed?.address
                        ? formatAddress(licensing?.licensed?.address)
                        : (
                          licensing?.licensed?.city
                            ? `${licensing?.licensed?.city?.name} - ${licensing?.licensed?.city?.uf}`
                            : 'Não informado'
                        )
                    }
                  </Card.Footer>
                )}
              />
              <Flexbox.Columns className="mt-2">
                <Flexbox.Column className="col-6 col-sm-12 my-2" />
              </Flexbox.Columns>
              {
                licensing?.documents?.length > 0 && (
                  <LicensingDocumentsCard documents={licensing.documents} />
                )
              }
              <div className="divider" />
              <h1 className="h5 mb-2">Informações sobre a licença</h1>
              <Flexbox.Columns>
                <Flexbox.Column className="col-9 col-sm-12">
                  <Infos license={licensing} />
                </Flexbox.Column>
              </Flexbox.Columns>
            </>
          )
        }
      </LoadManager>
    </Styles.Container>
  )
}

export default Show

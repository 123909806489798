import { Avatar, Card, Flexbox } from '@/components'
import dateMask from '@/lib/masks/date'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

function BatchesCard({ batches }) {
  const requesteds = batches.find(({ status }) => status === 'requested')

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Lotes aguardando aprovação</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-box" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        {
          requesteds.rows?.length ? (
            <table className="table text-small">
              <tbody>
                {
                  requesteds.rows.map((batch) => (
                    <tr key={batch?.id}>
                      <td width="1">
                        <Avatar
                          className="mr-1"
                          name={batch?.person?.fullName}
                          photoURL={batch?.person?.photoURL}
                          alt="Imagem de usuário"
                        />
                      </td>
                      <td>
                        {batch?.person.fullName}
                        <div>
                          <small><small>{dateMask(batch?.createdAt)}</small></small>
                        </div>
                      </td>
                      <td width="1">
                        <Link
                          to={`/app/batches/status/${batch?.status}/${batch?.id}`}
                          className="btn btn-action btn-sm btn-primary"
                        >
                          <i className="fas fa-eye" />
                        </Link>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          ) : (
            <div className="empty">
              <div className="empty-icon">
                <i className="fas fa-3x fa-check" />
              </div>
              <p className="empty-title h5">Tudo certo</p>
              <p className="empty-subtitle">Nenhum lote aguardando aprovação</p>
            </div>
          )
        }
      </Card.Body>
    </Card.Base>
  )
}

BatchesCard.propTypes = {
  batches: PropTypes.arrayOf(Object).isRequired,
}

export default BatchesCard

export const clinicalCareStatus = {
  screening: 'Triagem',
  forwarded: 'Encaminhado',
  hospitalized: 'Internado',
  medicalRelease: 'Alta médica',
  death: 'Falecido',
  pullout: 'Em retirada',
  caught: 'Saída',
  done: 'Concluído',
  canceled: 'Cancelado',
}

export const clinicalCareStatusClasses = {
  screening: 'text-bold',
  forwarded: 'text-bold text-primary',
  hospitalized: 'text-bold text-primary',
  medicalRelease: 'text-bold text-success',
  death: 'text-bold text-dark',
  pullout: 'text-bold text-success',
  done: 'text-bold text-success',
  caught: 'text-bold text-success',
  canceled: 'text-bold text-error',
}

export const LICENSING_TYPE = {
  CASTRATION_PROVIDER: 'castrationProvider',
  CHIP_PROVIDER: 'chipProvider',
  PROTECTOR: 'protector',
  NGO: 'ngo',
}

export const LICENSING_STATUS_MAP = {
  requested: 'Solicitado',
  approved: 'Aprovado',
  rejected: 'Rejeitado',
  revoked: 'Revogado',
}

export const licensingStatusColors = {
  requested: 'text-warning',
  approved: 'text-success',
  rejected: 'text-error',
  revoked: 'text-gray',
}

export const licensingStatusIcons = {
  requested: 'fas fa-clock',
  approved: 'fas fa-check',
  rejected: 'fas fa-times',
  revoked: 'fas fa-ban',
}

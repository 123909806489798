import {
  Avatar, Filter, Form, ListManager, Mask,
  PageHeader, Paginator, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import animals from '@/services/api/animals'
import React from 'react'
import { Link } from 'react-router-dom'
import FindChip from './components/FindChip'
import AnimalsQuickFilter from './components/QuickFilter'

function Animals({ title }) {
  usePageTitle({ title })
  const query = useQuery()

  const {
    loading, data, error,
  } = useAxiosRequest(animals.list, query)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Animais do município"
        icon="fas fa-paw"
        subtitle="Lista com os animais cujos tutores são do seu município"
        rightAction={() => (
          <>
            <FindChip />
            <Filter
              initialValues={{
                animalName: '', animalUid: '', onlyChip: false, onlyCity: false, tutorName: '', tutorEmail: '', tutorPhoneNumber: '', tutorDocumentNumber: '',
              }}
              title="Filtrar animais"
              renderForm={({ setFieldValue }) => (
                <>
                  <div className="divider text-center" data-content="Pet" />
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="animalName">Nome do animal</Form.Label>
                    <Form.ValidationField
                      name="animalName"
                      className="input-sm"
                      id="animalName"
                      autoComplete="none"
                      placeholder="Totó da Silva"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="animalUid">RGA</Form.Label>
                    <Form.ValidationField name="animalUid">
                      {
                        ({ field }) => (
                          <Mask.Rga
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            className="input-sm"
                            placeholder="####.####.####.####.####"
                            id="animalUid"
                            onAccept={(value) => {
                              setFieldValue('animalUid', value)
                            }}
                          />
                        )
                      }
                    </Form.ValidationField>
                  </Form.Group>
                  <Form.Group>
                    <Form.Switch className="form-switch" key="onlyChip" style={{ marginTop: 15 }}>
                      <Form.ValidationField
                        type="checkbox"
                        name="onlyChip"
                        id="onlyChip"
                      />
                      <i className="form-icon" />
                      &nbsp;Apenas animais com chip
                    </Form.Switch>
                  </Form.Group>
                  <Form.Group>
                    <Form.Switch className="form-switch" key="onlyCity" style={{ marginTop: 15 }}>
                      <Form.ValidationField
                        type="checkbox"
                        name="onlyCity"
                        id="onlyCity"
                      />
                      <i className="form-icon" />
                      &nbsp;Apenas animais do município
                    </Form.Switch>
                  </Form.Group>
                  <div className="divider text-center" data-content="Tutor" />
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="tutorName">Nome do tutor</Form.Label>
                    <Form.ValidationField
                      name="tutorName"
                      className="input-sm"
                      autoFocus
                      id="tutorName"
                      autoComplete="none"
                      placeholder="Fulano da Silva"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="tutorEmail">Email do tutor</Form.Label>
                    <Form.ValidationField
                      name="tutorEmail"
                      className="input-sm"
                      autoFocus
                      id="tutorEmail"
                      autoComplete="none"
                      placeholder="fulano@email.com"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="tutorPhoneNumber">Telefone do tutor</Form.Label>
                    <Form.ValidationField name="tutorPhoneNumber">
                      {
                        ({ field }) => (
                          <Mask.Phone
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            className="input-sm"
                            placeholder="Número do telefone"
                            id="tutorPhoneNumber"
                            onAccept={(value) => {
                              setFieldValue('tutorPhoneNumber', value)
                            }}
                          />
                        )
                      }
                    </Form.ValidationField>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="tutorDocumentNumber">CPF/CNPJ do tutor</Form.Label>
                    <Form.ValidationField name="tutorDocumentNumber">
                      {
                        ({ field }) => (
                          <Mask.CpfCnpj
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            className="input-sm"
                            placeholder="CPF/CNPJ do tutor"
                            id="tutorDocumentNumber"
                            onAccept={(value) => {
                              setFieldValue('tutorDocumentNumber', value)
                            }}
                          />
                        )
                      }
                    </Form.ValidationField>
                  </Form.Group>
                </>
              )}
            />
          </>
        )}
      />
      <AnimalsQuickFilter />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        <>
          <table className="table text-small table-striped table-hover">
            <thead>
              <tr>
                <th>Pet</th>
                <th>Tutor</th>
                <th>Raça</th>
                <th width="1">Chip</th>
                <th className="text-right" width="1">Ações</th>
              </tr>
            </thead>
            <tbody>
              {
                Boolean(data) && data.rows.map((animal) => (
                  <tr key={animal?.id}>
                    <td>
                      <Avatar
                        avatarSize="sm"
                        className="mr-1"
                        name={animal?.name}
                        photoURL={animal?.photoURL}
                        alt="Imagem do pet"
                      />
                      {animal?.name}
                    </td>
                    <td>
                      <Avatar
                        avatarSize="sm"
                        className="mr-1"
                        name={animal?.tutor?.fullName}
                        photoURL={animal?.tutor?.photoURL}
                        alt="Imagem do tutor"
                      />
                      {animal?.tutor?.fullName}
                    </td>
                    <td>
                      {animal?.category?.description} - {animal?.breed?.description}
                    </td>
                    <td>{animal?.chip ? animal?.chip?.uid : '-'}</td>
                    <td className="text-right">
                      <Link
                        to={`/app/pets/${animal?.id}`}
                        className="btn btn-action btn-sm btn-primary"
                      >
                        <i className="fas fa-eye" />
                      </Link>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          {
            Boolean(data) && (
              <div className="float-right my-2">
                <Paginator paginatedList={data} />
              </div>
            )
          }
        </>
      </ListManager>
    </Styles.Container>
  )
}

export default Animals

import api from './api'

const BASE = '/partner-licensings/:partnerLicensingId/settings/params'

export default {
  list() {
    return api.get(BASE)
  },
  update(body) {
    return api.put(BASE, body)
  },
}

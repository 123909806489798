/* eslint-disable react/jsx-no-target-blank */
import {
  Card, ErrorBox, Flexbox,
} from '@/components'
import PropTypes from 'prop-types'
import React from 'react'
import Row from './Row'

function AlbumsCard({
 albums, onChange, onDeleted,
}) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Álbuns de fotos</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-images" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body style={{ maxHeight: 600, overflow: 'auto' }}>
        {
          albums?.length
            ? (
              <>
                {
                  albums.map((album, index) => (
                    <Row
                      onUpdate={onChange}
                      onDeleted={onDeleted}
                      key={album.id}
                      album={album}
                      albums={albums}
                      index={index}
                    />
                  ))
                }
              </>
            ) : (
              <ErrorBox
                error={{
                  icon: 'fas fa-images fa-2x',
                  title: 'Nenhum álbum de fotos',
                  message: 'Esse pet não possui álbuns de fotos',
                }}
              />
            )
        }
      </Card.Body>
      {/* <Card.Footer>
        <Add onAdded={onAdded} />
      </Card.Footer> */}
    </Card.Base>
  )
}

AlbumsCard.propTypes = {
  albums: PropTypes.arrayOf(PropTypes.any).isRequired,
  onChange: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
}

export default AlbumsCard

/* eslint-disable camelcase */
import { transliterate } from 'paliari-js-utils'

const requiredFields = ['administrative_area_level_2', 'administrative_area_level_1', 'country']

export default (address_components) => {
  // eslint-disable-next-line prefer-spread
  const fields = [].concat.apply([], address_components.map(({ types }) => [...types]))
  requiredFields.forEach((key) => {
    if (!fields.includes(key)) throw new Error('Invalid address')
  })

  const country = address_components.find((item) => item.types.includes('country'))?.short_name
  const administrative_area_level_1 = address_components.find((item) => item.types.includes('administrative_area_level_1'))?.short_name
  const administrative_area_level_2 = address_components.find((item) => item.types.includes('administrative_area_level_2'))?.short_name

  return transliterate(`${country}_${administrative_area_level_1}_${administrative_area_level_2}`).replace(/ /g, '_')
}

const actions = {
  requested: ['approve', 'reject', 'revision', 'correction'],
  revision: ['approve', 'reject', 'correction'],
  corrected: ['approve', 'reject', 'correction'],
  correction: ['approve', 'reject'],
  approved: ['cancel', 'changeClinic'],
  preApproved: ['approveWithClinic', 'cancel'],
  scheduled: ['cancel', 'changeClinic'],
  attendance: ['cancel'],
  inTreatment: ['cancel', 'changeClinic'],
  awaitingConfirmation: ['confirm'],
}

export default (status) => actions[status] || []

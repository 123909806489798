import { Form, LoadManager } from '@/components'
import useBatch from '@/hooks/useBatch'
import usePageTitle from '@/hooks/usePageTitle'
import yup from '@/lib/yupPt'
import { setBatch } from '@/reducers/batch'
import batches from '@/services/api/batches'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  description: yup.string().trim().required().min(10),
})
function Reject({ title }) {
  usePageTitle({ title })
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const { state: { batch }, dispatch } = useBatch()
  const history = useHistory()

  async function reject({ description }) {
    try {
      setLoading(true)
      const { data } = await batches.reject(id, { eventData: { description } })
      dispatch(setBatch(data))
      toast.success('Lote rejeitado')
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <fieldset disabled={loading}>
      <Form.Formik
        initialValues={{ description: '' }}
        validationSchema={schema}
        onSubmit={reject}
        validateOnMount
      >
        {
          ({
            errors, touched, isValid,
          }) => (
            <Form.ValidationForm>
              <div className="modal active" id="modal-id">
                <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
                <div className="modal-container">
                  <div className="modal-header">
                    <a
                      type="button"
                      className="btn btn-clear float-right"
                      onClick={() => history.goBack()}
                    />
                    <div className="modal-title h5">Rejeitar o lote #{id}</div>
                  </div>
                  <div className="modal-body">
                    <div className="content">
                      <LoadManager loading={!batch}>
                        <>
                          <p>Confirma a rejeição do lote <b className="text-primary">#{id}</b>?</p>
                          <p className="text-small">
                            Esta ação vai gerar um evento de rejeição e não será reversível.
                          </p>

                          <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="description">Informe o motivo da rejeição <span className="text-error">*</span></Form.Label>
                            <Form.ValidationField
                              name="description"
                              component="textarea"
                              id="description"
                              placeholder="Estou rejeitando o lote porque ..."
                            />
                            {
                              touched?.description && errors?.description
                                ? <Form.Hint className="text-error">{errors.description}</Form.Hint> : null
                            }
                          </Form.Group>
                        </>
                      </LoadManager>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <a
                      className="btn mr-2"
                      disabled={loading}
                      onClick={() => history.goBack()}
                    >
                      <i className="fas fa-chevron-left" />
                      &nbsp;Voltar
                    </a>
                    <button
                      className={`btn btn-error ${loading ? 'loading' : ''}`}
                      type="submit"
                      disabled={!isValid}
                    >
                      <i className="fas fa-ban" />
                      &nbsp;Rejeitar
                    </button>
                  </div>
                </div>
              </div>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </fieldset>
  )
}

export default Reject

const mappings = {
  all: {
    label: 'Total de lotes',
    icon: 'fas fa-fw fa-box',
  },
  requested: {
    label: 'Lotes aguardando aprovação',
    icon: 'far fa-fw fa-question-circle',
  },
  rejected: {
    label: 'Lotes rejeitados',
    icon: 'fas fa-fw fa-thumbs-down',
  },
  approved: {
    label: 'Lotes aprovados',
    icon: 'fas fa-fw fa-check',
  },
}

export default (status) => mappings[status] || { label: 'Desconhecido' }

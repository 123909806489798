import React from 'react'
import { FooterContainer, Logo, Container } from './styles'
import { version } from '../../../package.json'

function Footer() {
  return (
    <Container>
      <FooterContainer className="text-ellipsis container grid-xl">
        <Logo />
        <div className="text-primary pr-1">Petis</div>
        <span className="text-ellipsis">
          - Desenvolvido por
          <a href="https://paliari.com.br" target="_blank" rel="noreferrer"> Paliari Engenharia de Software </a>
          ©  {new Date().getFullYear()}
          <code> <small>v{version}</small></code>
        </span>
      </FooterContainer>
    </Container>
  )
}

export default Footer

/* eslint-disable react/forbid-prop-types */
import React from 'react'
import PropTypes from 'prop-types'

import {
  ErrorContainer, SuccessContainer, InfoContainer, CloseButton, MessageContainer,
} from './styles'

function ErrorComponent({ message, close }) {
  return (
    <ErrorContainer>
      <MessageContainer>
        <i className="fas fa-exclamation-circle" />
        <span>
          {message}
        </span>
      </MessageContainer>
      <CloseButton onClick={close} role="button" className="fas fa-times" />
    </ErrorContainer>
  )
}

function InfoComponent({ message, close }) {
  return (
    <InfoContainer>
      <MessageContainer>
        <i className="fas fa-info-circle" />
        <span>
          {message}
        </span>
      </MessageContainer>
      <CloseButton onClick={close} role="button" className="fas fa-times" />
    </InfoContainer>
  )
}

function SuccessComponent({ message, close }) {
  return (
    <SuccessContainer>
      <MessageContainer>
        <i className="fas fa-check-circle" />
        <span>
          {message}
        </span>
      </MessageContainer>
      <CloseButton onClick={close} role="button" className="fas fa-times" />
    </SuccessContainer>
  )
}

function Toast({
  style, message, close, options,
}) {
  return (
    <div style={style}>
      { options.type === 'error' ? <ErrorComponent close={close} message={message} /> : null}
      { options.type === 'info' ? <InfoComponent close={close} message={message} /> : null}
      { options.type === 'success' ? <SuccessComponent close={close} message={message} /> : null}
    </div>
  )
}

const childrenProps = {
  message: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
}

Toast.propTypes = {
  style: PropTypes.object.isRequired,
  message: PropTypes.string.isRequired,
  close: PropTypes.func.isRequired,
  options: PropTypes.shape({
    type: PropTypes.string,
    position: PropTypes.string,
    timeout: PropTypes.number,
    offset: PropTypes.string,
  }).isRequired,
}
ErrorComponent.propTypes = childrenProps
InfoComponent.propTypes = childrenProps
SuccessComponent.propTypes = childrenProps

export default Toast

import { Avatar, Card, Flexbox } from '@/components'
import envs from '@/constants/envs'
import { ANIMAL } from '@/lib/enums'
import currency from '@/lib/masks/currency'
import dateMask from '@/lib/masks/date'
import rga from '@/lib/masks/rga'
import PropTypes from 'prop-types'
import React from 'react'
import DiedForm from '../DiedForm'

function AnimalCard({ animal, onChipOpened, onUpdate }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5 card-title">Dados do pet</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-paw" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column className="col-auto">
            <Avatar avatarSize="xl" name={animal?.name} photoURL={animal?.photoURL} />
          </Flexbox.Column>
          <Flexbox.Column className="text-ellipsis">
            <div className="h5">{animal?.name}</div>
            <div>{animal?.breed?.description}</div>
          </Flexbox.Column>
        </Flexbox.Columns>
        <div className="divider" />
        <Flexbox.Columns>
          <Flexbox.Column>RGA</Flexbox.Column>
          <a
            href={`${envs.REACT_APP_BASE_WEB_SHOW_URL}/rga/${animal?.uid}`}
            target="_blank"
            rel="noreferrer"
          >
            <Flexbox.Column className="col-auto text-bold">{rga(animal?.uid)}</Flexbox.Column>
          </a>
        </Flexbox.Columns>
        <Flexbox.Columns>
          <Flexbox.Column>Espécie</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">{animal?.category?.description}</Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          <Flexbox.Column>Nascimento</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">{dateMask(animal?.bornedAt, { dateStyle: 'short' })}</Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          <Flexbox.Column>Porte</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">{ANIMAL.size.t(animal?.size)}</Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          <Flexbox.Column>Peso</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">{animal?.weight ? currency(animal?.weight, '', ' Kg') : '-'}</Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns>
          <Flexbox.Column>Sexo</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">{ANIMAL.gender.t(animal?.gender)}</Flexbox.Column>
        </Flexbox.Columns>
        {
          animal?.diedAt ? (
            <Flexbox.Columns>
              <Flexbox.Column>Falecimento</Flexbox.Column>
              <Flexbox.Column className="col-auto text-bold">{dateMask(animal?.diedAt, { dateStyle: 'short' })}</Flexbox.Column>
            </Flexbox.Columns>
          ) : (
            <>
            <div className="divider" />
              <DiedForm onDied={({ diedAt }) => onUpdate({ ...animal, diedAt })} />
            </>
          )
        }
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column className="col-12">Observações</Flexbox.Column>
          <Flexbox.Column className="col-12 text-bold">
            {animal?.description || '-'}
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Body>
      <Card.Footer>
        <div className="divider" />
        <Flexbox.Columns>
          <Flexbox.Column>
            Chip:
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <div className="flex-centered">
              <b>
                {animal?.chip ? animal?.chip?.uid : 'Não informado'}
              </b>
              <a onClick={onChipOpened} className="btn btn-sm btn-action btn-link btn-icon">
                <i className="fas fa-edit" />
              </a>
            </div>
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Footer>
    </Card.Base>
  )
}

AnimalCard.propTypes = {
  animal: PropTypes.objectOf(PropTypes.any).isRequired,
  onChipOpened: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
}

export default AnimalCard

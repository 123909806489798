import React from 'react'
import PropTypes from 'prop-types'
import ImageThumb from '../ImageThumb'

function Avatar({
  name, photoURL, smart, alt, className, avatarSize, useThumb,
}) {
  const avatarClassMap = {
    xs: { width: 30, height: 30 },
    sm: { width: 32, height: 32 },
    md: { width: 40, height: 40 },
    lg: { width: 50, height: 50 },
    xl: { width: 60, height: 60 },
  }

  const imageSize = avatarClassMap[avatarSize] || { width: 100, height: 100 }

  if (!name) return null
  return (
    <figure className={`avatar avatar-${avatarSize} ${className}`} data-initial={name[0]}>
      {
        Boolean(photoURL) && (
          useThumb
            ? (
              <ImageThumb
                src={photoURL}
                width={imageSize.width}
                height={imageSize.height}
                smart={smart}
                alt={alt}
              />
            ) : <img src={photoURL} alt={alt} />
        )
      }
    </figure>
  )
}

Avatar.propTypes = {
  name: PropTypes.string.isRequired,
  photoURL: PropTypes.string,
  smart: PropTypes.bool,
  alt: PropTypes.string,
  className: PropTypes.string,
  avatarSize: PropTypes.string,
  useThumb: PropTypes.bool,
}

Avatar.defaultProps = {
  photoURL: null,
  smart: true,
  alt: '',
  className: '',
  avatarSize: 'md',
  useThumb: true,
}

export default Avatar

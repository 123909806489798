import {
 ListManager, PageHeader, Paginator, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import reports from '@/services/api/reports'
import React from 'react'
import FilterCastrations from '../components/FilterCastrations'

function CastrationsByBreed({ title }) {
  usePageTitle({ title })

  const query = useQuery()
  const { data, loading, error } = useAxiosRequest(reports.castrationsByBreed, query)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Castrações por raça e espécie"
        subtitle="Quantidade de castrações por raça e espécie"
        icon="fas fa-paw"
        rightAction={() => <FilterCastrations />}
      />
      <ListManager count={data?.count || 0} loading={loading} error={error}>
        {
          Boolean(data) && (
            <>
            <table className="table table-hover">
              <thead>
                <tr>
                  <th>Raça</th>
                  <th>Espécie</th>
                  <th width="100px" className="text-right">Quantidade</th>
                </tr>
              </thead>
              <tbody>
                {
                  data.rows.map((item) => (
                    <tr key={`${item?.breed}-${item?.category}`}>
                      <td>{item?.breed}</td>
                      <td>{item?.category}</td>
                      <td className="text-right">{item?.count}</td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
            {
                Boolean(data) && (
                  <div className="float-right my-2">
                    <Paginator paginatedList={data} />
                  </div>
                )
              }
            </>
          )
        }
      </ListManager>
    </Styles.Container>
  )
}

export default CastrationsByBreed

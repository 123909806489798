import {
  ListManager, PageHeader, Paginator, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import reports from '@/services/api/reports'
import React from 'react'

function AnimalsInFair({ title }) {
  usePageTitle({ title })

  const query = useQuery()
  const { data, loading, error } = useAxiosRequest(reports.animalsInFair, query)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Resumo das feiras de adoção"
        subtitle="Consulta de animais e adoções nas feiras do município e online"
        icon="fas fa-heart"
      />
      <ListManager count={data?.count || 0} loading={loading} error={error}>
        {
          Boolean(data) && (
            <>
              <div className="p-2 bg-gray s-rounded">
                <div className="text-small">
                  <span>Pets adotados nas feiras do município: </span>
                  <b>{data.extra.adoptedInCityFairs}</b>
                </div>
                <div className="text-small">
                  <span>Pets adotados na feira online: </span>
                  <b>{data.extra.adoptedInOnlineFairs}</b>
                </div>
                <div className="text-small">
                  <span>Adoções solicitadas nas feiras do município: </span>
                  <b>{data.extra.requestedAdoptionsInCityFairs}</b>
                </div>
                <div className="text-small">
                  <span>Total de adoções em qualquer status nas feiras do município: </span>
                  <b>{data.extra.totalAdoptionsInCityFairs}</b>
                </div>
              </div>

              <table className="table table-hover text-small">
                <thead>
                  <tr>
                    <th>Feira</th>
                    <th className="text-right" width="200px">Pets adicionados</th>
                    <th className="text-right" width="200px">Pets adotados</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.rows.map((item) => (
                      <tr key={item?.fairId}>
                        <td>{item.fairTitle}</td>
                        <td className="text-right">{item.countAnimals}</td>
                        <td className="text-right">{item.countAnimalsAdopted}</td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>

              {
                Boolean(data) && (
                  <div className="float-right my-2">
                    <Paginator paginatedList={data} />
                  </div>
                )
              }
            </>
          )
        }
      </ListManager>
    </Styles.Container>
  )
}

export default AnimalsInFair

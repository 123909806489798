import {
  Card, Filter, Flexbox, Form, ListManager,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import useQuery from '@/hooks/useQuery'
import timelineItems from '@/services/api/timelineItems'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import Row from './Row'

function TimelineCard() {
  const [loadingMore, setLoadingMore] = useState(false)
  const query = useQuery()
  const {
    data, error, loading, setData,
  } = useAxiosRequest(timelineItems.list, query)

  async function onLoadMore() {
    try {
      setLoadingMore(true)
      const res = await timelineItems.list({ page: data?.page + 1 })
      setData({
        ...res?.data,
        rows: [...data?.rows, ...res?.data?.rows],
      })
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoadingMore(false)
    }
  }

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Timeline</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <Filter
              initialValues={{
                onlyCommentsCastrations: false, onlyRead: false,
              }}
              title="Filtrar timeline"
              renderForm={() => (
                <>
                  <Form.Group>
                    <Form.Switch className="form-switch" key="onlyCommentsCastrations" style={{ marginTop: 15 }}>
                      <Form.ValidationField
                        type="checkbox"
                        name="onlyCommentsCastrations"
                        id="onlyCommentsCastrations"
                      />
                      <i className="form-icon" />
                      &nbsp;Apenas comentários nas castrações
                    </Form.Switch>
                  </Form.Group>
                  <Form.Group>
                    <Form.Switch className="form-switch" key="onlyRead" style={{ marginTop: 15 }}>
                      <Form.ValidationField
                        type="checkbox"
                        name="onlyRead"
                        id="onlyRead"
                      />
                      <i className="form-icon" />
                      &nbsp;Apenas eventos já lidos
                    </Form.Switch>
                  </Form.Group>
                </>
              )}
            />
          </Flexbox.Column>
        </Flexbox.Columns>
        <div className="divider" />
      </Card.Header>
      <Card.Body style={{ maxHeight: '560px', overflow: 'auto' }}>
        <div className="py-2">
          <ListManager count={data?.count || 0} loading={loading} error={error}>
            {
              data?.rows.map((item, index) => (
                <Row key={item?.id} item={item} last={index === data?.rows?.length - 1} />
              ))
            }
          </ListManager>
        </div>
        {
          data?.pages > data?.page && (
            <a className="btn btn-link" disabled={loadingMore} onClick={onLoadMore}>
              {loadingMore ? 'Carregando' : 'Carregar mais'}
            </a>
          )
        }
      </Card.Body>
    </Card.Base>
  )
}

export default TimelineCard

export default (address) => {
  let addressString = ''
  if (address.street) addressString += address.street
  if (address.number) addressString += `, ${address.number}`
  if (address.complement) addressString += `, ${address.complement}`
  if (address.district) addressString += `, ${address.district}`
  if (address.city) addressString += `, ${address.city.name} - ${address.city.uf}`
  if (address.zipcode) addressString += `, ${address.zipcode}`

  return addressString
}

import {
  Card, Flexbox, Form, Styles,
} from '@/components'
import usePageTitle from '@/hooks/usePageTitle'
import yup from '@/lib/yupPt'
import passwordReset from '@/services/auth/passwordReset'
import session from '@/services/auth/session'
import React, { useEffect, useState } from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'
import NewTokenButton from './NewTokenButton'
import { AppContainer, Logo, Overflow } from './styles'

const schema = yup.object().shape({
  token: yup.string().length(6).required(),
  password: yup.string().min(6).required(),
  confirmPassword: yup.string().test('match', 'Senha não confere', function match(confirmPassword) {
    return this.parent.password === confirmPassword
  }),
})
function Token({ title }) {
  const [loading, setLoading] = useState(false)
  const [reseting, setReseting] = useState(false)
  const [error, setError] = useState(false)
  const [accountInfo, setAccountInfo] = useState(null)

  const location = useLocation()
  const history = useHistory()
  usePageTitle({ title })

  const login = location.state?.login

  async function fetchAccountInfo() {
    setLoading(true)
    const { data } = await session.hasLocalAuthentication(login)
    setAccountInfo(data)
    setLoading(false)
  }

  async function sendToken() {
    await passwordReset.sendEmail(login)
  }

  async function submit({ password, token }) {
    try {
      setReseting(true)
      await passwordReset.reset({ password, token, login })
      history.replace({
        pathname: '/login',
        state: {
          success: 'Senha alterada com sucesso.',
          login,
        },
      })
    } catch (e) {
      setReseting(false)
      setError(e)
    }
  }

  useEffect(() => {
    fetchAccountInfo()
    sendToken()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!login) return <Redirect to="/login" />

  return (
    <AppContainer>
      <Overflow />
      {
        loading
          ? <div className="loading loading-lg text-center" />
          : (
            <fieldset disabled={reseting}>
              <Form.Formik
                initialValues={{
                  token: '',
                  password: '',
                  confirmPassword: '',
                }}
                validationSchema={schema}
                onSubmit={submit}
                validateOnMount
              >
                {
                  ({
                    errors, touched, values, isValid,
                  }) => (
                    <Form.ValidationForm>
                      <Card.Base style={{ minWidth: 300, maxWidth: 400 }}>
                        <Card.Header>
                          <Flexbox.Columns className="align-center">
                            <Flexbox.Column className="col-auto">
                              <Logo />
                            </Flexbox.Column>
                            <Flexbox.Column>
                              Petis - Nova senha
                            </Flexbox.Column>
                          </Flexbox.Columns>
                        </Card.Header>
                        <Card.Body>
                          {
                            error ? (
                              <Styles.Toast className="toast-error">
                                {error?.message}
                              </Styles.Toast>
                            ) : null
                          }
                          <p className="mt-2 text-center">
                            Informe o <b>código de 6 dígitos</b> que enviamos para
                            <b className="text-primary"> {accountInfo?.email}</b>
                          </p>
                          <Form.Group className={`${touched?.token && errors?.token ? 'has-error' : ''}`}>
                            <Form.ValidationField
                              className="input-lg text-center"
                              name="token"
                              maxLength="6"
                              autoFocus
                              id="token"
                              autoComplete="off"
                              placeholder="# # # # # #"
                            />
                          </Form.Group>
                          <NewTokenButton onClick={sendToken} />
                          {
                            values?.token?.length === 6
                            && (
                              <>
                                <div className="divider" />
                                <Form.Group className={`${touched?.password && errors?.password ? 'has-error' : ''}`}>
                                  <Form.Label htmlFor="password">Nova senha <span className="text-error">*</span></Form.Label>
                                  <Form.ValidationField
                                    name="password"
                                    type="password"
                                    id="password"
                                    placeholder="Nova senha segura"
                                  />
                                  {
                                    touched?.password && errors?.password
                                      ? <Form.Hint>{errors.password}</Form.Hint> : null
                                  }
                                </Form.Group>
                                <Form.Group className={`${touched?.confirmPassword && errors?.confirmPassword ? 'has-error' : ''}`}>
                                  <Form.Label htmlFor="confirmPassword">Confirme a senha <span className="text-error">*</span></Form.Label>
                                  <Form.ValidationField
                                    name="confirmPassword"
                                    type="password"
                                    id="confirmPassword"
                                    placeholder="Confirme a nova senha"
                                  />
                                  {
                                    touched?.confirmPassword && errors?.confirmPassword
                                      ? <Form.Hint>{errors.confirmPassword}</Form.Hint> : null
                                  }
                                </Form.Group>
                              </>
                            )
                          }
                        </Card.Body>
                        {
                          values?.token?.length === 6
                          && (
                            <Card.Footer>
                              <Flexbox.Columns className="align-center">
                                <Flexbox.Column>
                                  <Styles.Link className="btn-link btn-sm" to="/login">
                                    Voltar ao login
                                  </Styles.Link>
                                </Flexbox.Column>
                                <Flexbox.Column className="col-auto">
                                  <Styles.Button type="submit" className="btn-primary" disabled={!isValid}>
                                    <i className="fas fa-check" />
                                    &nbsp;Confirmar
                                  </Styles.Button>
                                </Flexbox.Column>
                              </Flexbox.Columns>
                            </Card.Footer>
                          )
                        }
                      </Card.Base>
                    </Form.ValidationForm>
                  )
                }
              </Form.Formik>
            </fieldset>
          )
      }
    </AppContainer>
  )
}

export default Token

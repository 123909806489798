import { Card, Flexbox } from '@/components'
import PropTypes from 'prop-types'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'

const scopes = {
  Castration: 'Castrações',
}

function BalanceCard({ licensing }) {
  const { pathname } = useLocation()

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">
              {licensing?.balances?.length === 1 ? 'Saldo' : 'Saldos'}
            </div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-exchange-alt" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        {
          !licensing?.balances?.length ? (
            <div>
              <div>
                Esta clínica ainda não possui <b>nenhum saldo</b>
              </div>
              {
                licensing?.status === 'requested' && (
                  <div className="mt-2">
                    <small>
                      Você poderá adicionar saldo depois de <b>aprovar o credenciamento</b>
                    </small>
                  </div>
                )
              }
            </div>
          ) : (
            <>
              {
                licensing?.balances.map((balance) => (
                  <Flexbox.Columns key={balance?.id}>
                    <Flexbox.Column className="d-flex align-center">
                      {scopes[balance?.scope] || balance?.scope}
                    </Flexbox.Column>
                    <Flexbox.Column className="col-auto">
                      <b>{balance?.amount}</b>
                    </Flexbox.Column>
                  </Flexbox.Columns>
                ))
              }
            </>
          )
        }
      </Card.Body>
      {
        licensing?.status === 'approved' && (
          <Card.Footer className="text-right">
            <Link to={`${pathname}/transaction`} className="btn btn-primary btn-sm">
              <i className="fas fa-plus" />
              &nbsp;Adicionar saldo
            </Link>
          </Card.Footer>
        )
      }
    </Card.Base>
  )
}

BalanceCard.propTypes = {
  licensing: PropTypes.objectOf(Object).isRequired,
}

export default BalanceCard

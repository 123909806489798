import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Card, Flexbox } from '@/components'
import dateMask from '@/lib/masks/date'
import { Link } from 'react-router-dom'
import { LICENSING } from '@/lib/enums'

function LicensingsCard({ licensings }) {
  const requesteds = licensings.find(({ status }) => status === 'requested')
  const routeMap = {
    castrator: 'clinics',
    protector: 'protectors',
    chipsApplyer: 'chips',
    ngo: 'ngos',
  }

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Solicitações de credenciamento</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-question" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        {
          requesteds.rows?.length ? (
            <table className="table text-small">
              <tbody>
                {
                  requesteds.rows.map((licensing) => (
                    <tr key={licensing?.id}>
                      <td width="1">
                        <Avatar
                          className="mr-1"
                          name={licensing?.licensed?.fullName}
                          photoURL={licensing?.licensed?.photoURL}
                          alt="Imagem de usuário"
                        />
                      </td>
                      <td>
                        {licensing?.licensed.fullName}
                        <div>
                          <small>
                            <small>{LICENSING.type.t(licensing?.type)}</small>
                            <small> - </small>
                            <small>{dateMask(licensing?.createdAt)}</small>
                          </small>
                        </div>
                      </td>
                      <td width="1">
                        <Link
                          to={`/app/${routeMap[licensing.type]}/${licensing.status}/${licensing?.id}`}
                          className="btn btn-action btn-sm btn-primary"
                        >
                          <i className="fas fa-eye" />
                        </Link>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          ) : (
            <div className="empty">
              <div className="empty-icon">
                <i className="fas fa-3x fa-check" />
              </div>
              <p className="empty-title h5">Tudo certo</p>
              <p className="empty-subtitle">Nenhuma solicitação aguardando aprovação</p>
            </div>
          )
        }
      </Card.Body>
    </Card.Base>
  )
}

LicensingsCard.propTypes = {
  licensings: PropTypes.arrayOf(Object).isRequired,
}

export default LicensingsCard

import { LoadManager, PageHeader, Styles } from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import batches from '@/services/api/batches'
import React from 'react'
import Row from './components/Row'
import mapStatus from './components/Row/mapStatus'

function Batches({ title }) {
  usePageTitle({ title })
  const { data, loading, error } = useAxiosRequest(batches.countStatus)
  const list = data
    ?.map((item) => ({ ...item, ...mapStatus(item.status) }))
    ?.sort((a, b) => a.sequence - b.sequence)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader icon="fas fa-box" title="Contagem dos lotes" subtitle="Lotes de serviços que as clínicas prestaram" />
      <LoadManager loading={loading} error={error}>

        <table className="table table-striped">
          <thead>
            <tr>
              <th>Status</th>
              <th className="text-center" width="130px">Quantidade</th>
              <th className="text-right" width="1">Ações</th>
            </tr>
          </thead>
          <tbody>
            {
              list?.map((item) => <Row key={item?.status} item={item} />)
            }
          </tbody>
        </table>
      </LoadManager>

    </Styles.Container>
  )
}

export default Batches

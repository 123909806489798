import { Form, LoadManager } from '@/components'
import useCastration from '@/hooks/useCastration'
import usePageTitle from '@/hooks/usePageTitle'
import yup from '@/lib/yupPt'
import { setCastration } from '@/reducers/castration'
import castrations from '@/services/api/castrations'
import rejectionReasons from '@/services/api/rejectionReasons'
import React, { useEffect, useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  description: yup.string().trim().required().min(10),
  rejectionReasonId: yup.number().required(),
})
function Reject({ title }) {
  usePageTitle({ title })
  const [reasons, setReasons] = useState([])
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const { state: { castration }, dispatch } = useCastration()
  const history = useHistory()

  async function fetchReasons() {
    const { data } = await rejectionReasons.list()
    setReasons(data)
  }

  useEffect(() => {
    fetchReasons()
  }, [])

  async function reject({ description, rejectionReasonId }) {
    try {
      setLoading(true)
      const body = { eventData: { description }, rejectionReasonId }
      const { data } = await castrations.reject(id, body)
      dispatch(setCastration(data))
      toast.success('Castração rejeitada')
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <fieldset disabled={loading}>
      <Form.Formik
        initialValues={{ description: '' }}
        validationSchema={schema}
        onSubmit={reject}
        validateOnMount
      >
        {
          ({
            errors, touched, isValid,
          }) => (
            <Form.ValidationForm>
              <div className="modal active" id="modal-id">
                <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
                <div className="modal-container">
                  <div className="modal-header">
                    <a
                      type="button"
                      className="btn btn-clear float-right"
                      onClick={() => history.goBack()}
                    />
                    <div className="modal-title h5">Rejeitar a castração #{id}</div>
                  </div>
                  <div className="modal-body">
                    <div className="content">
                      <LoadManager loading={!castration}>
                        <>
                          <p>Confirma a rejeição para a castração de <b className="text-primary">{castration?.animal?.name}</b>?</p>
                          <p className="text-small">
                            Esta ação vai gerar um evento de rejeição e avisará ao usuário o motivo.
                          </p>

                          <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="description">Descreva o que há de errado com a solicitação <span className="text-error">*</span></Form.Label>
                            <Form.ValidationField
                              name="description"
                              component="textarea"
                              id="description"
                              placeholder="Estou rejeitando a castração porque ..."
                            />
                            {
                              touched?.description && errors?.description
                                ? <Form.Hint className="text-error">{errors.description}</Form.Hint> : null
                            }
                          </Form.Group>
                          <Form.Group>
                            <Form.Label htmlFor="rejectionReasonId">Motivo da rejeição <span className="text-error">*</span></Form.Label>
                            <Form.ValidationField name="rejectionReasonId">
                              {
                                ({ field }) => (
                                  <Form.Select
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...field}
                                    id="rejectionReasonId"
                                    className="select-sm"
                                  >
                                    <option value={null}>Motivo da rejeição</option>
                                    {
                                      reasons.map((reason) => (
                                        <option key={reason?.id} value={reason?.id}>
                                          {reason?.description}
                                        </option>
                                      ))
                                    }
                                  </Form.Select>
                                )
                              }
                            </Form.ValidationField>
                          </Form.Group>
                        </>
                      </LoadManager>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <a
                      className="btn mr-2"
                      disabled={loading}
                      onClick={() => history.goBack()}
                    >
                      <i className="fas fa-chevron-left" />
                      &nbsp;Voltar
                    </a>
                    <button
                      className={`btn btn-error ${loading ? 'loading' : ''}`}
                      type="submit"
                      disabled={!isValid}
                    >
                      <i className="fas fa-ban" />
                      &nbsp;Rejeitar
                    </button>
                  </div>
                </div>
              </div>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </fieldset>
  )
}

export default Reject

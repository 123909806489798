import api from './api'

export default {
  countStatus() {
    return api.get('/partner-licensings/:partnerLicensingId/batches/count-status')
  },

  list(data = {}) {
    const { status, ...params } = data
    return api.get(`/partner-licensings/:partnerLicensingId/batches/${status}/list`, { params })
  },

  show(id) {
    return api.get(`/partner-licensings/:partnerLicensingId/batches/${id}`)
  },

  approve(id) {
    return api.post(`/partner-licensings/:partnerLicensingId/batches/${id}/approve`)
  },

  reject(id, body) {
    return api.post(`/partner-licensings/:partnerLicensingId/batches/${id}/reject`, body)
  },

}

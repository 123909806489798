import { useContext } from 'react'
import licensingContext from '@/contexts/licensingContext'

/**
 * @typedef {Object} LicensingReducer
 * @property {Object} state - State of licensing
 * @property {Function} dispatch - dispatch
 */

/**
 * @returns {LicensingReducer}
 */
 export default function useLicensing() {
  return useContext(licensingContext)
}

import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { CEP_PATTERN } from '@/lib/textMaskPatterns'
import { toast } from 'react-toastify'
import addresses from '@/services/api/addresses'
import Form from '../../Form'

function InputCep({
 value, touched, errors, onChange, onFoundAddress, isRequired,
}) {
  const [loading, setLoading] = useState(false)
  const [cep, setCep] = useState(value)

  const handleChange = useCallback(async (str) => {
    try {
      setCep(str)
      onChange('address.zipcode', str)
      if (str.length === 8) {
        setLoading(true)
        const { data } = await addresses.findAddressByZipcode(str)
        onFoundAddress(data)
      }
    } catch (e) {
      toast.error(e.message)
    } finally {
      setLoading(false)
    }
  }, [onChange, onFoundAddress])

  return (
    <Form.Group className={`${touched?.zipcode && errors?.zipcode ? 'has-error' : ''}`}>
      <Form.Label htmlFor="zipcode">CEP {isRequired && <span className="text-error">*</span>}</Form.Label>
      <Form.InputMask
        disabled={loading}
        value={cep}
        name="zipcode"
        mask={CEP_PATTERN}
        onChange={({ target }) => handleChange(target?.value.replace(/\D/g, ''))}
        placeholder="CEP"
      // eslint-disable-next-line react/jsx-props-no-spreading
      />
      {
        touched?.zipcode && errors?.zipcode
          ? <Form.Hint className="text-error">{errors.zipcode}</Form.Hint> : null
      }
    </Form.Group>
  )
}

InputCep.propTypes = {
  value: PropTypes.string,
  onChange: PropTypes.func,
  touched: PropTypes.objectOf(Object),
  errors: PropTypes.objectOf(Object),
  onFoundAddress: PropTypes.func,
  isRequired: PropTypes.bool,
}

InputCep.defaultProps = {
  value: '',
  onChange: () => {},
  onFoundAddress: () => {},
  touched: {},
  errors: {},
  isRequired: true,
}

export default InputCep

import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Card from '../Card'
import Flexbox from '../Flexbox'
import { Image } from './styles'
import ImageViewer from '../ImageViewer'

function LicensingDocumentsCard({ documents }) {
  const [imageViewerSrc, setImageViewerSrc] = useState('')

  return (
    <>
      <Card.Base>
        <Card.Header>
          <Flexbox.Columns className="align-center">
            <Flexbox.Column>
              <div className="h5">Documentos da solicitação</div>
            </Flexbox.Column>
            <Flexbox.Column className="col-auto">
              <i className="fas fa-id-card" />
            </Flexbox.Column>
          </Flexbox.Columns>
        </Card.Header>
        <Card.Body>
          {
            documents.map((document, index) => (
              <div className="mt-2" key={document.id}>
                <div><b>{document.title}</b></div>
                <Flexbox.Columns className="col-auto mt-2">
                  {
                    document.attachments.map((image) => (
                      <Flexbox.Column key={image} className="col-auto">
                        <a className="c-hand" onClick={() => setImageViewerSrc(image)}>
                          <Image src={image} />
                        </a>
                      </Flexbox.Column>
                  ))
                  }
                </Flexbox.Columns>
                {index + 1 < documents.length && <div className="divider" />}
              </div>
            ))
          }
        </Card.Body>
      </Card.Base>
      {Boolean(imageViewerSrc) && <ImageViewer src={imageViewerSrc} onClose={() => setImageViewerSrc('')} />}
    </>
  )
}

LicensingDocumentsCard.propTypes = {
  documents: PropTypes.arrayOf(Object).isRequired,
}

export default LicensingDocumentsCard

import api from './api'

export default {
  show(id) {
    return api.get(`/partner-licensings/:partnerLicensingId/animal-fairs/${id}`)
  },
  destroy(id) {
    return api.delete(`/partner-licensings/:partnerLicensingId/animal-fairs/${id}`)
  },
  complaints(id, params) {
    return api.get(`/partner-licensings/:partnerLicensingId/animal-fairs/${id}/complaints`, { params })
  },
  clearComplaints(id) {
    return api.delete(`/partner-licensings/:partnerLicensingId/animal-fairs/${id}/complaints`)
  },
  transferAnimal(fairId, body) {
    return api.post(`/partner-licensings/:partnerLicensingId/animal-fairs/${fairId}/transfer`, body)
  },
  chats(id, params = {}) {
    return api.get(`/partner-licensings/:partnerLicensingId/animal-fairs/${id}/chats`, { params })
  },
}

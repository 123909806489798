import envs from '@/constants/envs'
import axios from 'axios'
import qs from 'qs'
import { authorizationInterceptor, errorHandlerInterceptor } from '../interceptors'
import errors from './errors'

const api = axios.create({
  baseURL: `${envs.REACT_APP_BASE_API_URL}/partner`,
  paramsSerializer: qs.stringify,
})

api.interceptors.request.use(authorizationInterceptor)
api.interceptors.response.use((response) => response, errorHandlerInterceptor(errors))

export default api

import { LoadManager, PageHeader, Styles } from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import interval from '@/lib/masks/interval'
import reports from '@/services/api/reports'
import React from 'react'
import FilterCastrations from '../components/FilterCastrations'

function CastrationsTime({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const { data, loading, error } = useAxiosRequest(reports.castrationsTime, query)
  const { ngo, protector, tutor } = data || {}

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Tempo das castrações"
        subtitle="Mostra a quantidade de castrações solicitadas pelas ONG's"
        icon="fas fa-stopwatch"
        rightAction={() => <FilterCastrations />}
      />
      <LoadManager
        loading={loading}
        error={error}
      >
        <>
          <div className="p-2 bg-gray s-rounded">
            <div className="text-small">
              <b>Média para aprovação: </b>
              <span>
                É a média de tempo que o fornecedor leva para aprovar a castração
              </span>
            </div>
            <div className="text-small">
              <b>Média de atendimento: </b>
              <span>
                É o tempo médio que a clínica leva para concluir a castração após iniciá-la
              </span>
            </div>
            <div className="text-small">
              <b>Média Aprovação/Atendimento: </b>
              <span>
                É a média de tempo que o as castrações levam para
                o atendimento ser iniciado após a aprovação
              </span>
            </div>
            <div className="text-small">
              <b>Média total: </b>
              <span>
                É a média de tempo total que a castração
                leva para ser concluída após a sua solicitação
              </span>
            </div>
          </div>
          <table className="table table-hover text-small">
            <thead>
              <tr>
                <th>Tipo de licença</th>
                <th>Média para aprovação</th>
                <th>Média de atendimento</th>
                <th>Média Aprovação/Atendimento</th>
                <th>Média total</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Tutores</td>
                <td>{interval(tutor?.approvedTime)}</td>
                <td>{interval(tutor?.attendanceTime)}</td>
                <td>{interval(tutor?.approvedPerformedTime)}</td>
                <td>{interval(tutor?.totalTime)}</td>
              </tr>
              <tr>
                <td>{`${"ONG's"}`}</td>
                <td>{interval(ngo?.approvedTime)}</td>
                <td>{interval(ngo?.attendanceTime)}</td>
                <td>{interval(ngo?.approvedPerformedTime)}</td>
                <td>{interval(ngo?.totalTime)}</td>
              </tr>
              <tr>
                <td>Protetores</td>
                <td>{interval(protector?.approvedTime)}</td>
                <td>{interval(protector?.attendanceTime)}</td>
                <td>{interval(protector?.approvedPerformedTime)}</td>
                <td>{interval(protector?.totalTime)}</td>
              </tr>
            </tbody>
          </table>
        </>
      </LoadManager>
    </Styles.Container>
  )
}

export default CastrationsTime

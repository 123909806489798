import { Flexbox, Form, QuickFilter } from '@/components'
import React from 'react'

function AnimalsQuickFilter() {
  return (
    <QuickFilter
      initialValues={{
        animalGender: '', animalCategoryId: '',
      }}
      title="Filtrar castrações"
      renderForm={() => (
        <>
          <Flexbox.Column className="col-3 pt-2">
            <Form.Group>
              <Form.Label className="label-sm pt-2" htmlFor="animalCategoryId">Espécie do pet</Form.Label>
              <Form.ValidationField name="animalCategoryId">
                {
                  ({ field }) => (
                    <Form.Select
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      id="animalCategoryId"
                      className="select-sm"
                      placeholder="Espécie do pet"
                    >
                      <option value="">Todos</option>
                      <option value="1">Cachorro</option>
                      <option value="2">Gato</option>
                    </Form.Select>
                  )
                }
              </Form.ValidationField>
            </Form.Group>
          </Flexbox.Column>
          <Flexbox.Column className="col-3 pt-2">
            <Form.Group>
              <Form.Label className="label-sm pt-2" htmlFor="animalGender">Gênero do pet</Form.Label>
              <Form.ValidationField name="animalGender">
                {
                  ({ field }) => (
                    <Form.Select
                      // eslint-disable-next-line react/jsx-props-no-spreading
                      {...field}
                      id="animalGender"
                      className="select-sm"
                      placeholder="Gênero do pet"
                    >
                      <option value="">Todos</option>
                      <option value="male">Macho</option>
                      <option value="female">Fêmea</option>
                    </Form.Select>
                  )
                }
              </Form.ValidationField>
            </Form.Group>
          </Flexbox.Column>
        </>
      )}
    />
  )
}

export default AnimalsQuickFilter

import { Flexbox, Form, LoadManager } from '@/components'
import useClinic from '@/hooks/useClinic'
import usePageTitle from '@/hooks/usePageTitle'
import yup from '@/lib/yupPt'
import { setClinic } from '@/reducers/clinic'
import clinics from '@/services/api/clinics'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  description: yup.string().trim().required().min(5),
  amount: yup.number().required(),
})
function Transaction({ title }) {
  usePageTitle({ title })
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const { state: { clinic }, dispatch } = useClinic()
  const history = useHistory()

  async function onSubmit(form) {
    try {
      setLoading(true)
      const { data } = await clinics.addCastrations(id, form)
      dispatch(setClinic(data))
      toast.success(`Saldo de ${form?.amount} enviada para ${clinic?.licensed?.fullName}`)
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <fieldset disabled={loading}>
      <Form.Formik
        initialValues={{ description: '', amount: 0 }}
        validationSchema={schema}
        onSubmit={onSubmit}
        validateOnMount
      >
        {
          ({
            errors, touched, isValid,
          }) => (
            <Form.ValidationForm>

              <div className="modal active" id="modal-id">
                <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
                <div className="modal-container">
                  <div className="modal-header">
                    <a
                      type="button"
                      className="btn btn-clear float-right"
                      onClick={() => history.goBack()}
                    />
                    <div className="modal-title h5">Adicionar saldo</div>
                  </div>
                  <div className="modal-body">
                    <div className="content">
                      <LoadManager loading={!clinic}>
                        <>
                          <p>Você está adicionando saldo para <b className="text-primary">{clinic?.licensed?.fullName}</b>?</p>
                          <p className="text-small">
                            Esta ação vai gerar uma transação de castrações.
                          </p>
                          <Flexbox.Columns>
                            <Flexbox.Column className="col-4">
                              <Form.Group className={`${touched?.amount && errors?.amount ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="amount">Quantidade <span className="text-error">*</span></Form.Label>
                                <Form.ValidationField
                                  name="amount"
                                  type="number"
                                  id="amount"
                                  placeholder="Quantidade"
                                />
                                {
                                  touched?.amount && errors?.amount
                                    ? <Form.Hint className="text-error">{errors.amount}</Form.Hint> : null
                                }
                              </Form.Group>
                            </Flexbox.Column>
                            <Flexbox.Column className="col-8">
                              <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="description">Descrição <span className="text-error">*</span></Form.Label>
                                <Form.ValidationField
                                  name="description"
                                  id="description"
                                  placeholder="Estou adicionando saldo porque ..."
                                />
                                {
                                  touched?.description && errors?.description
                                    ? <Form.Hint className="text-error">{errors.description}</Form.Hint> : null
                                }
                              </Form.Group>
                            </Flexbox.Column>
                          </Flexbox.Columns>
                        </>
                      </LoadManager>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <a
                      className="btn btn-primary mr-2"
                      disabled={loading}
                      onClick={() => history.goBack()}
                    >
                      <i className="fas fa-chevron-left" />
                      &nbsp;Voltar
                    </a>
                    <button
                      className={`btn btn-success ${loading ? 'loading' : ''}`}
                      disabled={!isValid}
                      type="submit"
                    >
                      <i className="fas fa-check" />
                      &nbsp;Enviar saldo
                    </button>
                  </div>
                </div>
              </div>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </fieldset>

  )
}

export default Transaction

import { genderMap, sizeMap } from '@/constants/animal'
import address from '@/lib/formatters/address'
import dateMask from '@/lib/masks/date'
import documentNumberMask from '@/lib/masks/documentNumber'
import phoneNumber from '@/lib/masks/phoneNumber'
import PropTypes from 'prop-types'
import React from 'react'

export default function ConfirmAdoption({ values, onChangeStep }) {
  return (

    <div>
      <div className="h5">Confirme os dados</div>
      <p>
        Verifique com atenção se todos os dados estão corretos antes de criar a adoção
      </p>
      <div className="divider" />
      <div className="d-flex align-center mb-2">
        <div className="h6 text-bold">
          Pet
        </div>
        <a onClick={() => onChangeStep(1)} className="btn btn-link tooltip tooltip-right" data-tooltip="Editar">
          <i className="fas fa-edit" />
        </a>
      </div>
      <div className="mb-2">
        <div>
          <span>Nome: <b>{values?.animalName || '-'}</b></span>
        </div>
        <div>
          <span>Sexo: <b>{genderMap?.[values?.animalGender] || '-'}</b></span>
        </div>
        <div>
          <span>Porte: <b>{sizeMap?.[values?.animalSize] || '-'}</b></span>
        </div>
        <div>
          <span>Cor/Características: <b>{values?.animalColor || '-'}</b></span>
        </div>
        <div>
          <span>Nascimento: <b>{dateMask(values?.animalBornedAt, { dateStyle: 'short' } || '-')}</b></span>
        </div>
        <div>
          <span>Espécie: <b>{values?.category?.description}</b></span>
        </div>
        <div>
          <span>Raça: <b>{values?.breed?.description}</b></span>
        </div>
      </div>
      <div className="divider" />
      <div className="d-flex align-center mb-2">
        <div className="h6 text-bold">
          Tutor
        </div>
        <a onClick={() => onChangeStep(2)} className="btn btn-link tooltip tooltip-right" data-tooltip="Editar">
          <i className="fas fa-edit" />
        </a>
      </div>
      <div className="mb-2">
        <div>
          <span>Nome completo: <b>{values?.fullName}</b></span>
        </div>
        <div>
          <span>CPF/CNPJ: <b>{documentNumberMask(values?.documentNumber)}</b></span>
        </div>
        <div>
          <span>E-mail: <b>{values?.email || '-'}</b></span>
        </div>
        <div>
          <span>Fone: <b>{phoneNumber(values?.phoneNumber) || '-'}</b></span>
        </div>
        <div>
          {address(values?.address || {})}
        </div>
      </div>
      <div className="divider" />
      <div className="d-flex align-center mb-2">
        <div className="h6 text-bold">
          Detalhes da adoção
        </div>
        <a onClick={() => onChangeStep(3)} className="btn btn-link tooltip tooltip-right" data-tooltip="Editar">
          <i className="fas fa-edit" />
        </a>
      </div>
      <div className="mb-2">
        <div>
          <span>Adotado em: <b>{dateMask(values?.adoptedAt, { dateStyle: 'short' })}</b></span>
        </div>
        <div>
          <div>
            <small>Observações</small>
          </div>
          <div className="pre">{values?.description || '-'}</div>
        </div>
        <div>
          <div>
            <small>Termos</small>
          </div>
          <div className="pre">
            {values?.terms || '-'}
          </div>
        </div>
        <div>
          <div>
            <small>Anexo do termo de adoção</small>
          </div>
          <div>
            {values?.attachmentURL ? (
              <a href={values.attachmentURL} target="_blank" rel="noreferrer">
                <i className="fas fa-link" /> Exibir anexo
              </a>
            ) : '-'}
          </div>
        </div>
      </div>
    </div>
  )
}

ConfirmAdoption.propTypes = {
  values: PropTypes.objectOf(Object).isRequired,
  onChangeStep: PropTypes.func.isRequired,
}

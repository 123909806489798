import mask from 'slim-mask-js'

export default (value) => {
  if (!value) return ''
  const numbers = value.replace(/\D/g, '')
  if (numbers.length === 11) return mask(numbers, '(##) # ####-####')
  if (numbers.length === 10) return mask(numbers, '(##) ####-####')

  return value
}

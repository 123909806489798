import api from './api'

export default {
  list(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/animals', { params })
  },
  show(id) {
    return api.get(`/animals/${id}`)
  },
  create(body) {
    return api.post('/partner-licensings/:partnerLicensingId/animals', body)
  },
  createCastration(id, body) {
    return api.post(`/partner-licensings/:partnerLicensingId/animals/${id}/castrations`, body)
  },
  died(id, body) {
    return api.put(`/partner-licensings/:partnerLicensingId/animals/${id}/died`, body)
  },
  applyChip(id, body) {
    return api.post(`/partner-licensings/:partnerLicensingId/animals/${id}/apply-chip`, body)
  },
  partnerAnimals(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/animals/partner-animals', { params })
  },
}

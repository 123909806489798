import React from 'react'
import PropTypes from 'prop-types'
import { Card, Flexbox } from '@/components'
import { genderMap, sizeMap } from '@/constants/animal'
import dateMask from '@/lib/masks/date'

export default function AnimalCard({ data }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h6 card-title">Dados do pet</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-paw" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <small>
          <Flexbox.Columns>
            <Flexbox.Column>Nome</Flexbox.Column>
            <Flexbox.Column className="col-auto text-bold">{data?.animalName || '-'}</Flexbox.Column>
          </Flexbox.Columns>
          <Flexbox.Columns>
            <Flexbox.Column>Sexo</Flexbox.Column>
            <Flexbox.Column className="col-auto text-bold">
              {genderMap?.[data?.animalGender] || '-'}
            </Flexbox.Column>
          </Flexbox.Columns>
          <Flexbox.Columns>
            <Flexbox.Column>Porte</Flexbox.Column>
            <Flexbox.Column className="col-auto text-bold">
              {sizeMap?.[data?.animalSize] || '-'}
            </Flexbox.Column>
          </Flexbox.Columns>
          <Flexbox.Columns>
            <Flexbox.Column>Cor/Caract.</Flexbox.Column>
            <Flexbox.Column className="col-auto text-bold">
              {data?.animalColor || '-'}
            </Flexbox.Column>
          </Flexbox.Columns>
          <Flexbox.Columns>
            <Flexbox.Column>Nasc.</Flexbox.Column>
            <Flexbox.Column className="col-auto text-bold">
              {dateMask(data?.animalBornedAt, { dateStyle: 'short' }) || '-'}
            </Flexbox.Column>
          </Flexbox.Columns>
          <Flexbox.Columns>
            <Flexbox.Column>Espécie</Flexbox.Column>
            <Flexbox.Column className="col-auto text-bold">
              {data?.category?.description || '-'}
            </Flexbox.Column>
          </Flexbox.Columns>
          <Flexbox.Columns>
            <Flexbox.Column>Raça</Flexbox.Column>
            <Flexbox.Column className="col-auto text-bold">
              {data?.breed?.description || '-'}
            </Flexbox.Column>
          </Flexbox.Columns>
        </small>
      </Card.Body>
    </Card.Base>
  )
}

AnimalCard.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
}

import { Flexbox, Card } from '@/components'
import React from 'react'
import PropTypes from 'prop-types'
import Stars from './Stars'

function RatingCard({ rating }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Avaliação</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-star" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <Stars rate={rating?.rate} />
        {
          Boolean(rating?.description) && (
            <div className="mt-2">{rating?.description}</div>
          )
        }
      </Card.Body>
    </Card.Base>
  )
}

RatingCard.propTypes = {
  rating: PropTypes.objectOf(Object).isRequired,
}

export default RatingCard

import CommentForm from '@/components/Messages/CommentForm'
import PropTypes from 'prop-types'
import Row from '@/components/Messages/Row'
import React, {
  useEffect, useLayoutEffect, useRef, useState,
} from 'react'
import useInterval from '@/hooks/useInterval'
import { ListContainer } from '@/components/Messages/styles'

function Chat({
  fetchList, addMessage, providerId, readChat,
}) {
  const [lastLength, setLastLength] = useState(0)
  const [list, setList] = useState([])
  const endListRef = useRef()
  function handleCommentAdded(comment) {
    setList([...list, comment])
  }

  useInterval(fetchList, 5000)

  useEffect(() => {
    fetchList().then(({ data }) => {
      setList(data)
      setLastLength(data?.length)
    })
  }, [fetchList])

  useEffect(() => {
    readChat()
  }, [readChat])

  useLayoutEffect(() => {
    if (list.length > 0 && list.length !== lastLength) {
      endListRef.current?.scrollIntoView({ behavior: 'smooth' })
    }
  }, [lastLength, list])

  return (
    <>
      <ListContainer>
        {
          list.length === 0
          && (
            <div className="empty mb-2">
              <div className="empty-icon">
                <i className="fa-3x fas fa-envelope-open" />
              </div>
              <p className="empty-title h5">Chat vazio</p>
              <p className="empty-subtitle">Ainda não há mensagens no chat</p>
            </div>
          )
        }
        {
          list.map((comment) => (
            <div key={comment.id}>
              <Row
                key={comment?.id}
                comment={comment}
                providerId={providerId}
              />
            </div>
          ))
        }
        <span ref={endListRef} />
      </ListContainer>
      <CommentForm
        addMessage={addMessage}
        onCommentAdded={handleCommentAdded}
      />
    </>
  )
}

Chat.propTypes = {
  fetchList: PropTypes.func.isRequired,
  addMessage: PropTypes.func.isRequired,
  readChat: PropTypes.func.isRequired,
  providerId: PropTypes.number,
}

Chat.defaultProps = {
  providerId: null,
}

export default Chat

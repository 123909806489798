import { Avatar, Card, Flexbox } from '@/components'
import documentNumberMask from '@/lib/masks/documentNumber'
import phoneNumber from '@/lib/masks/phoneNumber'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

export default function PersonCard({ data }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h6 card-title">Dados do usuário bloqueado</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-user" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <small>
          <Flexbox.Columns>
            <Flexbox.Column>CPF/CNPJ</Flexbox.Column>
            <Flexbox.Column className="col-auto text-bold">
              {documentNumberMask(data?.documentNumber) || '-'}
            </Flexbox.Column>
          </Flexbox.Columns>
          <Flexbox.Columns>
            <Flexbox.Column>E-mail</Flexbox.Column>
            <Flexbox.Column className="col-auto text-bold">
              {data?.email || '-'}
            </Flexbox.Column>
          </Flexbox.Columns>
          <div className="divider text-center" data-content="Informações da conta" />
          {
            data.person
              ? (
                <>
                  <Flexbox.Columns className="align-center col-gapless">
                    <Flexbox.Column className="col-auto">
                      <Avatar photoURL={data.person.photoURL} name={data.person.fullName} />
                    </Flexbox.Column>
                    <Link to={`/app/petis-users/${data.person.id}`} className="text-dark">
                      <Flexbox.Column className="px-1" style={{ lineHeight: 1 }}>
                        <div className="text-bold">{data.person.fullName}</div>
                        <div><small>{data.person.email}</small></div>
                      </Flexbox.Column>
                    </Link>
                  </Flexbox.Columns>
                  <div className="divider" />
                  <Flexbox.Columns>
                    <Flexbox.Column>CPF/CNPJ</Flexbox.Column>
                    <Flexbox.Column className="col-auto text-bold">
                      {documentNumberMask(data.person.documentNumber) || '-'}
                    </Flexbox.Column>
                  </Flexbox.Columns>
                  <Flexbox.Columns>
                    <Flexbox.Column>Telefone</Flexbox.Column>
                    <Flexbox.Column className="col-auto text-bold">
                      {phoneNumber(data.person.phoneNumber) || '-'}
                    </Flexbox.Column>
                  </Flexbox.Columns>
                </>
              )
              : <div>Nenhuma conta vinculada ao E-mail ou CPF/CNPJ</div>
          }
        </small>
      </Card.Body>
    </Card.Base>
  )
}

PersonCard.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
}

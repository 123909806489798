import { AttachmentPreview, Flexbox, LoadManager } from '@/components'
import { clinicalCareStatus, clinicalCareStatusClasses } from '@/constants/clinicalCares'
import envs from '@/constants/envs'
import useClinicalCare from '@/hooks/useClinicalCare'
import usePageTitle from '@/hooks/usePageTitle'
import dateMask from '@/lib/masks/date'
import GoogleMapReact from 'google-map-react'
import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { MapContainer, MapMarker } from './styles'

function EventDetails({ title }) {
  usePageTitle({ title })
  const { eventIndex } = useParams()
  const { state: { clinicalCare } } = useClinicalCare()
  const history = useHistory()
  const event = clinicalCare?.events ? clinicalCare?.events[eventIndex] : null

  return (
    <div className="modal active" id="modal-id">
      <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
      <div className="modal-container">
        <div className="modal-header">
          <a
            type="button"
            className="btn btn-clear float-right"
            onClick={() => history.goBack()}
          />
          <div className="modal-title h5">Evento #{eventIndex + 1}</div>
        </div>
        <div className="modal-body">
          <div className="content">
            <LoadManager loading={!clinicalCare}>
              <>
                <p>
                  Data do evento:
                  <b> {dateMask(event?.createdAt, { dateStyle: 'full' })}</b>
                </p>
                <p>
                  Status do atendimento após o evento:
                  <b className={clinicalCareStatusClasses?.[clinicalCare.status]}>
                    &nbsp;{clinicalCareStatus[event?.status]}
                  </b>
                </p>
                <p>
                  Responsável pelo evento: <b>{event?.author?.fullName}</b>
                </p>
                {
                  Boolean(event?.description) && (
                    <>
                      <div className="divider" />
                      <small>Comentário do evento</small>
                      <div>
                        <cite className="pre">{event?.description}</cite>
                      </div>
                    </>
                  )
                }
                {
                  Boolean(event?.address?.location) && (
                    <>
                      <div className="divider" />
                      <h1 className="h5">Localização do evento</h1>
                      <MapContainer>
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: envs.REACT_APP_GOOGLE_MAPS_API_KEY }}
                          defaultCenter={{
                            lat: event?.address?.location?.coordinates[1],
                            lng: event?.address?.location?.coordinates[0],
                          }}
                          defaultZoom={18}
                          draggable={false}
                          options={{ fullscreenControl: null }}
                        >
                          <MapMarker
                            lat={event?.address?.location?.coordinates[1]}
                            lng={event?.address?.location?.coordinates[0]}
                          />
                        </GoogleMapReact>
                      </MapContainer>
                    </>
                  )
                }
                {
                  Boolean(event?.attachments?.length) && (
                    <>
                      <div className="divider" />
                      <h1 className="h5">Anexos do evento</h1>
                      <Flexbox.Columns>
                        {
                          (event.attachments || []).map((url) => (
                            <Flexbox.Column className="col-auto" key={url}>
                              <AttachmentPreview size={80} src={url} />
                            </Flexbox.Column>
                          ))
                        }
                      </Flexbox.Columns>
                    </>
                  )
                }
              </>
            </LoadManager>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventDetails

import React from 'react'
import { Card, Flexbox } from '@/components'
import PropTypes from 'prop-types'
import { LICENSING } from '@/lib/enums'
import { Link } from 'react-router-dom'
import { licensingStatusColors } from '@/constants/licensings'

function LicensingCard({ licensing }) {
  const routeMap = {
    castrator: 'clinics',
    protector: 'protectors',
    chipsApplyer: 'chips',
    ngo: 'ngos',
  }

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Licenças do usuário</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-certificate" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        {
          licensing?.length ? (
            <>
              <div className="divider" />
              <table className="table text-small table-striped table-hover">
                <thead>
                  <tr>
                    <th>Licença</th>
                    <th>Situação</th>
                    <th className="text-right" width="1">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    licensing.map((license) => (
                      <tr key={license?.id}>
                        <td>
                          {LICENSING.type.t(license?.type)}
                        </td>
                        <td className={licensingStatusColors[license?.status]}>
                          {LICENSING.status.t(license?.status)}
                        </td>
                        <td className="text-right">
                          <Link
                            to={`/app/${routeMap[license.type]}/${license.status}/${license?.id}`}
                            className="btn btn-action btn-sm btn-primary"
                          >
                            <i className="fas fa-eye" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            </>
          ) : null
        }
      </Card.Body>
    </Card.Base>
  )
}

LicensingCard.propTypes = {
  licensing: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default LicensingCard

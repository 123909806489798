import api from './api'

export default {
  signIn(body) {
    return api.post('/sessions/sign-in/local', body)
  },

  hasLocalAuthentication(login) {
    return api.post('/sessions/has-local-authentication', { login })
  },

  signOut() {
    return api.delete('/sessions/sign-out')
  },
}

import { Card, Flexbox } from '@/components'
import dateMask from '@/lib/masks/date'
import documentNumberMask from '@/lib/masks/documentNumber'
import phoneNumber from '@/lib/masks/phoneNumber'
import PropTypes from 'prop-types'
import React from 'react'
import { LogoGovBr } from './styles'

function GovBrCard({ personGovBr }) {
  return (
    <Card.Base>
      <Card.Header>
        <div className="d-flex align-center">
          <div className="h5">Dados do&nbsp;</div>
          <LogoGovBr alt="Logo do gov.br" />
        </div>
        <div>
          <small>&nbsp;(atualizado em {dateMask(personGovBr?.updatedAt, 'DD/MM/YYYY')})</small>
        </div>
      </Card.Header>
      <Card.Body>
        <div className="mb-1" />
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>E-mail</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {personGovBr?.email}
            {
              personGovBr?.email_verified
                ? <i className="fas fa-certificate text-success mt-1 ml-1 tooltip tooltip-left" data-tooltip="Verificado" />
                : <i className="fas fa-exclamation-circle text-error mt-1 ml-1 tooltip tooltip-left" data-tooltip="Não verificado" />
            }
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Fone</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {phoneNumber(personGovBr?.phone_number)}
            {
              personGovBr?.phone_number_verified
                ? <i className="fas fa-certificate text-success mt-1 ml-1 tooltip tooltip-left" data-tooltip="Verificado" />
                : <i className="fas fa-exclamation-circle text-error mt-1 ml-1 tooltip tooltip-left" data-tooltip="Não verificado" />
            }
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>CPF</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {documentNumberMask(personGovBr?.sub)}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Nome</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {personGovBr?.name}
          </Flexbox.Column>
        </Flexbox.Columns>
        <div className="divider text-center" data-content="Selos" />
        {
          (personGovBr?.selos ?? []).map((selo) => (
            <React.Fragment key={selo.id}>
              <Flexbox.Columns className="align-center mb-1">
                <Flexbox.Column className="col-auto">
                  <i className="fas fa-fw fa-lg fa-star" />
                </Flexbox.Column>
                <Flexbox.Column>
                  <div>{selo.descricao}</div>
                  <div><small>em {dateMask(selo.dataAtualizacao, 'DD/MM/YYYY')}</small></div>
                </Flexbox.Column>
              </Flexbox.Columns>
            </React.Fragment>
          ))
        }
        <div className="divider text-center" data-content="Níveis da conta" />
        {
          (personGovBr?.niveis ?? []).map((nivel) => (
            <React.Fragment key={nivel.id}>
              <Flexbox.Columns className="align-center mb-1">
                <Flexbox.Column className="col-auto">
                  <div className={`nivel-container flex-centered nivel-container-${nivel.id}`}>
                    {Number(nivel.id) === 1 && <i className="far fa-fw fa-star" />}
                    {Number(nivel.id) === 2 && <i className="fas fa-fw fa-star-half-alt" />}
                    {Number(nivel.id) === 3 && <i className="fas fa-fw fa-star" />}
                  </div>
                </Flexbox.Column>
                <Flexbox.Column>
                  <div>{nivel.descricao}</div>
                  <div><small>em {dateMask(nivel.dataAtualizacao, 'DD/MM/YYYY')}</small></div>
                </Flexbox.Column>
              </Flexbox.Columns>
            </React.Fragment>
          ))
        }
      </Card.Body>
    </Card.Base>
  )
}

GovBrCard.propTypes = {
  personGovBr: PropTypes.objectOf(Object).isRequired,
}

export default GovBrCard

import React from 'react'
import usePageTitle from '@/hooks/usePageTitle'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import { useParams } from 'react-router-dom'
import {
  AnimalCard, Card, Flexbox, LoadManager, Messages,
  PageHeader, Styles, UserCard,
} from '@/components'
import adoptionsRequested from '@/services/api/adoptionsRequested'
import formatAddress from '@/lib/formatters/address'
import FairCard from '../../../components/FairCard'
import Actions from './Actions'

function Show({ title }) {
  usePageTitle({ title })
  const { petId } = useParams()
  const {
    data, loading, error,
  } = useAxiosRequest(adoptionsRequested.show, petId)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Solicitação de adoção"
        icon="fas fa-hand-holding-heart"
        subtitle="Mais informações sobre a solicitação de adoção do pet"
      />
      <LoadManager loading={loading} error={error}>
        {
          Boolean(data) && (
            <>
              <Actions />
              <Flexbox.Columns>
                <Flexbox.Column className="col-5 col-lg-6 col-sm-12 my-2">
                  <UserCard
                    title="Doador"
                    user={data?.donor}
                    renderFooter={() => (
                      <Card.Footer>
                        {
                          Boolean(data?.donor?.address) && (
                            <>
                              <div className="divider" />
                              {formatAddress(data?.donor?.address)}
                            </>
                          )
                        }
                        <div className="divider" />
                      </Card.Footer>
                    )}
                  />
                </Flexbox.Column>
                <Flexbox.Column className="col-2 text-center hide-lg flex-centered">
                  <i className="fas fa-arrow-right fa-2x" />
                </Flexbox.Column>
                <Flexbox.Column className="col-5 col-lg-6 col-sm-12 my-2">
                  <UserCard
                    title="Solicitante"
                    user={data?.newTutor}
                    renderFooter={() => (
                      <Card.Footer>
                        {
                          Boolean(data?.newTutor?.address) && (
                            <>
                              <div className="divider" />
                              {formatAddress(data?.newTutor?.address)}
                            </>
                          )
                        }
                        <div className="divider" />
                      </Card.Footer>
                    )}
                  />
                </Flexbox.Column>
              </Flexbox.Columns>
              <div className="divider" />
              <Flexbox.Columns>
                <Flexbox.Column className="col-6 col-lg-12 my-2">
                  <AnimalCard animal={data?.animal} />
                </Flexbox.Column>
                <Flexbox.Column className="col-6 col-lg-12 my-2">
                  <FairCard fair={data?.fair} />
                </Flexbox.Column>
              </Flexbox.Columns>
              <Messages
                addMessage={(body) => adoptionsRequested.addMessage(
                  petId, data?.animalFair?.id, body,
                  )}
                tutorId={data?.donorId}
                providerId={data?.partner?.id}
                fetchList={() => adoptionsRequested.messages(petId, data?.animalFair?.id)}
                readChat={() => adoptionsRequested.readChat(petId, data?.animalFair?.id)}
              />
            </>
          )
        }
      </LoadManager>
    </Styles.Container>
  )
}

export default Show

import { Card, Flexbox } from '@/components'
import PropTypes from 'prop-types'
import React from 'react'

export default function DetailsCard({ data }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h6 card-title">Informações do bloqueio</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-file" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <small>
          <div className="mb-2">
            <small>Informações (aparece apenas aqui)</small>
            <div className="pre">{data.info || '-'}</div>
          </div>
          <div className="mb-2">
            <small>Motivo do bloqueio (aparece para o usuário)</small>
            <div className="pre">{data.description || '-'}</div>
          </div>
        </small>
      </Card.Body>
    </Card.Base>
  )
}

DetailsCard.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
}

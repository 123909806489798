import logo from '@/assets/img/logo.png'
import styled from 'styled-components'

export const AppContainer = styled.div.attrs(() => ({
  className: 'bg-light p-relative flex-centered',
}))`
  height: 100vh;
`

export const Overflow = styled.div.attrs(() => ({
  className: 'bg-primary p-absolute',
}))`
  top: 0;
  width: 100%;
  height: 50%;
  z-index: -1;
`

export const Logo = styled.img.attrs({
  src: logo,
  className: 'img-responsive',
})`
  max-width: 40px;
`

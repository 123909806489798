import api from './api'

export default {
  list(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/manual-adoptions', { params })
  },
  create(body) {
    return api.post('/partner-licensings/:partnerLicensingId/manual-adoptions', body)
  },
  show(id) {
    return api.get(`/partner-licensings/:partnerLicensingId/manual-adoptions/${id}`)
  },
  destroy(id) {
    return api.delete(`/partner-licensings/:partnerLicensingId/manual-adoptions/${id}`)
  },
}

import styled from 'styled-components'

export const Point = styled.div`
  border-radius: 50% 50% 50% 0;
  background-color: #e74c3c;
  border: 4px solid #c0392b;
  width: 20px;
  height: 20px;
  transform: rotate(-45deg);
`

import api from './api'

export default {
  current() {
    return api.get('/people/current')
  },
  partner() {
    return api.get('/partner-licensings/:partnerLicensingId/people/partner')
  },
  findByDocumentNumber(documentNumber) {
    return api.get(`/people/document-number/${documentNumber}`)
  },
}

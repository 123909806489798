import api from './api'

const BASE = '/partner-licensings/:partnerLicensingId/castrations'

export default {
  countStatus() {
    return api.get(`${BASE}/count-status`)
  },

  list(data = {}) {
    const { status, ...params } = data
    return api.get(`${BASE}/${status}/list`, { params })
  },

  allowedLicensings(id) {
    return api.get(`${BASE}/${id}/allowed-licensings`)
  },

  show(id) {
    return api.get(`${BASE}/${id}`)
  },

  approve(id, form) {
    return api.post(`${BASE}/${id}/approve`, form)
  },

  changeClinic(id, form) {
    return api.post(`${BASE}/${id}/change-castration-clinic`, form)
  },

  reject(id, body) {
    return api.post(`${BASE}/${id}/reject`, body)
  },

  cancel(id, body) {
    return api.post(`${BASE}/${id}/cancel`, body)
  },

  confirm(id, body) {
    return api.post(`${BASE}/${id}/confirm`, body)
  },

  correction(id, body) {
    return api.post(`${BASE}/${id}/correction`, body)
  },

  revision(id, body) {
    return api.post(`${BASE}/${id}/revision`, body)
  },

  messages(id) {
    return api.get(`${BASE}/${id}/messages`)
  },

  addMessage(id, body) {
    return api.post(`${BASE}/${id}/messages`, body)
  },
}

import { useContext } from 'react'
import clinicContext from '@/contexts/clinicContext'

/**
 * @typedef {Object} ClinicReducer
 * @property {Object} state - State of clinic
 * @property {Function} dispatch - dispatch
 */

/**
 * @returns {ClinicReducer}
 */
 export default function useClinic() {
  return useContext(clinicContext)
}

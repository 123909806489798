import { Card, Flexbox } from '@/components'
import PropTypes from 'prop-types'
import React from 'react'
import {
  Bar,
  BarChart,
  CartesianGrid, ResponsiveContainer, Tooltip, XAxis, YAxis,
} from 'recharts'
import mapStatus from './mapStatus'

function CastrationsChart({ counts }) {
  const data = counts.map(mapStatus).filter((i) => i.Quantidade)

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Castrações por status</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-cut" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <ResponsiveContainer width="100%" height={300}>
          <BarChart data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis fontSize={10} lang="pt-BR" dataKey="name" />
            <YAxis />
            <Tooltip cursor={{ fill: 'transparent' }} />
            <Bar maxBarSize={100} label="name" dataKey="Quantidade" />
          </BarChart>
        </ResponsiveContainer>
      </Card.Body>
    </Card.Base>

  )
}

CastrationsChart.propTypes = {
  counts: PropTypes.arrayOf(Object).isRequired,
}

export default CastrationsChart

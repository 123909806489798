import PropTypes from 'prop-types'
import React from 'react'
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch'
import { CloseContainer, Container, ImageContainer } from './styles'

function ImageViewer({ src, onClose }) {
  return (
    <Container aria-hidden="true">
      <CloseContainer onClick={() => onClose()}>
        <i className="fas fa-times fa-lg" />
      </CloseContainer>
      <TransformWrapper
        initialScale={1}
      >
        {
          () => (
            <ImageContainer>
              <TransformComponent>
                <img style={{ maxHeight: '95vh' }} className="s-rounded" src={src} alt="" />
              </TransformComponent>
            </ImageContainer>
          )
        }
      </TransformWrapper>
    </Container>
  )
}

ImageViewer.propTypes = {
  src: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default ImageViewer

import {
  Filter,
  Flexbox,
  Form,
  ListManager,
  Mask,
  PageHeader,
  Paginator,
  Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import dateMask from '@/lib/masks/date'
import documentNumberMask from '@/lib/masks/documentNumber'
import manualAdoptions from '@/services/api/manualAdoptions'
import React from 'react'
import { Link } from 'react-router-dom'

function ManualAdoptions({ title }) {
  const query = useQuery()
  usePageTitle({ title })

  const {
    loading, data, error,
  } = useAxiosRequest(manualAdoptions.list, query)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Adoções manuais"
        icon="fas fa-dog"
        subtitle="Lista com as adoções que não foram feitas pelo aplicativo Petis"
        rightAction={() => (
          <>
            <Filter
              initialValues={{
                fullName_cont: '', animalName_cont: '', documentNumber_eq: '',
              }}
              title="Filtrar adoções"
              renderForm={({ setFieldValue }) => (
                <>
                  <div className="divider text-center" data-content="Tutor" />
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="fullName_cont">Nome do tutor</Form.Label>
                    <Form.ValidationField
                      name="fullName_cont"
                      className="input-sm"
                      autoFocus
                      id="fullName_cont"
                      autoComplete="none"
                      placeholder="Fulano da Silva"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="documentNumber_eq">Documento do tutor</Form.Label>
                    <Form.ValidationField name="documentNumber_eq">
                      {
                        ({ field }) => (
                          <Mask.CpfCnpj
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            className="input-sm"
                            placeholder="CPF/CNPJ do tutor"
                            id="documentNumber_eq"
                            onAccept={(value) => {
                              setFieldValue('documentNumber_eq', value)
                            }}
                          />
                        )
                      }
                    </Form.ValidationField>
                  </Form.Group>
                  <div className="divider text-center" data-content="Animal" />
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="animalName_cont">Nome do animal</Form.Label>
                    <Form.ValidationField
                      name="animalName_cont"
                      className="input-sm"
                      id="animalName_cont"
                      autoComplete="none"
                      placeholder="Totó da Silva"
                    />
                  </Form.Group>
                </>
              )}
            />
            <Link className="btn btn-primary ml-2" to="/app/manual-adoptions/form/create">
              <i className="fas fa-plus" />
              &nbsp;Adicionar
            </Link>
          </>
        )}
      />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        {
          Boolean(data) && (
            <>
              <table className="table text-small table-striped table-hover">
                <thead>
                  <tr>
                    <th width="200px">Pet</th>
                    <th>Tutor</th>
                    <th width="150px">Data da adoção</th>
                    <th className="text-right" width="1">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.rows.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <Flexbox.Columns className="align-center col-gapless">
                            <Flexbox.Column className="ml-1">
                              {item.animalName || '-'}
                            </Flexbox.Column>
                          </Flexbox.Columns>
                        </td>
                        <td>
                          {documentNumberMask(item.documentNumber)} - {item.fullName}
                        </td>
                        <td>
                          {dateMask(item.adoptedAt, { dateStyle: 'short' })}
                        </td>
                        <td className="text-right">
                          <Link
                            to={`/app/manual-adoptions/${item?.id}`}
                            className="btn btn-action btn-sm btn-primary"
                          >
                            <i className="fas fa-eye" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              {
                Boolean(data) && (
                  <div className="float-right my-2">
                    <Paginator paginatedList={data} />
                  </div>
                )
              }
            </>
          )
        }
      </ListManager>
    </Styles.Container>
  )
}

export default ManualAdoptions

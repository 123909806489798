import {
 Avatar, ListManager, Paginator, Styles,
} from '@/components'
import useAdoptionFair from '@/hooks/useAdoptionFair'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import adoptionFairs from '@/services/api/adoptionFairs'
import React, { useEffect } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import AdoptionRequestedQuickFilter from './QuickFilter'

function AdoptionsRequested({ title }) {
  usePageTitle({ title })
  const location = useLocation()
  const { id } = useParams()
  const query = useQuery()
  const {
    loading, data, error, fetchData,
  } = useAxiosRequest(adoptionFairs.adoptionsRequested, id, query)
  const { state: { adoptionFair } } = useAdoptionFair()

  useEffect(() => {
    if (location) fetchData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  if (!adoptionFair) return null

  return (
    <Styles.Container className="grid-xl no-print">
      <AdoptionRequestedQuickFilter />
      <ListManager
        emptyMessage="Nenhuma solicitação de adoção na feira"
        emptyIcon="fas fa-hand-holding-heart"
        emptySubtitle="As solicitações de adoções vão aparecer aqui"
        loading={loading}
        count={data?.count || 0}
        error={error}
      >
        <>
          <table className="table text-small table-striped table-hover">
            <thead>
              <tr>
                <th>Pet</th>
                <th>Doador</th>
                <th>Solicitante</th>
                <th className="text-right" width={100}>Ações</th>
              </tr>
            </thead>
            <tbody>
              {
                data && data.rows.map((adoptionsRequested) => (
                  <tr key={adoptionsRequested.id}>
                    <td>
                      <Link to={`/app/pets/${adoptionsRequested?.animalId}`}>
                        <Avatar
                          avatarSize="sm"
                          className="mr-1"
                          name={adoptionsRequested?.animal?.name}
                          photoURL={adoptionsRequested?.animal?.photoURL}
                          alt="Imagem do pet"
                        />
                        {adoptionsRequested?.animal?.name}
                      </Link>
                    </td>
                    <td>
                      <Avatar
                        avatarSize="sm"
                        className="mr-1"
                        name={adoptionsRequested?.donor?.fullName}
                        photoURL={adoptionsRequested?.donor?.photoURL}
                        alt="Imagem de usuário"
                      />
                      {adoptionsRequested?.donor?.fullName}
                    </td>
                    <td>
                      <Avatar
                        avatarSize="sm"
                        className="mr-1"
                        name={adoptionsRequested?.newTutor?.fullName}
                        photoURL={adoptionsRequested?.newTutor?.photoURL}
                        alt="Imagem de usuário"
                      />
                      {adoptionsRequested?.newTutor?.fullName}
                    </td>
                    <td>
                      <div className="d-flex align-center" style={{ justifyContent: 'flex-end' }}>
                        <Link
                          to={`adoptions-requested/${adoptionsRequested?.id}/approve`}
                          data-tooltip="Aprovar adoção"
                          className="btn btn-action btn-sm btn-success mr-1 tooltip tooltip-top"
                        >
                          <i className="fas fa-check" />
                        </Link>
                        <Link
                          to={`adoptions-requested/${adoptionsRequested?.id}/reject`}
                          className="btn btn-action btn-sm btn-error mr-1 tooltip tooltip-top"
                          data-tooltip="Rejeitar adoção"
                        >
                          <i className="fas fa-ban" />
                        </Link>
                        <Link
                          to={`/app/adoption-requested/${adoptionsRequested?.id}`}
                          data-tooltip="Exibir"
                          className="btn btn-action btn-sm btn-primary tooltip tooltip-top"
                        >
                          <i className="fas fa-eye" />
                        </Link>
                      </div>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          {
            Boolean(data) && (
              <div className="float-right my-2">
                <Paginator paginatedList={data} />
              </div>
            )
          }
        </>
      </ListManager>
    </Styles.Container>
  )
}

export default AdoptionsRequested

import { Flexbox } from '@/components'
import { SCOPE, TYPE } from '@/constants/transaction'
import dateMask from '@/lib/masks/date'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

function TransactionRow({ transaction }) {
  const mapIdentifier = {
    icon: transaction?.amount > 0 ? 'fas fa-angle-double-up' : 'fas fa-angle-double-down',
    color: transaction?.amount > 0 ? 'text-success' : 'text-error',
    typeColor: {
      balance_maintenance: 'text-dark',
      service_charge: 'text-error',
      service_refund: 'text-warning',
    },
  }

  return (
    <>
      <Flexbox.Columns>
        {
          Boolean(transaction?.description) && (
            <Flexbox.Column className="col-12">
              <small>{transaction.description}</small>
            </Flexbox.Column>
          )
        }
        <Flexbox.Column className="col-auto">
          <div>
            <small className={mapIdentifier.color}>
              <b>
                <i className={mapIdentifier.icon} />
                &nbsp;{transaction?.amount > 0 && '+'}{transaction?.amount}
              </b>
            </small>
            <div>
              <small>Cód: <b>#{transaction?.id}</b></small>
            </div>
          </div>
        </Flexbox.Column>
        <Flexbox.Column className="col-4 col-md-6 text-ellipsis">
          <div>
            <small className="text-ellipsis">Autor: <b>{transaction?.author?.fullName || 'Sem registro de autor'}</b></small>
          </div>
          <div>
            <small>Data: <b>{dateMask(transaction?.createdAt, { minute: '2-digit', hour: '2-digit' })}</b></small>
          </div>
        </Flexbox.Column>
        <Flexbox.Column>
          <div>
            <small>Tipo:
              <b className={mapIdentifier.typeColor[transaction?.type]}>
                &nbsp;{TYPE[transaction?.type]}
              </b>
            </small>
          </div>
          <div>
            <small>
              {transaction?.transactionalId ? 'Referência:' : 'Serviço'}
              <b>
                &nbsp;{SCOPE[transaction?.transactionalType]} {transaction?.transactionalId ? `#${transaction?.transactionalId}` : ''}
                {
                  Boolean(transaction?.transactionalId) && (
                    <Link
                      to={`/app/castrations/status/all/${transaction?.transactionalId}`}
                      className="tooltip"
                      data-tooltip="Abrir serviço"
                    >
                      &nbsp;<i className="fas fa-eye" />
                    </Link>
                  )
                }
              </b>
            </small>
          </div>
        </Flexbox.Column>
      </Flexbox.Columns>
      <div className="divider" />
    </>
  )
}

TransactionRow.propTypes = {
  transaction: PropTypes.objectOf(Object).isRequired,
}

export default TransactionRow

import styled from 'styled-components'
import { Link as ReactRouterLink } from 'react-router-dom'
import logo from '@/assets/img/logo.png'

export const Container = styled.div.attrs({
  className: 'container',
})``

export const Divider = styled.div.attrs(({ className, dataContent }) => ({
  className: `divider text-center ${className}`,
  'data-content': dataContent,
}))``

export const Toast = styled.div.attrs(({ className }) => ({
  className: `toast ${className}`,
}))``

export const Loading = styled.div.attrs(({ className }) => ({
  className: `loading ${className}`,
}))``

export const ActionsContainer = styled.div`
  position: sticky;
  top: 65px;
  z-index: 9;
  padding: 10px 0;
  background-color: #fff;
  border-bottom: 0.05rem solid #f1f3f5;
  display: flex;
`

export const Logo = styled.img.attrs({
  src: logo,
})`
  width: 15px;
  margin-right: .2rem;
`

// -- Clickables --
export const Button = styled.button.attrs(({ className }) => ({
  className: `btn ${className}`,
}))``

export const A = styled.a.attrs(({ className }) => ({
  className: `btn ${className}`,
}))``

export const Link = styled(ReactRouterLink).attrs(({ className }) => ({
  className: `btn ${className}`,
}))``
// -- End Clickables --

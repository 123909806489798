import { Form, LoadManager } from '@/components'
import useCastration from '@/hooks/useCastration'
import usePageTitle from '@/hooks/usePageTitle'
import yup from '@/lib/yupPt'
import { setCastration } from '@/reducers/castration'
import castrations from '@/services/api/castrations'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  description: yup.string().trim().required().min(10),
})
function Revision({ title }) {
  usePageTitle({ title })
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const { state: { castration }, dispatch } = useCastration()
  const history = useHistory()

  async function revision({ description }) {
    try {
      setLoading(true)
      const { data } = await castrations.revision(id, { eventData: { description } })
      dispatch(setCastration(data))
      toast.success('Castração eviada para revisão')
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <fieldset disabled={loading}>
      <Form.Formik
        initialValues={{ description: '' }}
        validationSchema={schema}
        onSubmit={revision}
        validateOnMount
      >
        {
          ({
            errors, touched, isValid,
          }) => (
            <Form.ValidationForm>
              <div className="modal active" id="modal-id">
                <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
                <div className="modal-container">
                  <div className="modal-header">
                    <a
                      type="button"
                      className="btn btn-clear float-right"
                      onClick={() => history.goBack()}
                    />
                    <div className="modal-title h5">Enviar para revisão a castração #{id}</div>
                  </div>
                  <div className="modal-body">
                    <div className="content">
                      <LoadManager loading={!castration}>
                        <>
                          <p>Confirma o envio da castração de <b className="text-primary">{castration?.animal?.name}</b> para revisão?</p>
                          <p className="text-small">
                            Esta ação vai gerar um evento de revisão e avisará ao usuário o motivo.
                          </p>
                          <p className="text-small">
                            Utilize esta funcionalidade
                            <b> apenas quando tiver dúvidas da veracidade da solicitação</b>.
                          </p>
                          <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="description">Descreva o que deverá ser revisado <span className="text-error">*</span></Form.Label>
                            <Form.ValidationField
                              name="description"
                              component="textarea"
                              id="description"
                              placeholder="A castração deve ser revisada porque ..."
                            />
                            {
                              touched?.description && errors?.description
                                ? <Form.Hint className="text-error">{errors.description}</Form.Hint>
                                : null
                            }
                          </Form.Group>
                        </>
                      </LoadManager>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <a
                      className="btn mr-2"
                      disabled={loading}
                      onClick={() => history.goBack()}
                    >
                      <i className="fas fa-chevron-left" />
                      &nbsp;Voltar
                    </a>
                    <button
                      className={`btn btn-primary ${loading ? 'loading' : ''}`}
                      type="submit"
                      disabled={!isValid}
                    >
                      <i className="fas fa-question" />
                      &nbsp;Enviar para revisão
                    </button>
                  </div>
                </div>
              </div>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </fieldset>
  )
}

export default Revision

import {
  Avatar, Card, Flexbox, ImageThumb,
} from '@/components'
import dateMask from '@/lib/masks/date'
import PropTypes from 'prop-types'
import React from 'react'
import MapLocation from './MapLocation'

// import { Container } from './styles';

function CardDetails({ mistreatment }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Detalhes da denúncia</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-bullhorn" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <div>{mistreatment.description}</div>
        <div className="divider" />
        <div className="mt-2">
          <div><small>Endereço da denúncia:</small></div>
          <div>{mistreatment?.address?.formattedAddress ?? '-'}</div>
          {Boolean(mistreatment?.address?.reference) && (
            <div>Referência: {mistreatment?.address?.reference}</div>
          )}
          {Boolean(mistreatment?.address?.location)
            && <MapLocation location={mistreatment?.address?.location} />}
        </div>
        {
          Boolean(mistreatment?.images?.length) && (
            <>
              <div className="divider" />
              <div className="mt-2"><small>Imagens da denúncia:</small></div>
              <Flexbox.Columns>
                {
                  (mistreatment.images ?? []).map((url) => (
                    <Flexbox.Column key={url} className="col-auto">
                      <a href={url} target="_blank" rel="noreferrer">
                        <ImageThumb className="s-rounded" height={50} width={50} src={url} />
                      </a>
                    </Flexbox.Column>
                  ))
                }
              </Flexbox.Columns>
            </>
          )
        }
        {
          mistreatment?.comments?.length > 0 && (
            <>
              <div className="divider" />
              <div className="mt-2"><small>Comentários:</small></div>
              {
                mistreatment.comments.map((comment) => (
                  <div key={comment.id} className="tile bg-gray p-2 mt-2 s-rounded">
                    <div className="tile-icon">
                      <Avatar name={comment.author.fullName} photoURL={comment.author.photoURL} />
                    </div>
                    <div className="tile-content">
                      <div className="tile-title text-primary">{comment.author.fullName}</div>
                      <p className="tile-subtitle pre">
                        {comment.description}
                      </p>
                      <div><small>{dateMask(comment.createdAt)}</small></div>
                    </div>
                  </div>
                ))
              }
            </>
          )
        }
      </Card.Body>
    </Card.Base>
  )
}

CardDetails.propTypes = {
  mistreatment: PropTypes.objectOf(Object).isRequired,
}

export default CardDetails

import {
  Flexbox, Form,
} from '@/components'
import useQuery from '@/hooks/useQuery'
import yup from '@/lib/yupPt'
import clinicalCareItemClinics from '@/services/api/clinicalCareItemClinics'
import useModalAction from '@/views/app/AdoptionFairs/Show/components/Actions/useModalAction'
import PropTypes from 'prop-types'
import React, { useCallback, useLayoutEffect } from 'react'
import CurrencyFormat from 'react-currency-format'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  price: yup.number().min(0).required(),
  balance: yup.number().min(0).integer().required(),
})
function Update({ clinicItem, onUpdated }) {
  const {
    active, close, loading, setLoading, open,
  } = useModalAction()

  const { id } = useQuery()

  useLayoutEffect(() => {
    if (Number(id) === clinicItem.id) open()
  }, [clinicItem.id, id, open])

  const onConfirm = useCallback(async (values) => {
    try {
      setLoading(true)
      const res = await clinicalCareItemClinics.update(
        clinicItem.clinicId, { id: clinicItem.id, ...values },
      )
      onUpdated(res.data)
      close()
      toast.success(`${res.data?.clinicalCareItemProvider?.title} alterado com sucesso`)
    } catch (e) {
      toast.error(e.message)
    } finally {
      setLoading(false)
    }
  }, [clinicItem.clinicId, clinicItem.id, close, onUpdated, setLoading])

  return (
    <>
      <button
        data-tooltip="Editar"
        onClick={open}
        type="button"
        className="btn btn-primary btn-sm btn-action tooltip tooltip-left"
      >
        <i className="fas fa-edit" />
      </button>

      <Form.Formik
        initialValues={{ price: clinicItem.price, balance: clinicItem.balance }}
        validationSchema={schema}
        validateOnMount
        onSubmit={onConfirm}
      >
        {
          ({
            touched, errors, setFieldValue, isValid,
          }) => (
            <Form.ValidationForm className="my-2">
              <div className={`modal text-left ${active ? 'active' : ''}`}>
                <a type="button" className="modal-overlay" aria-label="Close" onClick={close} />
                <div className="modal-container">
                  <div className="modal-header">
                    <a
                      type="button"
                      className="btn btn-clear float-right"
                      onClick={close}
                    />
                    <div className="modal-title h5">
                      Editar item clínico
                    </div>
                  </div>
                  <div className="modal-body">
                    <div className="bg-gray p-2 s-rounded">
                      <Flexbox.Columns className="align-center">
                        <Flexbox.Column className="col-auto">
                          <i className="fas fa-star-of-life fa-2x" />
                        </Flexbox.Column>
                        <Flexbox.Column>
                          <div><b>{clinicItem?.clinicalCareItemProvider.title}</b></div>
                          <div>{clinicItem?.clinicalCareItemProvider.description}</div>
                        </Flexbox.Column>
                      </Flexbox.Columns>
                    </div>
                    <Flexbox.Columns>
                      <Flexbox.Column className="col-6">
                        <Form.Group className={`${touched?.price && errors?.price ? 'has-error' : ''}`}>
                          <Form.Label htmlFor="price">Preço unitário</Form.Label>
                          <Form.ValidationField
                            name="price"
                            id="price"
                          >
                            {
                              ({ field }) => (
                                <CurrencyFormat
                                  className="form-input"
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  prefix="R$ "
                                  placeholder="R$ 0,00"
                                  value={field.value ? field.value / 100 : ''}
                                  fixedDecimalScale
                                  decimalScale={2}
                                  allowNegative={false}
                                  onValueChange={
                                    ({ value }) => setFieldValue('price', Number(value) * 100)
                                  }
                                />
                              )
                            }
                          </Form.ValidationField>
                          {
                            touched?.price && errors?.price
                              ? <Form.Hint className="text-error">{errors.price}</Form.Hint> : null
                          }
                        </Form.Group>
                      </Flexbox.Column>
                      <Flexbox.Column className="col-6">
                        <Form.Group className={`${touched?.balance && errors?.balance ? 'has-error' : ''}`}>
                          <Form.Label htmlFor="balance">Quantidade <span className="text-error">*</span></Form.Label>
                          <Form.ValidationField
                            type="number"
                            placeholder="Quantidade disponível"
                            name="balance"
                            id="balance"
                          />
                          {
                            touched?.balance && errors?.balance
                              ? <Form.Hint className="text-error">{errors.balance}</Form.Hint> : null
                          }
                        </Form.Group>
                      </Flexbox.Column>
                    </Flexbox.Columns>
                  </div>
                  <div className="modal-footer text-right">
                    <button disabled={loading || !isValid} className={`btn btn-primary ${loading ? 'loading' : ''}`} type="submit">
                      <i className="fas fa-check" />
                      &nbsp;Confirmar
                    </button>
                  </div>
                </div>
              </div>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </>
  )
}

Update.propTypes = {
  onUpdated: PropTypes.func.isRequired,
  clinicItem: PropTypes.objectOf(Object).isRequired,
}

export default Update

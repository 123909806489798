export const INITIAL_STATE = { mistreatment: null }

export default function mistreatmentReducer(state, action) {
  switch (action.type) {
    case ('setMistreatment'): return { mistreatment: action.payload }
    default: return INITIAL_STATE
  }
}

export function setMistreatment(payload) {
  return { type: 'setMistreatment', payload }
}

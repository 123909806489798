/* eslint-disable jsx-a11y/anchor-is-valid */
import PropTypes from 'prop-types'
import React from 'react'

function ManualPaginator({ paginatedList, setPage, limitPages }) {
  const inFirstPage = paginatedList?.page <= 1
  const inLastPage = paginatedList?.page >= paginatedList?.pages

  if (paginatedList?.pages <= 1) return null

  return (
    <ul className="pagination">
      <li className={`page-item ${inFirstPage ? 'disabled' : ''}`}>
        <a className="c-hand" onClick={() => setPage(paginatedList?.page - 1)}>
          <i className="fas fa-chevron-left" />
        </a>
      </li>
      <li className={`page-item ${inFirstPage ? 'active' : ''}`}>
        <a className="c-hand" onClick={() => setPage(1)}>
          1
        </a>
      </li>

      {
        paginatedList?.page - limitPages > 1
          ? <li className="page-item"><span>...</span></li>
          : null
      }

      {
        Array(paginatedList?.pages).fill(null).map((_, index) => {
          const page = index + 1
          if (page === 1 || page === paginatedList?.pages) return null
          if (page - paginatedList?.page > limitPages) return null
          if (paginatedList?.page - page > limitPages) return null
          return (
            <li key={`paginator-${page}`} className={`page-item ${page === paginatedList?.page ? 'active' : ''}`}>
              <a className="c-hand" onClick={() => setPage(page)}>
                {page}
              </a>
            </li>
          )
        })
      }

      {
        paginatedList?.pages - paginatedList?.page > limitPages
          ? <li className="page-item"><span>...</span></li>
          : null
      }

      <li className={`page-item ${inLastPage ? 'active' : ''}`}>
        <a className="c-hand" onClick={() => setPage(paginatedList?.pages)}>
          {paginatedList?.pages}
        </a>
      </li>

      <li className={`page-item ${inLastPage ? 'disabled' : ''}`}>
        <a className="c-hand" onClick={() => setPage(paginatedList?.page + 1)}>
          <i className="fas fa-chevron-right" />
        </a>
      </li>
    </ul>
  )
}

ManualPaginator.propTypes = {
  paginatedList: PropTypes.objectOf(PropTypes.any).isRequired,
  setPage: PropTypes.func.isRequired,
  limitPages: PropTypes.number,
}

ManualPaginator.defaultProps = {
  limitPages: 3,
}

export default ManualPaginator

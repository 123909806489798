import {
  Flexbox,
  Form, LoadManager, PageHeader, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import params from '@/services/api/params'
import React, { useCallback, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import DocumentFormAdd from '../components/DocumentFormAdd'
import DocumentRow from '../components/DocumentRow'

function Ngos({ title }) {
  usePageTitle({ title })

  const [updating, setUpdating] = useState(false)
  const {
    loading, data, setData, error,
  } = useAxiosRequest(params.list)

  const documents = useMemo(() => (
    data?.licensingDocuments?.ngo || []
  ), [data?.licensingDocuments?.ngo])

  const onAdded = useCallback(async (document) => {
    try {
      setUpdating(true)
      const ngo = [...documents, { ...document, id: Date.now() }]
      const body = {
        ...data,
        licensingDocuments: { ...(data.licensingDocuments || {}), ngo },
      }

      await params.update(body)
      setData(body)

      toast.success('Documento adicionado com sucesso')
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setUpdating(false)
    }
  }, [data, documents, setData])

  const onUpdated = useCallback(async (document) => {
    try {
      setUpdating(true)
      const ngo = documents.map((i) => (i.id === document.id ? document : i))
      const body = {
        ...data,
        licensingDocuments: { ...(data.licensingDocuments || {}), ngo },
      }

      await params.update(body)
      setData(body)

      toast.success('Documento atualizado com sucesso')
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setUpdating(false)
    }
  }, [data, documents, setData])

  const onRemoved = useCallback(async (document) => {
    try {
      setUpdating(true)
      const ngo = documents.filter((i) => i.id !== document.id)
      const body = {
        ...data,
        licensingDocuments: { ...(data.licensingDocuments || {}), ngo },
      }
      await params.update(body)
      setData(body)

      toast.success('Documento removido com sucesso')
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setUpdating(false)
    }
  }, [data, documents, setData])

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Parâmetros para ONG's"
        icon="fas fa-building"
        subtitle="Definir os parâmetros para as ONG's"
      />
      <LoadManager loading={loading || updating} error={error}>
        <Flexbox.Columns>
          <Flexbox.Column className="col-8 col-sm-12">
            <Form.Group>
              <Form.Label>
                Documentos exigidos para os protetores solicitarem credenciamento
              </Form.Label>
              {
                documents.length
                  ? (
                    documents.map((document) => (
                      <DocumentRow
                        key={document.id}
                        document={document}
                        onUpdated={onUpdated}
                        onRemoved={onRemoved}
                      />
                    ))
                  )
                  : <small><cite>Nenhum documento é exigido</cite></small>
              }
            </Form.Group>
          </Flexbox.Column>
        </Flexbox.Columns>
        <DocumentFormAdd onAdded={onAdded} />
      </LoadManager>
    </Styles.Container>
  )
}

export default Ngos

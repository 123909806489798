import { LoadManager } from '@/components'
import useClinic from '@/hooks/useClinic'
import { setClinic } from '@/reducers/clinic'
import clinics from '@/services/api/clinics'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

function Approve() {
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const { state: { clinic }, dispatch } = useClinic()
  const history = useHistory()

  async function approve() {
    try {
      setLoading(true)
      const { data } = await clinics.approve(id)
      dispatch(setClinic(data))
      toast.success('Credenciamento da clínica aprovado')
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="modal active" id="modal-id">
      <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
      <div className="modal-container">
        <div className="modal-header">
          <a
            type="button"
            className="btn btn-clear float-right"
            onClick={() => history.goBack()}
          />
          <div className="modal-title h5">Aprovar a adesão #{id}</div>
        </div>
        <div className="modal-body">
          <div className="content">
            <LoadManager loading={!clinic}>
              <>
                <p>Confirma a adesão para a clínica <b className="text-primary">{clinic?.licensed?.fullName}</b>?</p>
                <p className="text-small">
                  Esta ação vai credenciar a clínica para efetuar castrações.
                </p>
              </>
            </LoadManager>
          </div>
        </div>
        <div className="modal-footer">
          <a
            className="btn btn-primary mr-2"
            disabled={loading}
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left" />
            &nbsp;Voltar
          </a>
          <button
            className={`btn btn-success ${loading ? 'loading' : ''}`}
            disabled={loading}
            type="button"
            onClick={approve}
          >
            <i className="fas fa-check" />
            &nbsp;Aprovar
          </button>
        </div>
      </div>
    </div>
  )
}

export default Approve

import { Avatar, Flexbox } from '@/components'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import rga from '@/lib/masks/rga'
import { Cluster, Point } from './styles'

function Marker({ cluster, points, mapRef }) {
  const [openPopover, setOpenPopover] = useState(false)
  const [longitude, latitude] = cluster.geometry.coordinates
  const { cluster: isCluster, count } = cluster.properties

  const clusterSize = 10 + (count / points.length) / 5

  if (isCluster) {
    return (
      <Cluster
        style={{
          width: `${clusterSize}px`,
          height: `${clusterSize}px`,
        }}
        onClick={() => {
          try {
            mapRef.current.setZoom(mapRef.current?.getZoom() + 1)
            mapRef.current.panTo({ lat: latitude, lng: longitude })
          } catch (e) {
            // eslint-disable-next-line no-console
            console.log('Cluster fail')
          }
        }}
      >
        {count}
      </Cluster>
    )
  }

  const { properties } = cluster

  return (
    <div
      className={`popover popover-top popover-only-click ${openPopover ? 'active' : ''}`}
    >
      <Point
        className="bg-primary"
        onClick={() => {
          setOpenPopover(true)
          mapRef.current.panTo({ lat: latitude + 0.00006, lng: longitude })
        }}
      >
        <Avatar
          avatarSize="md"
          name={properties?.animalName}
          photoURL={properties?.animalPhotoURL}
          alt="Imagem do animal"
        />
      </Point>
      <div className="popover-container">
        <div className="card">
          <div className="card-header">
            <Flexbox.Columns className="align-center">
              <Flexbox.Column>
                <div className="card-title h6">
                  <Avatar
                    avatarSize="sm"
                    name={properties?.animalName}
                    photoURL={properties?.animalPhotoURL}
                    alt="Imagem do animal"
                  />
                  <span> {properties.animalName}</span>
                </div>
              </Flexbox.Column>
              <Flexbox.Column className="col-auto">
                <a className="btn btn-link" onClick={() => setOpenPopover(false)}>
                  <i className="fas fa-times" />
                </a>
              </Flexbox.Column>
            </Flexbox.Columns>
          </div>
          <div className="card-body">
            <div>
              RGA: <b>{rga(properties?.animalUid)}</b>
            </div>
            <div>
              Espécie: <b>{properties?.animalCategory}</b>
            </div>
            <div>
              Raça: <b>{properties?.animalBreed}</b>
            </div>
          </div>
          <div className="card-footer">
            <div className="divider" />
            <Flexbox.Columns>
              <Flexbox.Column className="col-auto">
                <Link to={`/app/pets/${properties.id}`} className="btn btn-primary btn-sm">
                  <i className="fas fa-link" /> Abrir
                </Link>
              </Flexbox.Column>
            </Flexbox.Columns>
          </div>
        </div>
      </div>
    </div>

  )
}

Marker.propTypes = {
  cluster: PropTypes.objectOf(Object).isRequired,
  points: PropTypes.arrayOf(Object).isRequired,
  mapRef: PropTypes.objectOf(Object).isRequired,
}

export default Marker

import React from 'react'
import { useHistory, useParams } from 'react-router-dom'
import chats from '@/services/api/chats'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import { LoadManager } from '@/components'
import Chat from './Chat'

function Messages() {
  const history = useHistory()
  const { chatId } = useParams()

  const { data, loading, error } = useAxiosRequest(chats.show, chatId)

  const names = data?.chat?.people
    ?.map((person) => {
      if (person?.id === data?.partner?.id) {
        return 'Você'
      }
      return person?.displayName
    })
    ?.join(', ')

  return (
    <div className="modal active">
      <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
      <div className="modal-container">
        <LoadManager loading={loading} error={error}>
          {
            Boolean(data) && (
              <>
                <div className="modal-header">
                  <a
                    type="button"
                    className="btn btn-clear float-right"
                    onClick={() => history.goBack()}
                  />
                  <div className="modal-title h5 text-primary">
                    {data?.chat?.title}
                  </div>
                  <div className="modal-subtitle">
                    {names}
                  </div>
                </div>
                <div className="divider" />
                <div className="modal-body">
                  <Chat
                    addMessage={(body) => chats.addMessage(chatId, body)}
                    providerId={data?.partner?.id}
                    fetchList={() => chats.messages(chatId)}
                    readChat={() => chats.readChat(chatId)}
                  />
                </div>
              </>
            )
          }
        </LoadManager>
      </div>

    </div>
  )
}

export default Messages

import MAPPINGS from './mappings'

function t(key) {
  return this.translations[key] || key
}

export const ANIMAL = {
  size: {
    ...MAPPINGS.animal.size,
    t,
  },
  gender: {
    ...MAPPINGS.animal.gender,
    t,
  },
}

export const LICENSING = {
  type: {
    ...MAPPINGS.licensing.type,
    t,
  },
  status: {
    ...MAPPINGS.licensing.status,
    t,
  },
}

export const ADOPTION = {
  status: {
    ...MAPPINGS.adoption.status,
    t,
  },
}

export const CASTRATION = {
  status: {
    ...MAPPINGS.castration.status,
    t,
  },
}

export const COMPLAINT = {
  type: {
    ...MAPPINGS.complaint.type,
    t,
  },
}

export const LICENSE_TYPE = {
  type: {
    enum: {
      CASTRATION_PROVIDER: 'castrationProvider',
      CHIP_PROVIDER: 'chipProvider',
      FAIRS_MANAGER: 'fairsManager',
    },
    translations: {
      castrationProvider: 'Prover castração',
      chipProvider: 'Prover chip',
      fairsManager: 'Gerenciar feiras',
    },
    t(key) {
      return this.translations[key] || key
    },
  },
}

export const PERMISSION_TYPE = {
  type: {
    enum: {
      CASTRATION_PROVIDER: 'castrationProvider',
      CHIP_PROVIDER: 'chipProvider',
      FAIRS_MANAGER: 'fairsManager',
      CLINICAL_CARE: 'clinicalCare',
      MISTREATMENT: 'mistreatment',
    },
    translations: {
      castrationProvider: 'Castrações',
      chipProvider: 'Chips',
      fairsManager: 'Feiras',
      clinicalCare: 'Atendimentos clínicos',
      mistreatment: 'Denúncias de maus tratos',
    },
    t(key) {
      return this.translations[key] || key
    },
  },
}

export const CASTRATION_CORRECTION_REQUIRED = {
  type: {
    ...MAPPINGS.castrationCorrectionRequired.type,
    t,
  },
}

export const MISTREATMENT = {
  status: {
    ...MAPPINGS.mistreatment.status,
    t,
  },
  result: {
    ...MAPPINGS.mistreatment.result,
    t,
  },
}

import { Form, LoadManager } from '@/components'
import useLicensing from '@/hooks/useLicensing'
import documentNumberMask from '@/lib/masks/documentNumber'
import yup from '@/lib/yupPt'
import { setLicensing } from '@/reducers/licensing'
import protectors from '@/services/api/protectors'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  rejectedReason: yup.string().trim().required().min(10),
})

function Reject() {
  const { id } = useParams()
  const history = useHistory()
  const [rejecting, setRejecting] = useState(false)
  const { state: { licensing }, dispatch } = useLicensing()

  async function reject(form) {
    try {
      setRejecting(true)
      const { data } = await protectors.reject(id, form)
      dispatch(setLicensing(data))
      toast.success(`A solicitação de ${licensing?.licensed?.fullName} foi rejeitada`)
      history.goBack()
    } catch (e) {
      setRejecting(false)
      toast.error(e?.message)
    }
  }

  return (
    <fieldset disabled={rejecting}>
      <Form.Formik
        initialValues={{ rejectedReason: '' }}
        validationSchema={schema}
        onSubmit={reject}
        validateOnMount
      >
        {
          ({
            errors, touched, isValid,
          }) => (
            <Form.ValidationForm>
              <div className="modal active" id="modal-id">
                <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
                <div className="modal-container">
                  <div className="modal-header">
                    <a
                      type="button"
                      className="btn btn-clear float-right"
                      onClick={() => history.goBack()}
                    />
                    <div className="modal-title h5">Rejeitar protetor(a)</div>
                  </div>
                  <div className="modal-body">
                    <div className="content text-justify">
                      <LoadManager loading={!licensing}>
                        {
                          licensing?.status === 'requested' ? (
                            <>
                              <p>
                                Deseja rejeitar a solicitação do(a) usuário(a)
                                <b className="text-primary"> {licensing?.licensed?.fullName} </b>
                                portador(a) do documento
                                <b className="text-primary"> {documentNumberMask(licensing?.licensed?.documentNumber)} </b>
                                como protetor(a) independente?
                              </p>
                              <p className="text-small">
                                Com isso, este usuário precisará
                                fazer uma nova solicitação caso queira se credenciar
                              </p>
                            </>
                          )
                            : <p>Este credenciamento não pode mais ser rejeitado</p>
                        }
                        <Form.Group className={`${touched?.rejectedReason && errors?.rejectedReason ? 'has-error' : ''}`}>
                          <Form.Label htmlFor="label">Descreva o motivo de rejeitar a solicitação: <span className="text-error">*</span></Form.Label>
                          <Form.ValidationField
                            name="rejectedReason"
                            component="textarea"
                            id="rejectedReason"
                            placeholder="Solicitação rejeitada porque..."
                          />
                          {
                            touched?.rejectedReason && errors?.rejectedReason
                              ? <Form.Hint className="text-error">{errors.rejectedReason}</Form.Hint> : null
                          }
                        </Form.Group>
                      </LoadManager>
                    </div>
                  </div>
                  {
                    licensing?.status === 'requested' && (
                      <div className="modal-footer">
                        <a
                          className="btn mr-2"
                          disabled={rejecting}
                          onClick={() => history.goBack()}
                        >
                          <i className="fas fa-chevron-left" />
                          &nbsp;Voltar
                        </a>
                        <button
                          className={`btn btn-error ${rejecting ? 'loading' : ''}`}
                          disabled={!isValid}
                          type="submit"
                        >
                          <i className="fas fa-times" />
                          &nbsp;Rejeitar
                        </button>
                      </div>
                    )
                  }
                </div>
              </div>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </fieldset>
  )
}

export default Reject

const mapping = {
  requested: {
    title: 'Solicitadas',
    subtitle: 'Castrações que os usuários solicitaram',
    icon: 'fas fa-question',
  },
  rejected: {
    title: 'Rejeitadas',
    subtitle: 'Castrações que você rejeitou por algum motivo',
    icon: 'fas fa-thumbs-down',
  },
  canceled: {
    title: 'Canceladas',
    subtitle: 'Castrações canceladas após serem aprovadas',
    icon: 'fas fa-ban',
  },
  scheduled: {
    title: 'Agendada',
    subtitle: 'Castrações que foram agendadas pela clínica',
    icon: 'fas fa-calendar',
  },
  correction: {
    title: 'Em correção',
    subtitle: 'Castrações que precisam de correção do solicitante',
    icon: 'fas fa-edit',
  },
  inTreatment: {
    title: 'Em tratamento',
    subtitle: 'Castrações em que o pet precisa de tratamento antes de castrar',
    icon: 'fas fa-briefcase-medical',
  },
  corrected: {
    title: 'Corrigidas',
    subtitle: 'Castrações corrigidas pelo solicitante',
    icon: 'fas fa-edit',
  },
  preApproved: {
    title: 'Pré aprovadas',
    subtitle: 'Castrações que você aprovou e o Petis ainda irá distribuir para as clínicas',
    icon: 'fas fa-check-circle',
  },
  approved: {
    title: 'Aprovadas',
    subtitle: 'Castrações que você aprovou e aguardam a execução',
    icon: 'fas fa-thumbs-up',
  },
  revision: {
    title: 'Em revisão',
    subtitle: 'Castrações que estão em revisão antes de serem aprovadas',
    icon: 'fas fa-glasses',
  },
  attendance: {
    title: 'Em atendimento',
    subtitle: 'Castrações que estão em atendimento pela clínica',
    icon: 'fas fa-clinic-medical',
  },
  awaitingConfirmation: {
    title: 'Aguardando confirmação',
    subtitle: 'Castrações que estão aguardando a confirmação do usuário',
    icon: 'fas fa-user-clock',
  },
  completed: {
    title: 'Concluídas',
    subtitle: 'Castrações concluídas e confirmadas pelo usuário',
    icon: 'fas fa-check',
  },
  all: {
    title: 'Todas as castrações',
    icon: 'fas fa-cut',
  },
}

export default (status) => mapping[status] || mapping.all

import {
 Avatar, ListManager, PageHeader, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import reports from '@/services/api/reports'
import React from 'react'

function CastrationsAwaitingBatch({ title }) {
  usePageTitle({ title })

  const { data, loading, error } = useAxiosRequest(reports.castrationsAwaitingBatch)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Castrações aguardando lote"
        subtitle="Quantidade de castrações concluídas que ainda não foram adicionadas ao lote"
        icon="fas fa-clinic-medical"
      />
      <ListManager count={data?.clinics?.length || 0} loading={loading} error={error}>
        <table className="table table-hover text-small">
          <thead>
            <tr>
              <th>Clínica</th>
              <th className="text-right" width="200px">Quantidade</th>
            </tr>
          </thead>
          <tbody>
            {
              Boolean(data?.clinics) && (
                data?.clinics.map((row) => (
                  <tr key={row?.id}>
                    <td>
                    <Avatar
                      avatarSize="sm"
                      name={row?.fullName}
                      photoURL={row?.photoURL}
                      alt="Imagem de usuário"
                    />
                      &nbsp;{row?.fullName}
                    </td>
                    <td className="text-right">{row?.count}</td>
                  </tr>
                ))
              )
            }
          </tbody>
          <tfoot>
            <tr>
              <td />
              <td width="200px" className="text-bold text-right">Total: {data?.total}</td>
            </tr>
          </tfoot>
        </table>
      </ListManager>
    </Styles.Container>
  )
}

export default CastrationsAwaitingBatch

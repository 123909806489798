import {
  Avatar, Filter, Form,
  ListManager,
  Mask,
  PageHeader,
  Paginator,
  Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import documentNumberMask from '@/lib/masks/documentNumber'
import phoneNumber from '@/lib/masks/phoneNumber'
import petisUsers from '@/services/api/petisUsers'
import React from 'react'
import { Link } from 'react-router-dom'

function PetisUsers({ title }) {
  usePageTitle({ title })
  const query = useQuery()

  const {
    loading, data, error,
  } = useAxiosRequest(petisUsers.list, query)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Usuários do Petis no município"
        icon="fas fa-users"
        subtitle="Lista de usuários cujo endereço informado são do seu município"
        rightAction={() => (
          <>
            <Filter
              initialValues={{
                personName: '', personEmail: '', personDocumentNumber: '', personPhoneNumber: '',
              }}
              title="Filtrar usuários"
              renderForm={({ setFieldValue }) => (
                <>
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="personName">Nome do usuário</Form.Label>
                    <Form.ValidationField
                      name="personName"
                      className="input-sm"
                      autoFocus
                      id="personName"
                      autoComplete="none"
                      placeholder="Fulano da Silva"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="personDocumentNumber">CPF/CNPJ do usuário</Form.Label>
                    <Form.ValidationField name="personDocumentNumber">
                      {
                        ({ field }) => (
                          <Mask.CpfCnpj
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            className="input-sm"
                            placeholder="CPF/CNPJ do usuário"
                            id="personDocumentNumber"
                            onAccept={(value) => {
                              setFieldValue('personDocumentNumber', value)
                            }}
                          />
                        )
                      }
                    </Form.ValidationField>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="personEmail">Email do usuário</Form.Label>
                    <Form.ValidationField
                      name="personEmail"
                      className="input-sm"
                      id="personEmail"
                      autoComplete="none"
                      placeholder="fulano@email.com"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="personPhoneNumber">Telefone do usuário</Form.Label>
                    <Form.ValidationField name="personPhoneNumber">
                      {
                        ({ field }) => (
                          <Mask.Phone
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            className="input-sm"
                            placeholder="Número do telefone"
                            id="personPhoneNumber"
                            onAccept={(value) => {
                              setFieldValue('personPhoneNumber', value)
                            }}
                          />
                        )
                      }
                    </Form.ValidationField>
                  </Form.Group>
                </>
              )}
            />
            {/* <Add /> */}
          </>
        )}
      />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        <>
          <table className="table text-small table-striped table-hover">
            <thead>
              <tr>
                <th>Usuário</th>
                <th>CPF/CNPJ</th>
                <th>Telefone</th>
                <th width="1">E-mail</th>
                <th className="text-right" width="1">Ações</th>
              </tr>
            </thead>
            <tbody>
              {
                Boolean(data) && data.rows.map((person) => (
                  <tr key={person?.id}>
                    <td>
                      <Avatar
                        avatarSize="sm"
                        className="mr-1"
                        name={person?.displayName || person?.fullName}
                        photoURL={person?.photoURL}
                        alt="Imagem do pet"
                      />
                      {person?.fullName}
                    </td>
                    <td>
                      {person?.documentNumber ? documentNumberMask(person?.documentNumber) : '-'}
                    </td>
                    <td>{person?.phoneNumber ? phoneNumber(person?.phoneNumber) : '-'}</td>
                    <td>{person?.email}</td>
                    <td className="text-right">
                      <Link
                        to={`/app/petis-users/${person?.id}`}
                        className="btn btn-action btn-sm btn-primary"
                      >
                        <i className="fas fa-eye" />
                      </Link>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          {
            Boolean(data) && (
              <div className="float-right my-2">
                <Paginator paginatedList={data} />
              </div>
            )
          }
        </>
      </ListManager>
    </Styles.Container>
  )
}

export default PetisUsers

import { Styles } from '@/components'
import { LOCAL_STORAGE_AUTH_TOKEN } from '@/constants/session'
import useAuth from '@/hooks/useAuth'
import React, { useCallback, useEffect, useState } from 'react'

function AuthContainer({ children }) {
  const [loading, setLoading] = useState(true)
  const auth = useAuth()

  const fetchUser = useCallback(async () => {
    try {
      const token = localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN)
      if (token && !auth?.user) {
        await auth.refreshUser()
      }
    } finally {
      setLoading(false)
    }
  }, [auth])

  useEffect(() => {
    fetchUser()
  }, [fetchUser])

  if (loading) {
    return (
      <div className="full-vh d-flex flex-centered">
        <Styles.Loading className="loading-lg" />
      </div>
    )
  }

  return children
}

export default AuthContainer

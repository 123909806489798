import {
  Avatar, Filter, Form, ListManager, Mask, PageHeader, Paginator, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import dateMask from '@/lib/masks/date'
import documentNumberMask from '@/lib/masks/documentNumber'
import partnerBlacklists from '@/services/api/partnerBlacklists'
import React from 'react'
import { Link } from 'react-router-dom'
import FormButton from './components/Form'

export default function PartnerBlacklists({ title }) {
  const query = useQuery()
  usePageTitle({ title })

  const {
    loading, data, error, setData,
  } = useAxiosRequest(partnerBlacklists.list, query)

  function onAdded(partnerBlacklist) {
    setData({
      ...data,
      count: (data?.count || 0) + 1,
      rows: [partnerBlacklist, ...(data?.rows || [])],
    })
  }

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Bloqueios"
        icon="fas fa-user-slash"
        subtitle="Os usuários bloqueados não podem solicitar castrações para o fornecedor"
        rightAction={() => (
          <>
            <Filter
              initialValues={{
                'person.fullName_cont': '', documentNumber_eq: '', email_eq: '',
              }}
              title="Filtrar bloqueios"
              renderForm={({ setFieldValue }) => (
                <>
                  <div className="divider text-center" data-content="Tutor" />
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="fullName_cont">Nome do usuário</Form.Label>
                    <Form.ValidationField
                      name="person.fullName_cont"
                      className="input-sm"
                      autoFocus
                      id="person.fullName_cont"
                      autoComplete="none"
                      placeholder="Fulano da Silva"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="documentNumber_eq">CPF/CNPJ</Form.Label>
                    <Form.ValidationField name="documentNumber_eq">
                      {
                        ({ field }) => (
                          <Mask.CpfCnpj
                            name="documentNumber_eq"
                            placeholder="CPF/CNPJ"
                            className="input-sm"
                            id="documentNumber_eq"
                            autoComplete="none"
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            onAccept={(v) => setFieldValue('documentNumber_eq', v)}
                          />
                        )
                      }
                    </Form.ValidationField>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="email_eq">E-mail</Form.Label>
                    <Form.ValidationField
                      name="email_eq"
                      className="input-sm"
                      autoFocus
                      id="email_eq"
                      type="email"
                      autoComplete="none"
                      placeholder="E-mail"
                    />
                  </Form.Group>
                </>
              )}
            />
            <FormButton onAdded={onAdded} />
          </>
        )}
      />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        {
          Boolean(data) && (
            <>
              <table className="table text-small table-striped table-hover">
                <thead>
                  <tr>
                    <th>Usuário bloqueado</th>
                    <th>E-mail</th>
                    <th width="200px">CPF/CNPJ</th>
                    <th width="150px">Bloqueado em</th>
                    <th className="text-right" width="1">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.rows.map((blacklist) => (
                      <tr key={blacklist.id}>
                        <td>
                          {
                            blacklist.person ? (
                              <>
                                <Avatar avatarSize="sm" name={blacklist.person.fullName} photoURL={blacklist.person.photoURL} />
                                &nbsp;{blacklist.person.fullName}
                              </>
                            ) : '-'
                          }
                        </td>
                        <td>{blacklist.email || '-'}</td>
                        <td>{documentNumberMask(blacklist.documentNumber) || '-'}</td>
                        <td>{dateMask(blacklist.createdAt)}</td>
                        <td className="text-right">
                          <Link
                            to={`/app/blacklist/${blacklist?.id}`}
                            className="btn btn-action btn-sm btn-primary"
                          >
                            <i className="fas fa-eye" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
              {
                Boolean(data) && (
                  <div className="float-right my-2">
                    <Paginator paginatedList={data} />
                  </div>
                )
              }
            </>
          )
        }
      </ListManager>
    </Styles.Container>
  )
}

import usePageTitle from '@/hooks/usePageTitle'
import React, { useCallback, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import MyAnimals from './MyAnimals'
import AllAnimals from './AllAnimals'
import NewAnimal from './NewAnimal'

export default function AddAnimal({ title }) {
  usePageTitle({ title })
  const [selected, setSelected] = useState('myAnimals')
  const history = useHistory()
  const location = useLocation()

  const onSelect = useCallback((value) => {
    history.push(location.pathname)
    setSelected(value)
  }, [history, location.pathname])

  return (
    <div className="modal active">
      <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
      <div className="modal-container">
        <div className="modal-header">
          <a
            type="button"
            className="btn btn-clear float-right"
            onClick={() => history.goBack()}
          />
          <div className="modal-title h5">
            Adicionar pet na feira
          </div>
        </div>
        <div className="modal-body">
          <ul className="tab tab-block">
            <li className="tab-item c-hand">
              <a onClick={() => onSelect('myAnimals')} className={selected === 'myAnimals' ? 'active' : ''}>
                <i className="fas fa-paw" />
                &nbsp;Meus pets
              </a>
            </li>
            <li className="tab-item c-hand">
              <a onClick={() => onSelect('allAnimals')} className={selected === 'allAnimals' ? 'active' : ''}>
                <i className="fas fa-list" />
                &nbsp;Todos os pets
              </a>
            </li>
            <li className="tab-item c-hand">
              <a onClick={() => onSelect('newAnimal')} className={selected === 'newAnimal' ? 'active' : ''}>
                <i className="fas fa-plus" />
                &nbsp;Novo Pet
              </a>
            </li>
          </ul>
          {
            selected === 'myAnimals' && (
              <MyAnimals />
            )
          }
          {
            selected === 'allAnimals' && (
              <AllAnimals />
            )
          }
          {
            selected === 'newAnimal' && (
              <NewAnimal />
            )
          }
        </div>
      </div>
    </div>
  )
}

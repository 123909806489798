import mistreatmentContext from '@/contexts/mistreatmentContext'
import mistreatmentReducer, { INITIAL_STATE } from '@/reducers/mistreatment'
import List from '@/views/app/Mistreatments'
import Show from '@/views/app/Mistreatments/Show'
import Form from '@/views/app/Mistreatments/Form'
import Cancel from '@/views/app/Mistreatments/Show/Cancel'
import Done from '@/views/app/Mistreatments/Show/Done'
import Reject from '@/views/app/Mistreatments/Show/Reject'
import StartCheck from '@/views/app/Mistreatments/Show/StartCheck'
import React, { useReducer } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

function MistreatmentsRoutes() {
  const { path } = useRouteMatch()
  const [state, dispatch] = useReducer(mistreatmentReducer, INITIAL_STATE)

  return (
    <div>
      <Route path={path} exact>
        <List title="Petis - Parceiros / Denúncias de maus tratos" />
      </Route>
      <mistreatmentContext.Provider value={{ state, dispatch }}>
        <Route exact path={`${path}/form/add`}>
          <Form title="Petis - Parceiros / Denúncias de maus tratos / Nova denúncia" />
        </Route>
        <Route path={`${path}/show/:id`}>
          <Show title="Petis - Parceiros / Denúncias de maus tratos / Denúncia" />
        </Route>
        <Route path={`${path}/show/:id/start-check`}>
          <StartCheck title="Petis - Parceiros / Denúncias de maus tratos / Denúncia / Iniciar verificação" />
        </Route>
        <Route path={`${path}/show/:id/cancel`}>
          <Cancel title="Petis - Parceiros / Denúncias de maus tratos / Denúncia / Cancelar" />
        </Route>
        <Route path={`${path}/show/:id/reject`}>
          <Reject title="Petis - Parceiros / Denúncias de maus tratos / Denúncia / Rejeitar" />
        </Route>
        <Route path={`${path}/show/:id/done`}>
          <Done title="Petis - Parceiros / Denúncias de maus tratos / Denúncia / Concluir" />
        </Route>
      </mistreatmentContext.Provider>
    </div>
  )
}

export default MistreatmentsRoutes

import { ListManager, PageHeader, Styles } from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import reports from '@/services/api/reports'
import React from 'react'

function CastrationsByRejectionReason({ title }) {
  usePageTitle({ title })

  const query = useQuery()
  const { data, loading, error } = useAxiosRequest(reports.castrationsByRejectionReason, query)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Castrações por motivo de rejeição"
        subtitle="Quantidade de castrações por cada motivo de rejeição"
        icon="fas fa-ban"
      />
      <ListManager count={data?.length || 0} loading={loading} error={error}>
        <table className="table table-hover text-small">
          <thead>
            <tr>
              <th>Motivo</th>
              <th className="text-right" width="200px">Quantidade</th>
            </tr>
          </thead>
          <tbody>
            {
                Boolean(data) && (
                  data.map((row) => (
                    <tr key={row?.reason?.id}>
                      <td>{row?.reason?.description}</td>
                      <td className="text-right">{row?.count}</td>
                    </tr>
                  ))
                )
            }
          </tbody>
        </table>
      </ListManager>

    </Styles.Container>
  )
}

export default CastrationsByRejectionReason

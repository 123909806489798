import React from 'react'
import PropTypes from 'prop-types'
import { LICENSING_STATUS_MAP } from '@/constants/licensings'

function Breadcrumb({ match }) {
  const titleParams = LICENSING_STATUS_MAP[match.params.status]
  return <span>{titleParams}</span>
}

Breadcrumb.propTypes = {
  match: PropTypes.objectOf(Object).isRequired,
}

export default Breadcrumb

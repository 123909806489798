import api from './api'

export default {
  list(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/petis-users', { params })
  },
  create(body) {
    return api.post('/partner-licensings/:partnerLicensingId/petis-users', body)
  },
  createOrUpdateLowIncome(personId, body) {
    return api.post(`/partner-licensings/:partnerLicensingId/petis-users/${personId}/low-incomes`, body)
  },
  show(id) {
    return api.get(`/partner-licensings/:partnerLicensingId/petis-users/${id}`)
  },
  animals(id, params = {}) {
    return api.get(`/partner-licensings/:partnerLicensingId/petis-users/${id}/animals`, { params })
  },
  createAnimal(id, body) {
    return api.post(`/partner-licensings/:partnerLicensingId/petis-users/${id}/animals`, body)
  },
}

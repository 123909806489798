import clinicalCareContext from '@/contexts/clinicalCareContext'
import { useContext } from 'react'

/**
 * @typedef {Object} ClinicalCareReducer
 * @property {Object} state - State of castration
 * @property {Function} dispatch - dispatch
 */

/**
 * @returns {ClinicalCareReducer}
 */
 export default function useClinicalCare() {
  return useContext(clinicalCareContext)
}

import {
  AttachmentPreview,
  Avatar, Flexbox,
  ImageViewer,
} from '@/components'
import { clinicalCareStatus } from '@/constants/clinicalCares'
import dateMask from '@/lib/masks/date'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import mapEvents from './mapEvents'
import MapLocation from './components/MapLocation'

function TimelineItem({ event, index, providerCity }) {
  const [image, setImage] = useState('')
  const { pathname } = useLocation()
  const mappedEvent = mapEvents(event?.status)

  if (!mappedEvent) return null

  return (
    <>
      {
        Boolean(image) && (
          <ImageViewer src={image} onClose={() => setImage('')} />
        )
      }
      <div className="timeline-item">
        <div className="timeline-left">
          <span
            className={`timeline-icon tooltip tooltip-right ${mappedEvent?.bgIconClass} ${mappedEvent?.icon ? 'icon-lg' : ''}`}
            data-tooltip={clinicalCareStatus[event.status]}
          >
            <i className={`${mappedEvent?.icon}`} />
          </span>
        </div>
        <div className="timeline-content">
          <div className="tile">
            <div className="tile-content">
              <p className="tile-subtitle">
                <span className="text-bold">
                  {dateMask(event?.createdAt, { dateStyle: 'full' })}&nbsp;
                </span>
                <small className="label label-sm label-rounded">
                  <i className="far fa-clock" />&nbsp;
                  {new Date(event?.createdAt).toLocaleTimeString()}
                </small>
              </p>
              <p className="tile-title pre">{mappedEvent?.title}</p>
              {
                Boolean(event?.description) && (
                  <p className="tile-title">
                    <cite className="pre">{event?.description}</cite>
                  </p>
                )
              }

              <Flexbox.Columns>
                {
                  (event.attachments || []).map((url) => (
                    <Flexbox.Column className="col-auto" key={url}>
                      <AttachmentPreview size={80} src={url} />
                    </Flexbox.Column>
                  ))
                }
              </Flexbox.Columns>
              {
                Boolean(event?.address?.location) && (
                  <Flexbox.Column className="col-auto">
                    <MapLocation
                      sameCity={providerCity?.id === event?.address?.city?.id}
                      location={event?.address?.location}
                    />
                  </Flexbox.Column>
                )
              }
              {
                Boolean(event?.address) && (
                  <div className="my-2">
                    <small>
                      Endereço: <b>{event.address.formattedAddress}</b>
                    </small>
                  </div>
                )
              }
              <Flexbox.Columns className="align-center col-gapless">
                <Flexbox.Column className="col-auto">
                  <Avatar
                    avatarSize="sm"
                    name={event?.author?.fullName}
                    photoURL={event?.author?.photoURL}
                    alt="Imagem de usuário"
                  />
                </Flexbox.Column>
                <Flexbox.Column className="text-small ml-2">{event?.author?.fullName}</Flexbox.Column>
              </Flexbox.Columns>
              {
                event.outsourcedTransport
                && (
                  <div className="mt-2 pt-2">
                    <small>
                      <div className="chip">
                        <i className="fas fa-ambulance" />
                        &nbsp;Transporte terceirizado
                      </div>
                    </small>
                  </div>
                )
              }

              <div className="mt-2 pt-2">
                <Link to={`${pathname}/events/${index}`} className="c-hand text-small">Exibir detalhes do evento</Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

TimelineItem.propTypes = {
  event: PropTypes.objectOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  providerCity: PropTypes.objectOf(Object),
}

TimelineItem.defaultProps = {
  providerCity: null,
}

export default TimelineItem

import { Flexbox, Form, Mask } from '@/components'
import InputCep from '@/components/AddressForm/InputCep'
import PropTypes from 'prop-types'
import React from 'react'

export default function Tutor({
  touched, errors, setFieldValue,
}) {
  function onFoundAddress(address) {
    setFieldValue('address.zipcode', address.zipcode)
    setFieldValue('address.city', address.city)
    setFieldValue('address.street', address.street)
    setFieldValue('address.district', address.district)
  }

  return (
    <div>
      <div className="h5">Informe os dados do tutor</div>
      <Flexbox.Columns>
        <Flexbox.Column className="col-6 col-sm-12">
          <Form.Group className={`${touched?.documentNumber && errors?.documentNumber ? 'has-error' : ''}`}>
            <Form.Label htmlFor="documentNumber">CPF/CNPJ <span className="text-error">*</span></Form.Label>
            <Form.ValidationField name="documentNumber">
              {
                ({ field }) => (
                  <Mask.CpfCnpj
                    id="documentNumber"
                    placeholder="CPF/CNPJ"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    onAccept={(v) => setFieldValue('documentNumber', v)}
                  />
                )
              }
            </Form.ValidationField>
            {
              touched?.documentNumber && errors?.documentNumber
                ? <Form.Hint className="text-error">{errors.documentNumber}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>
        <Flexbox.Column className="col-6 col-sm-12">
          <Form.Group className={`${touched?.email && errors?.email ? 'has-error' : ''}`}>
            <Form.Label htmlFor="email">E-mail</Form.Label>
            <Form.ValidationField name="email" type="email" placeholder="E-mail" />
            {
              touched?.email && errors?.email
                ? <Form.Hint className="text-error">{errors.email}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>
        <Flexbox.Column className="col-8 col-sm-12">
          <Form.Group className={`${touched?.fullName && errors?.fullName ? 'has-error' : ''}`}>
            <Form.Label htmlFor="fullName">Nome completo <span className="text-error">*</span></Form.Label>
            <Form.ValidationField name="fullName" placeholder="Nome completo" />
            {
              touched?.fullName && errors?.fullName
                ? <Form.Hint className="text-error">{errors.fullName}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>
        <Flexbox.Column className="col-4 col-sm-12">
          <Form.Group className={`${touched?.phoneNumber && errors?.phoneNumber ? 'has-error' : ''}`}>
            <Form.Label htmlFor="phoneNumber">Telefone</Form.Label>
            <Form.ValidationField name="phoneNumber">
              {
                ({ field }) => (
                  <Mask.Phone
                    id="phoneNumber"
                    placeholder="Telefone"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    onAccept={(v) => setFieldValue('phoneNumber', v)}
                  />
                )
              }
            </Form.ValidationField>
            {
              touched?.phoneNumber && errors?.phoneNumber
                ? <Form.Hint className="text-error">{errors.phoneNumber}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>
        <Flexbox.Column className="col-4 col-sm-12">
          <Form.Group className={`${touched?.address?.zipcode && errors?.address?.zipcode ? 'has-error' : ''}`}>
            <Form.ValidationField name="address.zipcode">
              {
                ({ field }) => (
                  <InputCep
                    id="address.zipcode"
                    isRequired={false}
                    onFoundAddress={onFoundAddress}
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    onAccept={(v) => setFieldValue('address.zipcode', v)}
                  />
                )
              }
            </Form.ValidationField>
            {
              touched?.address?.zipcode && errors?.address?.zipcode
                ? <Form.Hint className="text-error">{errors?.address?.zipcode}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>
        <Flexbox.Column className="col-8 col-sm-12">
          <Form.Group>
            <Form.Label htmlFor="address.city">Cidade</Form.Label>
            <Form.ValidationField name="address.city">
              {
                ({ field }) => (
                  <output className="form-input disabled" placeholder="Cidade (preencha o CEP)">
                    {Boolean(field?.value) && (
                      <>
                        {field?.value?.name} - {field?.value?.uf}
                      </>
                    )}
                  </output>
                )
              }
            </Form.ValidationField>

          </Form.Group>
        </Flexbox.Column>
        <Flexbox.Column className="col-6 col-sm-12">
          <Form.Group className={`${touched?.address?.district && errors?.address?.district ? 'has-error' : ''}`}>
            <Form.Label htmlFor="address.district">Bairro</Form.Label>
            <Form.ValidationField name="address.district" placeholder="Bairro" />
            {
              touched?.address?.district && errors?.address?.district
                ? <Form.Hint className="text-error">{errors?.address?.district}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>
        <Flexbox.Column className="col-6 col-sm-12">
          <Form.Group className={`${touched?.address?.street && errors?.address?.street ? 'has-error' : ''}`}>
            <Form.Label htmlFor="address.street">Rua</Form.Label>
            <Form.ValidationField name="address.street" placeholder="Rua" />
            {
              touched?.address?.street && errors?.address?.street
                ? <Form.Hint className="text-error">{errors.street}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>
        <Flexbox.Column className="col-3 col-sm-12">
          <Form.Group className={`${touched?.address?.number && errors?.address?.number ? 'has-error' : ''}`}>
            <Form.Label htmlFor="address.number">Número</Form.Label>
            <Form.ValidationField name="address.number" placeholder="Número" />
            {
              touched?.address?.number && errors?.address?.number
                ? <Form.Hint className="text-error">{errors?.address?.number}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>
        <Flexbox.Column className="col-9 col-sm-12">
          <Form.Group className={`${touched?.address?.complement && errors?.address?.complement ? 'has-error' : ''}`}>
            <Form.Label htmlFor="address.complement">Complemento</Form.Label>
            <Form.ValidationField name="address.complement" placeholder="Complemento" />
            {
              touched?.address?.complement && errors?.address?.complement
                ? <Form.Hint className="text-error">{errors.complement}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>
      </Flexbox.Columns>
    </div>
  )
}

Tutor.propTypes = {
  touched: PropTypes.objectOf(Object).isRequired,
  errors: PropTypes.objectOf(Object).isRequired,
  setFieldValue: PropTypes.func.isRequired,
}

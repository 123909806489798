import { singularStatusMapLabel } from '@/constants/castration'

const map = {
  requested: {
    fill: '#3665E3',
  },
  rejected: {
    fill: '#F05C3A',
  },
  canceled: {
    fill: '#F05C3A',
  },
  approved: {
    fill: '#3B9677',
  },
  revision: {
    fill: '#C336E3',
  },
  attendance: {
    fill: '#2BBDE3',
  },
  corrected: {
    fill: '#E49042',
  },
  correction: {
    fill: '#7D7256',
  },
  awaitingConfirmation: {
    fill: '#E49042',
  },
  completed: {
    fill: '#3B9677',
  },
}

export default (item) => {
  const mapped = map[item?.status]
  return { ...mapped, name: singularStatusMapLabel[item.status], Quantidade: item?.count }
}

import React from 'react'
import PropTypes from 'prop-types'
import ReactPaginate from 'react-paginate'
import { Container } from './styles'

function PaginatedList({
  children,
  page,
  pages,
  onPageChange,
  pageRangeDisplayed,
  marginPagesDisplayed,
  containerClassName,
  pageClassName,
  previousLabel,
  nextLabel,
  previousLinkClassName,
  nextLinkClassName,
  activeClassName,
  disabledClassName,
}) {
  return (
    <Container>
      {children}
      {pages > 1 ? (
        <ReactPaginate
          pageCount={pages}
          pageRangeDisplayed={pageRangeDisplayed}
          marginPagesDisplayed={marginPagesDisplayed}
          onPageChange={onPageChange}
          containerClassName={containerClassName}
          pageClassName={pageClassName}
          previousLabel={previousLabel}
          nextLabel={nextLabel}
          previousLinkClassName={previousLinkClassName}
          nextLinkClassName={nextLinkClassName}
          activeClassName={activeClassName}
          disabledClassName={disabledClassName}
          forcePage={page - 1}
        />
      ) : null}
    </Container>
  )
}

PaginatedList.propTypes = {
  page: PropTypes.number.isRequired,
  pages: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  pageRangeDisplayed: PropTypes.number,
  marginPagesDisplayed: PropTypes.number,
  containerClassName: PropTypes.string,
  pageClassName: PropTypes.string,
  previousLabel: PropTypes.string,
  nextLabel: PropTypes.string,
  previousLinkClassName: PropTypes.string,
  nextLinkClassName: PropTypes.string,
  activeClassName: PropTypes.string,
  disabledClassName: PropTypes.string,
}

PaginatedList.defaultProps = {
  pageRangeDisplayed: 3,
  marginPagesDisplayed: 3,
  containerClassName: 'pagination',
  pageClassName: 'page-item c-hand',
  previousLabel: '',
  nextLabel: '',
  previousLinkClassName: 'fas fa-chevron-left mx-2',
  nextLinkClassName: 'fas fa-chevron-right mx-2',
  activeClassName: 'active',
  disabledClassName: 'disabled',
}

export default PaginatedList

import api from '../api'

export default {
  current() {
    return api.get('/partner-licensings/:partnerLicensingId/settings/terms/current')
  },
  updateCurrent(params = {}) {
    return api.put('/partner-licensings/:partnerLicensingId/settings/terms/current', params)
  },
}

import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import useAuth from '../../hooks/useAuth'

export default function GuestRoute({ children, ...rest }) {
  const auth = useAuth()

  return (
    <Route
      // eslint-disable-next-line react/jsx-props-no-spreading
      {...rest}
      render={() => (
        !auth.user ? (
          children
        )
          : (
            <Redirect
              to={{
                pathname: '/app',
              }}
            />
          )
      )}
    />
  )
}

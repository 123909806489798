import { Flexbox } from '@/components'
import PropTypes from 'prop-types'
import React, { useCallback } from 'react'
import { confirmAlert } from 'react-confirm-alert'

function MemberRow({ member, onRemoved }) {
  const onPressRemove = useCallback(() => {
    confirmAlert({
      title: 'Remover membro',
      message: `Você está certo que deseja remover '${member}'?`,
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim, remover',
          className: 'bg-error',
          onClick: () => onRemoved(member),
        },
      ],
    })
  }, [member, onRemoved])

  return (
    <div>
      <div className="bg-gray s-rounded p-1 mb-2">
        <Flexbox.Columns>
          <Flexbox.Column>
            <div className="p-1">
              <div><b>{member}</b></div>
            </div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <div className="p-1">
              <button
                type="button"
                className="btn btn-action btn-sm btn-error tooltip tooltip-left"
                data-tooltip="Remover"
                onClick={onPressRemove}
              >
                <i className="fas fa-trash" />
              </button>
            </div>
          </Flexbox.Column>
        </Flexbox.Columns>
      </div>
    </div>
  )
}

export default MemberRow

MemberRow.propTypes = {
  member: PropTypes.string.isRequired,
  onRemoved: PropTypes.func.isRequired,
}

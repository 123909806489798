import React from 'react'
import {
  FacebookIcon, FacebookShareButton, TelegramIcon, TelegramShareButton,
  WhatsappIcon, WhatsappShareButton, XIcon, TwitterShareButton,
} from 'react-share'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { CopyButton } from './styles'

const Share = ({ url, title, value }) => {
  function copyLink() {
    let text = title ? `${`${title}\n`}\n` : ''
    if (value) text += `${value}\n`
    text += url

    navigator.clipboard.writeText(text)
    toast.success('Link copiado com sucesso')
  }

  return (
    <div className="columns">
      <div className="column col-auto">
        <FacebookShareButton className="tooltip tooltip-top" data-tooltip="Compartilhar no Facebook" url={url} title={`${title}\n`} value={value}>
          <FacebookIcon size={22} round />
        </FacebookShareButton>
      </div>
      <div className="column col-auto">
        <WhatsappShareButton className="tooltip tooltip-top" data-tooltip="Compartilhar no WhatsApp" url={url} title={`${title}\n`} value={value}>
          <WhatsappIcon size={22} round />
        </WhatsappShareButton>
      </div>
      <div className="column col-auto">
        <TelegramShareButton className="tooltip tooltip-top" data-tooltip="Compartilhar no Telegram" url={url} title={`${title}\n`} value={value}>
          <TelegramIcon size={22} round />
        </TelegramShareButton>
      </div>
      <div className="column col-auto">
        <TwitterShareButton className="tooltip tooltip-top" data-tooltip="Compartilhar no X (Antigo Twitter)" url={url} title={`${title}\n`} value={value}>
          <XIcon size={22} round />
        </TwitterShareButton>
      </div>
      <div className="column col-auto">
        <CopyButton data-tooltip="Copiar o link" type="button" onClick={() => copyLink()}>
          <i className="fas fa-link" />
        </CopyButton>
      </div>
    </div>
  )
}

Share.propTypes = {
  url: PropTypes.string.isRequired,
  title: PropTypes.string,
  value: PropTypes.string,
}

Share.defaultProps = {
  title: '',
  value: '',
}

export default Share

import envs from '@/constants/envs'
import Thumbor from 'thumbor'

const thumbor = new Thumbor(envs.REACT_THUMBOR_SECURITY_KEY, envs.REACT_THUMBOR_BASE_URL)
thumbor.thumb = (url, { width, height, smart = true } = {}) => {
  thumbor.setImagePath(encodeURIComponent(url)).smartCrop(smart)
  if (width && height) {
    thumbor.resize(width, height)
  }

  return thumbor.buildUrl()
}

export default thumbor

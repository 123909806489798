import api from './api'

export default {
  sendEmail(login) {
    return api.post('/password-reset/send-email', { login })
  },
  reset({ login, token, password }) {
    return api.post('/password-reset', { login, token, password })
  },
}

import { LICENSING } from '@/lib/enums'
import currency from '@/lib/masks/currency'
import dateMask from '@/lib/masks/date'
import documentNumberMask from '@/lib/masks/documentNumber'
import phoneNumber from '@/lib/masks/phoneNumber'
import PropTypes from 'prop-types'
import React from 'react'
import Avatar from '../Avatar'
import Card from '../Card'
import Flexbox from '../Flexbox'
import { LogoGovBr } from './styles'

function UserCard({
  user, lowIncome, title, renderFooter,
}) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">{title}</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-user" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <Flexbox.Columns>
          <Flexbox.Column className="col-auto">
            <Avatar
              avatarSize="lg"
              name={user?.fullName}
              photoURL={user?.photoURL}
              alt="Imagem de usuário"
            />
          </Flexbox.Column>
          <Flexbox.Column>
            <div className="text-primary text-bold">{user?.fullName}</div>
            <div className="text-small">{user?.documentNumber ? documentNumberMask(user?.documentNumber) : '-'}</div>
          </Flexbox.Column>
        </Flexbox.Columns>
        <div className="divider" />
        {user?.documentNumber?.length === 11 && (
          <Flexbox.Columns className="mb-1">
            <Flexbox.Column>Data de nascimento</Flexbox.Column>
            <Flexbox.Column className="col-auto">
              {dateMask(user?.birthdayDate) || 'Não informado'}
            </Flexbox.Column>
          </Flexbox.Columns>
        )}
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>E-mail</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {user?.email}
          </Flexbox.Column>
        </Flexbox.Columns>
        <Flexbox.Columns className="mb-1">
          <Flexbox.Column>Fone</Flexbox.Column>
          <Flexbox.Column className="col-auto text-bold">
            {phoneNumber(user?.phoneNumber) || '-'}
          </Flexbox.Column>
        </Flexbox.Columns>
        {
          Boolean(user?.licensings?.length) && (
            <div className="pt-2">
              {
                user.licensings.map((licensing) => (
                  <span className="chip" key={licensing.id}>{LICENSING.type.t(licensing.type)}</span>
                ))
              }
            </div>
          )
        }
        {
          Boolean(lowIncome) && (
            <>
              <div className="divider text-center" data-content="Baixa renda" />
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>Renda familiar</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {currency(lowIncome?.familyIncome, 'R$ ')}
                </Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>Membros na fam.</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {lowIncome?.numberOfFamilyMembers}
                </Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>Nº CRAS</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {lowIncome?.cras || '-'}
                </Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>Nº NIS</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {lowIncome?.nis || '-'}
                </Flexbox.Column>
              </Flexbox.Columns>
            </>
          )
        }
        {
          Boolean(user?.personGovBr) && (
            <>
              <div className="divider" />
              <div className="d-flex align-center">
                Dados do&nbsp;<LogoGovBr alt="Logo gov.br" />
              </div>
              <div className="mb-2 pb-2">
                <small>&nbsp;(atualizado em {dateMask(user?.personGovBr?.updatedAt, 'DD/MM/YYYY')})</small>
              </div>
              <div className="mb-1" />
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>E-mail</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {user?.personGovBr?.email}
                  {
                    user?.personGovBr?.email_verified
                    ? <i className="fas fa-certificate text-success mt-1 ml-1 tooltip tooltip-left" data-tooltip="Verificado" />
                    : <i className="fas fa-exclamation-circle text-error mt-1 ml-1 tooltip tooltip-left" data-tooltip="Não verificado" />
                  }
                </Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>Fone</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {phoneNumber(user?.personGovBr?.phone_number)}
                  {
                    user?.personGovBr?.phone_number_verified
                    ? <i className="fas fa-certificate text-success mt-1 ml-1 tooltip tooltip-left" data-tooltip="Verificado" />
                    : <i className="fas fa-exclamation-circle text-error mt-1 ml-1 tooltip tooltip-left" data-tooltip="Não verificado" />
                  }
                </Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>CPF</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {documentNumberMask(user?.personGovBr?.sub)}
                </Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>Nome</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {user?.personGovBr?.name}
                </Flexbox.Column>
              </Flexbox.Columns>
              <div className="divider text-center" data-content="Selos" />
              {
                (user?.personGovBr?.selos ?? []).map((selo) => (
                  <React.Fragment key={selo.id}>
                    <Flexbox.Columns className="align-center mb-1">
                      <Flexbox.Column className="col-auto">
                        <i className="fas fa-fw fa-lg fa-star" />
                      </Flexbox.Column>
                      <Flexbox.Column>
                        <div>{selo.descricao}</div>
                        <div><small>em {dateMask(selo.dataAtualizacao, 'DD/MM/YYYY')}</small></div>
                      </Flexbox.Column>
                    </Flexbox.Columns>
                  </React.Fragment>
                ))
              }
              <div className="divider text-center" data-content="Níveis da conta" />
              {
                (user?.personGovBr?.niveis ?? []).map((nivel) => (
                  <React.Fragment key={nivel.id}>
                    <Flexbox.Columns className="align-center mb-1">
                      <Flexbox.Column className="col-auto">
                        <div className={`nivel-container flex-centered nivel-container-${nivel.id}`}>
                          {Number(nivel.id) === 1 && <i className="far fa-fw fa-star" />}
                          {Number(nivel.id) === 2 && <i className="fas fa-fw fa-star-half-alt" />}
                          {Number(nivel.id) === 3 && <i className="fas fa-fw fa-star" />}
                        </div>
                      </Flexbox.Column>
                      <Flexbox.Column>
                        <div>{nivel.descricao}</div>
                        <div><small>em {dateMask(nivel.dataAtualizacao, 'DD/MM/YYYY')}</small></div>
                      </Flexbox.Column>
                    </Flexbox.Columns>
                  </React.Fragment>
                ))
              }
            </>
          )
        }
      </Card.Body>
      {
        renderFooter()
      }
    </Card.Base>
  )
}

UserCard.propTypes = {
  user: PropTypes.objectOf(Object).isRequired,
  lowIncome: PropTypes.objectOf(Object),
  title: PropTypes.string,
  renderFooter: PropTypes.func,
}

UserCard.defaultProps = {
  lowIncome: null,
  title: 'Usuário',
  renderFooter: () => null,
}

export default UserCard

import { AddressForm, Form } from '@/components'
import useAuth from '@/hooks/useAuth'
import yup from '@/lib/yupPt'
import React, { useCallback, useState } from 'react'
import formatAddress from '@/lib/formatters/address'
import gmaps from '@/services/gmaps'
import address from '@/services/api/settings/address'
import { toast } from 'react-toastify'

// import { Container } from './styles';
const schema = yup.object().shape({
  address: yup.object().shape({
    zipcode: yup.string().trim().required().length(8),
    city: yup.object().shape({
      id: yup.number().required(),
      name: yup.string(),
      uf: yup.string(),
    }),
    street: yup.string().trim().required(),
    number: yup.string().trim().required(),
    complement: yup.string().trim(),
  }),
})
function Address() {
  const [loading, setLoading] = useState(false)
  const { licensing, setLicensing } = useAuth()

  const onSubmit = useCallback(async (body) => {
    try {
      setLoading(true)
      const formattedAddress = formatAddress(body?.address)
      const { location } = await gmaps.geolocation(formattedAddress)
      const { lat, lng } = location
      const point = { type: 'Point', coordinates: [lng, lat] }
      const { data } = await address.update({ address: body?.address, location: point })
      setLoading(false)
      toast.success('Endereço atualizado com sucesso!')
      setLicensing({ ...licensing, licensed: data })
    } catch {
      setLoading(false)
    }
  }, [licensing, setLicensing])

  return (
    <div className="modal-container">
      <fieldset disabled={loading}>
        <Form.Formik
          initialValues={{
            address: {
              id: '',
              zipcode: '',
              city: {
                id: '',
                name: '',
                uf: '',
              },
              street: '',
              district: '',
              number: '',
              complement: '',
            },
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {
            ({
              errors, touched, isValid, setFieldValue, values,
            }) => (
              <Form.ValidationForm>

                <div className="modal-header">
                  <div className="modal-title h5">
                    Endereço obrigatório
                  </div>
                </div>
                <div className="modal-body">
                  <div className="h3">Atenção</div>
                  <p>
                    Você precisa informar o endereço do parceiro/prefeitura antes de começar
                  </p>
                  <AddressForm
                    touched={touched}
                    errors={errors}
                    value={values?.address}
                    onChange={setFieldValue}
                  />
                </div>
                <div className="modal-footer">
                  <button
                    type="submit"
                    className={`btn btn-primary ${loading ? 'loading' : ''}`}
                    disabled={loading || !isValid}
                  >
                    <i className="fas fa-check" />
                    &nbsp;Salvar endereço
                  </button>
                </div>
              </Form.ValidationForm>
            )
          }
        </Form.Formik>
      </fieldset>
    </div>
  )
}

export default Address

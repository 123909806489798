import styled from 'styled-components'

const darkColor = '#4B1FB3'
const lightColor = '#fff'

export const ButtonContacts = styled.button.attrs({ type: 'button' })`
  background-color: #5C2BE3;
  border-color: ${darkColor};
  color: ${lightColor};
  
  &:focus,
  &:hover {
    background-color: ${(0.02, darkColor)};
    border-color: ${(0.05, darkColor)};
    color: ${lightColor};
  }

  &:active,
  &.active {
    background-color: ${(0.04, darkColor)};
    border-color: ${(0.07, darkColor)};
    color: ${lightColor};
  }

  &.loading {
    &::after {
      border-bottom-color: ${lightColor};
      border-left-color: ${lightColor};
    }
  }
`

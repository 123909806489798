export const INITIAL_STATE = { castration: null }

export default function castrationReducer(state, action) {
  switch (action.type) {
    case ('setCastration'): return { castration: action.payload }
    default: return INITIAL_STATE
  }
}

export function setCastration(payload) {
  return { type: 'setCastration', payload }
}

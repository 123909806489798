/* eslint-disable jsx-a11y/label-has-associated-control */
import { Flexbox, Form } from '@/components'
import yup from '@/lib/yupPt'
import castrations from '@/services/api/castrations'
import React, { useState } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { Container } from './styles'

const schema = yup.object().shape({
  description: yup.string().trim().required(),
})
function CommentForm({ onCommentAdded, castration }) {
  const [loading, setLoading] = useState(false)

  async function addComment(form, { resetForm }) {
    try {
      setLoading(true)
      const { data } = await castrations.addMessage(castration?.id, form)
      resetForm()
      onCommentAdded(data)
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <fieldset style={{ margin: 0, padding: 0 }} disabled={loading}>
      <Form.Formik
        initialValues={{ description: '' }}
        validationSchema={schema}
        onSubmit={addComment}
        validateOnMount
      >
        {
          ({
            errors, touched, isValid,
          }) => (
            <Form.ValidationForm>
              <Container className="bg-gray p-2">

                <Flexbox.Columns className="align-end">

                  <Flexbox.Column>
                    <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                      <Form.ValidationField
                        name="description"
                        id="description"
                      >
                        {
                          ({ field }) => (
                            <TextareaAutosize
                              name="description"
                              style={{ resize: 'none' }}
                              className="form-input"
                              rows={1}
                              maxRows={3}
                              placeholder="Escreva seu comentário..."
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...field}
                            />
                          )
                        }
                      </Form.ValidationField>
                    </Form.Group>
                  </Flexbox.Column>
                  <Flexbox.Column className="col-auto">
                    <button
                      type="submit"
                      className={`btn btn-link ${loading ? 'loading' : ''}`}
                      disabled={!isValid}
                    >
                      <i className="fas fa-paper-plane" />
                    </button>
                  </Flexbox.Column>
                </Flexbox.Columns>
              </Container>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </fieldset>
  )
}

CommentForm.propTypes = {
  onCommentAdded: PropTypes.func.isRequired,
  castration: PropTypes.objectOf(Object).isRequired,
}

export default CommentForm

/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import { Flexbox, Form } from '@/components'
import albums from '@/services/api/albums'
import upload from '@/services/api/upload'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { toast } from 'react-toastify'

const fileTypes = ['JPG', 'PNG', 'JPEG']

export default function AttachPictures({ albumId, onAttach }) {
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false)
  const [files, setFiles] = useState([])

  const onClose = useCallback(() => {
    setActive(false)
  }, [])

  async function onSubmit() {
    try {
      setLoading(true)
      const pictures = []
      for (const file of files) {
        const { data: { url } } = await upload.sendFile(file)
        pictures.push({ photoURL: url })
      }
      const { data } = await albums.attachPictures(albumId, pictures)
      toast.success('Imagens adicionadas')
      onClose()
      setLoading(false)
      onAttach(data)
    } catch (e) {
      setLoading(false)
      toast.error(e?.message)
    }
  }
  function onSelectFiles(params) {
    const added = Object.values(params).filter((file) => !files.find((f) => f.name === file.name))
    setFiles([...files, ...(added)])
  }

  return (
    <div>
      <a
        className="flex-centered no-decoration bg-gray s-rounded"
        style={{ width: 80, height: 80 }}
        onClick={() => setActive(true)}
      >
        <i className="fas fa-plus fa-lg" />
      </a>
      <div className={`modal ${active ? 'active' : ''}`}>
        <a type="button" className="modal-overlay" aria-label="Close" onClick={onClose} />
        <div className="modal-container">
          <div className="modal-header">
            <a
              type="button"
              className="btn btn-clear float-right"
              onClick={onClose}
            />
            <div className="modal-title h5">
              Novo álbum
            </div>
          </div>
          <div className="modal-body">
            <Flexbox.Columns className="p-2 align-center">

              <Flexbox.Column className="col-12">
                <div>
                  <Form.Label>Adicione ao menos uma foto</Form.Label>
                  <FileUploader
                    handleChange={onSelectFiles}
                    hoverTitle="Solte aqui"
                    required
                    multiple
                    types={fileTypes}
                  >
                    {
                      files.length === 0 ? (
                        <div className="c-hand bg-gray text-center text-dark" style={{ paddingTop: 30, paddingBottom: 30 }}>
                          <div className="empty-icon">
                            <i className="fas fa-paperclip fa-lg" />
                          </div>
                          <div className="empty-title h5">Selecione os anexos</div>
                          <div className="empty-subtitle">Clique ou arraste os anexos para adicionar</div>
                        </div>
                      ) : (
                        <div className="c-hand bg-gray text-center" style={{ paddingTop: 30, paddingBottom: 30 }}>
                          <div className="empty-title h5">Anexos adicionados: {files.length}</div>
                          <div className="empty-subtitle">Clique ou arraste anexos para adicionar mais</div>
                        </div>
                      )
                    }
                  </FileUploader>
                </div>
              </Flexbox.Column>
            </Flexbox.Columns>
          </div>
          <div className="modal-footer">
            <button type="submit" disabled={loading || !files.length} onClick={onSubmit} className={`btn btn-primary ${loading ? 'loading' : ''}`}>
              <i className="fas fa-check" />
              &nbsp;Adicionar fotos
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

AttachPictures.propTypes = {
  onAttach: PropTypes.func.isRequired,
  albumId: PropTypes.number.isRequired,
}

import api from './api'

export default {
  list(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/partner-blacklists', { params })
  },
  create(body) {
    return api.post('/partner-licensings/:partnerLicensingId/partner-blacklists', body)
  },
  show(id) {
    return api.get(`/partner-licensings/:partnerLicensingId/partner-blacklists/${id}`)
  },
  destroy(id) {
    return api.delete(`/partner-licensings/:partnerLicensingId/partner-blacklists/${id}`)
  },
}

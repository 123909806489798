import React, { useLayoutEffect, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from '@/components'
import { toast } from 'react-toastify'
import termsService from '@/services/api/settings/terms'

function Edit({ terms, onClose, onUpdate }) {
  const [loading, setLoading] = useState(false)
  const [content, setContent] = useState(terms)
  const textareaRef = useRef()

  useLayoutEffect(() => {
    textareaRef?.current?.focus()
  }, [])

  async function onSubmit(event) {
    event?.preventDefault()
    try {
      setLoading(true)
      await termsService.updateCurrent({ terms: content })
      toast.success('Termos atualizados com sucesso')
      onUpdate(content)
    } catch (e) {
      toast.error(e?.message)
      setLoading(false)
    }
  }

  return (
    <fieldset disabled={loading}>
      <form onSubmit={onSubmit}>
        <div className="modal active" id="modal-id">
          <a type="button" className="modal-overlay" aria-label="Close" onClick={onClose} />
          <div className="modal-container">
            <div className="modal-header">
              <a
                type="button"
                className="btn btn-clear float-right"
                onClick={onClose}
              />
              <div className="modal-title h5">Atualização dos termos</div>
            </div>
            <div className="modal-body">
              <div className="content">
                <Form.Group>
                  <Form.Label htmlFor="terms">
                    Informe os
                    <b> termos que o usuário deve concordar</b>
                  </Form.Label>
                  <textarea
                    ref={textareaRef}
                    onInput={(e) => setContent(e.target.value)}
                    placeholder="Para solicitar este serviço você deve concordar com...x"
                    id="terms"
                    cols="30"
                    rows="10"
                    className="form-input"
                    value={content}
                  />
                </Form.Group>
              </div>
            </div>
            <div className="modal-footer">
              <a
                className="btn mr-2"
                onClick={onClose}
              >
                <i className="fas fa-chevron-left" />
                &nbsp;Voltar
              </a>
              <button
                disabled={!content}
                className={`btn btn-primary ${loading ? 'loading' : ''}`}
                type="submit"
              >
                <i className="fas fa-save" />
                &nbsp;Salvar termos
              </button>
            </div>
          </div>
        </div>
      </form>
    </fieldset>
  )
}

Edit.propTypes = {
  terms: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
}

export default Edit

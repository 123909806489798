/* eslint-disable jsx-a11y/label-has-associated-control */
import { Avatar, Form } from '@/components'
import useAuth from '@/hooks/useAuth'
import { PERMISSION_TYPE } from '@/lib/enums'
import dateMask from '@/lib/masks/date'
import partnerPermissions from '@/services/api/settings/partnerPermissions'
import { Field, useFormikContext } from 'formik'
import PropTypes from 'prop-types'
import React, { useEffect, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'

const AutoSubmit = ({ item, setUpdating, onUpdate }) => {
  const { values, submitForm } = useFormikContext()

  async function updatePermissions() {
    try {
      setUpdating(true)
      await partnerPermissions.updatePermissions(item.id, values?.permissions || [])
      toast.success('Permissões atualizadas')
      onUpdate()
    } catch (e) {
      toast.error(e.message)
    } finally {
      setUpdating(false)
    }
  }

  useEffect(() => {
    if ((item.permissions || []).length !== (values.permissions || []).length) {
      updatePermissions()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values, submitForm, item])

  return null
}

function Row({ permission, onDelete, onUpdate }) {
  const [deleting, setDeleting] = useState(false)
  const [updating, setUpdating] = useState(false)
  const { licensing } = useAuth()

  async function destroy() {
    try {
      setDeleting(true)
      await partnerPermissions.destroy(permission?.id)
      toast.success(`Usuário ${permission?.person?.fullName} removido`)
      onDelete(permission)
    } catch (e) {
      toast.error(e?.message)
      setDeleting(false)
    }
  }

  function handleDelete() {
    confirmAlert({
      title: 'Remover usuário',
      message: `O usuário ${permission?.person?.fullName} não terá mais acesso ao ambiente de administrador.\nConfirmar?`,
      buttons: [
        { label: 'Não' },
        {
          icon: 'fas fa-user-minus',
          label: 'Sim, remover',
          className: 'bg-error',
          onClick: destroy,
        },
      ],
    })
  }

  if (!licensing) return null

  return (
    <tr key={permission?.id}>
      <td>
        <Avatar
          name={permission?.person?.fullName}
          photoURL={permission?.person?.photoURL}
          alt="Imagem de usuário"
        />
        <span className="ml-1">
          {permission?.person?.fullName}
        </span>
      </td>
      <td>{dateMask(permission?.createdAt, { hour: '2-digit', minute: '2-digit' })}</td>
      <td>
        <fieldset disabled={updating}>
          <Form.Formik
            initialValues={{
              permissions: permission.permissions || [],
            }}
          >
            {
              () => (
                <Form.ValidationForm>
                  {
                    licensing.licenseTypes.map((i) => (
                      <div key={i} className="form-group">
                        <label className="form-switch">
                          <Field type="checkbox" name="permissions" value={i} />
                          <i className="form-icon" />
                          {PERMISSION_TYPE.type.t(i)}
                        </label>
                      </div>
                    ))
                  }
                  <AutoSubmit item={permission} setUpdating={setUpdating} onUpdate={onUpdate} />
                </Form.ValidationForm>
              )
            }
          </Form.Formik>
        </fieldset>
      </td>
      <td className="text-right">
        <button
          type="button"
          className="btn btn-action btn-sm btn-error tooltip tooltip-left"
          data-tooltip="Remover usuário"
          disabled={deleting}
          onClick={handleDelete}
        >
          <i className={`fas fa-user-minus ${deleting ? 'loading' : ''}`} />
        </button>
      </td>
    </tr>
  )
}

Row.propTypes = {
  permission: PropTypes.objectOf(Object).isRequired,
  onDelete: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
}

export default Row

import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Flexbox, Form } from '@/components'
import yup from '@/lib/yupPt'
import animals from '@/services/api/animals'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  uid: yup.string().trim().required(),
})
function ModalChip({
 animal, onChange, active, onClose,
}) {
  const [loading, setLoading] = useState(false)

  const onSubmit = useCallback(async (form) => {
    try {
      setLoading(true)
      const { data } = await animals.applyChip(animal?.id, form)
      onChange(data)
      toast.success('Chip informado com sucesso')
      onClose()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }, [animal?.id, onChange, onClose])

  return (
    <fieldset disabled={loading}>
      <Form.Formik
        initialValues={{ uid: animal?.chip?.uid || '' }}
        validationSchema={schema}
        onSubmit={onSubmit}
        validateOnMount
      >
        {
          ({
            errors, touched, isValid, values,
          }) => (
            <Form.ValidationForm>
              <div className={`modal ${active ? 'active' : ''}`}>
                <a type="button" className="modal-overlay" aria-label="Close" onClick={onClose} />
                <div className="modal-container">
                  <div className="modal-header">
                    <a
                      type="button"
                      className="btn btn-clear float-right"
                      onClick={onClose}
                    />
                    <div className="modal-title h5">
                      {animal?.chip ? 'Alterar o código do chip' : 'Adicionar chip no animal'}
                    </div>
                  </div>
                  <div className="modal-body">
                    <Flexbox.Columns>
                      <Flexbox.Column className="col-12">
                        <Form.Group className={`${touched?.uid && errors?.uid ? 'has-error' : ''}`}>
                          <Form.Label htmlFor="uid">Informe o código do chip <span className="text-error">*</span></Form.Label>
                          <Form.ValidationField
                            name="uid"
                            id="uid"
                            placeholder="Código do chip"
                          />
                          {
                            touched?.uid && errors?.uid
                              ? <Form.Hint className="text-error">{errors.uid}</Form.Hint> : null
                          }
                        </Form.Group>
                      </Flexbox.Column>
                    </Flexbox.Columns>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="submit"
                      disabled={!isValid || loading || values?.uid === animal?.chip?.uid}
                      className={`btn btn-primary ${loading ? 'loading' : ''}`}
                    >
                      <i className="fas fa-save" />
                      &nbsp;Salvar
                    </button>
                  </div>
                </div>
              </div>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </fieldset>
  )
}

ModalChip.propTypes = {
  animal: PropTypes.objectOf(PropTypes.any).isRequired,
  active: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default ModalChip

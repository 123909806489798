import { Flexbox, Form } from '@/components'
import React from 'react'
import PropTypes from 'prop-types'
import yup from '@/lib/yupPt'
import ReactTextareaAutosize from 'react-textarea-autosize'

const schema = yup.object().shape({
  title: yup.string().trim().required(),
  description: yup.string().trim().required(),
  minAttachments: yup.number().min(1).max(5).required(),
})

function DocumentForm({
  active, onClose, initialValues, onSubmit, title,
}) {
  return (
    <Form.Formik
      initialValues={initialValues}
      validationSchema={schema}
      onSubmit={onSubmit}
      validateOnMount
    >
      {
        ({
          touched, errors, isValid,
        }) => (
          <Form.ValidationForm>
            <div className={`modal ${active ? 'active' : ''}`}>
              <a type="button" className="modal-overlay" aria-label="Close" onClick={onClose} />
              <div className="modal-container">
                <div className="modal-header">
                  <a
                    type="button"
                    className="btn btn-clear float-right"
                    onClick={onClose}
                  />
                  <div className="modal-title h5">
                    {title}
                  </div>
                </div>
                <div className="modal-body">
                  <Flexbox.Columns className="p-2 align-center">
                    <Flexbox.Column className="col-12">
                      <Form.Group className={`${touched?.title && errors?.title ? 'has-error' : ''}`}>
                        <Form.Label htmlFor="title">Título do documento <span className="text-error">*</span></Form.Label>
                        <Form.ValidationField
                          name="title"
                          id="title"
                          placeholder="CNH, Carteira de protetor, etc..."
                        />
                        {
                          touched?.title && errors?.title
                            ? <Form.Hint className="text-error">{errors.title}</Form.Hint> : null
                        }
                      </Form.Group>
                    </Flexbox.Column>
                    <Flexbox.Column className="col-12">
                      <Form.Group className={`${touched?.minAttachments && errors?.minAttachments ? 'has-error' : ''}`}>
                        <Form.Label htmlFor="minAttachments">Quantidade de fotos <span className="text-error">*</span></Form.Label>
                        <Form.ValidationField
                          type="number"
                          min={1}
                          name="minAttachments"
                          id="minAttachments"
                          placeholder="Quantidade de fotos"
                        />
                        {
                          touched?.minAttachments && errors?.minAttachments
                            ? <Form.Hint className="text-error">{errors.minAttachments}</Form.Hint> : null
                        }
                      </Form.Group>
                    </Flexbox.Column>
                    <Flexbox.Column className="col-12">
                      <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                        <Form.Label htmlFor="title">Descrição <span className="text-error">*</span></Form.Label>
                        <Form.ValidationField
                          name="description"
                          id="description"
                        >
                          {
                            ({ field }) => (
                              <ReactTextareaAutosize
                                name="description"
                                style={{ resize: 'none' }}
                                className="form-input"
                                minRows={3}
                                maxRows={5}
                                placeholder="Descreva como devem ser enviados os anexos"
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...field}
                              />
                            )
                          }
                        </Form.ValidationField>
                      </Form.Group>
                    </Flexbox.Column>
                  </Flexbox.Columns>
                </div>
                <div className="modal-footer">
                  <button type="submit" className="btn btn-primary" disabled={!isValid}>
                    <i className="fas fa-check" />
                    &nbsp;Confirmar
                  </button>
                </div>
              </div>
            </div>
          </Form.ValidationForm>
        )
      }
    </Form.Formik>
  )
}

export default DocumentForm

DocumentForm.propTypes = {
  active: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  initialValues: PropTypes.objectOf(Object),
}

DocumentForm.defaultProps = {
  initialValues: {
    title: '',
    description: '',
    minAttachments: null,
  },
}

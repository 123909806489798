import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Flexbox } from '@/components'
import dateMask from '@/lib/masks/date'

function Rejected({ license }) {
  return (
    <>
      <Flexbox.Columns className="align-center col-gapless pb-2">
        <Flexbox.Column className="col-auto">
          <div className="text-small">Licença rejeitada em</div>
          {
            license?.rejectedAt ? (
              <span>
                <span className="text-bold">
                  {dateMask(license?.rejectedAt, { dateStyle: 'full' })}&nbsp;
                </span>
                <small className="label label-sm label-rounded">
                  <i className="far fa-clock" />&nbsp;
                  {new Date(license?.rejectedAt).toLocaleTimeString()}
                </small>
              </span>
            ) : (
              <span className="text-gray text-small text-italic">Não há registro</span>
            )
          }
        </Flexbox.Column>
      </Flexbox.Columns>
      <Flexbox.Columns className="align-center col-gapless pb-2">
        <Flexbox.Column className="col-auto">
          <div className="text-small">Licença rejeitada por</div>
          {
            license?.rejectedBy ? (
              <>
                <Avatar
                  avatarSize="sm"
                  name={license?.rejectedBy?.fullName}
                  photoURL={license?.rejectedBy?.photoURL}
                  alt="Imagem de usuário"
                />
                <span className="text-bold pl-1">{license?.rejectedBy?.fullName}</span>
              </>
            ) : (
              <span className="text-gray text-small text-italic">Não há registro</span>
            )
          }
        </Flexbox.Column>
      </Flexbox.Columns>
      <Flexbox.Columns className="align-center col-gapless pb-2">
        <Flexbox.Column className="col-auto">
          <div className="text-small">Motivo da rejeição</div>
          {
            license?.rejectedReason ? (
              <span className="text-error text-small text-italic">
                {license?.rejectedReason}
              </span>
            ) : (
              <span className="text-gray text-small text-italic">Não há registro</span>
            )
          }
        </Flexbox.Column>

      </Flexbox.Columns>
      <div className="divider" />
    </>
  )
}

Rejected.propTypes = {
  license: PropTypes.objectOf(Object).isRequired,
}

export default Rejected

import {
  Flexbox, LoadManager, PageHeader, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import partnerBlacklists from '@/services/api/partnerBlacklists'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { confirmAlert } from 'react-confirm-alert'
import PersonCard from './PersonCard'
import AuthorCard from './AuthorCard'
import DetailsCard from './DetailsCard'

export default function Show({ title }) {
  usePageTitle({ title })
  const { id } = useParams()
  const [removing, setRemoving] = useState(false)
  const history = useHistory()

  const { data, loading, error } = useAxiosRequest(partnerBlacklists.show, id)

  async function removePartnerBlacklist() {
    try {
      setRemoving(true)
      await partnerBlacklists.destroy(id)
      toast.success('Adoção removida com sucesso')
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
      setRemoving(false)
    }
  }

  function handleRemove() {
    confirmAlert({
      title: 'Retirar bloqueio',
      message: 'Você está certo que deseja retirar este bloqueio?',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim, retirar',
          className: 'bg-error',
          onClick: removePartnerBlacklist,
        },
      ],
    })
  }

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Bloqueio"
        icon="fas fa-user-slash"
        subtitle="Este usuário está impedido de solicitar castrações"
      />
      <LoadManager loading={loading} error={error}>
        {
          Boolean(data) && (
            <div>

              <Styles.ActionsContainer>
                <a onClick={handleRemove} disabled={removing} className={`btn btn-primary btn-sm ${removing && 'loading'}`}>
                  <i className="fas fa-user" />
                  &nbsp;Retirar bloqueio
                </a>
              </Styles.ActionsContainer>

              <Flexbox.Columns>
                <Flexbox.Column className="col-6 col-sm-12 my-2">
                  <PersonCard data={data} />
                </Flexbox.Column>
                <Flexbox.Column className="col-6 col-sm-12 my-2">
                  <AuthorCard data={data} />
                </Flexbox.Column>
                <Flexbox.Column className="col-12 my-2">
                  <DetailsCard data={data} />
                </Flexbox.Column>
              </Flexbox.Columns>
            </div>
          )
        }
      </LoadManager>
    </Styles.Container>
  )
}

import Address from '@/views/app/settings/Address'
import ClinicalCareItems from '@/views/app/settings/ClinicalCareItems'
import Info from '@/views/app/settings/Info'
import Ngos from '@/views/app/settings/Ngos'
import Params from '@/views/app/settings/Params'
import Protectors from '@/views/app/settings/Protectors'
import Current from '@/views/app/settings/Terms/Current'
import Create from '@/views/app/settings/users/Create'
import List from '@/views/app/settings/users/List'
import React from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

function SettingsRoutes() {
  const { path } = useRouteMatch()

  return (
    <div>
      <Route exact path={`${path}/users`}>
        <List title="Petis - Parceiros / Configurações / Usuários" />
      </Route>
      <Route exact path={`${path}/users/create`}>
        <Create title="Petis - Parceiros / Configurações / Usuários / Novo Usuário" />
      </Route>
      <Route exact path={`${path}/terms`}>
        <Current title="Petis - Parceiros / Configurações / Termos" />
      </Route>
      <Route exact path={`${path}/address`}>
        <Address title="Petis - Parceiros / Configurações / Endereço" />
      </Route>
      <Route exact path={`${path}/params`}>
        <Params title="Petis - Parceiros / Configurações / Parâmetros" />
      </Route>
      <Route exact path={`${path}/ngos`}>
        <Ngos title="Petis - Parceiros / Configurações / ONG's" />
      </Route>
      <Route exact path={`${path}/protectors`}>
        <Protectors title="Petis - Parceiros / Configurações / Protetores" />
      </Route>
      <Route exact path={`${path}/clinical-care-items`}>
        <ClinicalCareItems title="Petis - Parceiros / Configurações / Itens clínicos" />
      </Route>
      <Route exact path={`${path}/info`}>
        <Info title="Petis - Parceiros / Configurações / Institucional" />
      </Route>
    </div>
  )
}

export default SettingsRoutes

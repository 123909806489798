import React from 'react'
import PropTypes from 'prop-types'
import { Card, Flexbox } from '@/components'
import documentNumberMask from '@/lib/masks/documentNumber'
import phoneNumber from '@/lib/masks/phoneNumber'

export default function TutorCard({ data }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h6 card-title">Dados do adotante</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-user" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <small>
          <Flexbox.Columns>
            <Flexbox.Column>Nome</Flexbox.Column>
            <Flexbox.Column className="col-auto text-bold">{data?.fullName || '-'}</Flexbox.Column>
          </Flexbox.Columns>
          <Flexbox.Columns>
            <Flexbox.Column>CPF/CNPJ</Flexbox.Column>
            <Flexbox.Column className="col-auto text-bold">{documentNumberMask(data?.documentNumber) || '-'}</Flexbox.Column>
          </Flexbox.Columns>
          <Flexbox.Columns>
            <Flexbox.Column>E-mail</Flexbox.Column>
            <Flexbox.Column className="col-auto text-bold">{data?.email || '-'}</Flexbox.Column>
          </Flexbox.Columns>
          <Flexbox.Columns>
            <Flexbox.Column>Telefone</Flexbox.Column>
            <Flexbox.Column className="col-auto text-bold">{phoneNumber(data?.phoneNumber) || '-'}</Flexbox.Column>
          </Flexbox.Columns>
        </small>
      </Card.Body>
      {
        Boolean(data?.address?.formattedAddress) && (
          <Card.Footer>
            <small>{data.address.formattedAddress}</small>
          </Card.Footer>
        )
      }
    </Card.Base>
  )
}

TutorCard.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
}

import React from 'react'
import PropTypes from 'prop-types'
import TimelineItem from './TimelineItem'

function Events({ events }) {
  return (
    <div className="timeline">
      {
        events.map((event, index) => <TimelineItem key={event?.id} index={index} event={event} />)
      }
    </div>
  )
}

Events.propTypes = {
  events: PropTypes.arrayOf(Object),
}

Events.defaultProps = {
  events: [],
}

export default Events

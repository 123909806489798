import useAuth from '@/hooks/useAuth'
import React from 'react'
import { Redirect, useLocation } from 'react-router-dom'

function LicensingContainer({ children }) {
  const auth = useAuth()

  const location = useLocation()

  if (!auth.licensingId) return <Redirect to={{ pathname: '/select-licensing', state: { from: location.pathname } }} />

  return children
}

export default LicensingContainer

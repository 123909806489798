import {
  Avatar, Filter, Form, Mask,
  ListManager, PageHeader, Paginator, Styles,
} from '@/components'
import { LICENSING_STATUS_MAP } from '@/constants/licensings'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import dateMask from '@/lib/masks/date'
import clinics from '@/services/api/clinics'
import React from 'react'
import { Link } from 'react-router-dom'

function Requested({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const params = { ...query, q: { ...query?.q, status: 'requested' } }
  const { data, error, loading } = useAxiosRequest(clinics.list, params)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Solicitações de credenciamento"
        icon="fas fa-question"
        subtitle="Estas são as clínicas que solicitaram credenciamento"
        rightAction={() => (
          <Filter
            initialValues={{
              clinicName: '', clinicDocumentNumber: '', clinicEmail: '', clinicPhoneNumber: '',
            }}
            title="Filtrar clínicas"
            renderForm={({ setFieldValue }) => (
              <>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="clinicName">Nome da clínica</Form.Label>
                  <Form.ValidationField
                    name="clinicName"
                    className="input-sm"
                    autoFocus
                    id="clinicName"
                    autoComplete="none"
                    placeholder="Clínica do pet"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="clinicDocumentNumber">Documento da clínica</Form.Label>
                  <Form.ValidationField name="clinicDocumentNumber">
                    {
                      ({ field }) => (
                        <Mask.CpfCnpj
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          className="input-sm"
                          placeholder="CPF/CNPJ da clínica"
                          id="clinicDocumentNumber"
                          onAccept={(value) => {
                            setFieldValue('clinicDocumentNumber', value)
                          }}
                        />
                      )
                    }
                  </Form.ValidationField>
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="clinicEmail">E-mail da clínica</Form.Label>
                  <Form.ValidationField
                    name="clinicEmail"
                    className="input-sm"
                    autoFocus
                    id="clinicEmail"
                    autoComplete="none"
                    placeholder="clinica@email.com"
                  />
                </Form.Group>
                <Form.Group>
                  <Form.Label className="text-sm" htmlFor="clinicPhoneNumber">Telefone da clínica</Form.Label>
                  <Form.ValidationField name="clinicPhoneNumber">
                    {
                      ({ field }) => (
                        <Mask.Phone
                          // eslint-disable-next-line react/jsx-props-no-spreading
                          {...field}
                          className="input-sm"
                          placeholder="Número do telefone"
                          id="clinicPhoneNumber"
                          onAccept={(value) => {
                            setFieldValue('clinicPhoneNumber', value)
                          }}
                        />
                      )
                    }
                  </Form.ValidationField>
                </Form.Group>
              </>
            )}
          />
        )}
      />
      <ListManager count={data?.count || 0} loading={loading} error={error}>
        {
          Boolean(data) && (
            <>
              <table className="table table-striped">
                <thead>
                  <tr>
                    <th>Clínica</th>
                    <th>Status</th>
                    <th>Solicitado em</th>
                    <th className="text-right" width="1">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.rows.map((licensing) => (
                      <tr key={licensing?.id}>
                        <td>
                          <Avatar
                            avatarSize="sm"
                            className="mr-1"
                            name={licensing?.licensed?.fullName}
                            photoURL={licensing?.licensed?.photoURL}
                            alt="Imagem de usuário"
                          />
                          {licensing?.licensed?.fullName}
                        </td>
                        <td>{LICENSING_STATUS_MAP[licensing?.status]}</td>
                        <td>{dateMask(licensing?.createdAt)}</td>
                        <td className="text-right">
                          <Link to={`/app/clinics/requested/${licensing?.id}`} className="btn btn-action btn-sm btn-primary">
                            <i className="fas fa-eye" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>

              {
                Boolean(data) && (
                  <div className="float-right my-2">
                    <Paginator paginatedList={data} />
                  </div>
                )
              }
            </>
          )
        }
      </ListManager>
    </Styles.Container>
  )
}

export default Requested

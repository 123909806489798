import {
  Avatar, Filter, Form,
  ListManager, PageHeader, Paginator, Styles,
} from '@/components'
import { singularStatusMapLabel } from '@/constants/batches'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import dateMask from '@/lib/masks/date'
import batches from '@/services/api/batches'
import React from 'react'
import { Link, useParams } from 'react-router-dom'
import mapTitles from './mapTitles'

function Status({ title }) {
  usePageTitle(title)
  const params = useParams()
  const query = useQuery()
  const { status } = params
  const { data, loading, error } = useAxiosRequest(batches.list, { status, ...query })

  const titleParams = mapTitles(params.status)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title={titleParams.title}
        icon={titleParams.icon}
        subtitle={titleParams.subtitle}
        rightAction={() => (
          <>
            <Filter
              initialValues={{
                clinicName: '', createdAt: '',
              }}
              title="Filtrar lotes"
              renderForm={() => (
                <>
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="clinicName">Nome da clínica</Form.Label>
                    <Form.ValidationField
                      name="clinicName"
                      className="input-sm"
                      autoFocus
                      id="clinicName"
                      autoComplete="none"
                      placeholder="Clínica do pet"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="createdAt">Lote solicitado em</Form.Label>
                    <Form.ValidationField
                      name="createdAt"
                      id="createdAt"
                    >
                      {
                        ({ field }) => (
                          <Form.DatePickerField
                            name="createdAt"
                            dateFormat="dd/MM/yyyy"
                            startDate={query.createdAt}
                            className="form-input input-sm"
                            id="createdAt"
                            placeholderText="Data da solicitação"
                            selected={field.value}
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                          />
                        )
                      }

                    </Form.ValidationField>
                  </Form.Group>
                </>
              )}
            />
          </>
        )}
      />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        <>
          <table className="table table-striped table-hover">
            <thead>
              <tr>
                <th width="300px">Clínica</th>
                <th width="150px">Solicitado em</th>
                <th className="text-center" width="150px">Quantidade</th>
                <th>Status</th>
                <th width="1" className="text-right">Ações</th>
              </tr>
            </thead>
            <tbody>
              {
                Boolean(data) && data.rows.map((batch) => (
                  <tr key={batch.id}>
                    <td className="text-ellipsis">
                      <Avatar
                        avatarSize="sm"
                        className="mr-1"
                        name={batch?.person?.fullName}
                        photoURL={batch?.person?.photoURL}
                        alt="Imagem de usuário"
                      />
                      {batch.person.fullName}
                    </td>
                    <td>{dateMask(batch.createdAt, { hour: '2-digit', minute: '2-digit' })}</td>
                    <td className="text-center">{batch?.amount}</td>
                    <td>{singularStatusMapLabel[batch?.status]}</td>
                    <td className="text-right">
                      <Link
                        to={`/app/batches/status/${batch.status}/${batch.id}`}
                        className="btn btn-action btn-sm btn-primary"
                      >
                        <i className="fas fa-eye" />
                      </Link>
                    </td>
                  </tr>
                ))
              }
            </tbody>
          </table>
          {
            Boolean(data) && (
              <div className="float-right my-2">
                <Paginator paginatedList={data} />
              </div>
            )
          }
        </>
      </ListManager>
    </Styles.Container>
  )
}

export default Status

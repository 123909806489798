import {
  Flexbox, LoadManager, PageHeader, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import animals from '@/services/api/animals'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import AdoptionsCard from './components/AdoptionsCard'
import AlbumsCard from './components/AlbumsCard'
import AnimalCard from './components/AnimalCard'
import AnimalFairsCard from './components/AnimalFairsCard'
import CastrationsCard from './components/CastrationsCard'
import ModalChip from './components/ModalChip'
import TutorCard from './components/TutorCard'

function Show({ title }) {
  usePageTitle({ title })
  const [chipOpened, setChipOpened] = useState(false)
  const { id } = useParams()

  const {
    data, loading, error, setData,
  } = useAxiosRequest(animals.show, id)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader title="Detalhes do pet" icon="fas fa-paw" subtitle="Histórico completo do pet" />
      <LoadManager loading={loading} error={error}>
        {
          Boolean(data) && (
            <>
              <Flexbox.Columns>
                <Flexbox.Column className="col-6 col-lg-12 my-2">
                  <AnimalCard
                    animal={data}
                    onUpdate={setData}
                    onChipOpened={() => setChipOpened(true)}
                  />
                </Flexbox.Column>
                <Flexbox.Column className="col-6 col-lg-12 my-2">
                  <TutorCard tutor={data?.tutor} />
                </Flexbox.Column>
                <Flexbox.Column className="col-12 my-2">
                  <CastrationsCard castrations={data?.castrations} />
                </Flexbox.Column>
                <Flexbox.Column className="col-12 my-2">
                  <AdoptionsCard adoptions={data?.adoptions} />
                </Flexbox.Column>
                <Flexbox.Column className="col-12 my-2">
                  <AnimalFairsCard animalFairs={data?.animalFairs} />
                </Flexbox.Column>
                <Flexbox.Column className="col-12 my-2">
                  <AlbumsCard
                    onAdded={(album) => setData({ ...data, albums: [...data.albums, album] })}
                    onChange={(album) => {
                      setData({
                        ...data,
                        albums: data.albums.map((a) => (a.id === album.id ? album : a)),
                      })
                    }}
                    onDeleted={(album) => {
                      setData({ ...data, albums: data.albums.filter((a) => a.id !== album.id) })
}}
                    albums={data?.albums}
                  />
                </Flexbox.Column>
              </Flexbox.Columns>
              {chipOpened
                && (
                  <ModalChip
                    active={chipOpened}
                    animal={data}
                    onChange={(animal) => setData({ ...data, ...animal })}
                    onClose={() => setChipOpened(false)}
                  />
                )}
            </>
          )
        }
      </LoadManager>
    </Styles.Container>
  )
}

export default Show

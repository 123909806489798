/* eslint-disable jsx-a11y/control-has-associated-label */
import { Card, Flexbox } from '@/components'
import React from 'react'
import PropTypes from 'prop-types'
import dateMask from '@/lib/masks/date'
import currency from '@/lib/masks/currency'

function Items({ items, price }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Itens utilizados</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-star-of-life" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        {
          !items.length ? <p>Nenhum item utilizado</p>
          : (
            <small>
              <table className="table">
                <thead>
                  <tr>
                    <th>Item</th>
                    <th width="200px">Adicionado em</th>
                    <th width="200px" className="text-right">Valor</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    items.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <span className="text-gray">{item.quantity}x </span>{item.title}
                          <div>
                            <small>{item.description}</small>
                          </div>
                        </td>
                        <td>{dateMask(item.createdAt)}</td>
                        <td className="text-right">{currency(item.price / 100, 'R$ ')}</td>
                      </tr>
                    ))
                  }
                </tbody>
                <tfoot>
                  <tr>
                    <th />
                    <th />
                    <th className="text-right">Total: {currency(price / 100, 'R$')}</th>
                  </tr>
                </tfoot>
              </table>
            </small>
          )
        }
      </Card.Body>
    </Card.Base>
  )
}

Items.propTypes = {
  items: PropTypes.arrayOf(Object).isRequired,
  price: PropTypes.number.isRequired,
}

export default Items

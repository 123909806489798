import {
  QuickFilter, Form, Flexbox,
} from '@/components'
import React from 'react'

function AdoptionRequestedQuickFilter() {
  return (
    <QuickFilter
      initialValues={{
        animalName: '', donorName: '', newTutorName: '',
      }}
      title="Filtrar adoções da feira"
      renderForm={() => (
        <>
          <Flexbox.Column className="col-3 pt-2">
            <Form.Group>
              <Form.Label className="label-sm pt-2" htmlFor="animalName">Nome do pet</Form.Label>
              <Form.ValidationField
                name="animalName"
                className="input-sm"
                id="animalName"
                autoComplete="none"
                placeholder="Totó da Silva"
              />
            </Form.Group>
          </Flexbox.Column>
          <Flexbox.Column className="col-3 pt-2">
            <Form.Group>
              <Form.Label className="label-sm pt-2" htmlFor="donorName">Nome do doador</Form.Label>
              <Form.ValidationField
                name="donorName"
                className="input-sm"
                id="donorName"
                autoComplete="none"
                placeholder="Fulano da Silva"
              />
            </Form.Group>
          </Flexbox.Column>
          <Flexbox.Column className="col-3 pt-2">
            <Form.Group>
              <Form.Label className="label-sm pt-2" htmlFor="newTutorName">Nome do solicitante</Form.Label>
              <Form.ValidationField
                name="newTutorName"
                className="input-sm"
                id="newTutorName"
                autoComplete="none"
                placeholder="Fulano da Silva"
              />
            </Form.Group>
          </Flexbox.Column>
        </>
      )}
    />
  )
}

export default AdoptionRequestedQuickFilter

import {
  ListManager, Avatar, PageHeader, Styles, Paginator, Flexbox, QuickFilter, Form,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import documentNumberMask from '@/lib/masks/documentNumber'
import reports from '@/services/api/reports'
import React from 'react'
import { Link } from 'react-router-dom'

function ClinicalCareItemClinicBalances({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const { data, loading, error } = useAxiosRequest(reports.clinicalCareItemClinicBalances, query)
  // const { ngo, protector, tutor } = data || {}

  function getClassName(balance) {
    if (balance <= 5) return 'text-error'
    if (balance <= 10) return 'text-primary'

    return 'text-success'
  }

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Estoque dos itens clínicos"
        subtitle="Mostra o estoque dos itens clínicos nas clínicas"
        icon="fas fa-star-of-life"
      />
      <QuickFilter
        initialValues={{
          'clinic.fullName_cont': '',
          'clinicalCareItemProvider.title_cont': '',
          'clinicalCareItemProvider.description_cont': '',
        }}
        queryAttribute="q"
        title="Filtrar"
        renderForm={() => (
          <>
            <Flexbox.Column className="mt-2">
              <Form.Group>
                <Form.Label className="label-sm pt-2" htmlFor="clinicFullName">Nome da clínica</Form.Label>
                <Form.ValidationField
                  name="['clinic.fullName_cont']"
                  className="input-sm"
                  id="clinicFullName"
                  autoComplete="none"
                  placeholder="Razão social da clínica"
                />
              </Form.Group>
            </Flexbox.Column>
            <Flexbox.Column className="mt-2">
              <Form.Group>
                <Form.Label className="label-sm pt-2" htmlFor="clinicalCareItemProviderTitleCont">Medicamento</Form.Label>
                <Form.ValidationField
                  name="['clinicalCareItemProvider.title_cont']"
                  className="input-sm"
                  id="clinicalCareItemProviderTitleCont"
                  autoComplete="none"
                  placeholder="Medicamento"
                />
              </Form.Group>
            </Flexbox.Column>
            <Flexbox.Column className="mt-2">
              <Form.Group>
                <Form.Label className="label-sm pt-2" htmlFor="clinicalCareItemProviderDescriptionCont">Descrição do medicamento</Form.Label>
                <Form.ValidationField
                  name="['clinicalCareItemProvider.description_cont']"
                  className="input-sm"
                  id="clinicalCareItemProviderDescriptionCont"
                  autoComplete="none"
                  placeholder="Descrição do medicamento"
                />
              </Form.Group>
            </Flexbox.Column>
          </>
        )}
      />
      <ListManager
        loading={loading}
        error={error}
        count={data?.count || 0}
      >
        {
          Boolean(data) && (
            <>
              <small>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Medicamento</th>
                      <th>Clínica</th>
                      <th className="text-right">Estoque</th>
                      <th width="100" className="text-right">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.rows.map((item) => (
                        <tr key={item.id} className={getClassName(item.balance)}>
                          <td>
                            <div><b>{item.clinicalCareItemProvider.title}</b></div>
                            <div>{item.clinicalCareItemProvider.description}</div>
                          </td>
                          <td>
                            <Flexbox.Columns className="align-center col-gapless">
                              <Flexbox.Column className="col-auto">
                                <Avatar
                                  avatarSize="md"
                                  className="mr-2"
                                  name={item?.clinic?.fullName}
                                  photoURL={item?.clinic?.photoURL}
                                  alt="Imagem de usuário"
                                />

                              </Flexbox.Column>
                              <Flexbox.Column>
                                <div>
                                  {item?.clinic?.fullName}
                                </div>
                                <div>
                                  <small>
                                    {documentNumberMask(item?.clinic?.documentNumber)}
                                  </small>
                                </div>
                              </Flexbox.Column>
                            </Flexbox.Columns>
                          </td>
                          <td className="text-right">{item.balance}</td>
                          <td className="text-right">
                            <Link to={`/app/clinical-cares/clinics/${item.clinicId}/items?id=${item.id}`} className="btn btn-action btn-sm btn-primary">
                              <i className="fas fa-eye" />
                            </Link>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </small>
              <Paginator paginatedList={data} />
            </>
          )
        }
      </ListManager>
    </Styles.Container>
  )
}

export default ClinicalCareItemClinicBalances

import {
  Card,
  Flexbox,
} from '@/components'
import currency from '@/lib/masks/currency'
import PropTypes from 'prop-types'
import React from 'react'
import LowIncomeForm from '../LowIncomeForm'

function LowIncomeCard({ lowIncome, onUpdate }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Dados de baixa renda</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-file" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        {
          lowIncome ? (
            <>
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>Renda familiar</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {currency(lowIncome?.familyIncome, 'R$ ')}
                </Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>Membros na fam.</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {lowIncome?.numberOfFamilyMembers}
                </Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>Nº CRAS</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {lowIncome?.cras || '-'}
                </Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns className="mb-1">
                <Flexbox.Column>Nº NIS</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">
                  {lowIncome?.nis || '-'}
                </Flexbox.Column>
              </Flexbox.Columns>
            </>
          ) : (
            <div><cite>Nenhum dado de baixa renda cadastrado</cite></div>
          )
        }
      </Card.Body>
      <Card.Footer>
        <LowIncomeForm initialData={lowIncome} onUpdate={onUpdate} />
      </Card.Footer>
    </Card.Base>
  )
}

LowIncomeCard.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  lowIncome: PropTypes.objectOf(Object),
}

LowIncomeCard.defaultProps = {
  lowIncome: null,
}

export default LowIncomeCard

import {
 Avatar, ListManager, Paginator, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import useQuery from '@/hooks/useQuery'
import { COMPLAINT } from '@/lib/enums'
import dateMask from '@/lib/masks/date'
import animalFairs from '@/services/api/animalFairs'
import React, { useCallback, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

// import { Container } from './styles';

function Complaints() {
  const [clearing, setClearing] = useState(false)
  const history = useHistory()
  const { petId } = useParams()
  const query = useQuery()
  const { data, loading, error } = useAxiosRequest(animalFairs.complaints, petId, query)

  const clearComplaints = useCallback(async () => {
    try {
      setClearing(true)
      await animalFairs.clearComplaints(petId)
      toast.success('Denúncias removidas')
      setClearing(false)
      history.goBack()
    } catch (e) {
      setClearing(false)
      toast.error(e?.message)
    }
  }, [history, petId])

  const handleClear = useCallback(async () => {
    confirmAlert({
      title: 'Limpar denúncias',
      message: 'Você está certo que deseja remover todas as denúncias?',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim, limpar denúncias',
          className: 'bg-primary',
          onClick: clearComplaints,
        },
      ],
    })
  }, [clearComplaints])

  return (
    <div className="modal active">
      <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
      <div className="modal-container">
        <div className="modal-header">
          <a
            type="button"
            className="btn btn-clear float-right"
            onClick={() => history.goBack()}
          />
          <div className="modal-title h5">
            Denúncias na postagem
          </div>
        </div>
        <div className="modal-body">
          <ListManager count={data?.count || 0} error={error} loading={loading}>
            {Boolean(data) && (
              <>
                <table className="table text-small">
                  <tbody>
                    {
                      data.rows.map((complaint) => (
                        <tr key={complaint.id}>
                          <td width={200} className="text-ellipsis">
                            <small>
                              Por:&nbsp;
                              <Avatar avatarSize="xs" name={complaint?.author?.fullName} photoURL={complaint?.author?.photoURL} />
                              &nbsp;{complaint?.author?.fullName}
                            </small>
                            <div>
                              <small>Em: <b>{dateMask(complaint?.createdAt)}</b></small>
                            </div>
                          </td>
                          <td>
                            <small>Motivo: <b>{COMPLAINT.type.t(complaint?.type)}</b></small>
                            <div>
                              <small>Detalhes: {complaint?.description ? <b>{complaint?.description}</b> : 'Não informado'}</small>
                            </div>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
                <div className="text-right">
                  <Paginator paginatedList={data} />
                </div>
              </>
            )}
          </ListManager>
        </div>
        <div className="modal-footer">
          {
            Boolean(data?.count) && (
              <Styles.Button
                onClick={handleClear}
                role="button"
                disabled={clearing}
                className={`btn-sm btn-primary ${clearing ? 'loading' : ''}`}
              >
                <i className="fas fa-broom" />
                &nbsp;Limpar denúncias
              </Styles.Button>
            )
          }
        </div>
      </div>
    </div>
  )
}

export default Complaints

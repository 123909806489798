const mapping = {
  screening: {
    title: 'O atendimento está em processo de triagem.',
    icon: '',
  },
  forwarded: {
    title: 'Atendimento encaminhado.',
    bgIconClass: 'bg-primary',
    icon: 'fas fa-ambulance fa-xs',
  },
  hospitalized: {
    title: 'Pet internado na clínica',
    bgIconClass: 'bg-primary',
    icon: 'fas fa-star-of-life',
  },
  medicalRelease: {
    title: 'O pet recebeu alta',
    bgIconClass: 'bg-success',
    icon: 'fas fa-user-md',
  },
  death: {
    title: 'O pet faleceu',
    bgIconClass: 'bg-dark',
    icon: 'fas fa-cross',
  },
  pullout: {
    title: 'O pet está em processo de retirada',
    bgIconClass: 'bg-primary',
    icon: 'fas fa-undo',
  },
  caught: {
    title: 'O pet foi retirado da clínica',
    bgIconClass: 'bg-success',
    icon: 'fas fa-undo',
  },
  done: {
    title: 'O atendimento foi concluído',
    bgIconClass: 'bg-success',
    icon: 'fas fa-check',
  },
  canceled: {
    title: 'O atendimento foi cancelado',
    bgIconClass: 'bg-error',
    icon: 'fas fa-times',
  },
}

export default (status) => mapping[status] || { title: status, icon: 'fas fa-question' }

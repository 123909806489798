import {
  Card,
  Flexbox, Form, PageHeader, Styles, UserCard,
} from '@/components'
import usePageTitle from '@/hooks/usePageTitle'
import partnerPermissions from '@/services/api/settings/partnerPermissions'
import React, { useLayoutEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

function Create({ title }) {
  usePageTitle({ title })
  const [query, setQuery] = useState('')
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(false)
  const inputRef = useRef()
  const history = useHistory()

  useLayoutEffect(() => {
    inputRef.current?.focus()
  }, [])

  async function fetchPerson(event) {
    event?.preventDefault()
    try {
      setLoading(true)
      const { data } = await partnerPermissions.findPerson(query)
      setUser(data)
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  async function addUser() {
    try {
      setLoading(true)
      const body = { person: { id: user?.id } }
      await partnerPermissions.create(body)
      toast.success(`${user?.fullName} adicionado aos usuários`)
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Adicionar usuário"
        icon="fas fa-user-plus"
        subtitle="Este usuário terá acesso ao fornecedor para atender solicitações"
      />
      <Flexbox.Columns>
        {
          user
            ? (
              <UserCard
                title="Confirma a adição deste usuário?"
                user={user}
                renderFooter={() => (
                  <Card.Footer className="text-right">
                    <button onClick={addUser} type="button" disabled={loading} className={`btn btn-primary ${loading ? 'loading' : ''}`}>
                      <i className="fas fa-plus" />
                      &nbsp;Adicionar usuário
                    </button>
                  </Card.Footer>
                )}
              />
            ) : (
              <fieldset disabled={loading}>
                <form onSubmit={fetchPerson}>
                  <Flexbox.Column className="col-6 col-sm-12">
                    <Card.Base>
                      <Card.Header>
                        <Flexbox.Columns className="align-center">
                          <Flexbox.Column>
                            <div className="h4">Novo usuário</div>
                          </Flexbox.Column>
                          <Flexbox.Column className="col-auto">
                            <i className="fas fa-lg fa-user-plus" />
                          </Flexbox.Column>
                        </Flexbox.Columns>
                      </Card.Header>
                      <Card.Body>
                        <div>
                          Este usuário terá poder para efetuar todas as ações
                          nas castrações e lotes,
                          também poderá aprovar ou rejeitar credenciamentos.
                        </div>
                        <div className="divider" />
                        <Form.Group>
                          <Form.Label htmlFor="queryInput">
                            Informe o <b>e-mail</b> ou <b>nº de documento</b> para buscar o usuário
                            <span className="text-error"> *</span>
                          </Form.Label>
                          <Form.Input ref={inputRef} onInput={(event) => setQuery(event?.target?.value)} id="queryInput" type="text" placeholder="Nº de documento ou e-mail" />
                        </Form.Group>
                      </Card.Body>
                      <Card.Footer className="text-right">
                        <button type="submit" className={`btn btn-primary ${loading ? 'loading' : ''}`} disabled={query.length < 8}>
                          <i className="fas fa-search" />
                          &nbsp;Buscar usuário
                        </button>
                      </Card.Footer>
                    </Card.Base>
                  </Flexbox.Column>
                </form>
              </fieldset>

            )
        }
      </Flexbox.Columns>
    </Styles.Container>
  )
}

export default Create

import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useLocation } from 'react-router-dom'
import AllAnimals from './AllAnimals'
import MyAnimals from './MyAnimals'
import NewAnimal from './NewAnimal'
import Avatar from '../Avatar'

export default function AnimalPicker({ title, animal, onChange }) {
  const [active, setActive] = useState(false)
  const [selected, setSelected] = useState('myAnimals')
  const history = useHistory()
  const location = useLocation()

  const onClose = useCallback(() => {
    setActive(false)
    history.replace(location.pathname)
  }, [history, location.pathname])

  const onAnimalChange = useCallback((value) => {
    onChange(value)
    onClose()
    setSelected('myAnimals')
  }, [onChange, onClose])

  return (
    <div>
      <a className="c-hand" onClick={() => setActive(true)}>
        {
          animal
            ? (
              <>
                <Avatar photoURL={animal.photoURL} avatarSize="sm" name={animal.name} />
                &nbsp;{animal.name}
              </>
            )
            : (
              <>
                <i className="fas fa-dog" />
                &nbsp;Escolher ou criar um pet
              </>
            )
        }
      </a>
      <div className={`modal ${active ? 'active' : ''}`}>
        <a type="button" className="modal-overlay" aria-label="Close" onClick={onClose} />
        <div className="modal-container">
          <div className="modal-header">
            <a
              type="button"
              className="btn btn-clear float-right"
              onClick={onClose}
            />
            <div className="modal-title h5">
              {title}
            </div>
          </div>
          <div className="modal-body">
            <ul className="tab tab-block">
              <li className="tab-item c-hand">
                <a onClick={() => setSelected('myAnimals')} className={selected === 'myAnimals' ? 'active' : ''}>
                  <i className="fas fa-paw" />
                  &nbsp;Meus pets
                </a>
              </li>
              <li className="tab-item c-hand">
                <a onClick={() => setSelected('allAnimals')} className={selected === 'allAnimals' ? 'active' : ''}>
                  <i className="fas fa-list" />
                  &nbsp;Todos os pets
                </a>
              </li>
              <li className="tab-item c-hand">
                <a onClick={() => setSelected('newAnimal')} className={selected === 'newAnimal' ? 'active' : ''}>
                  <i className="fas fa-plus" />
                  &nbsp;Novo Pet
                </a>
              </li>
            </ul>
            {
              selected === 'myAnimals' && (
                <MyAnimals onChange={onAnimalChange} />
              )
            }
            {
              selected === 'allAnimals' && (
                <AllAnimals onChange={onAnimalChange} />
              )
            }
            {
              selected === 'newAnimal' && (
                <NewAnimal onChange={onAnimalChange} />
              )
            }
          </div>
        </div>
      </div>
    </div>
  )
}

AnimalPicker.propTypes = {
  title: PropTypes.string.isRequired,
  animal: PropTypes.objectOf(Object),
  onChange: PropTypes.func.isRequired,
}

AnimalPicker.defaultProps = {
  animal: null,
}

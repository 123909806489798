import { errorColor } from '@/styles/variables'
import styled from 'styled-components'

export const ImageEvent = styled.img.attrs({
  className: 'img-responsive s-rounded',
})`
  max-width: 100%;
  transition: all .2s ease-in-out;

  :hover {
    transform: scale(1.02);
    box-shadow: 0 0.25rem 1rem rgba(48,55,66,.2);
  }
`

export const MapContainer = styled.div.attrs({ className: 's-rounded' })`
  width: 100%;
  height: 300px;
`

export const MapMarker = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 5px;
  background-color: ${errorColor};
  position: absolute;
  transform: translate(-50%, -100%);
`

import {
  Avatar, ListManager, PageHeader, Styles,
} from '@/components'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import interval from '@/lib/masks/interval'
import reports from '@/services/api/reports'
import React from 'react'
import FilterCastrations from '../components/FilterCastrations'

function CastrationsClinicTime({ title }) {
  usePageTitle({ title })
  const query = useQuery()
  const { data, loading, error } = useAxiosRequest(reports.clinicTime, query)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Tempo das clínicas"
        subtitle="Mostra a média de tempo no atendimento das clínicas"
        icon="fas fa-stopwatch"
        rightAction={() => <FilterCastrations />}
      />
      <ListManager count={data?.rows?.length || 0} loading={loading} error={error}>
        {
          Boolean(data) && (
            <>
              <div className="p-2 bg-gray s-rounded">
                <div className="text-small">
                  <b>Média de internamento: </b>
                  <span>
                    É o tempo médio que a clínica leva para concluir a castração após iniciá-la
                  </span>
                </div>
                <div className="text-small">
                  <b>Média total: </b>
                  <span>
                    É o tempo médio que a clínica leva para concluir
                    a castração após a aprovação do fornecedor
                  </span>
                </div>
              </div>
              <table className="table table-hover text-small">
                <thead>
                  <tr>
                    <th>Clínica</th>
                    <th className="text-right" width="200px">Média de internamento</th>
                    <th className="text-right" width="200px">Média total</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    data.rows.map((row) => (
                      <tr key={row?.id}>
                        <td>
                          <Avatar
                            avatarSize="sm"
                            name={row?.fullName}
                            photoURL={row?.photoURL}
                            alt="Imagem de usuário"
                          />
                          &nbsp;{row?.fullName}
                        </td>
                        <td className="text-right">
                          {interval(row?.attendanceTime)}
                        </td>
                        <td className="text-right">
                          {interval(row?.approvedPerformedTime)}
                        </td>
                      </tr>
                    ))
                  }
                  <tr>
                    <td>
                      <b>Tempo total das clínicas</b>
                    </td>
                    <td className="text-right">
                      {interval(data?.total?.attendanceTime)}
                    </td>
                    <td className="text-right">
                      {interval(data?.total?.approvedPerformedTime)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </>
          )
        }
      </ListManager>
    </Styles.Container>
  )
}

export default CastrationsClinicTime

import PropTypes from 'prop-types'
import React from 'react'

function AdditionalData({ additionalData }) {
  const stars = [1, 2, 3, 4, 5]

  const { rating } = additionalData || {}

  return (
    <>
      {
        rating && (
          <>
            {
              stars.map((star) => (
                <i key={star} className={`fas fa-star mr-1 ${star <= rating.rate ? 'text-primary' : 'text-gray'}`} />
              ))
            }
          </>
        )
      }
    </>
  )
}

AdditionalData.propTypes = {
  additionalData: PropTypes.objectOf(PropTypes.any),
}

AdditionalData.defaultProps = {
  additionalData: {},
}

export default AdditionalData

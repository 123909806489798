import batchContext from '@/contexts/batchContext'
import batchReducer, { INITIAL_STATE } from '@/reducers/batch'
import Batches from '@/views/app/Batches'
import Show from '@/views/app/Batches/Show'
import Approve from '@/views/app/Batches/Show/Approve'
import Reject from '@/views/app/Batches/Show/Reject'
import Status from '@/views/app/Batches/Status'
import React, { useReducer } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

function BatchesRoutes() {
  const { path } = useRouteMatch()
  const [state, dispatch] = useReducer(batchReducer, INITIAL_STATE)

  return (
    <div>
      <Route exact path={path}>
        <Batches title="Petis - Parceiros / Lotes" />
      </Route>
      <Route exact path={`${path}/status/:status`}>
        <Status title="Petis - Parceiros / Lotes" />
      </Route>
      <batchContext.Provider value={{ state, dispatch }}>
        <Route path={`${path}/status/:status/:id`}>
          <Show title="Petis - Parceiros / Lote" />
        </Route>
        <Route path={`${path}/status/:status/:id/approve`}>
          <Approve title="Petis - Parceiros / Aprovar lote" />
        </Route>
        <Route path={`${path}/status/:status/:id/reject`}>
          <Reject title="Petis - Parceiros / Rejeitar lote" />
        </Route>
      </batchContext.Provider>

    </div>
  )
}

export default BatchesRoutes

const actions = {
  screening: ['approve', 'setAnimal', 'changeClinic', 'cancel', 'death'],
  forwarded: ['cancel', 'death'],
  hospitalized: ['cancel', 'release', 'death'],
  medicalRelease: ['pullout', 'cancel'],
  death: [],
  pullout: ['done', 'cancel'],
  caught: ['done'],
  done: [],
  canceled: [],
}

export default (status) => actions[status] || []

import styled from 'styled-components'

export const Container = styled.div`
  width: 300px;
  right: -300px;
  position: fixed;
  background-color: #fff;
  border-left: 1px solid #eee;
  z-index: 500;
  top: 0;
  bottom: 0;
  transition: all .3s ease-in-out;

  &.active {
    right: 0;
  }
`

export const Content = styled.div`
  max-height: calc(100vh - 52px - 44px);
  overflow-y: auto;
`

export const Actions = styled.div`
`

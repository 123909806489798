import {
  Avatar, Card, ErrorBox, Flexbox,
} from '@/components'
import { textClasses } from '@/constants/castration'
import useAuth from '@/hooks/useAuth'
import { CASTRATION } from '@/lib/enums'
import dateMask from '@/lib/masks/date'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

function CastrationsCard({ castrations }) {
  const { licensing } = useAuth()

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Castrações do pet</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-cut" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body style={{ maxHeight: 300, overflow: 'auto' }}>
        {
          castrations?.length ? (

            <table className="table">
              <thead>
                <tr>
                  <th>Solicitante</th>
                  <th>Solicitado para</th>
                  <th width={200}>Solicitado em</th>
                  <th width={200}>Status</th>
                  <th width={1} className="text-right">Ações</th>
                </tr>
              </thead>
              <tbody>
                {
                  castrations.map((castration) => (
                    <tr key={castration?.id}>
                      <td>
                        <Avatar
                          avatarSize="sm"
                          name={castration?.tutor?.fullName}
                          photoURL={castration?.tutor?.photoURL}
                        />
                        &nbsp;{castration?.tutor?.fullName}
                      </td>
                      <td>
                        <Avatar
                          avatarSize="sm"
                          name={castration?.provider?.fullName}
                          photoURL={castration?.provider?.photoURL}
                        />
                        &nbsp;{castration?.provider?.fullName}
                      </td>
                      <td>{dateMask(castration?.createdAt)}</td>
                      <td className={textClasses[castration?.status]}>
                        {CASTRATION.status.t(castration?.status)}
                      </td>
                      <td className="text-right">
                        {
                          castration?.providerId === licensing?.licensedId && (
                            <Link
                              to={`/app/castrations/status/${castration?.status}/${castration?.id}`}
                              className="btn btn-action btn-sm btn-primary"
                            >
                              <i className="fas fa-eye" />
                            </Link>
                          )
                        }
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          ) : (
            <ErrorBox
              error={{
                icon: 'fas fa-cut fa-2x',
                title: 'Nenhuma castração',
                message: 'Esse pet não possui nenhuma castração',
              }}
            />
          )
        }
      </Card.Body>
      {/* <Card.Footer>
        <Add />
      </Card.Footer> */}
    </Card.Base>
  )
}

CastrationsCard.propTypes = {
  castrations: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default CastrationsCard

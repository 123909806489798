import React from 'react'
import PropTypes from 'prop-types'
import dateMask from '@/lib/masks/date'
import { Link } from 'react-router-dom'
import { Avatar } from '@/components'

function Castrations({ castrations }) {
  return (
    <table className="table table-striped table-hover">
      <thead>
        <tr>
          <th>Tutor</th>
          <th>Pet</th>
          <th>Solicitado em</th>
          <th width="1" className="text-right">Ações</th>
        </tr>
      </thead>
      <tbody>
        {
          Boolean(castrations) && castrations.map((castration) => (
            <tr key={castration.id}>
              <td>
                <Avatar
                  avatarSize="sm"
                  className="mr-1"
                  name={castration?.tutor?.fullName}
                  photoURL={castration?.tutor?.photoURL}
                  alt="Imagem de usuário"
                />
                {castration.tutor.fullName}
              </td>
              <td>
              <Link to={`/app/pets/${castration?.animalId}`}>
                <Avatar
                  avatarSize="sm"
                  name={castration?.animal?.name}
                  photoURL={castration?.animal?.photoURL}
                  smart={false}
                  alt="Imagem do animal"
                />
                {castration.animal.name}
              </Link>
              </td>
              <td>{dateMask(castration.createdAt, { hour: '2-digit', minute: '2-digit' })}</td>
              <td className="text-right">
                <Link
                  to={`/app/castrations/status/${castration?.status}/${castration.id}`}
                  className="btn btn-action btn-sm btn-primary"
                >
                  <i className="fas fa-eye" />
                </Link>
              </td>
            </tr>
          ))
        }
      </tbody>
    </table>
  )
}

Castrations.propTypes = {
  castrations: PropTypes.arrayOf(Object).isRequired,
}

export default Castrations

import { Form, LoadManager } from '@/components'
import useCastration from '@/hooks/useCastration'
import usePageTitle from '@/hooks/usePageTitle'
import yup from '@/lib/yupPt'
import { setCastration } from '@/reducers/castration'
import castrations from '@/services/api/castrations'
import React, { useState } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  description: yup.string().trim().required().min(10),
})

function Confirm({ title }) {
  usePageTitle({ title })
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const { state: { castration }, dispatch } = useCastration()
  const history = useHistory()

  async function confirm({ description }) {
    try {
      setLoading(true)
      const { data } = await castrations.confirm(id, { eventData: { description } })
      dispatch(setCastration(data))
      toast.success('Castração confirmada')
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <fieldset disabled={loading}>
      <Form.Formik
        initialValues={{ description: '' }}
        validationSchema={schema}
        onSubmit={confirm}
        validateOnMount
      >
        {
          ({
            errors, touched, isValid,
          }) => (
            <Form.ValidationForm>
              <div className="modal active" id="modal-id">
                <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
                <div className="modal-container">
                  <div className="modal-header">
                    <a
                      type="button"
                      className="btn btn-clear float-right"
                      onClick={() => history.goBack()}
                    />
                    <div className="modal-title h5">Confirmar castração #{id}</div>
                  </div>
                  <div className="modal-body">
                    <div className="content">
                      <LoadManager loading={!castration}>
                        <>
                          <p>
                            <i className="fas fa-exclamation-triangle" />&nbsp;
                            Utilize esse recurso somente se o tutor
                            não puder confirmar o atendimento.
                          </p>
                          <p className="text-small">
                            Neste momento você está ATESTANDO a execução da castração
                            e assumindo a responsabilidade da entrega do animal
                            ao seu tutor/responsável.
                          </p>
                          <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="description">
                              Explique porque está confirmando a castração de
                              <b className="text-primary"> {castration?.animal?.name} </b>
                              no lugar do tutor:
                              <span className="text-error">*</span>
                            </Form.Label>
                            <Form.ValidationField
                              name="description"
                              component="textarea"
                              id="description"
                              placeholder="Estou confirmando a castração porque ..."
                            />
                            {
                              touched?.description && errors?.description
                                ? <Form.Hint className="text-error">{errors.description}</Form.Hint> : null
                            }
                          </Form.Group>
                        </>
                      </LoadManager>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <a
                      className="btn mr-2"
                      disabled={loading}
                      onClick={() => history.goBack()}
                    >
                      <i className="fas fa-chevron-left" />
                      &nbsp;Voltar
                    </a>
                    <button
                      className={`btn btn-success ${loading ? 'loading' : ''}`}
                      type="submit"
                      disabled={!isValid}
                    >
                      <i className="fas fa-check" />
                      &nbsp;Confirmar castração
                    </button>
                  </div>
                </div>
              </div>
            </Form.ValidationForm>
          )
        }
      </Form.Formik>
    </fieldset>
  )
}

export default Confirm

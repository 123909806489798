import api from './api'

export default {
  list() {
    return api.get('/categories')
  },
  breedsByCategoryId(categoryId) {
    return api.get(`/categories/${categoryId}/breeds`)
  },
}

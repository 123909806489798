export const ADOPTION_FAIR_STATUS = {
  DRAFT: 'draft',
  CREATED: 'created',
  RUNNING: 'running',
  CLOSED: 'closed',
  CANCELED: 'canceled',
}

export const singularStatusMapLabel = {
  draft: 'Rascunho',
  created: 'Criada',
  running: 'Em andamento',
  closed: 'Encerrada',
  canceled: 'Cancelada',
}

export const iconClasses = {
  draft: 'fas fa-file',
  created: 'fas fa-square',
  running: 'fas fa-play-circle',
  closed: 'fas fa-check-circle',
  canceled: 'fas fa-times-circle',
}

export const textClasses = {
  draft: 'text-gray',
  created: 'text-primary',
  running: 'text-success pulse',
  closed: 'text-primary',
  canceled: 'text-error',
}

export const descriptions = {
  draft: 'Esta feira ainda não está disponível para os usuários',
  created: 'Os usuários já podem visualizar a feira',
  running: 'A feira está em andamento, os usuários podem adotar pets',
  closed: 'A feira acabou',
  canceled: 'A feira foi cancelada',
}

export const singularRestrictMapLabel = {
  true: 'Restrita',
  false: 'Livre',
}

export const textRestrict = {
  true: 'text-warning',
  false: 'text-success',
}

export const iconRestrict = {
  true: 'fas fa-lock',
  false: 'fas fa-lock-open',
}

export const descriptionsRestrict = {
  true: 'Apenas o organizador da feira pode adicionar animais à ela',
  false: 'Os usuários e o organizador da feira podem adicionar animais à ela',
}

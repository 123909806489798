import clinicContext from '@/contexts/clinicContext'
import clinicReducer, { INITIAL_STATE } from '@/reducers/clinic'
import Approve from '@/views/app/clinics/Show/Approve'
import Licensed from '@/views/app/clinics/Licensed'
import Requested from '@/views/app/clinics/Requested'
import Show from '@/views/app/clinics/Show'
import React, { useReducer } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'
import Reject from '@/views/app/clinics/Show/Reject'
import Transaction from '@/views/app/clinics/Show/Transaction'
import Transactions from '@/views/app/clinics/Show/Transactions'
import Revoke from '@/views/app/clinics/Show/Revoke'

function ClinicsRoutes() {
  const { path } = useRouteMatch()
  const [state, dispatch] = useReducer(clinicReducer, INITIAL_STATE)

  return (
    <div>
      <Route exact path={`${path}/approved`}>
        <Licensed title="Petis - Parceiros / Clínicas licenciadas" />
      </Route>
      <Route exact path={`${path}/requested`}>
        <Requested title="Petis - Parceiros / Solicitações de credenciamento" />
      </Route>
      <clinicContext.Provider value={{ state, dispatch }}>
        <Route path={`${path}/:status/:id`}>
          <Show title="Petis - Parceiros / Clínica" />
        </Route>
        <Route path={`${path}/:status/:id/revoke`}>
          <Revoke title="Petis - Parceiros / Clínica / Revogar" />
        </Route>
        <Route path={`${path}/:status/:id/approve`}>
          <Approve title="Petis - Parceiros / Aprovar clínica " />
        </Route>
        <Route path={`${path}/:status/:id/reject`}>
          <Reject title="Petis - Parceiros / Rejeitar clínica " />
        </Route>
        <Route path={`${path}/:status/:id/transaction`}>
          <Transaction title="Petis - Parceiros / Adicionar saldo" />
        </Route>
        <Route path={`${path}/:status/:id/transactions`}>
          <Transactions title="Petis - Parceiros / Transações da clínica" />
        </Route>
      </clinicContext.Provider>
    </div>
  )
}

export default ClinicsRoutes

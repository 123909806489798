import {
  Flexbox, LoadManager,
} from '@/components'
import { Button } from '@/components/styles'
import useAdoptionFair from '@/hooks/useAdoptionFair'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import dateMask from '@/lib/masks/date'
import phoneNumber from '@/lib/masks/phoneNumber'
import { setAdoptionFair } from '@/reducers/adoptionFair'
import adoptionsRequested from '@/services/api/adoptionsRequested'
import React, { useState } from 'react'
import {
  useHistory, useParams,
} from 'react-router-dom'
import { toast } from 'react-toastify'

function ApproveAdoptionRequested({ title }) {
  usePageTitle({ title })
  const [approving, setApproving] = useState(false)
  const { petId } = useParams()
  const history = useHistory()
  const { state: { adoptionFair }, dispatch } = useAdoptionFair()

  const {
    data, loading, error,
  } = useAxiosRequest(adoptionsRequested.show, petId)

  async function approve() {
    try {
      setApproving(true)
      await adoptionsRequested.approve(petId)
      dispatch(
        setAdoptionFair({
          ...adoptionFair,
          countAdoptionRequested: adoptionFair.countAdoptionRequested - 1,
        }),
      )
      toast.success('Solicitação de adoção aprovada')
      history.goBack()
      setApproving(false)
    } catch (e) {
      toast.error(e?.message)
      setApproving(false)
    }
  }

  return (
    <div className="modal active">
      <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
      <div className="modal-container">
        <div className="modal-header">
          <a
            type="button"
            className="btn btn-clear float-right"
            onClick={() => history.goBack()}
          />
          <div className="modal-title h5">
            Você está certo que deseja aprovar a solicitação de adoção do pet?
          </div>
        </div>
        <div className="modal-body">
          <LoadManager error={error} loading={loading}>
            <>
              <Flexbox.Columns className="align-center">
                <Flexbox.Column className="text-ellipsis">
                  <div className="h5">Detalhes do doador</div>
                </Flexbox.Column>
              </Flexbox.Columns>
              <div className="divider" />
              <Flexbox.Columns>
                <Flexbox.Column>Nome:</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">{data?.donor?.fullName}</Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns>
                <Flexbox.Column>Telefone:</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">{phoneNumber(data?.donor?.phoneNumber) || '-'}</Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns>
                <Flexbox.Column>Email:</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">{data?.donor?.email}</Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns>
                <Flexbox.Column>Pet doado em:</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">{dateMask(data?.animalFair?.createdAt)}</Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns className="align-center pt-2">
                <Flexbox.Column className="text-ellipsis">
                  <div className="h5">Detalhes do solicitante</div>
                </Flexbox.Column>
              </Flexbox.Columns>
              <div className="divider" />
              <Flexbox.Columns>
                <Flexbox.Column>Nome:</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">{data?.newTutor?.fullName}</Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns>
                <Flexbox.Column>Telefone:</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">{phoneNumber(data?.newTutor?.phoneNumber) || '-'}</Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns>
                <Flexbox.Column>Email:</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">{data?.newTutor?.email}</Flexbox.Column>
              </Flexbox.Columns>
              <Flexbox.Columns>
                <Flexbox.Column>Adoção solicitada em:</Flexbox.Column>
                <Flexbox.Column className="col-auto text-bold">{dateMask(data?.createdAt)}</Flexbox.Column>
              </Flexbox.Columns>
            </>
          </LoadManager>
        </div>
        <div className="modal-footer">
          <Button
            className={`btn-sm btn-success ml-2 ${approving ? 'loading' : ''}`}
            disabled={approving}
            onClick={approve}
          >
            <i className="fas fa-check" />
            &nbsp;Aprovar adoção
          </Button>
        </div>
      </div>
    </div>
  )
}

export default ApproveAdoptionRequested

import { LOCAL_STORAGE_AUTH_TOKEN, LOCAL_STORAGE_LICENSING_ID } from '@/constants/session'

export async function authorizationInterceptor(config) {
  const token = await localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN)
  if (!token) return config
  const { headers } = config
  return {
    ...config,
    url: config.url.replace(':partnerLicensingId', localStorage.getItem(LOCAL_STORAGE_LICENSING_ID)),
    headers: {
      ...headers,
      Authorization: `Bearer ${token}`,
    },
  }
}

export function errorHandlerInterceptor(errors = {}) {
  return (error) => {
    if (error?.response?.status === 401) window.location.replace('/login')
    if (error.message === 'Network Error') {
      return Promise.reject({
        code: 'networkError',
        message: 'Falha na conexão',
      })
    }
    const e = {
      ...(error.response.data || {}),
      code: error.response?.data?.code,
      message: errors[error.response?.data?.code] || error?.response?.data?.message,
    }

    return Promise.reject(e)
  }
}

/* eslint-disable react/jsx-props-no-spreading */
import {
  Filter, Form, ListManager, PageHeader, Paginator, Styles,
} from '@/components'
import { clinicalCareStatus } from '@/constants/clinicalCares'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import useQuery from '@/hooks/useQuery'
import clinicalCares from '@/services/api/clinicalCares'
import React from 'react'
import { Link } from 'react-router-dom'
import Row from './components/Row'

function ClinicalCares({ title }) {
  usePageTitle({ title })
  const query = useQuery()

  const {
    loading, data, error,
  } = useAxiosRequest(clinicalCares.list, query)

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Atendimentos clínicos"
        icon="fas fa-stethoscope"
        subtitle="Lista com os atendimentos clínicos"
        rightAction={() => (
          <>
            <Filter
              initialValues={{
                'animal.categoryId_eq': '',
                'animal.gender_eq': '',
                'animal.name_cont': '',
                'clinic.fullName_cont': '',
                status_eq: '',
                createdAt_between: [],
              }}
              title="Filtrar atendimentos"
              renderForm={({ values, setFieldValue }) => (
                <>
                  <div className="divider text-center" data-content="Períodos" />
                  <Form.Group>
                    <Form.Label className="text-sm" htmlFor="createdAtBetween">Solicitadas de - até</Form.Label>
                    <Form.DatePickerField
                      placeholderText="Data início - Data fim"
                      dateFormat="dd/MM/yyyy"
                      startDate={
                        values.createdAt_between[0] ? new Date(values.createdAt_between[0]) : null
                      }
                      endDate={
                        values.createdAt_between[1] ? new Date(values.createdAt_between[1]) : null
                      }
                      selectsEnd
                      className="form-input input-sm"
                      selectsRange
                      isClearable
                      name="createdAt_between"
                      onChange={(v) => {
                        const [start, end] = v
                        setFieldValue('createdAt_between', [start, end ? new Date(end.setUTCHours(23, 59, 59, 999)) : null])
                      }}
                    />
                  </Form.Group>
                  <div className="divider text-center" data-content="Detalhes do atendimento" />
                  <Form.Group>
                    <Form.Label htmlFor="status_eq">Status</Form.Label>
                    <Form.ValidationField name="status_eq">
                      {
                        ({ field }) => (
                          <Form.Select
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            id="status_eq"
                            className="select-sm"
                            placeholder="Status do atendimento"
                          >
                            <option value="">Todos</option>
                            {
                              Object.keys(clinicalCareStatus).map((status) => (
                                <option key={status} value={status}>
                                  {clinicalCareStatus[status]}
                                </option>
                              ))
                            }
                          </Form.Select>
                        )
                      }
                    </Form.ValidationField>
                  </Form.Group>
                  <div className="divider text-center" data-content="Animal" />
                  <Form.Group>
                    <Form.Label className="label-sm pt-2" htmlFor="animal.name_cont">Nome do pet</Form.Label>
                    <Form.ValidationField
                      name="['animal.name_cont']"
                      className="input-sm"
                      id="animal.name_cont"
                      autoComplete="none"
                      placeholder="Totó da Silva"
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="animal.categoryId_eq">Espécie do pet</Form.Label>
                    <Form.ValidationField name="['animal.categoryId_eq']">
                      {
                        ({ field }) => (
                          <Form.Select
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            {...field}
                            id="animal.categoryId_eq"
                            className="select-sm"
                            placeholder="Espécie do pet"
                          >
                            <option value="">Todos</option>
                            <option value="1">Cachorro</option>
                            <option value="2">Gato</option>
                          </Form.Select>
                        )
                      }
                    </Form.ValidationField>
                  </Form.Group>
                  <Form.Group>
                    <Form.Label htmlFor="animal.gender_eq">Gênero do pet</Form.Label>
                    <Form.ValidationField name="['animal.gender_eq']">
                      {
                        ({ field }) => (
                          <Form.Select
                            {...field}
                            id="animal.gender_eq"
                            className="select-sm"
                            placeholder="Gênero do pet"
                          >
                            <option value="">Todos</option>
                            <option value="male">Macho</option>
                            <option value="female">Fêmea</option>
                          </Form.Select>
                        )
                      }
                    </Form.ValidationField>
                  </Form.Group>
                  <div className="divider text-center" data-content="Clínica" />
                  <Form.Group>
                    <Form.Label className="label-sm pt-2" htmlFor="['clinic.fullName_cont']">Nome da clínica</Form.Label>
                    <Form.ValidationField
                      name="['clinic.fullName_cont']"
                      className="input-sm"
                      id="['clinic.fullName_cont']"
                      autoComplete="none"
                      placeholder="Nome da clínica"
                    />
                  </Form.Group>
                </>
              )}
            />
            <Link to="/app/clinical-cares/form/add" className="btn btn-primary">
              <i className="fas fa-plus" />
              &nbsp;Adicionar
            </Link>
          </>
        )}
      />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        {
          Boolean(data) && (
            <div>
              <small>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Pet</th>
                      <th>Clínica</th>
                      <th width="150px">Status</th>
                      <th width="150px">Criado em</th>
                      <th width="150px">Agendado para</th>
                      <th width="100px" className="text-right">Valor atual</th>
                      <th width="1">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.rows.map((clinicalCare) => (
                        <Row key={clinicalCare.id} clinicalCare={clinicalCare} />
                      ))
                    }
                  </tbody>
                </table>
              </small>
              {
                Boolean(data) && (
                  <div className="float-right my-2">
                    <Paginator paginatedList={data} />
                  </div>
                )
              }
            </div>
          )
        }
      </ListManager>
    </Styles.Container>
  )
}

export default ClinicalCares

/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import {
  Flexbox, Form, LoadManager,
} from '@/components'
import useMistreatment from '@/hooks/useMistreatment'
import usePageTitle from '@/hooks/usePageTitle'
import yup from '@/lib/yupPt'
import { setMistreatment } from '@/reducers/mistreatment'
import mistreatments from '@/services/api/mistreatments'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  description: yup.string().trim(),
})
function StartCheck({ title }) {
  usePageTitle({ title })
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const { state: { mistreatment }, dispatch } = useMistreatment()
  const history = useHistory()

  async function onSubmit(values) {
    try {
      setLoading(true)
      const { data } = await mistreatments.startCheck(id, values)
      dispatch(setMistreatment(data))
      toast.success('Verificação iniciada')
      setLoading(false)
      history.goBack()
    } catch (e) {
      setLoading(false)
      toast.error(e?.message)
    }
  }

  return (
    <fieldset disabled={loading}>
      <div className="modal active" id="modal-id">
        <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
        <div className="modal-container">
          <div className="modal-header">
            <a
              type="button"
              className="btn btn-clear float-right"
              onClick={() => history.goBack()}
            />
            <div className="modal-title h5">Iniciar verificação #{id}</div>
          </div>
          <div className="modal-body">
            <div className="content">
              <LoadManager loading={!mistreatment}>
                <>
                  <div className="mb-1">Confirma o início da verificação desta denúncia?</div>
                  <div className="text-small mb-1">
                    Esta ação vai gerar um evento de inicialização.
                  </div>
                  <Form.Formik
                    initialValues={{
                      description: '',
                    }}
                    validationSchema={schema}
                    onSubmit={onSubmit}
                    validateOnMount
                  >
                    {
                      ({ touched, errors }) => (
                        <Form.ValidationForm>
                          <Flexbox.Columns>
                            <Flexbox.Column className="col-12">
                              <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="description">Observações</Form.Label>
                                <Form.ValidationField
                                  name="description"
                                  id="description"
                                >
                                  {
                                    ({ field }) => (
                                      <textarea
                                        name="description"
                                        className="form-input"
                                        rows={2}
                                        placeholder="Você pode adicionar alguma observação..."
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...field}
                                      />
                                    )
                                  }
                                </Form.ValidationField>
                                {
                                  touched?.description && errors?.description
                                    ? <Form.Hint className="text-error">{errors.description}</Form.Hint> : null
                                }
                              </Form.Group>
                            </Flexbox.Column>
                          </Flexbox.Columns>
                          <div className="modal-footer" style={{ paddingLeft: 0, paddingRight: 0 }}>
                            <a
                              className="btn mr-2"
                              disabled={loading}
                              onClick={() => history.goBack()}
                            >
                              <i className="fas fa-chevron-left" />
                              &nbsp;Voltar
                            </a>
                            <button
                              className={`btn btn-primary ${loading ? 'loading' : ''}`}
                              disabled={loading}
                              type="submit"
                            >
                              <i className="fas fa-play" />
                              &nbsp;Iniciar
                            </button>
                          </div>
                        </Form.ValidationForm>
                      )
                    }
                  </Form.Formik>
                </>
              </LoadManager>
            </div>
          </div>
        </div>
      </div>
    </fieldset>
  )
}

export default StartCheck

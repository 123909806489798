/* eslint-disable jsx-a11y/control-has-associated-label */
import React from 'react'
import PropTypes from 'prop-types'
import ImageThumb from '../ImageThumb'

export default function AttachmentPreview({ src, size }) {
  const format = src.split('.').pop()

  if (format === 'pdf') {
    return (
      <a href={src} target="_blank" rel="noreferrer">
        <img alt="icone pdf" src="/pdf-icon.png" className="s-rounded bg-dark" style={{ width: size }} />
      </a>
    )
  }

  return (
    <a href={src} target="_blank" rel="noreferrer">
      <ImageThumb className="s-rounded" src={src} width={size} height={size} />
    </a>
  )
}

AttachmentPreview.propTypes = {
  src: PropTypes.string.isRequired,
  size: PropTypes.number.isRequired,
}

import { useContext } from 'react'
import adoptionFairContext from '@/contexts/adoptionFairContext'

/**
 * @typedef {Object} AdoptionFairReducer
 * @property {Object} state - State of adoption fair
 * @property {Function} dispatch - dispatch
 */

/**
 * @returns {AdoptionFairReducer}
 */
 export default function useAdoptionFair() {
  return useContext(adoptionFairContext)
}

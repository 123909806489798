export const LICENSING_TYPE = {
  CASTRATION_PROVIDER: 'castrationProvider',
  CHIP_PROVIDER: 'chipProvider',
  FAIRS_MANAGER: 'fairsManager',
  CLINICAL_CARE: 'clinicalCare',
  MISTREATMENT: 'mistreatment',
}

export const LICENSING_TYPE_TRANSLATION = {
  [LICENSING_TYPE.CASTRATION_PROVIDER]: 'Fornecer castrações',
  [LICENSING_TYPE.CHIP_PROVIDER]: 'Aplicar chips',
  [LICENSING_TYPE.FAIRS_MANAGER]: 'Gerenciar feiras',
  [LICENSING_TYPE.CLINICAL_CARE]: 'Atendimentos clínicos',
  [LICENSING_TYPE.MISTREATMENT]: 'Denúncias de maus tratos',
}

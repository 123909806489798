import {
  AddressPicker,
  AnimalPicker,
  Flexbox, Form, Mask, PageHeader, Styles,
} from '@/components'
import usePageTitle from '@/hooks/usePageTitle'
import yup from '@/lib/yupPt'
import clinicalCares from '@/services/api/clinicalCares'
import React, { useCallback, useLayoutEffect, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  clinic: yup.object().shape({
    id: yup.number().required(),
  }).required(),
  protocolNumber: yup.string().trim(),
  scheduledTo: yup.date(),
  extraData: yup.object().shape({
    personName: yup.string(),
    personPhoneNumber: yup.string(),
  }).optional(),
  description: yup.string().trim().required(),
})
function ClinicalCaresForm({ title }) {
  const [animal, setAnimal] = useState(null)
  const [address, setAddress] = useState(null)
  const [loading, setLoading] = useState(false)
  const [fetchingLicensings, setFetchingLicensings] = useState(false)
  const [licensings, setLicensings] = useState([])
  const history = useHistory()

  usePageTitle({ title })

  const onSubmit = useCallback(async (values) => {
    try {
      setLoading(true)
      const body = { ...values, animal, address }
      const { data } = await clinicalCares.create(body)
      history.replace(`/app/clinical-cares/show/${data.id}`)
    } catch (e) {
      toast.error(e?.message)
      setLoading(false)
    }
  }, [address, animal, history])

  const fetchLicensings = useCallback(async () => {
    try {
      setFetchingLicensings(true)
      const { data } = await clinicalCares.allowedClinics()
      setLicensings(data)
    } finally {
      setFetchingLicensings(false)
    }
  }, [])

  useLayoutEffect(() => {
    fetchLicensings()
  }, [fetchLicensings])

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Novo atendimento clínico"
        icon="fas fa-stethoscope"
        subtitle="Informe os dados para criar o atendimento, após a criação o atendimento irá para triagem"
      />
      <fieldset disabled={loading}>
        <Form.Group>
          <Form.Label htmlFor="animal">Qual é o pet que será atendido? <small>(Será necessário informar antes de encaminhar para a clínica)</small></Form.Label>
          <AnimalPicker onChange={setAnimal} animal={animal} title="Informar o pet a ser atendido" />
        </Form.Group>
        <div className="divider" />
        <Form.Group>
          <Form.Label htmlFor="animal">Informe o endereço onde o pet será buscado  <span className="text-error">*</span></Form.Label>
          <AddressPicker title="Informe o local do pet" address={address} onChange={setAddress} />
        </Form.Group>
        <div className="divider" />
        <Form.Formik
          initialValues={{
            description: '', protocolNumber: '', clinic: null, extraData: { personName: '', personPhoneNumber: '' },
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {
            ({
              errors, touched, isValid, values, setFieldValue,
            }) => (
              <Form.ValidationForm>
                <Flexbox.Columns>

                  <Flexbox.Column className="col-12">
                    {
                      fetchingLicensings ? (
                        <div className="columns mx-1 mb-2">
                          <div className="column col-auto loading" />
                          <div className="column">
                            <cite>
                              Buscando licenças
                            </cite>
                          </div>
                        </div>
                      ) : (
                        <Form.Group>
                          <Form.Label>Qual clínica realizará o atendimento? <span className="text-error">*</span></Form.Label>
                          {
                            !fetchingLicensings && !licensings.length
                            && (
                              <div>
                                <small>
                                  <cite>
                                    Nenhuma clínica está licenciada
                                    para realizar atendimentos,
                                    <Link to="/app/clinical-cares/clinics"> clique aqui para gerenciar as licenças</Link>
                                  </cite>
                                </small>
                                <div className="divider" />
                              </div>
                            )
                          }
                          {
                            licensings.map((licensing) => (
                              <React.Fragment key={licensing?.id}>
                                <small>
                                  <Form.Label className="form-radio mb-2">
                                    <Form.ValidationField
                                      type="radio"
                                      name="clinic.id"
                                      value={licensing?.licensedId}
                                      checked={
                                        String(values?.clinic?.id) === String(licensing?.licensedId)
                                      }
                                    />
                                    <i className="form-icon" />
                                    &nbsp;{licensing?.licensed?.fullName}
                                  </Form.Label>
                                </small>
                              </React.Fragment>
                            ))
                          }
                        </Form.Group>
                      )
                    }
                  </Flexbox.Column>
                  <div className="column col-12 divider" />
                  <Flexbox.Column className="col-4 col-sm-12">
                    <Form.Group>
                      <Form.Label htmlFor="scheduledTo">Agendado para</Form.Label>
                      <Form.ValidationField
                        name="scheduledTo"
                        id="scheduledTo"
                      >
                        {
                          ({ field }) => (
                            <Form.DatePickerField
                              name="scheduledTo"
                              dateFormat="dd/MM/yyyy HH:mm"
                              minDate={new Date()}
                              showTimeSelect
                              className="form-input"
                              id="scheduledTo"
                              placeholderText="Nascido em"
                              selected={field.value || null}
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...field}
                            />
                          )
                        }

                      </Form.ValidationField>
                    </Form.Group>
                  </Flexbox.Column>
                  <div className="column col-12 divider" />
                  <Flexbox.Column className="col-12">
                    <Form.Group className={`${touched?.description && errors?.description ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="description">Informe os sintomas, situação do pet e qualquer informação pertinente <span className="text-error">*</span></Form.Label>
                      <Form.ValidationField
                        name="description"
                        id="description"
                      >
                        {
                          ({ field }) => (
                            <textarea
                              name="description"
                              className="form-input"
                              rows={5}
                              placeholder={
                                `Exemplo: O pet foi resgatado, está apresentando os seguintes sintomas:
- Febre
- Apatia
- Falta de apetite`
                              }
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...field}
                            />
                          )
                        }
                      </Form.ValidationField>
                      {
                        touched?.description && errors?.description
                          ? <Form.Hint className="text-error">{errors.description}</Form.Hint> : null
                      }
                    </Form.Group>
                  </Flexbox.Column>
                  <Flexbox.Column className="col-12">
                    <Form.Group className={`${touched?.district && errors?.district ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="protocolNumber">Protocolo</Form.Label>
                      <Form.ValidationField
                        name="protocolNumber"
                        id="protocolNumber"
                        placeholder="Protocolo"
                      />
                      {
                        touched?.protocolNumber && errors?.protocolNumber
                          ? <Form.Hint className="text-error">{errors.protocolNumber}</Form.Hint> : null
                      }
                    </Form.Group>
                  </Flexbox.Column>
                  <Flexbox.Column className="col-12 my-2">
                    <div className="divider" />
                  </Flexbox.Column>
                  <Flexbox.Column className="col-6 col-sm-12">
                    <Form.Group className={`${touched?.extraData?.personName && errors?.extraData?.personName ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="extraData.personName">Nome da pessoa</Form.Label>
                      <Form.ValidationField
                        name="extraData.personName"
                        id="extraData.personName"
                        placeholder="Nome da pessoa"
                      />
                      {
                        touched?.extraData?.personName && errors?.extraData?.personName
                          ? <Form.Hint className="text-error">{errors?.extraData?.personName}</Form.Hint> : null
                      }
                    </Form.Group>
                  </Flexbox.Column>
                  <Flexbox.Column className="col-6 col-sm-12">
                    <Form.Group className={`${touched?.extraData?.personPhoneNumber && errors?.extraData?.personPhoneNumber ? 'has-error' : ''}`}>
                      <Form.Label htmlFor="extraData.personPhoneNumber">Fone da pessoa</Form.Label>
                      <Form.ValidationField name="extraData.personPhoneNumber">
                        {
                          ({ field }) => (
                            <Mask.Phone
                              id="extraData.personPhoneNumber"
                              placeholder="(00) 99999-9999"
                              // eslint-disable-next-line react/jsx-props-no-spreading
                              {...field}
                              onAccept={(v) => setFieldValue('extraData.personPhoneNumber', v)}
                            />
                          )
                        }
                      </Form.ValidationField>
                      {
                        touched?.extraData?.personPhoneNumber
                          && errors?.extraData?.personPhoneNumber
                          ? <Form.Hint className="text-error">{errors?.extraData?.personPhoneNumber}</Form.Hint> : null
                      }
                    </Form.Group>
                  </Flexbox.Column>
                </Flexbox.Columns>
                <div className="text-right mt-2">
                  <button type="submit" className={`btn btn-primary ${loading ? 'loading' : ''}`} disabled={!isValid || !address}>
                    <i className="fas fa-check" />
                    &nbsp;Criar atendimento
                  </button>
                </div>
              </Form.ValidationForm>
            )
          }
        </Form.Formik>
      </fieldset>
    </Styles.Container>
  )
}

export default ClinicalCaresForm

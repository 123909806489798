import styled from 'styled-components'

export const Base = styled.ul.attrs({
  className: 'menu',
})``

export const Divider = styled.li.attrs({
  className: 'divider text-center',
})``

export const Item = styled.li.attrs({
  className: 'menu-item',
})``

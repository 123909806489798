import { Flexbox, Card } from '@/components'
import React from 'react'
import PropTypes from 'prop-types'
import Stars from './Stars'

function RatingCard({ avgRating }) {
  let ratingLabel = ''
  if (avgRating.rating >= 0 && avgRating.rating <= 1) {
    ratingLabel = 'Ruim'
  } else if (avgRating.rating > 1 && avgRating.rating <= 2) {
    ratingLabel = 'Regular'
  } else if (avgRating.rating > 2 && avgRating.rating <= 3) {
    ratingLabel = 'Bom'
  } else if (avgRating.rating > 3 && avgRating.rating <= 4) {
    ratingLabel = 'Ótimo'
  } else if (avgRating.rating > 4 && avgRating.rating <= 5) {
    ratingLabel = 'Excelente'
  }

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Avaliação média da clínica</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-star" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        {
          avgRating?.rating ? (
            <>
              <div>
                <b>{avgRating?.rating}</b> / 5.00
              </div>
              <small>{ratingLabel}</small>
              <Stars rate={avgRating?.rating} />
            </>
          ) : <div>Esta clínica ainda não possui avaliação</div>
        }

      </Card.Body>
    </Card.Base>
  )
}

RatingCard.propTypes = {
  avgRating: PropTypes.objectOf(Object).isRequired,
}

export default RatingCard

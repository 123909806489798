import api from '../api'

export default {
  list(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/settings/partner-permissions', { params })
  },
  findPerson(query = '') {
    return api.get(`/partner-licensings/:partnerLicensingId/settings/partner-permissions/find-person/${query}`)
  },
  create(params) {
    return api.post('/partner-licensings/:partnerLicensingId/settings/partner-permissions', params)
  },
  destroy(id) {
    return api.delete(`/partner-licensings/:partnerLicensingId/settings/partner-permissions/${id}`)
  },
  updatePermissions(id, permissions) {
    return api.put(`/partner-licensings/:partnerLicensingId/settings/partner-permissions/${id}/permissions`, permissions)
  },
}

import React from 'react'
import List from '@/views/app/ManualAdoptions'
import Form from '@/views/app/ManualAdoptions/Form'
import Show from '@/views/app/ManualAdoptions/Show'
import { Route, useRouteMatch } from 'react-router-dom'

function ManualAdoptionsRoutes() {
  const { path } = useRouteMatch()

  return (
    <div>
        <Route path={path} exact>
          <List title="Petis - Parceiros / Adoções manuais" />
        </Route>
        <Route path={`${path}/form/create`} exact>
          <Form title="Petis - Parceiros / Adoções manuais / Novo" />
        </Route>
        <Route path={`${path}/:id`} exact>
          <Show title="Petis - Parceiros / Adoção manual" />
        </Route>
    </div>
  )
}

export default ManualAdoptionsRoutes

import { Flexbox, Form } from '@/components'
import { genderMap, sizeMap } from '@/constants/animal'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useState } from 'react'
import categoriesService from '@/services/api/categories'

export default function Animal({
  values, setFieldValue, touched, errors,
}) {
  const [categories, setCategories] = useState([])
  const [breeds, setBreeds] = useState([])

  async function fetchCategories() {
    const { data } = await categoriesService.list()
    setCategories(data)
  }

  const fetchBreeds = useCallback(async () => {
    const { data } = await categoriesService.breedsByCategoryId(values?.category?.id)
    setBreeds(data)
  }, [values?.category?.id])

  useEffect(() => {
    fetchCategories()
  }, [])

  useEffect(() => {
    if (values?.category?.id) fetchBreeds()
    else {
      setFieldValue('breed.id', '')
      setBreeds([])
    }
  }, [fetchBreeds, setFieldValue, values])

  return (
    <div>
      <div className="h5">Escolha o pet</div>
      <p>
        Informe os dados do pet que foi adotado
      </p>
      <Flexbox.Columns>
        <Flexbox.Column className="col-6 col-sm-12">
          <Form.Group className={`${touched?.animalName && errors?.animalName ? 'has-error' : ''}`}>
            <Form.Label htmlFor="animalName">Nome do Pet</Form.Label>
            <Form.ValidationField id="animalName" placeholder="Pet da Silva" name="animalName" />
            {
              touched?.animalName && errors?.animalName
                ? <Form.Hint className="text-error">{errors.animalName}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>
        <Flexbox.Column className="col-3 col-sm-12">
          <Form.Group className={`${touched?.animalGender && errors?.animalGender ? 'has-error' : ''}`}>
            <Form.Label htmlFor="animalGender">Sexo do Pet</Form.Label>
            <Form.ValidationField name="animalGender">
              {
                ({ field }) => (
                  <Form.Select
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    id="animalGender"
                  >
                    <option value={null}>Escolha o sexo</option>
                    {
                      Object.keys(genderMap).map((value) => (
                        <option key={value} value={value}>{genderMap[value]}</option>
                      ))
                    }
                  </Form.Select>
                )
              }
            </Form.ValidationField>
            {
              touched?.animalGender && errors?.animalGender
                ? <Form.Hint className="text-error">{errors.animalGender}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>
        <Flexbox.Column className="col-3 col-sm-12">
          <Form.Group className={`${touched?.animalSize && errors?.animalSize ? 'has-error' : ''}`}>
            <Form.Label htmlFor="animalSize">Porte do Pet</Form.Label>
            <Form.ValidationField name="animalSize">
              {
                ({ field }) => (
                  <Form.Select
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    id="animalSize"
                  >
                    <option value={null}>Escolha o porte</option>
                    {
                      Object.keys(sizeMap).map((value) => (
                        <option key={value} value={value}>{sizeMap[value]}</option>
                      ))
                    }
                  </Form.Select>
                )
              }
            </Form.ValidationField>
            {
              touched?.animalSize && errors?.animalSize
                ? <Form.Hint className="text-error">{errors.animalSize}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>
        <Flexbox.Column className="col-8 col-sm-12">
          <Form.Group className={`${touched?.animalColor && errors?.animalColor ? 'has-error' : ''}`}>
            <Form.Label htmlFor="animalColor">Cor e características</Form.Label>
            <Form.ValidationField id="animalColor" name="animalColor" placeholder="Amarelo com pintinhas azuis" />
            {
              touched?.animalColor && errors?.animalColor
                ? <Form.Hint className="text-error">{errors.animalColor}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>
        <Flexbox.Column className="col-4 col-sm-12">
          <Form.Group className={`${touched?.animalBornedAt && errors?.animalBornedAt ? 'has-error' : ''}`}>
            <Form.Label htmlFor="animalBornedAt">Nascido em</Form.Label>
            <Form.ValidationField name="animalBornedAt">
              {
                ({ field }) => (
                  <Form.DatePickerField
                    locale="pt-BR"
                    name="animalBornedAt"
                    placeholderText="Nascido em"
                    timeInputLabel="Hora"
                    onChange={(value) => setFieldValue('animalBornedAt', value)}
                    selected={field.value}
                    maxDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    className="form-input"
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                  />
                )
              }
            </Form.ValidationField>
            {
              touched?.animalBornedAt && errors?.animalBornedAt
                ? <Form.Hint className="text-error">{errors.animalBornedAt}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>
        <Flexbox.Column className="col-6 col-sm-12">
          <Form.Group className={`${touched?.category?.id && errors?.category?.id ? 'has-error' : ''}`}>
            <Form.Label htmlFor="category.id">Espécie do Pet <span className="text-error">*</span></Form.Label>
            <Form.ValidationField name="category.id">
              {
                ({ field }) => (
                  <Form.Select
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    onChange={({ target: { value } }) => setFieldValue('category', categories.find(({ id }) => Number(id) === Number(value)))}
                    id="category.id"
                  >
                    <option value={0}>Escolha a espécie</option>
                    {
                      categories.map((category) => (
                        <option
                          key={category.id}
                          value={category.id}
                        >
                          {category.description}
                        </option>
                      ))
                    }
                  </Form.Select>
                )
              }
            </Form.ValidationField>
            {
              touched?.category?.id && errors?.category?.id
                ? <Form.Hint className="text-error">{errors?.category?.id}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>
        <Flexbox.Column className="col-6 col-sm-12">
          <Form.Group className={`${touched?.breed?.id && errors?.breed?.id ? 'has-error' : ''}`}>
            <Form.Label htmlFor="breed.id">Raça do Pet <span className="text-error">*</span></Form.Label>
            <Form.ValidationField name="breed.id">
              {
                ({ field }) => (
                  <Form.Select
                    // eslint-disable-next-line react/jsx-props-no-spreading
                    {...field}
                    onChange={({ target: { value } }) => setFieldValue('breed', breeds.find(({ id }) => Number(id) === Number(value)))}
                    disabled={!breeds.length}
                    id="breed.id"
                  >
                    <option value={null}>Escolha a raça</option>
                    {
                      breeds.map((breed) => (
                        <option
                          key={breed.id}
                          value={breed.id}
                        >
                          {breed.description}
                        </option>
                      ))
                    }
                  </Form.Select>
                )
              }
            </Form.ValidationField>
            {
              touched?.breed?.id && errors?.breed?.id
                ? <Form.Hint className="text-error">{errors?.breed?.id}</Form.Hint> : null
            }
          </Form.Group>
        </Flexbox.Column>
      </Flexbox.Columns>
    </div>
  )
}

Animal.propTypes = {
  setFieldValue: PropTypes.func.isRequired,
  values: PropTypes.objectOf(Object).isRequired,
  touched: PropTypes.objectOf(Object).isRequired,
  errors: PropTypes.objectOf(Object).isRequired,
}

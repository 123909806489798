import { Avatar } from '@/components'
import { CASTRATION_STATUS, singularStatusMapLabel } from '@/constants/castration'
import useQuery from '@/hooks/useQuery'
import dateMask from '@/lib/masks/date'
import PropTypes from 'prop-types'
import qs from 'qs'
import React from 'react'
import { Link } from 'react-router-dom'
import { Badge } from './styles'

function CastrationsCard({ castrations }) {
  const query = useQuery()

  const activeNavCastrations = query?.activeNavCastrations || CASTRATION_STATUS.REQUESTED
  const { rows = [] } = castrations?.find(({ status }) => status === activeNavCastrations)

  return (
    <div className="panel panel-custom">
      <div className="panel-header">
        <div className="panel-title h5">Castrações</div>
        <div>
          <cite>Castrações que estão aguardando alguma ação</cite>
        </div>
        <div>
          <small>Aqui você vê as <b>5 primeiras</b> para cada situação</small>
        </div>
        <div className="divider" />
        <nav className="panel-nav">
          <ul className="tab tab-block">
            {
              castrations.map((item) => (
                <li key={item?.status} className={`tab-item ${activeNavCastrations === item?.status ? 'active' : ''}`}>
                  <Link to={`?${qs.stringify({ activeNavCastrations: item?.status })}`}>
                    <div className="d-inline p-relative">
                      {singularStatusMapLabel[item?.status]}
                      {Boolean(item?.count) && <Badge>{item?.count}</Badge>}
                    </div>
                  </Link>
                </li>
              ))
            }
          </ul>
        </nav>
        <div className="panel-body">
          {
            rows?.length > 0 ? (
              <table className="table table-striped table-hover text-small">
                <thead>
                  <tr>
                    <th>Tutor</th>
                    <th>Pet</th>
                    <th>Solicitado em</th>
                    <th width="1" className="text-right">Ações</th>
                  </tr>
                </thead>
                <tbody>
                  {
                    rows.map((castration) => (
                      <tr key={castration.id}>
                        <td>
                          <Avatar
                            avatarSize="sm"
                            name={castration?.tutor?.fullName}
                            photoURL={castration?.tutor?.photoURL}
                            className="mr-1"
                            alt="Imagem de usuário"
                          />
                          {castration.tutor.fullName}
                        </td>
                        <td>
                          <Link to={`/app/pets/${castration?.animalId}`}>
                            <Avatar
                              avatarSize="sm"
                              name={castration?.animal?.name}
                              photoURL={castration?.animal?.photoURL}
                              smart={false}
                              className="mr-1"
                              alt="Imagem de usuário"
                            />
                            {castration.animal.name}
                          </Link>
                        </td>
                        <td>{dateMask(castration.createdAt, { hour: '2-digit', minute: '2-digit' })}</td>
                        <td className="text-right">
                          <Link
                            to={`/app/castrations/status/${castration?.status}/${castration?.id}`}
                            className="btn btn-action btn-sm btn-primary"
                          >
                            <i className="fas fa-eye" />
                          </Link>
                        </td>
                      </tr>
                    ))
                  }
                </tbody>
              </table>
            ) : (
              <div className="empty">
                <div className="empty-icon">
                  <i className="fas fa-3x fa-check" />
                </div>
                <p className="empty-title h5">Tudo certo</p>
                <p className="empty-subtitle">Nenhuma castração aguardando ações por aqui</p>
              </div>
            )
          }
        </div>
      </div>
      <div className="panel-footer text-right">
        <Link
          to={`/app/castrations/status/${activeNavCastrations}`}
          className="btn btn-sm btn-primary"
        >
          <i className="fas fa-folder-open" />
          &nbsp;Ver tudo
        </Link>
      </div>
    </div>
  )
}

CastrationsCard.propTypes = {
  castrations: PropTypes.arrayOf(Object).isRequired,
}

export default CastrationsCard

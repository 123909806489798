import { Avatar, Card, Flexbox } from '@/components'
import dateMask from '@/lib/masks/date'
import documentNumberMask from '@/lib/masks/documentNumber'
import phoneNumber from '@/lib/masks/phoneNumber'
import PropTypes from 'prop-types'
import React from 'react'
import { Link } from 'react-router-dom'

export default function AuthorCard({ data }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h6 card-title">Bloqueado por</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-user-secret" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <small>
          <Flexbox.Columns className="align-center col-gapless">
            <Flexbox.Column className="col-auto">
              <Avatar photoURL={data.author.photoURL} name={data.author.fullName} />
            </Flexbox.Column>
            <Link to={`/app/petis-users/${data.author.id}`} className="text-dark">
              <Flexbox.Column className="px-1" style={{ lineHeight: 1 }}>
                <div className="text-bold">{data.author.fullName}</div>
                <div><small>{data.author.email}</small></div>
              </Flexbox.Column>
            </Link>
          </Flexbox.Columns>
          <div className="divider" />
          <Flexbox.Columns>
            <Flexbox.Column>CPF/CNPJ</Flexbox.Column>
            <Flexbox.Column className="col-auto text-bold">
              {documentNumberMask(data.author.documentNumber) || '-'}
            </Flexbox.Column>
          </Flexbox.Columns>
          <Flexbox.Columns>
            <Flexbox.Column>Telefone</Flexbox.Column>
            <Flexbox.Column className="col-auto text-bold">
              {phoneNumber(data.author.phoneNumber) || '-'}
            </Flexbox.Column>
          </Flexbox.Columns>
          <div className="divider" />
          <Flexbox.Columns>
            <Flexbox.Column>Bloqueado em</Flexbox.Column>
            <Flexbox.Column className="col-auto text-bold">
              {dateMask(data.createdAt)}
            </Flexbox.Column>
          </Flexbox.Columns>
        </small>
      </Card.Body>
    </Card.Base>
  )
}

AuthorCard.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
}

import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Form } from '@/components'
import yup from '@/lib/yupPt'
import adoptionFairs from '@/services/api/adoptionFairs'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  terms: yup.string().trim().required(),
})
function Terms({ fair, onFairSave }) {
  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState(false)

  const onSubmit = useCallback(async (form) => {
    try {
      setLoading(true)
      const { data } = await adoptionFairs.update(fair?.id, form)
      onFairSave(data)
      setLoading(false)
      setActive(false)
      toast.success('Termos de adoção salvos')
    } catch (e) {
      toast.error(e?.message)
      setLoading(false)
    }
  }, [fair?.id, onFairSave])

  return (
    <>
      <div className="d-flex p-relative">
        <div className="h6">Termos de adoção</div>
        {
          (fair?.status === 'created' || fair?.status === 'draft') && (
            <button
              className="btn btn-link btn-sm tooltip tooltip-right no-print"
              data-tooltip="Editar termos"
              style={{ marginTop: -3 }}
              type="button"
              onClick={() => setActive(true)}
            >
              <i className="fas fa-edit" />
            </button>
          )
        }
      </div>
      <div className="pre">
        {
          fair?.terms
            ? fair?.terms
            : (
              <>
                <button onClick={() => setActive(true)} className="btn btn-primary btn-sm mt-1" type="button">
                  <i className="fas fa-plus" />
                  &nbsp;Adicionar termos
                </button>
                <div className="mt-1">
                  <cite className="text-error">Você precisa adicionar os termos de adoção antes para poder iniciar a feira</cite>
                </div>
              </>
            )
        }
      </div>
      <fieldset disabled={loading}>
        <Form.Formik
          initialValues={{ terms: fair?.terms }}
          validationSchema={schema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {
            ({
              errors, touched, isValid,
            }) => (
              <Form.ValidationForm>
                <div className={`modal ${active ? 'active' : ''}`}>
                  <a type="button" className="modal-overlay" aria-label="Close" onClick={() => setActive(false)} />
                  <div className="modal-container">
                    <div className="modal-header">
                      <a
                        type="button"
                        className="btn btn-clear float-right"
                        onClick={() => setActive(false)}
                      />
                      <div className="modal-title h5">
                        Termos de adoção
                      </div>
                    </div>
                    <div className="modal-body">
                      <Form.Group className={`${touched?.terms && errors?.terms ? 'has-error' : ''}`}>
                        <Form.Label htmlFor="terms">Informe os termos de adoção <span className="text-error">*</span></Form.Label>
                        <Form.ValidationField
                          name="terms"
                          id="terms"
                        >
                          {
                            ({ field }) => (
                              <textarea
                                name="terms"
                                className="form-input"
                                rows={5}
                                placeholder="Para a adoção do animal é necessário..."
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...field}
                              />
                            )
                          }
                        </Form.ValidationField>
                        {
                          touched?.terms && errors?.terms
                            ? <Form.Hint className="text-error">{errors.terms}</Form.Hint> : null
                        }
                      </Form.Group>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" disabled={!isValid} className={`btn btn-primary ${loading ? 'loading' : ''}`}>
                        <i className="fas fa-save" />
                        &nbsp;Salvar
                      </button>
                    </div>
                  </div>
                </div>
              </Form.ValidationForm>
            )
          }
        </Form.Formik>
      </fieldset>
    </>
  )
}

Terms.propTypes = {
  fair: PropTypes.objectOf(Object).isRequired,
  onFairSave: PropTypes.func.isRequired,
}

export default Terms

import {
  Card, Flexbox, PageHeader, Styles,
} from '@/components'
import GoogleMapReact from 'google-map-react'
import usePageTitle from '@/hooks/usePageTitle'
import React from 'react'
import useAuth from '@/hooks/useAuth'
import envs from '@/constants/envs'
import formatAddress from '@/lib/formatters/address'
import { Point } from './styles'
import FormAddress from './Form'

function Address({ title }) {
  const { licensing } = useAuth()

  const center = licensing?.licensed?.location ? {
    lat: licensing?.licensed?.location?.coordinates[1],
    lng: licensing?.licensed?.location?.coordinates[0],
  } : {}

  usePageTitle({ title })

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        icon="fas fa-map-marker-alt"
        title="Endereço do parceiro/prefeitura"
        subtitle="Este é o endereço do parceiro/prefeitura"
      />
      <Flexbox.Columns>
        <Flexbox.Column className="col-6 col-sm-12">
          <Card.Base style={{ height: 'auto' }}>
            <Card.Image style={{ height: 200 }}>
              {
                Boolean(licensing) && (
                  <GoogleMapReact
                    center={center}
                    defaultZoom={15}
                    options={{
                      disableDefaultUI: true,
                      zoomControlOptions: null,
                      draggable: false,
                      maxZoom: 15,
                      minZoom: 15,
                    }}
                    bootstrapURLKeys={{
                      key: envs.REACT_APP_GOOGLE_MAPS_API_KEY,
                      libraries: ['visualization'],
                    }}
                  >
                    <Point
                      lat={center.lat}
                      lng={center.lng}
                    />
                  </GoogleMapReact>
                )
              }
            </Card.Image>
            <Card.Body>
              <p>{formatAddress(licensing?.licensed?.address)}</p>
            </Card.Body>
            <Card.Footer>
              <FormAddress />
            </Card.Footer>
          </Card.Base>
        </Flexbox.Column>
      </Flexbox.Columns>
    </Styles.Container>
  )
}

export default Address

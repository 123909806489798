export const statusColorMap = {
  opened: 'text-primary',
  checking: 'text-info',
  done: 'text-success',
  rejected: 'text-error',
  canceled: 'text-error',
}

export const resultColorMap = {
  awaiting: '',
  measuresApplied: 'text-error',
  unfounded: 'text-green',
  canceled: '',
}

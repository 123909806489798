import { Flexbox, Form } from '@/components'
import yup from '@/lib/yupPt'
import chips from '@/services/api/chips'
import React, { useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  uid: yup.string().trim().required(),
})
function FindChip() {
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false)
  const history = useHistory()

  const onSubmit = useCallback(async (form) => {
    try {
      setLoading(true)
      const { data } = await chips.findByUid(form?.uid)
      toast.success('Animal encontrado')
      history.push(`/app/pets/${data?.animal?.id}`)
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }, [history])

  return (
    <>
      <a
        className="btn btn-link tooltip tooltip-left"
        onClick={() => setActive(true)}
        data-tooltip="Buscar chip"
      >
        <i className="fas fa-microchip" />
      </a>
      <fieldset disabled={loading}>
        <Form.Formik
          initialValues={{ uid: '' }}
          validationSchema={schema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {
            ({ errors, touched, isValid }) => (
              <Form.ValidationForm>
                <div className={`modal ${active ? 'active' : ''}`}>
                  <a type="button" className="modal-overlay" aria-label="Close" onClick={() => setActive(false)} />
                  <div className="modal-container">
                    <div className="modal-header">
                      <a
                        type="button"
                        className="btn btn-clear float-right"
                        onClick={() => setActive(false)}
                      />
                      <div className="modal-title h5">Buscar chip</div>
                    </div>
                    <div className="modal-body">
                      <Flexbox.Columns>
                        <Flexbox.Column className="col-12">
                          <Form.Group className={`${touched?.uid && errors?.uid ? 'has-error' : ''}`}>
                            <Form.Label htmlFor="uid">Informe o código do chip <span className="text-error">*</span></Form.Label>
                            <Form.ValidationField
                              name="uid"
                              id="uid"
                              placeholder="Código do chip"
                            />
                            {
                              touched?.uid && errors?.uid
                                ? <Form.Hint className="text-error">{errors.uid}</Form.Hint> : null
                            }
                          </Form.Group>
                        </Flexbox.Column>
                      </Flexbox.Columns>
                    </div>
                    <div className="modal-footer">
                      <button
                        type="submit"
                        disabled={!isValid || loading}
                        className={`btn btn-primary ${loading ? 'loading' : ''}`}
                      >
                        <i className="fas fa-search" />
                        &nbsp;Buscar
                      </button>
                    </div>
                  </div>
                </div>
              </Form.ValidationForm>
            )
          }
        </Form.Formik>
      </fieldset>
    </>
  )
}

export default FindChip

import {
  Flexbox, Form, Mask,
} from '@/components'
import yup from '@/lib/yupPt'
import adoptionFairs from '@/services/api/adoptionFairs'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { toast } from 'react-toastify'
import { ButtonContacts } from './styles'

const schema = yup.object().shape({
  email: yup.string().trim().email(),
  phoneNumber: yup.string().trim(),
})
function EditContacts({
  fair, onFairSave, btnClass, btnText, btnIcon,
}) {
  const [loading, setLoading] = useState(false)
  const [active, setActive] = useState(false)

  const onSubmit = useCallback(async (body) => {
    try {
      setLoading(true)
      const { data } = await adoptionFairs.updateContacts(fair.id, body)
      onFairSave(data)
      toast.success('Opções de contato atualizadas com sucesso!')
      setActive(false)
    } catch (err) {
      toast.error('Erro ao atualizar opções de contato')
    }
    setLoading(false)
  }, [fair, onFairSave])

  return (
    <>
      <ButtonContacts
        type="button"
        className={btnClass}
        onClick={() => setActive(true)}
      >
        <i className={btnIcon} />
        &nbsp;{btnText}
      </ButtonContacts>
      <fieldset disabled={loading}>
        <Form.Formik
          initialValues={{
            email: fair?.email || '',
            phoneNumber: fair?.phoneNumber || '',
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
          validateOnMount
        >
          {
            ({
              isValid, setFieldValue,
            }) => (
              <Form.ValidationForm>
                <div className={`modal ${active ? 'active' : ''}`}>
                  <a type="button" className="modal-overlay" aria-label="Close" onClick={() => setActive(false)} />
                  <div className="modal-container">
                    <div className="modal-header">
                      <a
                        type="button"
                        className="btn btn-clear float-right"
                        onClick={() => setActive(false)}
                      />
                      <div className="modal-title h5">
                        Editar opções de contato
                      </div>
                      <div>
                        <small className="text-primary text-center">
                          As opções de contato adicionadas aqui
                          serão exibidas para os usuários na página da feira.
                        </small>
                      </div>
                      <div style={{ marginTop: -8 }}>
                        <small className="text-primary text-center">
                          Elas facilitarão o contato
                          da população com o organizador da feira.
                        </small>
                      </div>
                    </div>
                    <div className="modal-body" style={{ marginTop: -20 }}>

                      <Flexbox.Columns>
                        <Flexbox.Column className="col-12">
                          <Form.Group>
                            <Form.Label htmlFor="email">E-mail</Form.Label>
                            <Form.ValidationField
                              name="email"
                              id="email"
                              placeholder="emailparacontato@email.com"
                            />
                          </Form.Group>
                        </Flexbox.Column>
                        <Flexbox.Column className="col-6">
                          <Form.Group>
                            <Form.Label htmlFor="phoneNumber">Telefone</Form.Label>
                            <Form.ValidationField name="phoneNumber">
                              {
                                ({ field }) => (
                                  <Mask.Phone
                                    id="phoneNumber"
                                    placeholder="(00) 99999-9999"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...field}
                                    onAccept={(v) => setFieldValue('phoneNumber', v)}
                                  />
                                )
                              }
                            </Form.ValidationField>
                          </Form.Group>
                        </Flexbox.Column>
                      </Flexbox.Columns>
                    </div>
                    <div className="modal-footer">
                      <button type="submit" disabled={!isValid} className={`btn btn-primary ${loading ? 'loading' : ''}`}>
                        <i className="fas fa-save" />
                        &nbsp;Salvar
                      </button>
                    </div>
                  </div>
                </div>
              </Form.ValidationForm>
            )
          }
        </Form.Formik>
      </fieldset>
    </>
  )
}

EditContacts.propTypes = {
  fair: PropTypes.objectOf(Object),
  onFairSave: PropTypes.func,
  btnClass: PropTypes.string,
  btnText: PropTypes.string,
  btnIcon: PropTypes.string,
}

EditContacts.defaultProps = {
  fair: null,
  onFairSave: () => { },
  btnClass: 'btn btn-sm mx-1',
  btnText: 'Editar opções de contato',
  btnIcon: 'fas fa-pencil-alt',
}

export default EditContacts

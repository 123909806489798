import { AddressForm, Form } from '@/components'
import useAuth from '@/hooks/useAuth'
import yup from '@/lib/yupPt'
import React, { useCallback, useState } from 'react'
import formatAddress from '@/lib/formatters/address'
import gmaps from '@/services/gmaps'
import address from '@/services/api/settings/address'
import { toast } from 'react-toastify'

// import { Container } from './styles';
const schema = yup.object().shape({
  address: yup.object().shape({
    zipcode: yup.string().trim().required().length(8),
    city: yup.object().shape({
      id: yup.number().required(),
      name: yup.string(),
      uf: yup.string(),
    }),
    street: yup.string().trim().required(),
    number: yup.string().trim().required(),
    complement: yup.string().trim(),
  }),
})
function FormAddress() {
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false)
  const { licensing, setLicensing } = useAuth()

  const onSubmit = useCallback(async (body) => {
    try {
      setLoading(true)
      const formattedAddress = formatAddress(body?.address)
      const { location } = await gmaps.geolocation(formattedAddress)
      const { lat, lng } = location
      const point = { type: 'Point', coordinates: [lng, lat] }
      const { data } = await address.update({ address: body?.address, location: point })
      setLoading(false)
      toast.success('Endereço atualizado com sucesso!')
      setLicensing({ ...licensing, licensed: data })
      setActive(false)
    } catch {
      setLoading(false)
    }
  }, [licensing, setLicensing])

  return (
    <>
      <div className="text-right">
        <button onClick={() => setActive(true)} className="btn btn-primary" type="button">
          <i className="fas fa-edit" />
          &nbsp;Editar
        </button>
      </div>
      <div className={`modal ${active ? 'active' : ''}`}>
        <a type="button" className="modal-overlay" aria-label="Close" onClick={() => setActive(false)} />
        <div className="modal-container">
          {
            Boolean(licensing?.licensed?.address) && (
              <fieldset disabled={loading}>
                <Form.Formik
                  initialValues={{
                    address: {
                      zipcode: licensing?.licensed?.address?.zipcode || '',
                      city: {
                        id: licensing?.licensed?.address?.city?.id || '',
                        name: licensing?.licensed?.address?.city?.name || '',
                        uf: licensing?.licensed?.address?.city?.uf || '',
                      },
                      street: licensing?.licensed?.address?.street || '',
                      district: licensing?.licensed?.address?.district || '',
                      number: licensing?.licensed?.address?.number || '',
                      complement: licensing?.licensed?.address?.complement || '',
                    },
                  }}
                  validationSchema={schema}
                  onSubmit={onSubmit}
                  validateOnMount
                >
                  {
                    ({
                      errors, touched, isValid, setFieldValue, values,
                    }) => (
                      <Form.ValidationForm>

                        <div className="modal-header">
                        <a
                          type="button"
                          className="btn btn-clear float-right"
                          onClick={() => setActive(false)}
                        />
                          <div className="modal-title h5">
                            Endereço do parceiro/prefeitura
                          </div>
                        </div>
                        <div className="modal-body">
                          <div className="h3">Atenção</div>
                          <p>
                            Tenha certeza que o endereço está correto antes de salvá-lo
                          </p>
                          <AddressForm
                            touched={touched}
                            errors={errors}
                            value={values?.address}
                            onChange={setFieldValue}
                          />
                        </div>
                        <div className="modal-footer">
                          <button
                            type="submit"
                            className={`btn btn-primary ${loading ? 'loading' : ''}`}
                            disabled={loading || !isValid}
                          >
                            <i className="fas fa-check" />
                            &nbsp;Salvar endereço
                          </button>
                        </div>
                      </Form.ValidationForm>
                    )
                  }
                </Form.Formik>
              </fieldset>
            )
          }
        </div>
      </div>
    </>
  )
}

export default FormAddress

import {
  Avatar, ListManager, PageHeader, Styles,
} from '@/components'
import { LICENSING_STATUS_MAP } from '@/constants/licensings'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import usePageTitle from '@/hooks/usePageTitle'
import dateMask from '@/lib/masks/date'
import clinicalCares from '@/services/api/clinicalCares'
import React, { useCallback, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import clinics from '@/services/api/clinics'
import { Link } from 'react-router-dom'
import Add from './Add'

function Clinics({ title }) {
  usePageTitle({ title })
  const [revoking, setRevoking] = useState()

  const {
    data, error, loading, setData,
  } = useAxiosRequest(clinicalCares.allowedClinics)

  const revoke = useCallback((licensing) => {
    confirmAlert({
      title: 'Revogar licença?',
      message: `Você está certo que deseja revogar a licença de ${licensing?.licensed?.fullName}?`,
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim',
          className: 'bg-error',
          onClick: async () => {
            try {
              setRevoking(licensing.id)
              await clinics.revoke(licensing.id)
              toast.success('Licença revogada')
              setData(data.filter((i) => i.id !== licensing.id))
            } catch (e) {
              toast.error(e.message)
            } finally {
              setRevoking(undefined)
            }
          },
        },
      ],
    })
  }, [data, setData])

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        icon="fas fa-clinic-medical"
        subtitle="Lista das clínicas que podem realizar atendimentos"
        title="Clínicas para atendimento"
        rightAction={() => <Add onAdded={(item) => setData([item, ...data])} />}
      />
      <ListManager count={data?.length || 0} loading={loading} error={error}>
        {
          Boolean(data) && (
            <table className="table table-striped">
              <thead>
                <tr>
                  <th>Clínica</th>
                  <th>Status</th>
                  <th>Adicionado em</th>
                  <th className="text-right" width="100">Ações</th>
                </tr>
              </thead>
              <tbody>
                {
                  data.map((licensing) => (
                    <tr key={licensing?.id}>
                      <td>
                        <Avatar
                          avatarSize="sm"
                          className="mr-1"
                          name={licensing?.licensed?.fullName}
                          photoURL={licensing?.licensed?.photoURL}
                          alt="Imagem de usuário"
                        />
                        {licensing?.licensed?.fullName}
                      </td>
                      <td>{LICENSING_STATUS_MAP[licensing?.status]}</td>
                      <td>{dateMask(licensing?.createdAt)}</td>
                      <td className="text-right">
                        <Link
                          to={`clinics/${licensing.licensedId}/items`}
                          className="btn btn-action btn-sm btn-primary mr-2 tooltip tooltip-left"
                          data-tooltip="Configurar itens"
                        >
                          <i className="fas fa-cog" />
                        </Link>
                        <a
                          disabled={revoking === licensing.id}
                          data-tooltip="Revogar licença"
                          className={`btn btn-action btn-sm btn-error tooltip tooltip-left ${revoking === licensing.id ? 'loading' : ''}`}
                          onClick={() => revoke(licensing)}
                        >
                          <i className="fas fa-trash" />
                        </a>
                      </td>
                    </tr>
                  ))
                }
              </tbody>
            </table>
          )
        }
      </ListManager>
    </Styles.Container>
  )
}

export default Clinics

import { ImageViewer, LoadManager } from '@/components'
import { singularStatusMapLabel } from '@/constants/castration'
import envs from '@/constants/envs'
import useCastration from '@/hooks/useCastration'
import usePageTitle from '@/hooks/usePageTitle'
import dateMask from '@/lib/masks/date'
import GoogleMapReact from 'google-map-react'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { ImageEvent, MapContainer, MapMarker } from './styles'

function EventDetails({ title }) {
  usePageTitle({ title })
  const [image, setImage] = useState('')
  const { eventIndex } = useParams()
  const { state: { castration } } = useCastration()
  const history = useHistory()
  const event = castration?.events ? castration?.events[eventIndex] : null

  return (
    <div className="modal active" id="modal-id">
      <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
      <div className="modal-container">
        <div className="modal-header">
          <a
            type="button"
            className="btn btn-clear float-right"
            onClick={() => history.goBack()}
          />
          <div className="modal-title h5">Evento #{eventIndex + 1}</div>
        </div>
        <div className="modal-body">
          <div className="content">
            <LoadManager loading={!castration}>
              <>
                <p>
                  Data do evento:
                  <b> {dateMask(event?.createdAt, { dateStyle: 'full' })}</b>
                </p>
                <p>
                  Status da castração após o evento: <b>{singularStatusMapLabel[event?.status]}</b>
                </p>
                <p>
                  Responsável pelo evento: <b>{event?.author?.fullName}</b>
                </p>
                {
                  Boolean(event?.description) && (
                    <>
                      <div className="divider" />
                      <small>Comentário do evento</small>
                      <div>
                        <cite className="pre">{event?.description}</cite>
                      </div>
                      <div className="divider" />
                    </>
                  )
                }
                {
                  Boolean(event?.location) && (
                    <>
                      <div className="divider" />
                      <h1 className="h5">Localização do evento</h1>
                      <MapContainer>
                        <GoogleMapReact
                          bootstrapURLKeys={{ key: envs.REACT_APP_GOOGLE_MAPS_API_KEY }}
                          defaultCenter={{
                            lat: event?.location?.coordinates[1],
                            lng: event?.location?.coordinates[0],
                          }}
                          defaultZoom={18}
                          draggable={false}
                          options={{ fullscreenControl: null }}
                        >
                          <MapMarker
                            lat={event?.location?.coordinates[1]}
                            lng={event?.location?.coordinates[0]}
                          />
                        </GoogleMapReact>
                      </MapContainer>
                    </>
                  )
                }
                {
                  Boolean(event?.photoURL) && (
                    <>
                      <div className="divider" />
                      {
                        Boolean(image) && (
                          <ImageViewer src={image} onClose={() => setImage('')} />
                        )
                      }
                      <h1 className="h5">Imagem do evento</h1>
                      <a onClick={() => setImage(event?.photoURL)}>
                        <ImageEvent className="c-hand" src={event?.photoURL} />
                      </a>
                    </>
                  )
                }
              </>
            </LoadManager>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EventDetails

import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react'
import { Form, PageHeader, Styles } from '@/components'
import usePageTitle from '@/hooks/usePageTitle'
import yup from '@/lib/yupPt'
import { validateCNPJ, validateCPF } from 'validations-br'
import { toast } from 'react-toastify'
import manualAdoptions from '@/services/api/manualAdoptions'
import { useHistory } from 'react-router-dom'
import Animal from './components/Animal'
import Tutor from './components/Tutor'
import AdoptionData from './components/AdoptionData'
import ConfirmAdoption from './components/Confirm'

const schemas = {
  1: yup.object().shape({
    animalName: yup.string().trim(),
    animalColor: yup.string().trim(),
    animalGender: yup.string().trim(),
    animalSize: yup.string().trim(),
    animalBornedAt: yup.mixed(),
    breed: yup.object().shape({ id: yup.mixed().required() }),
    category: yup.object().shape({ id: yup.mixed().required() }),
  }),
  2: yup.object().shape({
    fullName: yup.string().trim().required(),
    email: yup.string().trim().email(),
    documentNumber: yup.string().test(
      'is-document-number',
      'Documento inválido',
      (value = '') => (value?.length < 12 ? validateCPF(value) : validateCNPJ(value)),
    ).required(),
    phoneNumber: yup.string(),
    address: yup.object().shape({
      street: yup.string(),
      district: yup.string(),
      zipcode: yup.string(),
      complement: yup.string(),
      number: yup.string(),
      city: yup.mixed(),
    }),
  }),
  3: yup.object().shape({
    adoptedAt: yup.mixed().required(),
    description: yup.string(),
    terms: yup.string(),
    attachmentURL: yup.string(),
  }),
  4: yup.object().shape({}),
}

function ManualAdoptionsForm({ title }) {
  usePageTitle({ title })
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const formRef = useRef()
  const history = useHistory()

  const schema = useMemo(() => schemas[step], [step])

  useEffect(() => {
    formRef.current?.validateForm()
  }, [schema])

  const renderForm = useCallback((values, errors, touched, isValid, setFieldValue) => {
    if (step === 1) {
      return (
        <Animal
          values={values}
          errors={errors}
          touched={touched}
          setFieldValue={setFieldValue}
        />
      )
    }
    if (step === 2) {
      return (
        <Tutor
          setFieldValue={setFieldValue}
          values={values}
          touched={touched}
          errors={errors}
        />
      )
    }
    if (step === 3) {
      return (
        <AdoptionData
          setFieldValue={setFieldValue}
          touched={touched}
          errors={errors}
        />
      )
    }
    if (step === 4) return <ConfirmAdoption values={values} onChangeStep={setStep} />
    return null
  }, [step])

  async function onSubmit(values) {
    try {
      setLoading(true)
      await manualAdoptions.create(values)
      toast.success('Adoção manual criada com sucesso')
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <Styles.Container className="grid-xl">
      <PageHeader
        title="Nova adoção"
        icon="fas fa-dog"
        subtitle="Crie uma nova adoção manual"
      />
      <fieldset disabled={loading}>
        <Form.Formik
          innerRef={formRef}
          initialValues={{
            fullName: '',
            email: '',
            documentNumber: '',
            phoneNumber: '',
            animalName: '',
            animalColor: '',
            animalGender: '',
            animalSize: '',
            animalBornedAt: null,
            breed: { id: '' },
            category: { id: '' },
            address: {
              street: '', complement: '', district: '', number: '', city: null,
            },
          }}
          validationSchema={schema}
          onSubmit={onSubmit}
          validateOnMount
        >

          {
            ({
              values, errors, touched, isValid, setFieldValue,
            }) => (
              <Form.ValidationForm>
                <div className="panel">
                  <div className="panel-header" />
                  <div className="panel-nav">
                    <ul className="step">
                      <li className={`step-item ${step === 1 ? 'active' : ''}`}>
                        <a className="tooltip" data-tooltip="Pet">Pet</a>
                      </li>
                      <li className={`step-item ${step === 2 ? 'active' : ''}`}>
                        <a className="tooltip" data-tooltip="Tutor">Tutor</a>
                      </li>
                      <li className={`step-item ${step === 3 ? 'active' : ''}`}>
                        <a className="tooltip" data-tooltip="Detalhes da adoção">Detalhes da adoção</a>
                      </li>
                      <li className={`step-item ${step === 4 ? 'active' : ''}`}>
                        <a className="tooltip" data-tooltip="Confirmar">Confirmar</a>
                      </li>
                    </ul>
                  </div>
                  <div className="panel-body mt-2">
                    <div className="divider" />
                    <div>
                      {renderForm(values, errors, touched, isValid, setFieldValue)}
                    </div>
                  </div>
                  <div className="panel-footer text-right">
                    <div className="divider" />
                    <>
                      <a disabled={step === 1} onClick={() => setStep(step - 1)} className="btn btn-primary mr-2">
                        <i className="fas fa-chevron-left" />
                        &nbsp;Anterior
                      </a>
                      {
                        step < 4 ? (
                          <a disabled={!isValid} onClick={() => setStep(step + 1)} className="btn btn-primary">
                            Avançar&nbsp;
                            <i className="fas fa-chevron-right" />
                          </a>
                        ) : (
                          <button type="submit" className={`btn btn-success ${loading && 'loading'}`}>
                            <i className="fas fa-check" />
                            &nbsp;Criar adoção
                          </button>
                        )
                      }
                    </>
                  </div>
                </div>
              </Form.ValidationForm>
            )
          }
        </Form.Formik>
      </fieldset>

    </Styles.Container>
  )
}

export default ManualAdoptionsForm

import clinicalCareItems from '@/services/api/settings/clinicalCareItems'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'

export default function Row({ item, onEdit, onStatusChange }) {
  const [loading, setLoading] = useState(false)

  const changeStatus = useCallback(async (active) => {
    try {
      setLoading(true)
      await clinicalCareItems.update(item.id, { ...item, active })
      toast.success('Item clínico salvo')
      onStatusChange()
    } catch (e) {
      toast.error(e?.message)
      setLoading(false)
    }
  }, [item, onStatusChange])

  const activate = useCallback(async () => {
    confirmAlert({
      title: 'Reativar o item clínico',
      message: `Você está certo que deseja reativar este item?\n${item.title}`,
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim, reativar',
          className: 'bg-success',
          onClick: () => changeStatus(true),
        },
      ],
    })
  }, [changeStatus, item.title])

  const deactivate = useCallback(async () => {
    confirmAlert({
      title: 'Inativar o item clínico',
      message: `Você está certo que deseja inativar este item?\n${item.title}`,
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim, inativar',
          className: 'bg-error',
          onClick: () => changeStatus(false),
        },
      ],
    })
  }, [changeStatus, item.title])

  return (
    <tr>
      <td style={{ opacity: item.active ? 1 : 0.4, textDecoration: item.active ? 'none' : 'line-through' }}>
        <div>{item.title}</div>
        <div>
          <small>{item.description || ''}</small>
        </div>
      </td>
      <td className="text-right">
          {
            item.active
              ? (
                <a disabled={loading} className="btn btn-action btn-sm btn-error tooltip" data-tooltip="Inativar" onClick={deactivate}>
                  <i className="fas fa-lock" />
                </a>
              )
              : (
                <a disabled={loading} className="btn btn-action btn-sm btn-success tooltip" data-tooltip="Reativar" onClick={activate}>
                  <i className="fas fa-unlock" />
                </a>
              )
          }
          <a disabled={loading} className="btn btn-action btn-sm btn-primary ml-1 tooltip" data-tooltip="Editar" onClick={() => onEdit(item)}>
            <i className="fas fa-edit" />
          </a>
      </td>
    </tr>
  )
}

Row.propTypes = {
  item: PropTypes.objectOf(Object).isRequired,
  onEdit: PropTypes.func.isRequired,
  onStatusChange: PropTypes.func.isRequired,
}

import React from 'react'
import {
  Avatar, Card, ErrorBox, Flexbox,
} from '@/components'
import PropTypes from 'prop-types'
import { singularStatusMapLabel, textClasses } from '@/constants/adoptionFair'
import { Link } from 'react-router-dom'

function AnimalFairsCard({ animalFairs }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Histórico de feiras</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-map-marker-alt" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        {
          animalFairs?.length ? (
            <table className="table">
              <thead>
                <tr>
                  <th>Feira</th>
                  <th>Status da Feira</th>
                  <th>Quem adicionou?</th>
                  <th width={150}>Adotado?</th>
                  <th width={200} className="text-right">Adotado por</th>
                </tr>
              </thead>
              <tbody>
                {
                  animalFairs.map((animalFair) => (
                    <tr key={animalFair?.id}>
                      {
                        animalFair?.fair?.isOnline
                          ? <td>{animalFair?.fair?.title}</td>
                          : (
                            <td>
                              <Link to={`/app/adoption-fairs/${animalFair?.fair?.id}/pets`}>
                                {animalFair?.fair?.title}
                              </Link>
                            </td>
                          )
                      }
                      <td>
                        <span className={textClasses[animalFair?.fair?.status]}>
                          {singularStatusMapLabel[animalFair?.fair?.status]}
                        </span>
                      </td>
                      <td>
                        <Avatar
                          avatarSize="sm"
                          name={animalFair?.person?.fullName}
                          photoURL={animalFair?.person?.photoURL}
                        />
                        &nbsp;{animalFair?.person?.fullName}
                      </td>
                      <td>
                        {animalFair?.adopted ? 'Sim' : 'Não'}
                      </td>
                      {
                        animalFair?.adopted
                          ? (
                            <td className="text-right">
                              <Avatar
                                avatarSize="sm"
                                name={animalFair?.adoption?.newTutor?.fullName}
                                photoURL={animalFair?.adoption?.newTutor?.photoURL}
                              />
                              &nbsp;{animalFair?.adoption?.newTutor?.fullName}
                            </td>
                          )
                          : (
                            <td className="text-right">-</td>
                          )
                      }
                    </tr>
                  ))
                }
              </tbody>
            </table>
          ) : (
            <ErrorBox
              error={{
                icon: 'fas fa-map-marker-alt fa-2x',
                title: 'Nenhuma feira',
                message: 'Esse pet não possui histórico em feiras de adoção',
              }}
            />
          )
        }
      </Card.Body>
    </Card.Base>
  )
}

AnimalFairsCard.propTypes = {
  animalFairs: PropTypes.arrayOf(PropTypes.any).isRequired,
}

export default AnimalFairsCard

import { LOCAL_STORAGE_AUTH_TOKEN, LOCAL_STORAGE_LICENSING_ID } from '@/constants/session'
import people from '@/services/api/people'
import session from '@/services/auth/session'
import { useCallback, useEffect, useState } from 'react'

export default function useProvideAuth() {
  const [user, setUser] = useState(null)
  const [licensing, setLicensing] = useState(null)
  const [licensingId, setLicensingIdLocal] = useState(null)
  const [token, setToken] = useState(localStorage.getItem(LOCAL_STORAGE_AUTH_TOKEN))

  const refreshUser = useCallback(async () => {
    try {
      const { data } = await people.current()
      setUser(data)
    } catch (e) {
      localStorage.removeItem(LOCAL_STORAGE_AUTH_TOKEN)
      setToken(null)
      setUser(null)
    }
  }, [])

  const signIn = useCallback(async (body) => {
    const { data } = await session.signIn(body)
    localStorage.setItem(LOCAL_STORAGE_AUTH_TOKEN, data?.token)
    setToken(data?.token)
  }, [])

  const setLicensingId = useCallback((id) => {
    localStorage.setItem(LOCAL_STORAGE_LICENSING_ID, id)
    setLicensingIdLocal(id)
  }, [])

  const signOut = useCallback(async () => {
    await session.signOut()
    localStorage.clear()
    setToken(null)
    setUser(null)
  }, [])

  useEffect(() => {
    setLicensingIdLocal(Number(localStorage.getItem(LOCAL_STORAGE_LICENSING_ID)))
    if (token) refreshUser()
  }, [refreshUser, setLicensingId, token])

  return {
    user,
    token,
    signIn,
    signOut,
    refreshUser,
    setLicensingId,
    licensingId,
    licensing,
    setLicensing,
  }
}

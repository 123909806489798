import React from 'react'
import PropTypes from 'prop-types'
import * as OffCanvas from './styles'
import Header from './Header'

function Sidebar({ children, routes }) {
  return (
    <OffCanvas.OffCanvas>
      <div className="docs-navbar no-print">
        <OffCanvas.Toggle>
          <OffCanvas.ToggleIcon />
        </OffCanvas.Toggle>
        <div className="btns d-flex" />
      </div>

      <OffCanvas.Sidebar>
        <OffCanvas.Brand>
          <OffCanvas.BrandLogo />
          <div className="ml-2 h3 text-primary">Petis</div>
        </OffCanvas.Brand>

        <OffCanvas.Nav>
          <OffCanvas.AccordionContainer>
            {
              routes.filter(({ hide }) => !hide).map((route) => {
                if (route.children) {
                  return (
                    <OffCanvas.Accordion key={route.key}>
                      <OffCanvas.AccordionCheckbox id={route.key} name={`accordion-checkbox-${route.key}`} />
                      <OffCanvas.AccordionHeader htmlFor={route.key}>
                        <i className={`${route.icon} fa-fw`} />
                        &nbsp;{route.breadcrumb}
                      </OffCanvas.AccordionHeader>
                      <OffCanvas.AccordionBody>
                        <OffCanvas.Menu>
                          {
                            route.children.filter(({ hide }) => !hide).map((child) => {
                              if (child.hidden) return null
                              return (
                                <li className="menu-item" key={child.key}>
                                  <OffCanvas.NavLink to={child.path}>
                                    <small>
                                      <i className={`${child.icon} fa-fw`} />
                                      &nbsp;{child.breadcrumb}
                                    </small>
                                  </OffCanvas.NavLink>
                                </li>
                              )
                            })
                          }
                        </OffCanvas.Menu>
                      </OffCanvas.AccordionBody>
                    </OffCanvas.Accordion>
                  )
                }
                return (
                  <OffCanvas.NavLinkContainer key={route.key}>
                    <OffCanvas.NavLink exact={Boolean(route.exact)} to={route.path}>
                      <i className={`${route.icon} fa-fw`} />
                      &nbsp;{route.breadcrumb}
                    </OffCanvas.NavLink>
                  </OffCanvas.NavLinkContainer>
                )
              })
            }
          </OffCanvas.AccordionContainer>
        </OffCanvas.Nav>
      </OffCanvas.Sidebar>

      <OffCanvas.Overlay />

      <OffCanvas.Content>
        <Header routes={routes} />
        {children}
      </OffCanvas.Content>
    </OffCanvas.OffCanvas>
  )
}

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.any),
}

Sidebar.defaultProps = {
  routes: [],
}

export default Sidebar

import React from 'react'
import PropTypes from 'prop-types'
import { Avatar, Card, Flexbox } from '@/components'
import dateMask from '@/lib/masks/date'

export default function DetailsCard({ data }) {
  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h6 card-title">Detalhes da adoção</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-dog" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        <small>
          <Flexbox.Columns className="align-center">
            <Flexbox.Column className="col-8 col-sm-12">
              <div>
                <small>Adicionada por</small>
                <div className="d-flex align-center mt-1">
                  <Avatar name={data?.author?.fullName} photoURL={data?.author?.photoURL} />
                  <div className="ml-1" style={{ lineHeight: 1 }}>
                    <div className="text-bold">{data?.author?.fullName}</div>
                    <small>{data?.author?.email}</small>
                  </div>
                </div>
              </div>
            </Flexbox.Column>
            <Flexbox.Column className="col-4 col-sm-12">
              <div>
                <small>Adicionado em</small>
                <div className="text-bold">{dateMask(data?.createdAt)}</div>
              </div>
            </Flexbox.Column>
          </Flexbox.Columns>
          <div className="divider" />
          <Flexbox.Columns>
            <Flexbox.Column className="col-12 my-2">
              <div>
                <small>Data da adoção</small>
                <div className="text-bold">{dateMask(data?.adoptedAt, { dateStyle: 'short' }) || '-'}</div>
              </div>
            </Flexbox.Column>
            <Flexbox.Column className="col-12 my-2">
              <div>
                <small>Observações</small>
                <div>{data?.description || '-'}</div>
              </div>
            </Flexbox.Column>
            <Flexbox.Column className="col-12 my-2">
              <div>
                <small>Termo da adoção</small>
                <div>{data?.terms || '-'}</div>
              </div>
            </Flexbox.Column>
            <Flexbox.Column className="col-12 my-2">
              <div>
                <small>Anexo do termo</small>
                <div>
                  {
                    data?.attachmentURL
                      ? (
                        <a href={data.attachmentURL} target="_blank" rel="noreferrer">
                          <i className="fas fa-link" /> Exibir anexo
                        </a>
                      )
                      : '-'
                  }
                </div>
              </div>
            </Flexbox.Column>
          </Flexbox.Columns>
        </small>
      </Card.Body>
    </Card.Base>
  )
}

DetailsCard.propTypes = {
  data: PropTypes.objectOf(Object).isRequired,
}

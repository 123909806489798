import api from './api'

export default {
  list(params = {}) {
    return api.get('/partner-licensings/:partnerLicensingId/clinical-cares', { params })
  },
  allowedClinics() {
    return api.get('/partner-licensings/:partnerLicensingId/clinical-cares/allowed-clinics')
  },
  show(id) {
    return api.get(`/partner-licensings/:partnerLicensingId/clinical-cares/${id}`)
  },
  create(body) {
    return api.post('/partner-licensings/:partnerLicensingId/clinical-cares', body)
  },
  setAnimal(id, body) {
    return api.post(`/partner-licensings/:partnerLicensingId/clinical-cares/${id}/set-animal`, body)
  },
  approve(id, body) {
    return api.post(`/partner-licensings/:partnerLicensingId/clinical-cares/${id}/approve`, body)
  },
  changeClinic(id, form) {
    return api.post(`/partner-licensings/:partnerLicensingId/clinical-cares/${id}/change-clinical-care-clinic`, form)
  },
  pullout(id, body) {
    return api.post(`/partner-licensings/:partnerLicensingId/clinical-cares/${id}/pullout`, body)
  },
  release(id, body) {
    return api.post(`/partner-licensings/:partnerLicensingId/clinical-cares/${id}/release`, body)
  },
  done(id, body) {
    return api.post(`/partner-licensings/:partnerLicensingId/clinical-cares/${id}/done`, body)
  },
  cancel(id, body) {
    return api.post(`/partner-licensings/:partnerLicensingId/clinical-cares/${id}/cancel`, body)
  },
  death(id, body) {
    return api.post(`/partner-licensings/:partnerLicensingId/clinical-cares/${id}/death`, body)
  },
  messages(id) {
    return api.get(`/partner-licensings/:partnerLicensingId/clinical-cares/${id}/messages`)
  },
  addMessage(id, body) {
    return api.post(`/partner-licensings/:partnerLicensingId/clinical-cares/${id}/messages`, body)
  },
}

import { LoadManager } from '@/components'
import useBatch from '@/hooks/useBatch'
import usePageTitle from '@/hooks/usePageTitle'
import { setBatch } from '@/reducers/batch'
import batches from '@/services/api/batches'
import React, { useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

function Approve({ title }) {
  usePageTitle({ title })
  const [loading, setLoading] = useState(false)
  const { id } = useParams()
  const { state: { batch }, dispatch } = useBatch()
  const history = useHistory()

  async function approve() {
    try {
      setLoading(true)
      const { data } = await batches.approve(id)
      dispatch(setBatch(data))
      toast.success('Lote aprovado')
      history.goBack()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="modal active" id="modal-id">
      <a type="button" className="modal-overlay" aria-label="Close" onClick={() => history.goBack()} />
      <div className="modal-container">
        <div className="modal-header">
          <a
            type="button"
            className="btn btn-clear float-right"
            onClick={() => history.goBack()}
          />
          <div className="modal-title h5">Aprovar o lote #{id}</div>
        </div>
        <div className="modal-body">
          <div className="content">
            <LoadManager loading={!batch}>
              <>
                <p>Confirma a aprovação do lote <b className="text-primary">#{id}</b>?</p>
                <p className="text-small">
                  Esta ação vai gerar um evento de aprovação e não será reversível.
                </p>
              </>
            </LoadManager>
          </div>
        </div>
        <div className="modal-footer">
          <a
            className="btn mr-2"
            disabled={loading}
            onClick={() => history.goBack()}
          >
            <i className="fas fa-chevron-left" />
            &nbsp;Voltar
          </a>
          <button
            className={`btn btn-success ${loading ? 'loading' : ''}`}
            disabled={loading}
            type="button"
            onClick={approve}
          >
            <i className="fas fa-check" />
            &nbsp;Aprovar
          </button>
        </div>
      </div>
    </div>
  )
}

export default Approve

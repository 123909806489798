import api from './api'

const BASE = '/partner-licensings/:partnerLicensingId/protectors'

export default {
  list(params = {}) {
    return api.get(BASE, { params })
  },
  show(id) {
    return api.get(`${BASE}/${id}`)
  },

  approve(id) {
    return api.post(`${BASE}/${id}/approve`)
  },

  reject(id, body) {
    return api.post(`${BASE}/${id}/reject`, body)
  },

  revoke(id, body) {
    return api.post(`${BASE}/${id}/revoke`, body)
  },
}

import Avatar from '@/components/Avatar'
import Flexbox from '@/components/Flexbox'
import Form from '@/components/Form'
import ListManager from '@/components/ListManager'
import Paginator from '@/components/Paginator'
import QuickFilter from '@/components/QuickFilter'
import useAxiosRequest from '@/hooks/useAxiosRequest'
import useQuery from '@/hooks/useQuery'
import animals from '@/services/api/animals'
import PropTypes from 'prop-types'
import React from 'react'

export default function MyAnimals({ onChange }) {
  const query = useQuery()

  const { data, error, loading } = useAxiosRequest(
    animals.partnerAnimals, { ...(query || {}), take: 5 },
  )

  return (
    <div className="mt-2">
      <QuickFilter
        initialValues={{ name_cont: '' }}
        title="Filtrar meus pets"
        renderForm={() => (
          <Flexbox.Column className="pt-2">
            <Form.Group>
              <Form.Label className="label-sm pt-2" htmlFor="name_cont">Nome do pet</Form.Label>
              <Form.ValidationField
                name="name_cont"
                className="input-sm"
                id="name_cont"
                autoComplete="none"
                placeholder="Totó da Silva"
              />
            </Form.Group>
          </Flexbox.Column>
        )}
      />
      <ListManager loading={loading} count={data?.count || 0} error={error}>
        {
          Boolean(data) && (
            <div>
              <small>
                <table className="table">
                  <thead>
                    <tr>
                      <th>Pet</th>
                      <th width="1">Ações</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      data.rows.map((row) => (
                        <tr key={row.id}>
                          <td>
                            <div>
                              <Avatar
                                avatarSize="sm"
                                className="mr-1"
                                name={row?.name}
                                photoURL={row?.photoURL}
                                alt="Imagem do pet"
                              />
                              {row?.name}
                            </div>
                            <div>
                              <small>
                                {row.category.description} - {row.breed.description}
                              </small>
                            </div>
                          </td>
                          <td className="text-right">
                            <a
                              onClick={() => onChange(row)}
                              disabled={row === row.id}
                              className={`btn btn-action btn-primary btn-sm ${row === row.id ? 'loading' : ''}`}
                            >
                              <i className="fas fa-check" />
                            </a>
                          </td>
                        </tr>
                      ))
                    }
                  </tbody>
                </table>
              </small>
              <Paginator paginatedList={data} />
            </div>
          )
        }
      </ListManager>
    </div>
  )
}

MyAnimals.propTypes = {
  onChange: PropTypes.func.isRequired,
}

import licensingContext from '@/contexts/licensingContext'
import licensingReducer, { INITIAL_STATE } from '@/reducers/licensing'
import Licensed from '@/views/app/ngos/Licensed'
import Requested from '@/views/app/ngos/Requested'
import Show from '@/views/app/ngos/Show'
import Approve from '@/views/app/ngos/Show/Approve'
import Reject from '@/views/app/ngos/Show/Reject'
import Revoke from '@/views/app/ngos/Show/Revoke'
import React, { useReducer } from 'react'
import { Route, useRouteMatch } from 'react-router-dom'

function NgosRoutes() {
  const { path } = useRouteMatch()
  const [state, dispatch] = useReducer(licensingReducer, INITIAL_STATE)

  return (
    <div>
      <Route exact path={`${path}/approved`}>
        <Licensed title="Petis - Parceiros / ONGs licenciadas" />
      </Route>
      <Route exact path={`${path}/requested`}>
        <Requested title="Petis - Parceiros / Licenciar ONGs" />
      </Route>
      <licensingContext.Provider value={{ state, dispatch }}>
        <Route path={`${path}/:status/:id`}>
          <Show title="Petis - Parceiros / Exibir licença" />
        </Route>
        <Route path={`${path}/:status/:id/approve`}>
          <Approve title="Petis - Parceiros / Aprovar ONG" />
        </Route>
        <Route path={`${path}/:status/:id/reject`}>
          <Reject title="Petis - Parceiros / Rejeitar ONG" />
        </Route>
        <Route path={`${path}/:status/:id/revoke`}>
          <Revoke title="Petis - Parceiros / Revogar ONG" />
        </Route>
      </licensingContext.Provider>
    </div>
  )
}

export default NgosRoutes

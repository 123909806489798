import envs from '@/constants/envs'

export function castrationUrl(castration) {
  return `${envs.REACT_APP_BASE_WEB_SHOW_URL}/castration/${castration?.uid}`
}

export function batchUrl(batch) {
  return `${envs.REACT_APP_BASE_WEB_SHOW_URL}/batch/${batch?.uid}`
}

export function rgaUrl(animal) {
  return `${envs.REACT_APP_BASE_WEB_SHOW_URL}/rga/${animal?.uid}`
}

export function clinicalCareUrl(clinicalCare) {
  return `${envs.REACT_APP_BASE_WEB_SHOW_URL}/clinical-care/${clinicalCare?.uid}`
}

import { Flexbox, Form } from '@/components'
import yup from '@/lib/yupPt'
import petisUsers from '@/services/api/petisUsers'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import CurrencyFormat from 'react-currency-format'
import { useParams } from 'react-router-dom'
import TextareaAutosize from 'react-textarea-autosize'
import { toast } from 'react-toastify'

const schema = yup.object().shape({
  numberOfFamilyMembers: yup.number().integer().min(1).required(),
  familyIncome: yup.number().min(1),
  cras: yup.string(),
  nis: yup.string(),
  benefitsReceived: yup.string(),
  otherInformation: yup.string(),
})
export default function LowIncomeForm({ initialData, onUpdate }) {
  const [active, setActive] = useState(false)
  const [loading, setLoading] = useState(false)
  const { id } = useParams()

  const onClose = useCallback(() => {
    setActive(false)
  }, [])

  const onSubmit = async (values) => {
    try {
      setLoading(true)
      const { data } = await petisUsers.createOrUpdateLowIncome(id, values)
      toast.success('Dados atualizados')
      onUpdate(data)
      onClose()
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setLoading(false)
    }
  }

  return (
    <div className="text-left">
      <a className="btn btn-primary btn-sm" onClick={() => setActive(true)}>
        <i className="fas fa-file" />
        &nbsp;Informar dados
      </a>
      {
        active && (
          <fieldset disabled={loading}>
            <Form.Formik
              initialValues={initialData ?? {
                numberOfFamilyMembers: '',
                familyIncome: '',
                cras: '',
                nis: '',
                benefitsReceived: '',
                otherInformation: '',
              }}
              validationSchema={schema}
              onSubmit={onSubmit}
              validateOnMount
            >
              {
                ({
                  touched, errors, setFieldValue, isValid,
                }) => (
                  <Form.ValidationForm>
                    <div className={`modal ${active ? 'active' : ''}`}>
                      <a type="button" className="modal-overlay" aria-label="Close" onClick={onClose} />
                      <div className="modal-container">
                        <div className="modal-header">
                          <a
                            type="button"
                            className="btn btn-clear float-right"
                            onClick={onClose}
                          />
                          <div className="modal-title h5">
                            Dados de baixa renda
                          </div>
                        </div>
                        <div className="modal-body">
                          <Flexbox.Columns className="p-2 align-center">
                            <Flexbox.Column className="col-6 col-sm-12">
                              <Form.Group className={`${touched?.numberOfFamilyMembers && errors?.numberOfFamilyMembers ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="numberOfFamilyMembers">Membros na família <span className="text-error">*</span></Form.Label>
                                <Form.ValidationField
                                  name="numberOfFamilyMembers"
                                  type="number"
                                  step="1"
                                  id="numberOfFamilyMembers"
                                  placeholder="Membros na família"
                                />
                                {
                                  touched?.numberOfFamilyMembers && errors?.numberOfFamilyMembers
                                    ? <Form.Hint className="text-error">{errors.numberOfFamilyMembers}</Form.Hint> : null
                                }
                              </Form.Group>
                            </Flexbox.Column>
                            <Flexbox.Column className="col-6 col-sm-12">
                              <Form.Group className={`${touched?.familyIncome && errors?.familyIncome ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="familyIncome">Renda familiar <span className="text-error">*</span></Form.Label>
                                <Form.ValidationField
                                  name="familyIncome"
                                  id="familyIncome"
                                >
                                  {
                                    ({ field }) => (
                                      <CurrencyFormat
                                        className="form-input"
                                        thousandSeparator="."
                                        decimalSeparator=","
                                        prefix="R$ "
                                        placeholder="R$ 0,00"
                                        value={field.value}
                                        fixedDecimalScale
                                        decimalScale={2}
                                        allowNegative={false}
                                        onValueChange={
                                          ({ value }) => setFieldValue('familyIncome', Number(value))
                                        }
                                      />
                                    )
                                  }
                                </Form.ValidationField>
                                {
                                  touched?.familyIncome && errors?.familyIncome
                                    ? <Form.Hint className="text-error">{errors.familyIncome}</Form.Hint> : null
                                }
                              </Form.Group>
                            </Flexbox.Column>
                            <Flexbox.Column className="col-6 col-sm-12">
                              <Form.Group className={`${touched?.cras && errors?.cras ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="cras">CRAS</Form.Label>
                                <Form.ValidationField
                                  name="cras"
                                  id="cras"
                                  placeholder="Número do CRAS"
                                />
                                {
                                  touched?.cras && errors?.cras
                                    ? <Form.Hint className="text-error">{errors.cras}</Form.Hint> : null
                                }
                              </Form.Group>
                            </Flexbox.Column>
                            <Flexbox.Column className="col-6 col-sm-12">
                              <Form.Group className={`${touched?.nis && errors?.nis ? 'has-error' : ''}`}>
                                <Form.Label htmlFor="nis">NIS</Form.Label>
                                <Form.ValidationField
                                  name="nis"
                                  id="nis"
                                  placeholder="Número do NIS"
                                />
                                {
                                  touched?.nis && errors?.nis
                                    ? <Form.Hint className="text-error">{errors.nis}</Form.Hint> : null
                                }
                              </Form.Group>
                            </Flexbox.Column>
                            <Flexbox.Column className="col-12">
                              <Form.Group className={`${touched?.benefitsReceived && errors?.benefitsReceived ? 'has-error' : ''}`}>
                              <Form.Label htmlFor="benefitsReceived">Benefícios recebidos</Form.Label>
                                <Form.ValidationField
                                  name="benefitsReceived"
                                  id="benefitsReceived"
                                >
                                  {
                                    ({ field }) => (
                                      <TextareaAutosize
                                        name="benefitsReceived"
                                        style={{ resize: 'none' }}
                                        className="form-input"
                                        rows={1}
                                        maxRows={3}
                                        placeholder="Liste os benefícios..."
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...field}
                                      />
                                    )
                                  }
                                </Form.ValidationField>
                              </Form.Group>
                            </Flexbox.Column>
                            <Flexbox.Column className="col-12">
                              <Form.Group className={`${touched?.otherInformation && errors?.otherInformation ? 'has-error' : ''}`}>
                              <Form.Label htmlFor="otherInformation">Outras informações</Form.Label>
                                <Form.ValidationField
                                  name="otherInformation"
                                  id="otherInformation"
                                >
                                  {
                                    ({ field }) => (
                                      <TextareaAutosize
                                        name="otherInformation"
                                        style={{ resize: 'none' }}
                                        className="form-input"
                                        rows={1}
                                        maxRows={3}
                                        placeholder="Liste os benefícios..."
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...field}
                                      />
                                    )
                                  }
                                </Form.ValidationField>
                              </Form.Group>
                            </Flexbox.Column>
                          </Flexbox.Columns>
                        </div>
                        <div className="modal-footer">
                          <button type="submit" disabled={!isValid || loading} className={`btn btn-primary ${loading ? 'loading' : ''}`}>
                            <i className="fas fa-check" />
                            &nbsp;Atualizar dados
                          </button>
                        </div>
                      </div>
                    </div>
                  </Form.ValidationForm>
                )
              }
            </Form.Formik>
          </fieldset>
        )
      }
    </div>
  )
}

LowIncomeForm.propTypes = {
  onUpdate: PropTypes.func.isRequired,
  initialData: PropTypes.objectOf(Object),
}

LowIncomeForm.defaultProps = {
  initialData: null,
}

import { Card, Flexbox, ImageThumb } from '@/components'
import PropTypes from 'prop-types'
import React from 'react'

function Prescritions({ prescritions }) {
  if (!prescritions) return null

  return (
    <Card.Base>
      <Card.Header>
        <Flexbox.Columns className="align-center">
          <Flexbox.Column>
            <div className="h5">Receitas</div>
          </Flexbox.Column>
          <Flexbox.Column className="col-auto">
            <i className="fas fa-syringe" />
          </Flexbox.Column>
        </Flexbox.Columns>
      </Card.Header>
      <Card.Body>
        {!prescritions.length && <p>Nenhuma receita</p>}
        {
          prescritions.map((record) => (
            <div key={record.id} className="mb-2 p-2 bg-gray s-rounded">
              <h5>{record.title}</h5>
              <div className="my-2">
                {
                  record.data.map((i) => (
                    <div key={i.name} className="my-2">
                      <small>
                        <b>{i.name}</b>
                        <div>
                          <span>{i.dosage}</span>
                        </div>
                      </small>
                    </div>
                  ))
                }
              </div>
              <Flexbox.Columns>
                {
                  record.attachments.map((url) => (
                    <Flexbox.Column key={url} className="col-auto">
                      <a href={url} target="_blank" rel="noreferrer">
                        <ImageThumb className="s-rounded" height={50} width={50} src={url} />
                      </a>
                    </Flexbox.Column>
                  ))
                }
              </Flexbox.Columns>
            </div>
          ))
        }
      </Card.Body>
    </Card.Base>
  )
}

Prescritions.propTypes = {
  prescritions: PropTypes.arrayOf(Object).isRequired,
}

export default Prescritions

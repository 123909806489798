import { Styles } from '@/components'
import PropTypes from 'prop-types'
import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import allowedActions from './allowedActions'

function Actions({ mistreatment }) {
  const actions = allowedActions(mistreatment?.status)
  const { pathname } = useLocation()

  if (!actions.length) return null

  return (
    <Styles.ActionsContainer>
      {
        actions.includes('start') && (
          <Link className="btn btn-sm btn-primary mx-1" to={`${pathname}/start-check`}>
            <i className="fas fa-play" />
            &nbsp;Iniciar verificação
          </Link>
        )
      }
      {
        actions.includes('done') && (
          <Link className="btn btn-sm btn-success mx-1" to={`${pathname}/done`}>
            <i className="fas fa-check" />
            &nbsp;Concluir
          </Link>
        )
      }
      {
        actions.includes('reject') && (
          <Link className="btn btn-sm btn-error mx-1" to={`${pathname}/reject`}>
            <i className="fas fa-times" />
            &nbsp;Rejeitar
          </Link>
        )
      }
      {
        actions.includes('cancel') && (
          <Link className="btn btn-sm btn-error mx-1" to={`${pathname}/cancel`}>
            <i className="fas fa-ban" />
            &nbsp;Cancelar
          </Link>
        )
      }
    </Styles.ActionsContainer>
  )
}

Actions.propTypes = {
  mistreatment: PropTypes.objectOf(Object).isRequired,
}

export default Actions

/* eslint-disable react/jsx-no-target-blank */
import { Flexbox, ImageThumb } from '@/components'
import dateMask from '@/lib/masks/date'
import albumsService from '@/services/api/albums'
import PropTypes from 'prop-types'
import React, { useCallback, useState } from 'react'
import { confirmAlert } from 'react-confirm-alert'
import { toast } from 'react-toastify'
import AttachPictures from '../AttachPictures'

function Row({
  albums, album, index, onUpdate, onDeleted,
}) {
  const [disabled, setDisabled] = useState(false)

  const onRemoveImage = useCallback(async (id) => {
    try {
      setDisabled(true)
      await albumsService.detachPicture(album.id, id)
      toast.success('Foto removida')
      onUpdate({ ...album, pictures: album.pictures.filter((i) => i.id !== id) })
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setDisabled(false)
    }
  }, [album, onUpdate])

  const handleRemoveImage = useCallback(async (id) => {
    confirmAlert({
      title: 'Remover foto',
      message: 'Você está certo disso?',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim',
          className: 'bg-error',
          onClick: () => onRemoveImage(id),
        },
      ],
    })
  }, [onRemoveImage])

  const onRemoveAlbum = useCallback(async () => {
    try {
      setDisabled(true)
      await albumsService.destroy(album.id)
      toast.success('Álbum excluido')
      onDeleted(album)
    } catch (e) {
      toast.error(e?.message)
    } finally {
      setDisabled(false)
    }
  }, [album, onDeleted])

  const handleRemoveAlbum = useCallback(async () => {
    confirmAlert({
      title: 'Excluir álbum',
      message: 'Você está certo disso?',
      buttons: [
        { label: 'Não' },
        {
          label: 'Sim',
          className: 'bg-error',
          onClick: onRemoveAlbum,
        },
      ],
    })
  }, [onRemoveAlbum])

  return (
    <div className="mb-2" key={album.id}>
      <div className="mb-1">
        <div className="h6 text-bold">{album.title}</div>
        <div>{album.description}</div>
        <div><small>{dateMask(album.createdAt)}</small></div>
      </div>
      <Flexbox.Columns>
        <AttachPictures
          albumId={album.id}
          onAttach={(pictures) => onUpdate({
            ...album,
            pictures: [...album.pictures, ...pictures],
          })}
        />
        {
          album.pictures.map(({ id, photoURL }) => (
            <Flexbox.Column key={photoURL} className="col-auto p-relative">
              <a
                onClick={() => handleRemoveImage(id)}
                disabled={disabled}
                className="btn btn-sm btn-error btn-action"
                style={{ position: 'absolute', right: 0, top: 0 }}
              >
                <i className="fas fa-trash" />
              </a>
              <a href={photoURL} target="_blank">
                <ImageThumb className="img-responsive s-rounded" width={80} height={80} src={photoURL} />
              </a>
            </Flexbox.Column>
          ))
        }
      </Flexbox.Columns>
      <a onClick={handleRemoveAlbum} className="btn btn-error btn-sm mt-2">
        <i className="fas fa-trash" />
        &nbsp;Excluir álbum
      </a>
      {albums.length > index + 1 && <div className="divider" />}
    </div>
  )
}

Row.propTypes = {
  albums: PropTypes.arrayOf(Object).isRequired,
  album: PropTypes.objectOf(Object).isRequired,
  index: PropTypes.number.isRequired,
  onUpdate: PropTypes.func.isRequired,
  onDeleted: PropTypes.func.isRequired,
}

export default Row
